/* eslint-disable array-callback-return */
import React, { Component } from "react";
import urlMapping from "../../redux/API/api";
import apiCall from "../../redux/API/apiCall";
import { Loader } from "../../layOuts/Loader";
import ErrorHandlePopup from "../ErrorHandlePopup";
import $ from 'jquery';
import { ErrorPopup } from "../../layOuts/errorPopup";
var interval = null;
export default class NetworkView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRefreshModal: false,
      id: this.props.id,
      showLoader: false,
      deviceData: this.props.data,
      toggleStatus: false,
      networkViewData: [],
      networkTableData: [],
      isAxiosError: false,
      networkColumns: [
        {
          name: "SSID",
          value: "SSID",
          minWidth: "150px",
          selector: "SSID",
          sortable: true,
          center: true,
          cell: (row) => (
            <span title={row.SSID}>
              {row.SSID !== "" && row.SSID !== "null" && row.SSID !== null
                ? row.SSID
                : "-"}
            </span>
          ),
        },
        {
          name: "VLAN",
          value: "VLAN",
          minWidth: "150px",
          selector: "VLAN",
          sortable: true,
          center: true,
          cell: (row) => (
            <span title={row.VLAN}>
              {row.VLAN !== "" && row.VLAN !== "null" && row.VLAN !== null
                ? row.VLAN
                : "-"}
            </span>
          ),
        },
        {
          name: "Name",
          value: "name",
          minWidth: "150px",
          selector: "name",
          sortable: true,
          center: true,
          cell: (row) => (
            <span title={row.name}>
              {row.name !== "" && row.name !== "null" && row.name !== null
                ? row.name
                : "-"}
            </span>
          ),
        },
        {
          name: "Network Name",
          value: "networkName",
          minWidth: "150px",
          selector: "networkName",
          sortable: true,
          center: true,
          cell: (row) => (
            <span title={row.networkName}>
              {row.networkName !== "" &&
                row.networkName !== "null" &&
                row.networkName !== null
                ? row.networkName
                : "-"}
            </span>
          ),
        },
        {
          name: "Network IP",
          value: "network_ip",
          minWidth: "150px",
          selector: "network_ip",
          sortable: true,
          center: true,
          cell: (row) => (
            <span title={row.network_ip}>
              {row.network_ip !== "" &&
                row.network_ip !== "null" &&
                row.network_ip !== null
                ? row.network_ip
                : "-"}
            </span>
          ),
        },
        {
          name: "Port",
          value: "port",
          minWidth: "150px",
          selector: "port",
          sortable: true,
          center: true,
          cell: (row) => (
            <span title={row.port}>
              {row.port !== "" && row.port !== "null" && row.port !== null
                ? row.port
                : "-"}
            </span>
          ),
        },
      ],
    };
  }
  componentDidMount() {
    this.getNetworkView();
  }
  componentWillUnmount() {
    clearInterval(interval);
    interval = null;
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (Object.keys(nextProps.data).length !== 0) {
      this.setState({
        deviceData: nextProps.data,
      });
    }
    if (nextProps.id !== this.props.id) {
      this.setState(
        {
          id: nextProps.id,
        },
        () => this.getNetworkView()
      );
    }
  }

  getNetworkView = () => {
    let data = {
      device_id: this.state.id,
    };
    if (String(data.device_id) !== "") {
      this.setState({ showLoader: true });
      apiCall(urlMapping.getNetworkView(data), (response) => {
        this.setState({ showLoader: false });
        if (response?.Success) {
          let networkTableData = response?.data?.filter(
            (element) => element.port !== undefined
          );
          let networkData = response?.data?.filter(
            (item) => item.name !== "Control Tunnel" && item.network_zone !== 'datatunnel'
          )
          this.setState({
            networkViewData: networkData,
            networkTableData
          },
            () => this.savedData()
          );
        } else {
          if (parseInt(response?.response?.status) === 401) {
            this.setState({ showRefreshModal: true })
          } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
            this.setState({
              title: 'Network Error',
              message: 'Something went wrong. Please try again later.',
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          } else {
            let networkTableData = [];
            this.setState({ networkViewData: [], networkTableData });
          }
        }
      });
    }
  };
  savedData = () => {
    let data = {
      device_id: this.state.id,
      data_key: "network"
    };
    this.setState({ showLoader: true });
    apiCall(urlMapping.savedData(data), (response) => {
      if (response?.success) {
        if (Object.keys(response?.data).length > 0) {
          Object.keys(response?.data)?.map((network) => {
            let { networkViewData } = this.state;
            let networkData = networkViewData?.filter(item => item.interfaceName === network)?.[0];
            if (networkData) {
              networkData.ip_address = response?.data?.[network]?.ipAddress;
              networkData.gateway = response?.data?.[network]?.gateway;
              networkData.netmask = response?.data?.[network]?.netmask;
              networkData.subnet = response?.data?.[network]?.subnet;
            }
            networkViewData.filter(item => item.interfaceName === network)[0] = networkData;
            this.setState({
              networkViewData,
              showLoader: false
            })
          })
        } else {
          this.setState({
            showLoader: false
          })
        }
      }
      else {
        if (parseInt(response?.response?.status) === 401) {
          this.setState({
            showRefreshModal: true
          });
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else {
          this.setState({
            showLoader: false
          });
        }
      }
    })
  }
  manageAutoRefresh = (e) => {
    if (e.target.checked === true) {
      interval = setInterval(() => {
        this.setState({ toggleStatus: true });
        this.getNetworkView();
      }, 5000);
    } else {
      clearInterval(interval);
      interval = null;
      this.setState({ toggleStatus: false });
    }
  };
  getFirstRow = () => {
    for (let i = 2; i < 8; i++) {
      return this.state.networkViewData.length > i ? (
        <li className={`top-list-${i - 1}`}>
          <p>
            <strong> {this.state.networkViewData[i].name}</strong>
          </p>
          <div className="n-box">
            <div>Network: {this.state.networkViewData[i].networkName} </div>
            <div>Port: {this.state.networkViewData[i].port} </div>
            <div>VLAN: {this.state.networkViewData[i].VLAN}</div>
            <div>SSID: {this.state.networkViewData[i].SSID}</div>
          </div>
        </li>
      ) : (
        <li className={`top-list-${i - 1} no-data-a`}>
          <p>
            <strong>Network {i - 1}</strong>
          </p>
          <div className="n-box">No Configured</div>
        </li>
      );
    }
  };
  refreshTelemetry = (network) => {
    this.setState({ showLoader: true });
    let network_key = [];
    if (network.bridgeStatus === "Enabled")
      network_key.push(network.Bridge_name);
    else
      network_key.push(network.interfaceName);
    let data = {
      device_ip: this.props.data.device_control_tunnel_ip,
      network_key,
      device_id: this.state.id
    }
    apiCall(urlMapping.get_Global_refresh(data), (response) => {
      this.setState({ showLoader: false });
      if (response?.success) {
        let { networkViewData } = this.state;
        let networkData = networkViewData.filter(item => item.name === network.name)?.[0];
        if (networkData) {
          networkData.ip_address = response?.data?.ip_address;
          networkData.gateway = response?.data?.gateway;
          networkData.netmask = response?.data?.netmask;
          networkData.subnet = response?.data?.subnet;
        }
        networkViewData.filter(item => item.name === network.name)[0] = networkData;
        this.setState({ networkViewData })
      }
      else {
        if (parseInt(response?.response?.status) === 401) {
          this.setState({
            showRefreshModal: true
          });
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else {
          this.setState({
            showLoader: false
          });
        }
      }
    })
  }
  refreshGlobalTelemetry = (network) => {
    this.setState({ showLoader: true });
    let network_key = []
    if (network) {
      if (network.bridgeStatus === "Enabled")
        network_key.push(network.Bridge_name);
      else
        network_key.push(network.interfaceName);
    } else {
      this.state.networkViewData.map((item) => {
        item.protocol === "dhcpclient" && network_key.push((item.interfaceName).toString())
      })
    }
    let data = {
      device_ip: this.props.data.device_control_tunnel_ip,
      network_key,
      device_id: this.state.id
    }
    apiCall(urlMapping.get_Global_refresh(data), (response) => {
      this.setState({ showLoader: false });
      if (response?.success) {
        network_key.map((network) => {
          let { networkViewData } = this.state;
          let networkData = networkViewData.filter(item => item.interfaceName === network)?.[0];
          if (networkData) {
            networkData.ip_address = response?.data?.[network]?.ipAddress;
            networkData.gateway = response?.data?.[network]?.gateway;
            networkData.netmask = response?.data?.[network]?.netmask;
            networkData.subnet = response?.data?.[network]?.subnet;
          }
          networkViewData.filter(item => item.interfaceName === network)[0] = networkData;
          this.setState({ networkViewData })
        })
      }
      else {
        if (parseInt(response?.response?.status) === 401) {
          this.setState({
            showRefreshModal: true
          });
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else {
          this.setState({
            showLoader: false
          });
        }
      }
    })
  }
  render() {
    return (
      <>
        {this.state.showLoader && <Loader />}
        <div className="white-bg pl-2 pr-2">
          {this.state.networkViewData?.length > 0 ?
            <div>
              <div className="ta-box set-pos mb-0">
                <div className="action-btn d-flex align-items-center">
                  <button
                    className="border-0"
                    disabled={this.state.networkViewData?.filter(item => item?.protocol === "dhcpclient")?.length === 0}
                  >
                    <img
                       className={this.state.deviceData?.device_status === 1 ? "" : "disabled"}
                      style={{ width: "25px" }}
                      src="/images/refreshT.png"
                      title="Refresh Telemetry data"
                      onClick={this.state.deviceData?.device_status === 1 ?
                        () => this.refreshGlobalTelemetry() : undefined}
                    /></button>
                  <button className="short-btn" onClick={() => this.getNetworkView()} disabled={this.state.toggleStatus === true} title="Refresh">
                    <img src="/images/svg-icons/refreshing.svg" alt="" /> Refresh
                  </button>
                  <div className="d-flex align-items-center">
                    <label className="switch mr-2 ml-3 z-999"
                      title="Auto Refresh">
                      <input
                        type="checkbox"
                        onClick={(e) => this.manageAutoRefresh(e)}
                      ></input>
                      <span className="slider round"></span>
                    </label>
                    <span className="responsiveAutoRefresh">Auto Refresh</span>
                  </div>
                </div>
              </div>
              <div className="networks-view">
                <div className="row no-gutters">
                  {
                    this.state.networkViewData?.map((item, index) => {
                      return (
                        <div key={index} className="col-md-3 col-sm-6 mb-3">
                          <div className="network-card rounded">
                            <div className="network-header rounded-top d-flex justify-content-between p-2">
                              <div className="">
                                Network Name <strong>{item?.name}</strong>
                              </div>
                              {item?.protocol === 'dhcpclient' &&
                                <img 
                                className={this.state.deviceData?.device_status === 1 ? "img-telemetry" : "img-telemetry disabled"}
                                  style={{ width: "25px", cursor: "pointer" }}
                                  src="/images/refreshT.png"
                                  title="Refresh Telemetry data"
                                  onClick={this.state.deviceData?.device_status === 1 ?
                                    () => this.refreshGlobalTelemetry(item) : undefined}
                                />}
                              <div className="text-right">
                                Protocol{" "}
                                <strong>
                                  {item?.protocol === "staticAddr"
                                    ? "Static Address"
                                    : item?.protocol !== "ippt" ? "DHCP Client" : "IPPT"}
                                </strong>
                              </div>
                            </div>
                            <div className="p-2 network-body">
                              <ul>
                                {item?.protocol !== "ippt" && <><li>
                                  <span>IP Address:</span>
                                  <strong>
                                    {item?.ip_address ? item?.ip_address : "--"}
                                  </strong>
                                </li>
                                  <li>
                                    <span>MTU:</span>
                                    <strong>
                                      {item?.mtu ? item?.mtu : "--"}
                                    </strong>
                                  </li></>}
                                <li>
                                  <span>Netmask:</span>
                                  <strong>
                                    {item?.netmask ? item?.netmask : "--"}
                                  </strong>
                                </li>
                                <li>
                                  <span>Gateway:</span>
                                  <strong>
                                    {item?.gateway ? item?.gateway : "--"}
                                  </strong>
                                </li>
                                {/* {item.protocol !== "ippt" &&  */}
                                {item?.protocol !== "ippt" && <><li>
                                  <span>DNS:</span>
                                  <strong>{item?.DNS ? item?.DNS : "--"}</strong>
                                </li>
                                  <li>
                                    <span>DHCP Server:</span>
                                    <strong>
                                      {item?.dhcpServer === "1"
                                        ? "Enabled"
                                        : "Disabled"}
                                    </strong>
                                  </li>
                                  {item?.dhcpServer === "1" && (
                                    <li>
                                      <span>Range:</span>
                                      <strong>{item?.dhcp_range}</strong>
                                    </li>
                                  )}
                                  {item?.dhcpServer === "1" && (
                                    <li>
                                      <span>Lease Time:</span>
                                      <strong>{item?.leaseTime}</strong>
                                    </li>
                                  )}
                                  <li>
                                    <span>Bridge:</span>
                                    <strong>{item?.bridgeStatus}</strong>
                                  </li>
                                  <li>
                                    <span>Type:</span>
                                    <strong>
                                      {item?.network_zone.toUpperCase()}
                                    </strong>
                                  </li>
                                  <li>
                                    <span>VLAN ID:</span>
                                    <strong>{item?.VLAN ? item?.VLAN : "--"}</strong>
                                  </li>
                                  {item.name === "Default" &&
                                    <li>
                                      <span>Incremental IP</span>
                                      <input type="checkbox"
                                        checked={item?.is_auto_increment && item?.is_auto_increment === "1" ? true : false}
                                        style={{ cursor: "not-allowed" }}
                                      />
                                    </li>
                                  } </>}
                                {item.protocol === "ippt" && <>
                                  <li>
                                    <span>Static Route Destination IP</span>
                                    <span className="ippt-network-span-view">{typeof item?.staticRouteDestinationIP === 'string' && item?.staticRouteDestinationIP?.split(',')?.join(' ')}</span>
                                  </li>
                                  <li>
                                    <span>Lease Time</span>
                                    <span>{item?.leaseTime} mins</span>
                                  </li>
                                  <li>
                                    <span>Type:</span>
                                    <strong>
                                      {item?.network_zone.toUpperCase()}
                                    </strong>
                                  </li>
                                  <li>
                                    <span>Masquerade</span>
                                    <label className="switch gray mt-2"><input type="checkbox" checked={item?.masquerade === '1'} /><span className="slider round" style={{ cursor: "not-allowed" }}></span></label>
                                  </li>
                                  <li>
                                    <span>MAC ACL</span>
                                    <span className="ippt-network-span-view">{typeof item?.macacl === 'string' && item?.macacl?.split(',')?.join(' ')}</span>
                                  </li>
                                </>}
                              </ul>
                            </div>
                            <div className="network-footer">
                              <div className="border-top row no-gutters align-items-center">
                                <div className="col-sm-6 p-2">
                                  <strong>Mapped SSID</strong>
                                </div>
                                <div className="col-sm-6 border-left p-2 mssid-value">
                                  <div className="nf-list">
                                    {/* LONG-1509_Added comma to seperate diffrent ssid*/}
                                    {item?.ssid ? (
                                      <span>{item?.ssid.join(" ")}</span>
                                    ) : (
                                      <span>NA</span>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="border-top row no-gutters align-items-center">
                                <div className="col-sm-6 p-2">
                                  <strong>Mapped Interface</strong>
                                </div>
                                <div className="col-sm-6 border-left p-2 mi-value">
                                  <div className="nf-list">
                                    <strong>Bridge Name</strong>
                                    {item?.Bridge_name ? (
                                      <span>{item?.Bridge_name}</span>
                                    ) : (
                                      <span>NA</span>
                                    )}
                                  </div>
                                  <div className="nf-list">
                                    <strong>Interface Added</strong>
                                    {item?.interfaceName ? (
                                      <span>{item?.interfaceName}</span>
                                    ) : (
                                      <span>NA</span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                </div>
              </div>

            </div>
            :
            <div className="no-tunnels">
              No networks found for this device
            </div>
          }
        </div>
        {this.state.showRefreshModal && <ErrorHandlePopup />}
        {(this.state.isAxiosError) &&
          <ErrorPopup
            title={this.state.title}
            message={this.state.message}
            error={this.state.error}
            delete={this.state.delete}
            reset={() => this.setState({ isAxiosError: false })}
          />
        }
      </>
    );
  }
}