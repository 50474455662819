/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import SideMenu from "../../layOuts/SideMenu";
import Header from "../../layOuts/Header";
import ApiCall from "../../redux/API/apiCall";
import urlMapping from "../../redux/API/api";
import { Loader } from "../../layOuts/Loader";
import moment from "moment";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import $ from "jquery";
import { ErrorPopup } from "../../layOuts/errorPopup";
import ConGraph from "./conGraph";
import Mapnew from "./mapnew";
import AppliedTemplateList from "./AppliedTemplateList";
import ConnectedClientList from "./connectedClientList";
import ModemInfo from "./ModemInfo";
import NetworkView from "./NetworkView";
import { LinkPopup } from "../../layOuts/linkPopup";
import * as Common from "../../utils/common";
import ErrorHandlePopup from "../ErrorHandlePopup";
import Troubleshoot from "../troubleshoot/Troubleshoot";
import * as Action from "../../redux/actionTypes";
import DeviceCheckpoint from "./DeviceCheckpoint";
import TunnelsView from "./TunnelsView";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Report from "./Report";
import { Calendar } from 'primereact/calendar';
import Chronology from "./Chronology";
import DataPolling from "./DataPolling";
import DropDown from "./DropDown";

class DeviceDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRefreshModal: false,
      showLoader: false,
      //EMGT-322-EM page is getting blank
      tabChange: this?.props?.history?.location?.state?.tabChange ? this?.props?.history?.location?.state?.tabChange : false,
      selectedTab: this?.props?.history?.location?.state?.selectedTab ? this?.props?.history?.location?.state?.selectedTab : "Dashboard",
      deviceDetails: this?.props?.location?.state ? this?.props?.location?.state : undefined,
      showAppliedAction: false,
      title: "",
      message: "",
      show: false,
      error: false,
      delete: false,
      dataReset: false,
      showList: false,
      deviceData: [],
      deviceModel: "",
      graphData: [],
      changeWithDrop: "",
      option: {},
      selectedDuration: 1,
      selectedDurationForCon: 1,
      totalDownTime: "",
      endDate: "",
      startDate: "",
      endDateCon: "",
      conData: [],
      alertWarning: [],
      alertPageCount: 0,
      pageNoAlert: 1,
      pageSizeAlert: 10,
      startDateCon: "",
      keyUpdate: "",
      addressDetail: [],
      center: [],
      last24DataUsage: "",
      appliedTemplateList: [],
      applyTemplateData: {},
      openApplyTemplate: true,
      pageNo: 1,
      pageSize: 10,
      selectedOption: [],
      alertWarningCount: {},
      deviceStat: {},
      totalCountWarningAndAlert: "",
      totalCount: 0,
      view: false,
      startDate: '',
      endDate: '',
      // firstMonth: "",
      // secondMonth: "",
      // thirdMonth: "",
      // firstValue: "",
      // secondValue: "",
      // thirdValue: "",
      device_id: this?.props?.location?.state?.data?.device_id ? this?.props?.location?.state?.data?.device_id : "",
      mapAddress: "",
      DeviceList: [],
      DeviceLookUpDeviceList: [],
      editName: "",
      series: [],
      wanData: [],
      isConnected: false,
      is_bolt_reset: false,
      showFixed: false,
      showPassword: false,
      showWebUiPassword: false,
      copied: false,
      webUiCopied: false,
      is_fixed_ip: false,
      dudate: "",
      bandwidthFullView: false,
      connectivityFullView: false,
      mapFullView: false,
      selected_con_time: "24 Hrs.",
      retain: {},
      deviceList: [],
      deviceOptions: [],
      dataUsage_Conf: 0,
      redirect: false,
      wancheckNetworks: false,
      selected_Tab: "",
      deviceSeverity: "",
      tabValue: false,
      startTime: moment().subtract(1, 'h'),
      endTime: moment(),
      status: 'green',
      dataUsageType: "daily",
      selectedUsageOption: "cellular_data_usage",
      selectedDuration: 1,
      custom: false,
      diffDays: 0,
      offlineDevice: false,
      isAxiosError: false,
      mapUpdate: Math?.random(),
      responseData: 0,
      cpuResponseData: 0,
      selectedLayer: 'Default'
    };
  }

  returnClassStatus = (template_status) => {
    switch (template_status) {
      case "Successful":
        return "status completed";
      case "Deleted":
        return "status offline";
      case "Pending":
        return "status pending";
      case "Failed":
        return "status offline";
      default:
        return "status offline";
    }
  };

  returnStatus = (template_status) => {
    switch (template_status) {
      case "Successful":
        return "Successful";
      case "Deleted":
        return "Deleted";
      case "Pending":
        return "Pending";
      case "Failed":
        return "Failed";
      default:
        return "Pending";
    }
  };

  async componentDidMount() {
    await this.getDeviceLookUpDeviceList();
    if (this?.props?.location?.state) {
      this.setState(
        {
          device_id: this?.props?.location?.state?.data?.device_id,
          selectedUsageOption: Common.getModels(this?.props?.location?.state?.data?.device_model) !== 'vCPE' ? "cellular_data_usage" : "wan_data_usage",
        },
        () => {
          this.refresh();
        }
      );
    } else {
      this.props.history.push("/devices/configured");
    }
    //EMGT-322-EM page is getting blank
    if (this?.props?.location?.state?.filterData?.deviceModel || this?.props?.location?.state?.filterData?.status) {
      this.setState({
        retain: {
          item1: this?.props?.location?.state?.filterData?.deviceModel,
          item2: this?.props?.location?.state?.filterData?.deviceId,
          item3: this?.props?.location?.state?.filterData?.status,
          deviceName: this?.props?.location?.state?.deviceName,
          selectedUsageOption: this?.props?.location?.state?.filterData?.deviceModel !== 'vCPE' ? "cellular_data_usage" : "wan_data_usage",
        }
      })
    }
    if (this.state.tabChange === true) {
      $("#pills-dashboard-tab").attr('class', 'nav-link')
      $("#pills-templates-tab").attr('class', 'nav-link active')
      $("#pills-dashboard").attr('class', 'tab-pane fade')
      $("#pills-templates").attr('class', 'tab-pane fade active show')
      this.setState({
        tabChange: false
      })
    }
  }
  getDeviceList = () => {
    let device = [];
    {
      this.state.DeviceLookUpDeviceList &&
        this.state.DeviceLookUpDeviceList.length > 0 &&
        this.state.DeviceLookUpDeviceList.sort((a, b) =>
          a.device_name.localeCompare(b.device_name)
        ).map((item) => {
          device.push({
            value: this.state.deviceData.device_id,
            label: this.state.deviceData.device_name,
            icon: (
              <i
                className={
                  this.state.deviceData.device_status === 1 ? "status online" : "status offline"
                }
              ></i>
            ),
          });
        });
    }
    let defaultValue = [];
    //EMGT-316-EM - Randomly, Device status in the drop down is incorrect
    defaultValue.push({
      value: this.state.deviceData.device_id,
      label: this.state.deviceData.device_name,
      icon: (
        <i
          className={
            this.state.deviceData.device_status === 1
              ? "status online"
              : "status offline"
          }
        ></i>
      ),
    });
    this.getDeviceLookUpDeviceList();
    this.setState({
      deviceOptions: device,
      deviceList: defaultValue,
    });
  };
  getDeviceLookUpDeviceList = async () => {
    this.setState({ showLoader: true });
    await ApiCall(urlMapping.deviceLookUp(), (response) => {
      this.setState({
        showLoader: false,
      });
      if (response?.data) {
        const data = response?.data;
        this.setState({
          DeviceLookUpDeviceList: data,
          count: response?.count,
        });
      } else {
        if (response?.response?.status === 401) {
          this.setState({
            showRefreshModal: true,
          });
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
      }
    });
  };
  openOperationList = (value) => {
    $("#exampleModal").modal("hide");
    this.props.history.push({
      pathname: "/operations",
      state: { data: value },
    });
  };
  closeLinkpopup = () => {
    $("#exampleModal").modal("hide");
    this.setState({ showLink: false, id: "", show: false });
  };
  refresh = async () => {
    this.setState({
      conData: [],
      selectedDurationForCon: 1,
    })
    const selected = this.state.selectedTab;
    if (selected === "Dashboard") {
      this.getAddressAndLocation();
      this.getDeviceSeverity();
      // this.setDropdown();
      await this.getDeviceDetails();
      // this.getDeviceUsageData();
      this.updatePolling();
      this.getDeviceUpTime(1);
    } else {
      await this.getDeviceDetails(true);
    }
  };
  getDeviceUsageData = (e) => {
    this.setState({
      startDate: '',
      endDate: '',
    })
    let endDate = moment().format('YYYY-MM-DD HH:mm');
    let startDate = moment().format('YYYY-MM-DD HH:mm');
    startDate = moment(startDate)
    startDate = startDate
      // .set({ minute: 0, second: 0, millisecond: 0 })
      .format("YYYY-MM-DD HH:mm");
    // start_date = moment.utc(start_date).format('YYYY-MM-DD HH');
    endDate = moment(endDate);
    endDate = endDate.format('YYYY-MM-DD HH:mm');
    let day = e ? parseInt(e.target.value) : 1;
    if (!isNaN(day)) {
      let edate;
      this.setState({ selectedDuration: day })
      // if (e === 1) {
      // if (moment().format("HH:mm") > moment().format("HH")) {
      //   edate = moment.utc().add(1, "hours").format("YYYY-MM-DD HH:mm")
      // }
      // else {
      edate = moment().format("YYYY-MM-DD HH:mm")
      // }
      // }
      // endDate = e === 1 ? edate : moment.utc().format('YYYY-MM-DD HH:mm')

      // startDate = day === 1 ? moment.utc(edate).subtract(23, 'hours').format("YYYY-MM-DD HH:mm") : day === 7 ?
      //   moment.utc().subtract(7, 'days').format('YYYY-MM-DD HH:mm') : moment.utc().subtract(30, 'days').format('YYYY-MM-DD HH:mm')
      switch (day) {
        case 1:
          startDate = this.state.dataUsageType === 'daily' ?
            moment(edate)
              // .set({ minute: 0, second: 0, millisecond: 0 })
              .subtract(24, 'hours').format("YYYY-MM-DD HH:mm") :
            moment(edate).subtract(24, 'hours').format("YYYY-MM-DD HH:mm");
          break;
        case 7:
          // startDate = this.state.dataUsageType === 'daily' ?
          // moment.utc().subtract(8, 'days').format('YYYY-MM-DD HH:mm') :
          startDate = moment().subtract(6, 'days').format('YYYY-MM-DD HH:mm');
          break;
        case 30:
          // startDate = this.state.dataUsageType === 'daily' ?
          // moment.utc().subtract(31, 'days').format('YYYY-MM-DD HH:mm') :
          startDate = moment().subtract(29, 'days').format('YYYY-MM-DD HH:mm');
          break;
      }
      this.setState({
        startDate: startDate,
        endDate: endDate,
        custom: false,
        diffDays: 0
      }, () => this.getDeviceUsage())
    } else {
      this.setState({
        custom: true,
        startDate: '',
        selectedDuration: e.target.value
      })
    }
  }
  getAddressAndLocation = () => {
    let data = { device_id: this.state.device_id };
    ApiCall(urlMapping.getAddresbyId(data), (response) => {
      if (response?.success && Object.keys(response?.data)?.length) {
        // EMGT-110 : GPS Map not reporting accurate location of device
        this.setState(
          {
            addressDetail: response?.data,
            // message: response?.message ? response?.message : "",
            keyUpdate: Math?.random(),
          },
          () => {
            this.savedData("location")
          }
        );
      } else {
        if (parseInt(response?.response?.status) === 401) {
          this.setState({
            showRefreshModal: true,
          });
        }
        else {
          this.setState({
            addressDetail: '',
            mapAddress: ''
          });
        }
      }
    });
  };
  setpositon = async () => {
    let x = "";
    if (this.state?.addressDetail?.latitude && this.state?.addressDetail?.longitude) {
      if (this.state?.addressDetail?.latitude !== "" && this.state?.addressDetail?.longitude !== "") {
        await $.get(
          `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${this.state.addressDetail.latitude}&lon=${this.state.addressDetail.longitude}`,
          function (data) {
            x = data;
          }
        );
        let array = [
          this.state?.addressDetail?.latitude,
          this.state?.addressDetail?.longitude,
        ];
        this.setState({
          center: array,
          mapAddress: x,
          showLoader: false,
          mapUpdate: Math?.random()
        });
      }
    }
    else {
      this.setState({
        center: [],
        mapAddress: '',
        showLoader: false,
        mapUpdate: Math?.random()
      });
    }
  };

  getDeviceDetails = async (refresh) => {
    if (this.state.deviceDetails !== undefined) {
      this.setState({
        showLoader: true,
      });
      const data = {
        device_id: this.state.device_id,
      };
      await ApiCall(urlMapping.getDeviceDetails(data), (response) => {
        this.setState({
          showLoader: false,
        });
        if (response?.success) {
          let model = Common.getModels(response?.data?.[0]?.device_model);
          if (model === "vCPE") {
            if (this.state.selectedTab === 'ConnectedClient' || this.state.selectedTab === 'ModemInfo') {
              if (this.state.selectedTab === 'ConnectedClient') {
                $("#pills-cd-tab").attr('class', 'nav-link')
                $("#pills-cd").attr('class', 'tab-pane fade')
              }
              else if (this.state.selectedTab === 'ModemInfo') {
                $("#pills-modeminfo-tab").attr('class', 'nav-link')
                $("#pills-modeminfo").attr('class', 'tab-pane fade')
              }
              $("#pills-dashboard-tab").attr('class', 'nav-link active')
              $("#pills-dashboard").attr('class', 'tab-pane fade active show')
              this.setState({
                selectedTab: 'Dashboard'
              })
            }
          }
          else if (model === 'Packetfence') {
            if (this.state.selectedTab === 'ConnectedClient' || this.state.selectedTab === 'ModemInfo'
              || this.state.selectedTab === 'AppliedTemplate' || this.state.selectedTab === 'Networks'
              || this.state.selectedTab === 'Tunnels' || this.state.selectedTab === 'Device-Checkpoint'
              || this.state.selectedTab === 'Polling') {
              if (this.state.selectedTab === 'ConnectedClient') {
                $("#pills-cd-tab").attr('class', 'nav-link')
                $("#pills-cd").attr('class', 'tab-pane fade')
              }
              else if (this.state.selectedTab === 'ModemInfo') {
                $("#pills-modeminfo-tab").attr('class', 'nav-link')
                $("#pills-modeminfo").attr('class', 'tab-pane fade')
              }
              else if (this.state.selectedTab === 'AppliedTemplate') {
                $("#pills-templates-tab").attr('class', 'nav-link')
                $("#pills-templates").attr('class', 'tab-pane fade')
                $("#pills-dashboard-tab").attr('class', 'nav-link active')
              }
              else if (this.state.selectedTab === 'Networks') {
                $("#pills-Networks-tab").attr('class', 'nav-link')
                $("#pills-Networks").attr('class', 'tab-pane fade')
              }
              else if (this.state.selectedTab === 'Tunnels') {
                $("#pills-Tunnels-tab").attr('class', 'nav-link')
                $("#pills-Tunnels").attr('class', 'tab-pane fade')
              }
              else if (this.state.selectedTab === 'Device-Checkpoint') {
                $("#pills-Device-Checkpoint-tab").attr('class', 'nav-link')
                $("#pills-Device-Checkpoint").attr('class', 'tab-pane fade')
              }
              else if (this.state.selectedTab === 'Polling') {
                $("#pills-Polling-tab").attr('class', 'nav-link')
                $("#pills-Polling").attr('class', 'tab-pane fade')
              }
              $("#pills-dashboard-tab").attr('class', 'nav-link active')
              $("#pills-dashboard").attr('class', 'tab-pane fade active show')
              this.setState({
                selectedTab: 'Dashboard'
              })

            }
          }
          this.setState({
            deviceData: response?.data?.[0],
            deviceModel: model,
            is_fixed_ip: response?.data?.[0]?.is_fixed_ip,
            selectedUsageOption: model !== 'vCPE' ? "cellular_data_usage" : "wan_data_usage",
          }, () => {
            if (this.state.selectedTab === "Dashboard") {
              this.getDeviceUsageData();
              if (refresh) {
                this.setState({
                  conData: [],
                  selectedDurationForCon: 1,
                }, () => this.getDeviceUpTime(1))
              }
              this.savedData('port_status');
            }
          });
        } else {
          if (parseInt(response?.response?.results) === 401) {
            this.setState({
              showLoader: false,
              showRefreshModal: true
            });
          }
          else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
            this.setState({
              title: 'Network Error',
              message: 'Something went wrong. Please try again later.',
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          }
          else {
            this.props.history.push("/devices/configured");
          }
        }
      });
      //EMGT-316-EM - Randomly, Device status in the drop down is incorrect
      this.getDeviceList();
      this.state.wancheckNetworks && this.checkNetworks()
    }
  };
  savedData = (value) => {
    let data = {
      device_id: this.state.device_id,
      data_key: value
    };
    this.setState({ showLoader: true });
    ApiCall(urlMapping.savedData(data), (response) => {
      if (response?.success) {
        if (value === "location") {
          this.setState(
            {
              addressDetail: response?.data,
              keyUpdate: Math.random(),
            },
            () => {
              this.setpositon()
            }
          );
        }
        else {
          let { deviceData } = this.state;
          deviceData.eth_port_status = response?.data
          this.setState({
            deviceData,
            showLoader: false
          })
        }
      }
      else {
        if (parseInt(response?.response?.status) === 401) {
          this.setState({
            showRefreshModal: true,
            showLoader: false
          });
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else {
          this.setState({
            showLoader: false
          });
        }
      }
    })
  }
  confirmFactoryReset = () => {
    if (this.state.deviceData.device_status === 1) {
      this.setState(
        {
          title: "Bootstrap Reset Device",
          message:
            //LONG-3580
            "Bootstrap Reset operation will remove all the applied configurations, delete all tunnels established and the device will maintain the ZTP key files. ZTP process is not needed as device will come back online to EM automatically. Click OK to proceed or Click Cancel to cancel the operation.",
          show: true,
          error: true,
          delete: true,
          factoryReset: true,
        },
        () => {
          $("#errorMessage").modal("show");
        }
      );
    } else {
      this.setState(
        {
          title: "Bootstrap Reset Devices",
          message: "Device must be online for bootstrap reset",
          show: true,
          error: true,
          delete: false,
        },
        () => $("#errorMessage").modal("show")
      );
    }
  };
  confirmReboot = () => {
    if (this.state.deviceData.device_status === 1) {
      this.setState(
        {
          title: "Reboot Device",
          message:
            "Reboots the device and bring back device online to the EM automatically. Click OK to proceed or Click Cancel to cancel the operation.",
          show: true,
          error: true,
          delete: true,
          reboot: true,
        },
        () => {
          $("#errorMessage").modal("show");
        }
      );
    } else {
      this.setState(
        {
          title: "Reboot Devices",
          message: "Device must be online for reboot",
          show: true,
          error: true,
          delete: false,
        },
        () => $("#errorMessage").modal("show")
      );
    }
  };
  confirmDelete = () => {
    let popupMessage = "";
    if (this.state.deviceData.group_name !== "") {
      popupMessage = "This device is present in the" + " \"" + `${this.state.deviceData.group_name}` + "\" " + "device group. Please remove it from the device group before deleting.";
      this.setState(
        {
          title: "Factory Reset Device",
          message: popupMessage,
          show: true,
          error: true,
          delete: false,
        },
        () => {
          $("#errorMessage").modal("show");
        }
      );
    }
    else {
      if (this.state.deviceData.device_status === 1) {
        popupMessage = "Factory Reset operation will remove all the applied configurations, delete all tunnels established, removes ZTP key files in the device, reset the BOLT and the device will go into factory state. ZTP process is needed to bring back device online to the EM. Click OK to proceed or Click Cancel to cancel the operation.";
        this.setState(
          {
            title: "Factory Reset Device",
            message: popupMessage,
            show: true,
            error: true,
            delete: true,
            offlineDevice: false
          },
          () => {
            $("#errorMessage").modal("show");
          }
        );
      }
      else {
        popupMessage =
          "The device is offline.\nClick \"Yes\" to clear the device from this EM immediately.Factory resets won't be performed on the device side."
        this.setState(
          {
            title: "Factory Reset Device",
            message: popupMessage,
            show: true,
            error: true,
            info: true,
            delete: true,
            offlineDevice: true
          },
          () => {
            $("#errorMessage").modal("show");
          }
        );
      }
    }
  };
  checkDeviceTask = async () => {
    let deviceMac = [];
    deviceMac.push(this.state.deviceData.device_mac);

    let data = { device_mac: deviceMac, operation_type: ["Firmware Upgrade"] };
    this.setState({
      showLoader: true, show: false,
    });
    $("#errorMessage").modal("hide");

    const trueArr = [];
    await ApiCall(urlMapping.checkDeviceTask(data), (response) => {
      this.setState({
        showLoader: false,
      });
      if (response?.success === true) {
        const data = response?.data;
        Object.keys(data)?.forEach((item) => {
          if (data?.[item]?.status === true) {
            trueArr?.push(data?.[item]);
          }
        });
        if (trueArr?.length > 0) {
          this.setState(
            {
              title: "Device Task",
              message: trueArr?.[0]?.message,
              show: true,
              error: true,
              delete: false,
              toggledClearRows: !this.state.toggledClearRows,
            },
            () => $("#errorMessage").modal("show")
          );
        }
        this.setState({
          // show: false,
          error: false,
          delete: false,
        });
      } else {
        $("#errorMessage").modal("hide");
        if (parseInt(response?.response?.status) === 401) {
          this.setState({
            showRefreshModal: true,
          });
        } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        } else {
          this.setState(
            {
              title: "Device Task",
              message: response?.message,
              show: true,
              error: true,
              delete: false,
            },
            () => $("#errorMessage").modal("show")
          );
        }
      }
    });
    return trueArr;
  };
  deleteDevices = async () => {
    let data = { device_ids: [this.state.deviceData.device_id] };
    const haveTask = await this.checkDeviceTask();
    this.setState({
      showLoader: true,
    });
    if (haveTask.length === 0) {
      ApiCall(urlMapping.deleteDevice(data), (response) => {
        this.setState({
          show: false
        }, () => $("#errorMessage").modal("hide"));
        if (response?.success === true) {
          this.setState(
            {
              title: "Delete Device",
              message: response?.message,
              show: true,
              error: false,
              delete: false,
              showLoader: false,
              redirect: true
            },
            () => {
              $("#errorMessage").modal("show");
            }
          );
        } else {
          $("#errorMessage").modal("hide");
          if (parseInt(response?.response?.status) === 401) {
            this.setState({
              showRefreshModal: true,
              showLoader: false
            });
          }
          else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
            this.setState({
              title: 'Network Error',
              message: 'Something went wrong. Please try again later.',
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          }
          else {
            this.setState(
              {
                title: "Delete Devices",
                message: response?.message,
                show: true,
                error: true,
                showLoader: false,
                delete: false
              },
              () => $("#errorMessage").modal("show")
            );
          }
        }
      });
    } else {
      this.setState({
        showLoader: false,
      });
    }
  };

  getDeviceUsage = async (e) => {
    this.setState({ showLoader: true })
    let start_date = moment(this.state.startDate);
    let end_date =
      //  this.state.dataUsageType === 'daily' ? moment(this.state.endDate)
      // .set({ minute: 0, second: 0, millisecond: 0 }).format('YYYY-MM-DD HH:mm') :
      moment(this.state.endDate);
    if (this.state.dataUsageType === 'daily') {
      // if (this.state.selectedDuration === 1) {
      //     start_date = moment.utc(new Date().setHours(0, 0, 0, 0)).format("YYYY-MM-DD HH:mm");
      // }
      // if (this.state.diffDays === 1) {
      //     start_date = moment.utc(start_date).format("YYYY-MM-DD HH:mm");
      // }     
      start_date = this.state.selectedDuration === 1
        // || this.state.diffDays === 1 
        ? moment(start_date).set({ minute: 59, second: 59, millisecond: 0 }).format("YYYY-MM-DD HH:mm") :
        moment(start_date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format("YYYY-MM-DD HH:mm");
      start_date = moment.utc(new Date(start_date));
      start_date = start_date.format('YYYY-MM-DD HH:mm');
      end_date = moment.utc(new Date(end_date));
      end_date = this.state.custom ? end_date.add(1, 'days').format('YYYY-MM-DD HH:mm') : end_date.format('YYYY-MM-DD HH:mm');
      let data = {
        device_id: this.state.device_id,
        day_no: this.state.custom ? this.state.diffDays + 1 : this.state.selectedDuration,
        start_date,
        // .set({ minute: 0, second: 0, millisecond: 0 }).format('YYYY-MM-DD HH:mm'),
        end_date,
      };
      this.setState({ showLoader: true })
      await ApiCall(urlMapping.getDeviceDelta(data), (response) => {
        this.setState({ showLoader: false })
        if (response?.success) {
          this.setState({ graphData: response?.data }, () => this.graphData())
        } else {
          if (parseInt(response?.response?.status) === 401) {
            this.setState({ showErrorModal: true })
          } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
            this.setState({
              title: 'Network Error',
              message: 'Something went wrong. Please try again later.',
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          } else {
            this.setState({
              showLoader: false,
              graphData: [],
            }, () => this.graphData());
          }
        }
      })
    } else {
      start_date = this.state.selectedDuration === 1
        // || this.state.diffDays === 1
        ? moment(start_date).set({ minute: 59, second: 59, millisecond: 0 }).format("YYYY-MM-DD HH:mm") :
        moment(start_date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format("YYYY-MM-DD HH:mm");
      start_date = moment.utc(new Date(start_date));
      start_date = start_date.format('YYYY-MM-DD HH:mm');


      // start_date = moment.utc(new Date(start_date))
      //       start_date = start_date.set({ minute: 0, second: 0, millisecond: 0 }).format("YYYY-MM-DD HH:mm");
      // start_date = moment.utc(start_date).format('YYYY-MM-DD HH');
      end_date = moment.utc(new Date(end_date));
      end_date = this.state.custom ? end_date.add(1, 'days').format('YYYY-MM-DD HH:mm') : end_date.format('YYYY-MM-DD HH:mm');
      let data = {
        device_id: this.state.device_id,
        day_no: this.state.custom ? this.state.diffDays + 1 : this.state.selectedDuration,
        start_date,
        // .set({ minute: 0, second: 0, millisecond: 0 }).format('YYYY-MM-DD HH:mm'),
        end_date,
      };
      await ApiCall(urlMapping.deviceDataUsage(data), (response) => {
        if (response?.success) {
          this.setState({
            graphData: response?.data,
            showLoader: false
          }, () => this.graphData());
        } else {
          if (parseInt(response?.response?.results) === 401) {
            this.setState({
              showRefreshModal: true,
              showLoader: false,
              graphData: [],
            }, () => this.graphData());
          } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
            this.setState({
              title: 'Network Error',
              message: 'Something went wrong. Please try again later.',
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          } else {
            this.setState({
              showLoader: false,
              graphData: [],
            }, () => this.graphData());
          }
        }
      });
    }
  };

  getTimeExact = (time, val) => {
    let date = moment.utc(time);
    date = date.local();
    return date.format(val ? 'YYYY-MM-DD HH:mm:ss' : 'DD MMMM YYYY hh:mm:ss A');
  }

  getTime = (time, lastValue) => {
    // get local time
    let date = moment.utc(time);
    date = date.local();
    // if its daily.day data ignore minutes in time 
    if (this.state.selectedDuration === 1
      // || this.state.diffDays === 1
    ) {
      if (this.state.dataUsageType === 'daily')
        date = date.set({ minute: 0, second: 0, millisecond: 0 }).format('DD MMMM HH:mm')
      else
        date = date.format('DD MMMM HH:mm')
    } else
      date = date.format('DD MMMM');
    return date;
  }
  onDropChange = (e) => {
    if (e) {
      this.props.dispatch({
        type: Action.DROPDOWN_CHANGE,
        payload: e.value,
      });
      let device = [];
      device.push(e);
      this.setState(
        {
          //LONG-2930-EM - Front-end Issues 4, Able to copy device password only
          copied: false,
          webUiCopied: false,
          //LONG-3017 : GUI password issues
          showPassword: false,
          showWebUiPassword: false,
          device_id: e.value,
          keyUpdate: Math.random(),
          deviceList: device,
          //EMGT-575-EM - Device name validation is not proper
          editOn: false,
          mapFullView: false,
          selectedLayer: 'Default'
        },
        () => this.refresh()
      );
    }
  };
  closeGraph = () => {
    this.setState({
      isConnected: false,
      wancheckNetworks: false
    });
    $("#wanDataUsage").modal("hide");
  };
  getTooltip = (val) => {
    if (val.split(':')[0].split(' ').length > 2) {
      let parts = val.split(':')[0].split(' ');
      return (parseInt(parts[2]) + 1).toString() + ':00';
    } else
      return (parseInt(val.split(':')[0]) + 1).toString() + ':00'
  }
  graphData = () => {
    // let dataTunnel = []
    // let wifiUsage = []
    let ControlTunnel = []
    let wanUsage = []
    let cellularUsage = []
    let time = []

    if (this.state.dataUsageType === "daily") {
      // const dailyData = [];
      // this.state.graphData.map((item) => {
      //   if (moment(this.getTime(item.bucket)).format('mm') === '00') {
      //     dailyData.push(item);
      //   }
      // });
      // if (this.state.graphData.length > 1 && moment(this.getTime(this.state.graphData[this.state.graphData.length - 1].bucket)).minutes() !== 0) {
      //   dailyData.push(this.state.graphData[this.state.graphData.length - 1]);
      // }
      // for (let index = 1; index < dailyData.length; index++) {
      //   // if (moment(this.getTime(dailyData[index].bucket)).format('mm') === '00') {
      //   ControlTunnel.push(
      //     dailyData[index].control_tunnel_usage -
      //       dailyData[index - 1].control_tunnel_usage >
      //       0
      //       ? (dailyData[index].control_tunnel_usage -
      //         dailyData[index - 1].control_tunnel_usage)
      //       : 0
      //   );
      //   wanUsage.push(
      //     dailyData[index].wan_data_usage -
      //       dailyData[index - 1].wan_data_usage >
      //       0
      //       ? (dailyData[index].wan_data_usage -
      //         dailyData[index - 1].wan_data_usage)
      //       : 0
      //   );
      //   cellularUsage.push(
      //     dailyData[index].cellular_data_usage -
      //       dailyData[index - 1].cellular_data_usage >
      //       0
      //       ? (dailyData[index].cellular_data_usage -
      //         dailyData[index - 1].cellular_data_usage)
      //       : 0
      //   );
      //   if (index === dailyData.length - 1 && moment(this.getTime(dailyData[dailyData.length - 1].bucket)).minutes() !== 0) {
      //     time.push(this.getTime(dailyData[index].bucket, true));
      //   } else
      //     time.push(this.getTime(dailyData[index].bucket));
      //   // }
      // }

      for (let index = 0; index < this.state.graphData.length; index++) {
        // wifiUsage.push(this.state.graphData[index].wifi_data_usage)
        ControlTunnel.push(this.state.graphData[index].control_tunnel_usage)
        wanUsage.push(this.state.graphData[index].wan_data_usage)
        cellularUsage.push(this.state.graphData[index].cellular_data_usage)
        if (index === this.state.graphData.length - 1 && moment(this.getTime(this.state.graphData[this.state.graphData.length - 1].bucket)).minutes() !== 0) {
          time.push(this.getTime(this.state.graphData[index].bucket, true));
        } else
          time.push(this.getTime(this.state.graphData[index].bucket));
        // }
        // if (this.state.selectedDuration === 1 || this.state.diffDays === 1)
        // index = index + 5
      }

    }
    else {
      for (let index = 0; index < this.state.graphData.length; index++) {
        if (moment(this.getTime(this.state.graphData[index].bucket)).format('mm') === '00') {
          ControlTunnel.push(this.state.graphData[index].control_tunnel_usage)
          wanUsage.push(this.state.graphData[index].wan_data_usage)
          cellularUsage.push(this.state.graphData[index].cellular_data_usage)
          time.push(this.getTime(this.state.graphData[index].bucket));
        }
      }
      if (this.state.graphData.length > 1 && moment(this.getTime(this.state.graphData[this.state.graphData.length - 1].bucket)).minutes() !== 0) {
        ControlTunnel.push(this.state.graphData[this.state.graphData.length - 1].control_tunnel_usage)
        wanUsage.push(this.state.graphData[this.state.graphData.length - 1].wan_data_usage)
        cellularUsage.push(this.state.graphData[this.state.graphData.length - 1].cellular_data_usage)
        time.push(this.getTime(this.state.graphData[this.state.graphData.length - 1].bucket));
      }
    }

    if (this.state.selectedDuration === 1
      // || this.state.diffDays === 1
    ) {
      time = this.getExactTime(time)

    }
    let series = [
      {
        name: "wan_data_usage",
        data: wanUsage,
      },
      {
        name: "cellular_data_usage",
        data: cellularUsage,
      },
      {
        name: "control_tunnel_usage",
        data: ControlTunnel,
      },
      // {
      //   name: "Data-Tunnel",
      //   data: dataTunnel,
      // },
      // {
      //   name: "Wifi-Usage",
      //   data: wifiUsage,
      // },
    ]
    const obj = this;
    let { selectedDuration } = this.state;
    let data1 = {
      chart: {
        height: 300,
        type:
          this.state.dataUsageType === "daily" ? "column" :
            "area"
      },
      title: {
        text: ''
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false
      },
      yAxis: {
        title: {
          text: 'Data usage'
        },
        type: 'logarithmic',
        tickInterval: 0.5,
        labels: {
          formatter: function () {
            return '<a href="' + obj.calculateValue(this.value, 0) + '">' +
              obj.calculateValue(this.value, 0) + '</a>';
          }
        }
      },
      tooltip: {
        enabled: true,
        crosshairs: true,
        formatter: function () {
          if ((obj.state.selectedDuration === 1
            //  || obj.state.diffDays === 1
          ) && obj.state.dataUsageType === 'daily') {
            return this.x + '-' + obj.getTooltip(this.x) + '<br>  <div className="dot-circle">&#9679;</div>' + this.series.name + ' ' + obj.calculateValue(this.y.toFixed(4), 4);
          } else
            return this.x + '<br>  <div className="dot-circle">&#9679;</div>' + this.series.name + ' ' + obj.calculateValue(this.y.toFixed(4), 4)
        }
      },
      xAxis: {
        categories: time,
        title: {
          text: "Time Interval"
        },
        labels: {
          formatter: function () {
            let parts = this.value.split(' ');
            if (selectedDuration === 1
              // || obj.state.diffDays === 1
            ) {
              if (parts.length > 1) {
                return parts[0] + " " + parts[1].substr(0, 3) + " " + parts[2]
              } else {
                return '<a href="' + time[this.value] + '">' +
                  this.value + '</a>';
              }
            } else {
              return '<a href="' + time[this.value] + '">' +
                parts[0] + " " + parts[1].substr(0, 3) + '</a>';
            }
          }
        }
      },
      plotOptions: {
        series: {
          label: {
            connectorAllowed: false
          },
          connectNulls: true,
          pointStart: time[this.value],
          marker: {
            enabled: false,
            symbol: 'circle',
            radius: 2,
            states: {
              hover: {
                enabled: true
              }
            }
          }
        }
      },
      legend: { enabled: false },
      series: series.filter(item => item.name === this.state.selectedUsageOption),
    }
    this.setState({ option: data1, key: this.state.keyUpdate })
  }

  setDataUsageType = (e) => {
    this.setState({
      selectedUsageOption: e.target.value
    }, () => this.getDeviceUsage())
  }
  calculateValue = (val, fix) => {
    let unit = '';
    let iteration = 1;
    val = Math.abs(val);
    if (val > 0) {
      for (let i = 1; i <= 4; i++) {
        if (val >= 1000) {
          val = val / 1000;
          iteration = i;
        }
      }
      switch (iteration) {
        case 1:
          unit = val.toFixed(fix) + ' KB';
          break;
        case 2:
          unit = val.toFixed(fix) + ' MB';
          break;
        case 3:
          unit = val.toFixed(fix) + ' GB';
          break;
        case 4:
          unit = val.toFixed(fix) + ' TB';
          break;
        default:
          unit = val.toFixed(fix) + ' KB';
          break;
      }
      return unit;
    } else
      return val;
  }
  getExactTime = (array) => {
    let x = moment(array[0], "DD MMMM HH:mm").format("DD");
    for (let i = 0; i < array.length; i++) {
      if (moment(array[i], "DD MMMM HH:mm").format("DD") !== x) {
        array[i] = moment(array[i], "DD MMMM HH:mm").format("DD MMMM HH:mm");
        x = moment(array[i], "DD MMMM HH:mm").format("DD");
      } else {
        array[i] = moment(array[i], "DD MMMM HH:mm").format("HH:mm");
      }
    }
    return array

  }
  setUsageType = (e) => {
    this.setState({
      dataUsageType: e.target.value
    }, () => this.getDeviceUsage())
  }
  setStartTime = (e) => {
    this.setState({
      startDate: e.value ? e.value : '',
      endDate: '',
      keyUpdate: Math.random()
    })
  }
  setEndTime = (e) => {
    let startDate = moment(this.state.startDate).format("YYYY-MM-DD HH:mm");
    let endDate = moment(e.value).format("YYYY-MM-DD HH:mm");
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);
    const diffTime = Math.abs(date2 - date1);
    var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    this.setState({
      endDate: e.value ? e.value : '',
      diffDays
    }, () => {
      if (this.state.endDate !== '')
        this.getDeviceUsage()
    })
  }
  getAppliedTemplateList = () => {
    this.setState({ showLoader: true, selectedTab: "AppliedTemplate" });
    let data = { device_id: this.state.device_id };
    ApiCall(
      urlMapping.deviceAppliedTemplateList(
        data,
        this.state.pageNo,
        this.state.pageSize
      ),
      (response) => {
        if (response?.results) {
          this.setState({
            appliedTemplateList: response?.results,
            showLoader: false,
            totalCount: response?.count,
          });
        } else {
          if (parseInt(response?.response?.results) === 401) {
            this.setState({
              showLoader: false,
              showRefreshModal: true,
            });
          } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
            this.setState({
              title: 'Network Error',
              message: 'Something went wrong. Please try again later.',
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          } else {
            this.setState({
              showLoader: false,
            });
          }
        }
      }
    );
  };

  resetPopup = () => {
    this.setState(
      {
        show: false,
        error: false,
        delete: false,
        factoryReset: false,
        reboot: false,
        showModel: false,
        offlineDevice: false
      },
      () => {
        $("#errorMessage").modal("hide");
        if (this.state.redirect)
          this.props.history.push("/devices/configured");
      }
    );
  };
  resetPopupFixedIp = () => {
    $("#errorMessage").modal("show");
    if (this.state.showFixed) {
      this.setState({ showFixed: false, id: "" });
      this.getDeviceDetails();
    } else {
      this.setState({
        showCheck: false,
        error: false,
      });
    }

    $("#errorMessage").modal("hide");
  };
  getDeviceUpTime = (e) => {
    //EMGT-329-EM - Delay in Device connectivity switching from day, week and month	
    this.setState({ showLoader: true });
    let day = e;
    let currentLocalTime = moment();
    let edate = moment(currentLocalTime).format("YYYY-MM-DD HH:mm:ss");
    let endDate = e === 1 ? edate : moment(currentLocalTime).format("YYYY-MM-DD HH:mm:ss");
    let registerTime = this.getTimeExact(this.state.deviceData.created_date, "register");
    let last24Days = moment(edate).subtract(25, "hours").format("YYYY-MM-DD HH:mm:ss");
    let sevenDays = moment(currentLocalTime).subtract(7, "days").format("YYYY-MM-DD HH:mm:ss");
    let thirtyDays = moment(currentLocalTime).subtract(30, "days").format("YYYY-MM-DD HH:mm:ss");
    let startDate =
      day === 1
        ? moment(last24Days).isAfter(registerTime)
          ? last24Days
          : registerTime
        : day === 7
          ? moment(sevenDays).isAfter(registerTime)
            ? sevenDays
            : registerTime
          : moment(thirtyDays).isAfter(registerTime)
            ? thirtyDays
            : registerTime;
    let selected_con_time =
      day === 1
        ? "24 Hrs."
        : day === 7
          ? "7 days"
          : "30 days";
    let data = {
      host_id: this.state.deviceData.z_host_id,
      start_date: moment(startDate).unix(),
      end_date: moment(endDate).unix(),
    };
    ApiCall(urlMapping.deviceConnectivityUsage(data), (response) => {
      if (response?.success) {
        this.setState(
          {
            conData: response?.data ? response?.data : [],
            selectedDurationForCon: day,
            selected_con_time,
            //EMGT-329-EM - Delay in Device connectivity switching from day, week and month	
            showLoader: false,
          },
          () => {
            this.getTotalDownTime();
          }
        );
      } else {
        if (response?.response?.status === 401) {
          this.setState({
            showRefreshModal: true,
          });
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
      }
    });
  };
  getTotalDownTime = () => {
    let downTime = [];
    if (this.state.conData.length > 0) {
      let result = {
        x: 0,
        low: new Date(this.state.conData[0].clock * 1000).toISOString(),
        status: this.state.conData[0].value,
        color: this.state.conData[0].value === "0" ? "red" : "green",
      };
      for (let i = 0; i < this.state.conData.length; i++) {
        if (i < this.state.conData.length - 1) {
          if (
            this.state.conData[i].value !== this.state.conData[i + 1].value
          ) {
            result.high = new Date(this.state.conData[i + 1].clock * 1000).toISOString();
            downTime.push(result);
            result = {
              x: 0,
              low: new Date(this.state.conData[i + 1].clock * 1000).toISOString(),
              status: this.state.conData[i + 1].value,
              color: this.state.conData[i + 1].value === "0" ? "red" : "green",
            };
          }
        } else {
          result.high = new Date(this.state.conData[i].clock * 1000).toISOString();
          downTime.push(result);
        }
      }
      let totalDownTime = 0;
      downTime.map((item, index) => {
        if (item.status === "0") {
          const start = moment(item.low);
          const end = moment(item.high);
          let duration = moment.duration(end.diff(start));
          duration = duration.asMilliseconds();
          totalDownTime = totalDownTime + Math.abs(duration);
        }
      });
      //EMGT-539-EM Device Connectivity (24 hours, 7 Days, 30 Days, ) Not working correctly
      const days = moment.duration(totalDownTime).asDays();
      totalDownTime = moment.utc(totalDownTime).format("HH:mm:ss");
      totalDownTime = days.toString().split('.')[0] + " days " + totalDownTime;
      this.setState({ totalDownTime });
    }
  };
  // Message changed on updating is fixed ip
  handleChange = (e) => {
    this.setState(
      {
        title: "Update Fixed IP",
        message: "Are you sure you want to update fixed IP?",
        showCheck: true,
        showFixed: false,
        error: true,
        delete: true,
      },
      () => $("#errorMessage").modal("show")
    );
  };

  changeIsFixedIp = () => {
    let data = {
      device_id: this.state.device_id,
      is_fixed_ip: Number(!this.state.is_fixed_ip),
    };
    this.setState({
      showLoader: true,
    });
    ApiCall(urlMapping.updateFixedIp(data), (response) => {
      if (response?.success) {
        this.setState({
          showLoader: false,
        });
        $("#errorMessage").modal("hide");

        this.setState(
          {
            title: "Update Fixed IP ",
            message: response?.message,
            showFixed: true,
            showCheck: false,
            error: false,
            delete: false,
          },
          () => $("#errorMessage").modal("show")
        );
      } else {
        if (parseInt(response?.response?.status) === 401) {
          this.setState({
            showRefreshModal: true,
          });
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else {
          this.setState({
            showLoader: false,
          });
          $("#errorMessage").modal("hide");
          this.setState(
            {
              title: "Update Fixed IP ",
              message: response?.message,
              showCheck: true,
              error: true,
              delete: false,
            },
            () => $("#errorMessage").modal("show")
          );
        }
      }
    });
  };

  addTimes = (times) => {
    let duration = 0;
    times.forEach((time) => {
      duration = duration + moment.duration(time).as("milliseconds");
    });
    return moment.utc(duration).format("HH:mm:ss");
  };

  getDeviceSeverity = () => {
    let data = { device_id: this.state.device_id };
    ApiCall(urlMapping.deviceSeverity(data), (response) => {
      if (response?.success) {
        this.setState({ alertWarningCount: response?.data }, () =>
          this.getTotalCount()
        );
      } else {
        if (parseInt(response?.response?.status) === 401) {
          this.setState({
            showRefreshModal: true,
          });
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
      }
    });
  };
  updatePolling() {
    this.setState({
      showLoader: true,
    });
    let data = {
      device_id: this.state.device_id,
    }
    ApiCall(urlMapping.getPollingData(data), (response) => {
      this.setState({
        showLoader: false,
      });
      if (response?.success) {
        let data = response?.data;
        let deviceData = data?.data_usage?.keys;
        this.setState({ long_lat: data?.getMapDetailsFromZabbix?.keys?.location?.status === 0 ? 1 : 0 })
        if (data?.device_memory_statistics?.keys?.["p_available_memory"]?.status === 0 && data?.device_memory_statistics?.keys?.["total_memory"]?.status === 0) {
          this.setState({
            responseData: 1
          });
        } else {
          this.setState({
            responseData: 0
          });
        }
        if (data?.device_memory_statistics?.keys?.["cpu_util"]?.status === 0 && data?.device_memory_statistics?.keys?.["no_of_cpu"]?.status === 0) {
          this.setState({
            cpuResponseData: 1
          })
        } else {
          this.setState({
            cpuResponseData: 0
          });
        }
        this.getDeviceStatistics()
        if (data?.data_usage?.keys) {
          this.setState({
            data_tunnel_conf: data?.data_usage?.keys.tunnel_data_usage?.status === 0 ? 1 : 0,
            control_tunnel_conf: data?.data_usage?.keys?.control_tunnel_data_usage?.status === 0 ? 1 : 0,
            wan_data_conf: data?.data_usage.keys?.wan_data_usage?.status === 0 ? 1 : 0,
            cellular_data_conf: data?.data_usage?.keys?.cellular_data_usage?.status === 0 ? 1 : 0
          })
        }
        if (deviceData?.tunnel_data_usage?.status === 0 && deviceData?.control_tunnel_data_usage?.status === 0 &&
          deviceData?.wan_data_usage?.status === 0 && deviceData?.cellular_data_usage?.status === 0) {
          this.setState({ dataUsage_Conf: 1 })
        }
      }
      else {
        if (parseInt(response?.response?.status) === 401) {
          this.setState({
            showRefreshModal: true,
          });
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
      }
    });
  }
  getDeviceStatistics = () => {
    let data = { device_id: this.state.device_id };
    ApiCall(urlMapping.deviceStatistics(data), (response) => {
      if (response?.success) {
        this.setState({ deviceStat: response?.data });
      } else {
        if (parseInt(response?.response?.status) === 401) {
          this.setState({
            showRefreshModal: true,
          });
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
      }
    });
  };

  getTotalCount = () => {
    let sum = 0;
    Object.keys(this.state.alertWarningCount).map(
      (item) => (sum = sum + this.state.alertWarningCount[item])
    );

    this.setState({ totalCountWarningAndAlert: sum });
  };
  factoryResetDevices = async (id) => {
    let deviceId = [id];
    const haveTask = await this.checkDeviceTask();
    let data = {
      device_ids: deviceId,
      is_bolt_reset: this.state.is_bolt_reset,
    };
    this.setState({
      showLoader: true,
    });
    if (haveTask.length === 0) {
      ApiCall(urlMapping.factoryResetDevices(data), (response) => {
        if (response?.success) {
          $("#exampleModal").modal("hide");
          this.setState(
            {
              showLoader: false,
              title: "Bootstrap Reset Devices",
              message: response?.message,
              show: true,
              id: deviceId,
              showList: true,
              error: false,
              delete: false,
              factoryReset: false,
              is_bolt_reset: false,
            },
            () => $("#exampleModal").modal("show")
          );
        } else {
          $("#errorMessage").modal("hide");
          if (parseInt(response?.response?.status) === 401) {
            this.setState({
              showLoader: false,
              showRefreshModal: true,
            });
          }
          else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
            this.setState({
              title: 'Network Error',
              message: 'Something went wrong. Please try again later.',
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          }
          else {
            this.setState(
              {
                showLoader: false,
                title: "Bootstrap Reset Devices",
                message: response?.message,
                show: true,
                error: true,
                delete: false,
                factoryReset: false,
                is_bolt_reset: false,
              },
              () => $("#errorMessage").modal("show")
            );
          }
        }
      });
    } else {
      this.setState({
        showLoader: false,
      });
    }
  };

  rebootDevices = async (id) => {
    let deviceId = [id];
    let data = { device_ids: deviceId };
    const haveTask = await this.checkDeviceTask();
    this.setState({
      showLoader: true,
    });
    if (haveTask.length === 0) {
      ApiCall(urlMapping.rebootDevices(data), (response) => {
        this.setState({
          showLoader: false,
        });
        $("#exampleModal").modal("hide");
        if (response?.success) {
          this.setState(
            {
              title: "Reboot Devices",
              message:
                "Reboot Operation Initiated Successfully. To view the Operation logs ",
              show: true,
              showList: true,
              id: deviceId,
              error: false,
              delete: false,
            },
            () => $("#exampleModal").modal("show")
          );
        } else {
          $("#errorMessage").modal("hide");
          if (parseInt(response?.response?.status) === 401) {
            this.setState({
              showRefreshModal: true,
            });
          }
          else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
            this.setState({
              title: 'Network Error',
              message: 'Something went wrong. Please try again later.',
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          }
          else {
            this.setState(
              {
                title: "Reboot Devices",
                message: response?.message,
                show: true,
                error: true,
                delete: false,
              },
              () => $("#errorMessage").modal("show")
            );
          }
        }
      });
    } else {
      this.setState({
        showLoader: false,
      });
    }
  };
  checkBoltAction = () => {
    $("#errorMessage").modal("hide");
    //LONG-2242-EM - Bootstrap page - name for Bolt and cancel button in bolt popup
    this.setState(
      {
        title: "Factory Reset BOLT",
        message:
          "Do you want to Factory Reset the BOLT as well? (Factory Resetting Bolt will reset the APN Configurations)",
        show: false,
        error: true,
        delete: true,
        showModel: true,
        factoryReset: true,
      },
      () => {
        $("#errorMessage").modal("show");
      }
    );
  };
  changeSelectedTab = (tab) => {
    this.setState({ selectedTab: tab }, () => {
      $("#pills-templates-tab").attr("class", "nav-link");
      $("#pills-Device-Checkpoint-tab").attr("class", "nav-link active");
      $("#pills-templates").attr("class", "tab-pane fade");
      $("#pills-Device-Checkpoint").attr("class", "tab-pane fade active show");
      $("#pills-QoS-Statistics-tab").attr("class", "nav-link active");
    });
  };
  chooseAction = (
    deleteAct,
    factoryResetAct,
    rebootAct,
    id,
    dataResetAct,
    redirect
  ) => {
    if (this.state.offlineDevice) {
      this.clearDevice(id)
    }
    else if (deleteAct && factoryResetAct) {
      this.factoryResetDevices(id);
    } else if (deleteAct && rebootAct) {
      this.rebootDevices(id);
      this.setState({ reboot: false });
    } else if (deleteAct && dataResetAct) {
      this.dataUsageReset([id]);
      this.setState({ dataReset: false });
    } else if (redirect) {
      this.props.history.push("/devices/configured");
    } else if (deleteAct) {
      this.deleteDevices();
    }
    else {
      return "undefined";
    }
  };
  updateDeviceNameFunc = () => {
    let data = {
      device_id: this.state.device_id,
      device_name: this.state.editName,
    };
    this.setState({ showLoader: true });
    ApiCall(urlMapping.updateDeviceName(data), (response) => {
      if (response?.success) {
        this.setState({ showLoader: false, editOn: false, editName: "" }, () =>
          this.getDeviceDetails()
        );
      } else {
        if (parseInt(response?.response?.status) === 401) {
          this.setState({
            showLoader: false,
            showRefreshModal: true,
          });
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else {
          this.setState(
            {
              title: "Update Device Name",
              message: response?.message,
              show: true,
              error: true,
              delete: false,
              showLoader: false,
              editOn: false,
              editName: "",
            },
            () => $("#errorMessage").modal("show")
          );
        }
      }
    });
  };

  confirmDataUsageReset = () => {
    this.setState(
      {
        title: "Data Usage Reset",
        //LONG-3580
        message:
          "Data Usage Reset will reset all the counters in device and EM. Click OK to proceed or Click Cancel to cancel the operation.",
        show: true,
        error: true,
        delete: true,
        dataReset: true,
      },
      () => {
        $("#errorMessage").modal("show");
      }
    );
  };

  dataUsageReset = (id) => {
    let data = { device_ids: id };
    this.setState({ showLoader: true });

    ApiCall(urlMapping.dataUsageReset(data), (response) => {
      this.setState({ showLoader: false });
      if (response?.success) {
        this.setState(
          {
            title: "Data Usage Reset",
            message: response?.message,
            show: true,
            error: false,
            delete: false,
          },
          () => $("#errorMessage").modal("show")
        );
      } else {
        if (parseInt(response?.response?.status) === 401) {
          this.setState({ showRefreshModal: true });
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        } else {
          this.setState(
            {
              title: "Data Usage Reset",
              message: response?.message,
              show: true,
              error: true,
              delete: false,
            },
            () => $("#errorMessage").modal("show")
          );
        }
      }
    });
  };

  returnImage = (device_model) => {
    const models = Common.getModels(device_model);
    const str = device_model?.split("-")?.[0];

    switch (models) {
      case "Duvel_Model":
        return str === "FX2000e"
          ? "/images/Product_Images/FX2000/Duvel_DSIM.png"
          : "/images/Product_Images/FX2000/Duvel_back angle.png";

      case "Moretti_Model":
        return "/images/Product_Images/Moretti_M2100_M2000_A_B_C/Moretti generic packaging image_glamour.png";

      case "Stormbreaker":
        return "/images/Product_Images/S2000e_Stormbreaker/SBDINClip1.png";

      case "Firestorm":
        return (str === "FW2010" || str === "FW2010e")
          ? "/images/Product_Images/FW3000.png"
          : "/images/Product_Images/FW2000_FW2000e/FW2000_back angle.png";

      case "CHIMAY":
        return str === "FG2000e"
          ? "/images/Product_Images/FG2000e/Chimay ioT_back angle.png"
          : "/images/Product_Images/FG2000/Chimay_ortho_back.png";

      case "vCPE":
        return "/images/vCPE_Image100.png";

      case "Packetfence":
        return "/images/Product_Images/Packetfence/PF.png";

      case "Bradshaw":
        return "/images/Product_Images/bradshaw.png";

      case "Orval":
        return "/images/Product_Images/orval.png";

      case "Sander":
        return "/images/Product_Images/FW3000.png";

      default:
        return "/images/dd-img.png";
    }
  };

  showPass = () => {
    const x = document.getElementById("device-password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };
  //EMGT-435-EM - Not Able to copy the Encrypted password in HTTP on KVM bringup EM
  copyPassword = () => {
    this.setState({
      copied: true,
    });
  };

  showWebUiPass = () => {
    const x = document.getElementById("webui-password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
    this.setState((prevState) => ({
      showWebUiPassword: !prevState.showWebUiPassword,
    }));
  };

  copyWebUiPassword = () => {
    this.setState({
      webUiCopied: true,
    });
  };

  reportpage = (time, status) => {
    if (time && status)
      this.setState({
        selectedTab: "Report",
        startTime: time?.startDate,
        endTime: time?.endDate,
        status,
        connectivityFullView: this.state.connectivityFullView ? false : this.state.connectivityFullView
      }, () => {
        $("#pills-dashboard-tab").attr('class', 'nav-link')
        $("#pills-Report-tab").attr('class', 'nav-link active')
        $("#pills-dashboard").attr('class', 'tab-pane fade')
        $("#pills-Report").attr('class', 'tab-pane fade active show')
      })
  }
  navigate = (value) => {
    this.setState({
      selectedTab: "Troubleshoot",
      deviceSeverity: value,
      selected_Tab: "Warning&Alerts",
      tabValue: true,
    }, () => {
      $("#pills-dashboard-tab").attr('class', 'nav-link')
      $("#pills-Troubleshoot-tab").attr('class', 'nav-link active')
      $("#pills-dashboard").attr('class', 'tab-pane fade')
      $("#pills-Troubleshoot").attr('class', 'tab-pane fade active show')
    })
  }
  //LONG-2708-EM - MiFi || Device Dashboard || Password Changes || Frontend
  // getPassword = (data) => {
  //   const data1 = Buffer.from(`${data}`, 'base64').toString('utf8');
  //   return data1;
  // }
  refreshTelemetry = () => {
    this.setState({ showLoader: true });
    let data = {
      device_ip: this.state.deviceData.device_control_tunnel_ip,
      key: 'location',
      device_id: this.state.device_id,
      device_model: ""
    }
    ApiCall(urlMapping.getTelemetry(data), (response) => {
      this.setState({ showLoader: false });
      if (response?.success) {
        this.setState(
          {
            addressDetail: response?.data,
            keyUpdate: Math.random(),
          },
          () => this.setpositon()
        );
      }
      else {
        if (parseInt(response?.response?.status) === 401) {
          this.setState({
            showRefreshModal: true
          });
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else {
          this.setState({
            showLoader: false
          });
        }
      }
    })
  }
  downloadGraph = (value, key) => {
    this.setState({ showLoader: true })
    let data =
    {
      data: value,
      key: key,
      dataType: this.state.selectedUsageOption,
      usageType: this.state.dataUsageType,
      user_time_zone: moment.tz.guess()
    }
    ApiCall(urlMapping.downloadDataUsageGraphs(data), (response) => {
      this.setState({ showLoader: false })
      if (parseInt(response?.response?.status) === 200) {
        const url = new Blob([response?.data])
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(url);
        link.setAttribute('download', ("Device_Bandwidthusage" + "_" + moment().format('YYYY-MM-DD HH:mm') + ".xlsx"));
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.setState({
          showLoader: false
        })
      } else {
        if (parseInt(response?.response?.status) === 401) {
          this.setState({ showErrorModal: true })
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
      }
    })
  }
  refreshIntervals = (key) => {
    this.setState({ showLoader: true });
    let data = {
      device_ip: this.state.deviceData.device_control_tunnel_ip,
      key: key,
      device_id: this.state.device_id,
      device_model: ""
    }
    ApiCall(urlMapping.getTelemetry(data), (response) => {
      this.setState({ showLoader: false });
      if (response?.success) {
        let { deviceData } = this.state;
        deviceData.eth_port_status = response?.data
        this.setState({
          deviceData
        })
      }
      else {
        if (parseInt(response?.response?.status) === 401) {
          this.setState({
            showRefreshModal: true
          });
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else {
          this.setState({
            showLoader: false
          });
        }
      }
    })
  }
  onCancel = () => {
    this.setState(
      {
        show: false,
        error: false,
        delete: false,
        factoryReset: false,
        reboot: false,
        showModel: false,
        offlineDevice: false
      },
      () => {
        $("#errorMessage").modal("hide");
        this.deleteDevices();
      }
    );
  }
  clearDevice = (id) => {
    this.setState(
      {
        show: false,
        error: false,
        delete: false,
        factoryReset: false,
        reboot: false,
        showModel: false,
        offlineDevice: false,
        showLoader: true
      }
    )
    let data = {
      device_ids: {
        devices_having: [id],
        devices_to_approve: [],
      }
    };
    ApiCall(urlMapping.clearDevice(data), (response) => {
      if (response?.success) {
        this.setState({
          title: "Clear Device",
          showLoader: false,
          show: true,
          error: false,
          message: response?.message,
          redirect: true
        }, () => {
          $("#errorMessage").modal("show");
        })
      }
      else {
        if (parseInt(response?.response?.status) === 401) {
          this.setState({
            showLoader: false,
            showRefreshModal: true
          })
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else {
          this.setState(
            {
              showLoader: false
            });
        }
      }
    })
  }

  setSelectedLayer = (layer) => {
    this.setState({
      selectedLayer: layer
    });
  };

  render() {
    return (
      <div>
        <SideMenu />
        {this.state.showLoader && <Loader />}
        <section
          id="rhsMain"
          className={this.props.open ? "rhs-main" : "rhs-main expand-w"}
        >
          <Header />

          {/* Fullscreen button added on device dashboard graph  */}
          {this.state.mapFullView ? (
            <div className="bg-white rounded full-view">
              <div className="gps-location">
                <h1 className="page-title txt-blue mb-2">GPS Location</h1>
                <img
                  src="./images/svg-icons/resize.svg"
                  alt=""
                  title="Minimize"
                  className="cancel-btn"
                  onClick={() => this.setState({ mapFullView: false })}
                />
                <div className="row no-gutters">
                  {/* EMGT-110 : GPS Map not reporting accurate location of device */}
                  {this.state.mapAddress && (this.state.center[0] !== "0.000000" || this.state.center[1] !== "0.000000") ? (
                    <>
                      <div className="col-md-6 col-sm-12 ">
                        <div className="gps-address pr-md-4 pr-sm-0 mt-md-0 mt-sm-3">
                          <div className="di-list p-0">
                            <strong>Address:</strong>
                            <span>
                              {this.state.mapAddress.display_name !==
                                "Soul Buoy"
                                ? this.state.mapAddress.display_name
                                : ""}
                            </span>
                          </div>
                          <div className="d-flex">
                            <div className="di-list w-50">
                              <strong>Latitude:</strong>
                              <span>{this.state.center[0]}</span>
                            </div>
                            <div className="di-list  w-50">
                              <strong>Longitude:</strong>
                              <span>{this.state.center[1]}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 col-sm-12 " key={this.state.mapUpdate} >
                        {this.state.center.length > 0 && (
                          <Mapnew
                            selectedLayer={this.state.selectedLayer}
                            setSelectedLayer={this.setSelectedLayer}
                            center={this.state.center}
                            zoom={10}
                            maxZoom={20}
                            scrollWheelZoom={true}
                          />
                        )}
                      </div>
                    </>
                  ) : (
                    <div>Location not available</div>
                  )}
                </div>
              </div>
            </div>
          ) : this.state.bandwidthFullView ? (
            <div className="du-box bg-white h-fill">
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="page-title txt-blue flex-grow-1">
                  Total Bandwidth Usages
                </h1>
                <div className="form-pos">
                  <select
                    className="form-control"
                    onChange={this.setUsageType}
                    value={this.state.dataUsageType}
                    disabled={this.state.selectedDuration === "custom" && this.state.endDate === ''}
                    style={{ cursor: this.state.selectedDuration === "custom" && this.state.endDate === '' ? "not-allowed" : "" }}
                  >
                    <option value='daily'>Daily</option>
                    <option value='cumulative'>Cumulative</option>
                  </select>
                  <select
                    className="form-control"
                    onChange={(e) => this.getDeviceUsageData(e)}
                    value={this.state.selectedDuration.toString()}
                  >
                    <option value='1'>Last 24 hours</option>
                    <option value='7'>Week</option>
                    <option value='30'>Month</option>
                    <option value='custom'>Custom</option>
                  </select>
                  {this.state.custom && <div className="tbu-date tbu-date1">
                    <div className="form-group" id="schedulerDiv">
                      <label>Start Date</label>
                      <Calendar
                        id="navigatorstemplate"
                        value={this.state.startDate}
                        onChange={(e) => this.setStartTime(e)}
                        readonlyInput="true"
                        placeholder="MM/DD/YYYY"
                        monthNavigator={true}
                        yearNavigator={true}
                        hideOnDateTimeSelect={true}
                        yearRange={`${new Date().getFullYear() - 10}: ${new Date().getFullYear() + 10}`}
                        maxDate={new Date()}
                        minDate={new Date(moment().subtract(90, 'days'))}
                      />
                    </div>

                    <div className="form-group" id="schedulerDiv">
                      <div className={this.state.startDate ? "" : "disabled"} key={this.state.keyUpdate}>
                        <label>End Date</label>
                        <Calendar
                          id="navigatorstemplate"
                          value={this.state.endDate}
                          onChange={this.state.startDate ? (e) => this.setEndTime(e) : "disabled"}
                          disabled={this.state.startDate === '' ? true : false}
                          readonlyInput="true"
                          placeholder="MM/DD/YYYY"
                          monthNavigator={true}
                          yearNavigator={true}
                          hideOnDateTimeSelect={true}
                          yearRange={`${new Date().getFullYear() - 10}: ${new Date().getFullYear() + 10}`}
                          minDate={new Date(moment(this.state.startDate).add(1, 'days'))}
                          maxDate={new Date(moment(this.state.startDate).add(90, 'days')) > new Date() ? new Date() :
                            new Date(moment(this.state.startDate).add(90, 'days'))}
                        />
                      </div>
                    </div>
                  </div>
                  }
                  <select
                    className="form-control"
                    onChange={this.setDataUsageType}
                    value={this.state.selectedUsageOption}
                    disabled={this.state.selectedDuration === "custom" && this.state.endDate === ''}
                    style={{ cursor: this.state.selectedDuration === "custom" && this.state.endDate === '' ? "not-allowed" : "" }}
                  >
                    {!this.state.wan_data_conf &&
                      (this.state.deviceModel !== 'Moretti_Model' && this.state.deviceModel !== 'Duvel_Model' && this.state.deviceModel !== 'Stormbreaker' &&
                        this.state.deviceModel !== 'Firestorm')
                      &&
                      <option value='wan_data_usage'>Eth-WAN</option>}
                    {!this.state.cellular_data_conf && this.state.deviceModel !== 'vCPE' &&
                      <option value='cellular_data_usage'>Cellular-WAN</option>}
                    {!this.state.control_tunnel_conf && <option value='control_tunnel_usage'>Mgmt. Plane</option>}
                    {/* {!this.state.data_tunnel_conf &&
                      (this.state.deviceModel !== 'Moretti_Model' && this.state.deviceModel !== 'Duvel_Model' && this.state.deviceModel !== 'Stormbreaker' && this.state.deviceModel !== 'Firestorm')
                      &&
                      <option value='Data-Tunnel'>Data Tunnel</option>}
                    {!this.state.wifi_data_conf &&
                      (this.state.deviceModel !== 'Moretti_Model' && this.state.deviceModel !== 'Duvel_Model' && this.state.deviceModel !== 'Stormbreaker' && this.state.deviceModel !== 'Firestorm' && this.state.deviceModel !== 'vCPE')
                      &&
                      <option value='Wifi-Usage'>Wifi Usage</option>} */}
                  </select>
                  <button
                    className="form-control border-0"
                    style={{ width: "100px" }}
                    title={"Download Graph Data"}
                    onClick={() => { this.downloadGraph(this.state.graphData, "Bandwidth") }}
                  >
                    <img src="/images/svg-icons/download-arrow.svg" />
                  </button>
                  <li
                    className="nav-item fwidth border-0"
                    key="fullscreen"
                    onClick={() => this.setState({ bandwidthFullView: false })}>

                    <img
                      src="./images/svg-icons/resize.svg"
                      style={{ width: "30px" }}
                      className="view-btn"
                      alt=""
                    />
                  </li>
                </div>
              </div>
              {this.state?.graphData?.length !== 0 ? <div
                className={this.state.custom ? "graph-box mt-8" : "graph-box mt-2"}
                key={this.state.keyUpdate}
              >
                <HighchartsReact
                  highcharts={Highcharts}
                  options={this.state.option}
                />
              </div> : <div className="no-data-key">There are no records to display</div>}
            </div>
          ) : this.state.connectivityFullView ? (
            <div className="du-box bg-white device-conct-height">
              <div className="d-flex justify-content-between align-items-center ">
                <h1 className="page-title text-blue">
                  Device Connectivity
                  <div
                    className="tooltip-c"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="To check connectivity time span mouse hover on the bar"
                  >
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                  <div className="sesion-time">
                    {`Downtime in Last ${this.state.selected_con_time}`}{" "}
                    <strong>{`${this.state.totalDownTime} (HH:mm:ss)`}</strong>
                  </div>
                </h1>
                <ul className="nav nav-pills mt-2">
                  <li className="nav-item">
                    <button
                      className={
                        this.state.selectedDurationForCon === 1
                          ? "nav-link active"
                          : "nav-link"
                      }
                      onClick={() => this.getDeviceUpTime(1)}
                    >
                      24 hrs
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={
                        this.state.selectedDurationForCon === 7
                          ? "nav-link active"
                          : "nav-link"
                      }
                      onClick={() => this.getDeviceUpTime(7)}
                    >
                      7 days
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={
                        this.state.selectedDurationForCon === 30
                          ? "nav-link active"
                          : "nav-link"
                      }
                      onClick={() => this.getDeviceUpTime(30)}
                    >
                      30 Days
                    </button>
                  </li>
                  <li
                    className="nav-item fwidth connectivityResize"
                    key="fullscreen"
                    onClick={() =>
                      this.setState({ connectivityFullView: false })
                    }
                  >
                    <img
                      src="./images/svg-icons/resize.svg"
                      className="cancel-btn connectivityCancel"
                      alt=""
                    />
                  </li>
                </ul>
                {/* <li className="nav-item fwidth"
                                  key="fullscreen"
                                  onClick={() =>
                                    this.setState({ connectivityFullView: false })
                                  }
                                >
                                  
                                </li> */}
              </div>
              <div className="graph-box mt-5 pt-4 dc-graph">
                {this.state.conData.length > 0 ? (
                  <ConGraph
                    device_status={this.state.deviceData.device_status}
                    redirect={(time, status) => this.reportpage(time, status)}
                    data={this.state.conData}
                    selected_con_time={this.state.selected_con_time}
                  />
                ) : (
                  <div className="text-center p-5">
                    No Data Available
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="no-bg page-height">
              <div className="page-header bg d-flex align-items-center device-head">
                <div
                  className="back-page-icon"
                  onClick={
                    this?.props?.location?.state && this?.props?.location?.state?.back
                      ? this?.props?.location?.state?.default
                        ? () => this.props.history.push("/bootstrapTemplates")
                        : () => this.props.history.push("/templates")
                      :
                      this?.props?.location?.state?.backTo ? () => this.props.history.push("/devices/configured")
                        : () =>
                          this.props.history.push({
                            pathname: "/devices/configured",
                            state: {
                              retain: this.state.retain,
                              pagenName:
                                this?.props?.location?.state?.filterData
                                  ?.deviceModel ||
                                  this?.props?.location?.state?.filterData?.status
                                  ? "Clicked"
                                  : "",
                              searchValue:
                                this?.props?.location?.state?.searchValue,
                              searchKey: this?.props?.location?.state?.searchValue
                                ? "SearchClicked"
                                : "",
                            },
                          })
                  }
                >
                  <img
                    src="/images/svg-icons/back-page.svg"
                    alt=""
                    title="Back"
                  />
                </div>
                <div>
                  <DropDown
                    DeviceLookUpDeviceList={this.state.DeviceLookUpDeviceList}
                    deviceOptions={this.state.deviceOptions}
                    deviceData={this.state.deviceData}
                    onDropChange={this.onDropChange}
                  />
                </div>
                <ul
                  className="tab-nav nav nav-pills flex-grow-1"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="pills-dashboard-tab"
                      data-toggle="pill"
                      href="#pills-dashboard"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                      //LONG-3017-EM-GUI password issues
                      onClick={() => {
                        this.setState(
                          {
                            selectedTab: "Dashboard",
                            copied: false,
                            webUiCopied: false,
                            showPassword: false,
                            showWebUiPassword: false,
                            deviceSeverity: "",
                            selected_Tab: "",
                            tabValue: false,
                            editOn: false
                          },
                          () => this.refresh()
                        );
                      }}
                    >
                      Dashboard
                    </a>
                  </li>
                  {(this.state.deviceModel !== "vCPE" && this.state.deviceModel !== "Packetfence") && (
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="pills-cd-tab"
                        data-toggle="pill"
                        href="#pills-cd"
                        role="tab"
                        aria-controls="pills-cd"
                        aria-selected="false"
                        onClick={() =>
                          this.setState({ selectedTab: "ConnectedClient" })
                        }
                      >
                        Connected Device
                      </a>
                    </li>
                  )}
                  {this.state.deviceModel !== 'Packetfence' &&
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="pills-templates-tab"
                        data-toggle="pill"
                        href="#pills-templates"
                        role="tab"
                        aria-controls="pills-templates"
                        aria-selected="false"
                        onClick={() =>
                          this.setState({ selectedTab: "AppliedTemplate" })
                        }
                      >
                        {" "}
                        Templates
                      </a>
                    </li>
                  }
                  {(this.state.deviceModel !== "vCPE" && this.state.deviceModel !== "Packetfence") && (
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="pills-modeminfo-tab"
                        data-toggle="pill"
                        href="#pills-modeminfo"
                        role="tab"
                        aria-controls="pills-modeminfo"
                        aria-selected="false"
                        onClick={() =>
                          this.setState({ selectedTab: "ModemInfo" })
                        }
                      >
                        {" "}
                        Modem
                      </a>
                    </li>
                  )}
                  {this.state.deviceModel !== 'Packetfence' &&
                    <>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="pills-Networks-tab"
                          data-toggle="pill"
                          href="#pills-Networks"
                          role="tab"
                          aria-controls="pills-Networks"
                          aria-selected="false"
                          onClick={() => this.setState({ selectedTab: "Networks" })}
                        >
                          {" "}
                          Networks
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="pills-Tunnels-tab"
                          data-toggle="pill"
                          href="#pills-Tunnels"
                          role="tab"
                          aria-controls="pills-Tunnels"
                          aria-selected="false"
                          onClick={() => this.setState({ selectedTab: "Tunnels" })}
                        >
                          {" "}
                          Tunnels
                        </a>
                      </li> </>}
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="pills-Troubleshoot-tab"
                      data-toggle="pill"
                      href="#pills-Troubleshoot"
                      role="tab"
                      aria-controls="pills-Troubleshoot"
                      aria-selected="false"
                      onClick={() =>
                        this.setState({
                          selectedTab: "Troubleshoot",
                          deviceSeverity: "",
                          selected_Tab: "",
                          tabValue: false
                        })
                      }
                    >
                      {" "}
                      Troubleshoot
                    </a>
                  </li>
                  {this.state.deviceModel !== 'Packetfence' &&
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="pills-Device-Checkpoint-tab"
                        data-toggle="pill"
                        href="#pills-Device-Checkpoint"
                        role="tab"
                        aria-controls="pills-Device-Checkpoint"
                        aria-selected="false"
                        onClick={() =>
                          this.setState({ selectedTab: "Device-Checkpoint" })
                        }
                      >
                        {" "}
                        Device Checkpoint
                      </a>
                    </li>}
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="pills-Report-tab"
                      data-toggle="pill"
                      href="#pills-Report"
                      role="tab"
                      aria-controls="pills-Report"
                      aria-selected="false"
                      onClick={() =>
                        this.setState({
                          selectedTab: "Report",
                          startTime: "",
                          endTime: ""
                        })
                      }
                    >
                      Reports
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="pills-Chronology-tab"
                      data-toggle="pill"
                      href="#pills-Chronology"
                      role="tab"
                      aria-controls="pills-Chronology"
                      aria-selected="false"
                      onClick={() =>
                        this.setState({
                          selectedTab: "Chronology"
                        })
                      }
                    >
                      Chronology
                    </a>
                  </li>
                  {Common.getModels(this.state.deviceData.device_model) !== 'Packetfence' && <li className="nav-item">
                    <a
                      className="nav-link"
                      id="pills-Polling-tab"
                      data-toggle="pill"
                      href="#pills-Polling"
                      role="tab"
                      aria-controls="pills-Polling"
                      aria-selected="false"
                      onClick={() =>
                        this.setState({
                          selectedTab: "Polling",
                          startTime: "",
                          endTime: ""
                        })
                      }
                    >
                      Telemetry
                    </a>
                  </li>}
                </ul>

                {this.state.selectedTab === "Dashboard" && (
                  <div className="da-box">
                    <img
                      className="a-dots"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      src="/images/svg-icons/ellipsis.svg"
                      alt=""
                    />
                    <div className="drop-box dropdown-menu dropdown-menu-right">
                      {/* Disabled Reboot and Bootstrap Reset if device is added to any group */}
                      <ul>
                        <li
                          onClick={
                            (this.props?.userRole === "Viewer_Group")
                              ? undefined
                              : () => this.confirmReboot()
                          }
                          className={
                            (this.props?.userRole === "Viewer_Group")
                              ? "disabled"
                              : undefined
                          }
                        >
                          <img src="/images/svg-icons/retry.svg" alt="" />{" "}
                          Reboot
                        </li>
                        <li
                          onClick={
                            (this.state.deviceData.group_name ||
                              (this.props?.userRole === "Viewer_Group" ||
                                this.props?.userRole === "Operator_Group"))
                              ? undefined
                              : Common.getModels(this.state.deviceData.device_model) === 'Packetfence' ? "" : () => this.confirmFactoryReset()
                          }
                          className={
                            (this.state.deviceData.group_name ||
                              (this.props?.userRole === "Viewer_Group" ||
                                this.props?.userRole === "Operator_Group"))
                              ? "disabled"
                              : Common.getModels(this.state.deviceData.device_model) === 'Packetfence' ? "disabled" : undefined
                          }
                        >
                          <img src="/images/svg-icons/refreshing.svg" alt="" />{" "}
                          Bootstrap Reset
                        </li>
                        <li
                          className={
                            (this.props?.userRole === "Viewer_Group" || this.props?.userRole === "Operator_Group")
                              ? "disabled"
                              : ""}
                          onClick={() =>
                            (this.props?.userRole === "Viewer_Group" ||
                              this.props?.userRole === "Operator_Group")
                              ? undefined
                              : this.confirmDelete()}
                        >
                          <img
                            className="delete"
                            src="/images/svg-icons/delete.svg"
                            alt=""
                          />{" "}
                          Factory Reset
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>

              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-dashboard"
                  role="tabpanel"
                  aria-labelledby="pills-dashboard-tab"
                >
                  <div className="device-status bg-white">
                    <div className="d-flex align-items-top">
                      <div className="device-img">
                        <img
                          src={this.returnImage(
                            this.state.deviceData.device_model
                          )}
                          alt=""
                        />
                      </div>
                      <div className="connected-device">
                        <div
                          className={
                            this.state.editOn
                              ? "row hide-mode"
                              : "row"
                          }
                        >
                          <div className="col-9 d-flex align-items-center">
                            <h1 className="page-title">
                              {this.state.deviceData.device_name}
                            </h1>
                            <button
                              className="btn btn-primary btn-sm"
                              disabled={
                                (this.props?.userRole === "Viewer_Group" ||
                                  this.props?.userRole === "Operator_Group")}
                              onClick={() => this.setState({ editOn: true, editName: this.state.deviceData.device_name })}
                            >
                              Edit
                            </button>
                          </div>
                        </div>
                        {this.state.editOn && (
                          <div className="editmode d-flex align-items-center">
                            <input
                              type="text"
                              maxLength={32}
                              value={this.state.editName}
                              onChange={(e) =>
                                this.setState({ editName: e.target.value })
                              }
                            />
                            <button
                              disabled={this.state.editName.trim() === ""}
                              onClick={() => this.updateDeviceNameFunc()}
                            >
                              <img src="./images/svg-icons/tick.svg" alt="" />
                            </button>
                            <button
                              className="cancel"
                              style={{ paddingRight: "100px" }}
                              //EMGT-575-EM - Device name validation is not proper
                              onClick={() => this.setState({ editOn: false, editName: "" })}
                            >
                              <img src="./images/svg-icons/cancel.svg" alt="" />
                            </button>
                          </div>
                        )}
                        {this.state.deviceModel !== 'Packetfence' &&
                          <div className="d-flex align-items-center mt-2">
                            <input
                              type="checkbox"
                              className={
                                (this.props?.userRole === "Viewer_Group" ||
                                  this.props?.userRole === "Operator_Group") ? "disabled" : ""}
                              disabled={
                                (this.props?.userRole === "Viewer_Group" ||
                                  this.props?.userRole === "Operator_Group")}
                              checked={this.state.deviceData.is_fixed_ip}
                              onChange={(e) => this.handleChange(e)}
                            />
                            <label className="ml-2 mb-0">Is Fixed IP</label>
                          </div>
                        }
                        <div className="row d-flex justify-content-between">
                          <div className="col-md-4">
                            <div className="ds-txt">Device Status</div>
                            <div className="d-status">
                              <span>
                                <i
                                  className={
                                    this.state.deviceData.device_status === 1
                                      ? "status online"
                                      : "status offline"
                                  }
                                ></i>
                                {this.state.deviceData.device_status === 1
                                  ? "Online"
                                  : "Offline"}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="ds-txt">Bootstrap Status</div>
                            <div className="d-status">
                              <span>
                                <i
                                  className={
                                    this.state.deviceData.bootstrap_status === "Failure"
                                      ? "status offline"
                                      : this.state.deviceData.bootstrap_status === "Success"
                                        ? "status online"
                                        : this.state.deviceData.bootstrap_status === "In_Progress"
                                          ? "status added"
                                          : this.state.deviceData.bootstrap_status === "Not_Initiated"
                                            ? "status not_initialized"
                                            : this.state.deviceData.bootstrap_status === "In_Queue"
                                              ? "status in_queue"
                                              : ""
                                  }
                                ></i>
                                {this.state.deviceData.bootstrap_status === "Failure"
                                  ? "Failure"
                                  : this.state.deviceData.bootstrap_status === "Success"
                                    ? "Success"
                                    : this.state.deviceData.bootstrap_status === "In_Progress"
                                      ? "In Progress"
                                      : this.state.deviceData.bootstrap_status === "Not_Initiated"
                                        ? "Not Initiated"
                                        : this.state.deviceData.bootstrap_status === "In_Queue"
                                          ? "In Queue"
                                          : "-"
                                }
                              </span>
                            </div>
                          </div>
                          {this.state.deviceModel !== 'Packetfence' &&
                            <div className="col-md-4">
                              <div className="ds-txt">Assigned Group</div>
                              <div className="d-status">
                                <span>
                                  {this.state.deviceData.group_name
                                    ? this.state.deviceData.group_name
                                    : "-"}
                                </span>
                              </div>
                            </div>
                          }
                        </div>
                        <div className="ds-txt">LAN
                          <img
                            style={{ width: "25px", cursor: "pointer", "margin-left": "230px" }}
                            src="/images/refreshT.png"
                            title="Refresh Telemetry data"
                            className={this.state.deviceData.device_status === 1 ? "" : "disabled"}
                            onClick={this.state.deviceData.device_status === 1 ?
                              () => this.refreshIntervals("port_status") : undefined}
                          /></div>
                        <ul className="port-wifi border rounded p-2 lan-wan-port">
                          {(this.state.deviceModel === "Duvel_Model" ||
                            this.state.deviceModel === "Stormbreaker" ||
                            this.state.deviceModel === "Packetfence" ||
                            this.state.deviceModel === "Firestorm" ||
                            this.state.deviceModel === "vCPE") &&
                            this.state.deviceData.eth_port_status?.wan_eth0 === "" &&
                            (
                              <li>
                                <img
                                  className={"rounded"}
                                  src={
                                    this.state.deviceData.eth_port_status
                                      ?.eth0 === 1
                                      ? "/images/svg-icons/port-green.svg"
                                      : "/images/svg-icons/port-gray.svg"
                                  }
                                  alt=""
                                />
                                <span>ETH0</span>
                              </li>
                            )}
                          {(this.state.deviceModel === "vCPE" ||
                            this.state.deviceModel === "Bradshaw" ||
                            this.state.deviceModel === "Orval" ||
                            this.state.deviceModel === "Sander") &&
                            this.state.deviceData.eth_port_status?.wan_eth1 === "" &&
                            <li>
                              <img
                                className={"rounded"}
                                src={
                                  this.state.deviceData.eth_port_status
                                    ?.eth1 === 1
                                    ? "/images/svg-icons/port-green.svg"
                                    : "/images/svg-icons/port-gray.svg"
                                }
                                alt=""
                              />
                              <span>ETH1</span>
                            </li>}
                          {this.state.deviceModel !== "Duvel_Model" &&
                            this.state.deviceModel !== "Packetfence" &&
                            this.state.deviceModel !== "Moretti_Model" &&
                            this.state.deviceModel !== "Stormbreaker" &&
                            this.state.deviceModel !== "Firestorm" &&
                            this.state.deviceModel !== "Bradshaw" &&
                            this.state.deviceModel !== "Sander" &&
                            this.state.deviceData.eth_port_status?.wan_eth2 === "" &&
                            (
                              <li>
                                <img
                                  className="rounded"
                                  src={
                                    this.state.deviceData.eth_port_status
                                      ?.eth2 === 1
                                      ? "/images/svg-icons/port-green.svg"
                                      : "/images/svg-icons/port-gray.svg"
                                  }
                                  alt=""
                                />
                                <span>ETH2</span>
                              </li>
                            )}
                          {
                            this.state.deviceModel !== "Packetfence" &&
                            this.state.deviceModel !== "Duvel_Model" &&
                            this.state.deviceModel !== "Moretti_Model" &&
                            this.state.deviceModel !== "Stormbreaker" &&
                            this.state.deviceModel !== "Bradshaw" &&
                            this.state.deviceModel !== "Orval" &&
                            this.state.deviceModel !== "Sander" &&
                            this.state.deviceModel !== "Firestorm" &&
                            (
                              <>
                                {this.state.deviceData.eth_port_status?.wan_eth3 === "" &&
                                  <li>
                                    <img
                                      className="rounded"
                                      src={
                                        this.state.deviceData.eth_port_status
                                          ?.eth3 === 1
                                          ? "/images/svg-icons/port-green.svg"
                                          : "/images/svg-icons/port-gray.svg"
                                      }
                                      alt=""
                                    />
                                    <span>ETH3</span>
                                    {this.state.deviceModel === "CHIMAY" && <span><small>(LAN2)</small></span>}
                                  </li>}
                                {this.state.deviceData.eth_port_status?.wan_eth4 === "" &&
                                  <li>
                                    <img
                                      className="rounded"
                                      src={
                                        this.state.deviceData.eth_port_status
                                          ?.eth4 === 1
                                          ? "/images/svg-icons/port-green.svg"
                                          : "/images/svg-icons/port-gray.svg"
                                      }
                                      alt=""
                                    />
                                    <span>ETH4</span>
                                    {this.state.deviceModel === "CHIMAY" && <span><small>(LAN1)</small></span>}
                                  </li>}
                                {this.state.deviceData.eth_port_status?.wan_eth5 === "" &&
                                  <li>
                                    <img
                                      className="rounded"
                                      src={
                                        this.state.deviceData.eth_port_status
                                          ?.eth5 === 1
                                          ? "/images/svg-icons/port-green.svg"
                                          : "/images/svg-icons/port-gray.svg"
                                      }
                                      alt=""
                                    />
                                    <span>ETH5</span>
                                    {this.state.deviceModel === "CHIMAY" && (
                                      <span>
                                        <small>
                                          (5Gbps LAN)
                                        </small>
                                      </span>
                                    )}
                                  </li>}
                              </>
                            )}
                          {this.state.deviceModel === "vCPE" && (
                            <>
                              {this.state.deviceData.eth_port_status
                                ?.wan_eth6 === "" &&
                                <li>
                                  <img
                                    className={"rounded"}
                                    src={
                                      this.state.deviceData.eth_port_status
                                        ?.eth6 === 1
                                        ? "/images/svg-icons/port-green.svg"
                                        : "/images/svg-icons/port-gray.svg"
                                    }
                                    alt=""
                                  />
                                  <span>ETH6</span>
                                </li>}
                              {this.state.deviceData.eth_port_status
                                ?.wan_eth7 === "" &&
                                <li>
                                  <img
                                    className={"rounded"}
                                    src={
                                      this.state.deviceData.eth_port_status
                                        ?.eth7 === 1
                                        ? "/images/svg-icons/port-green.svg"
                                        : "/images/svg-icons/port-gray.svg"
                                    }
                                    alt=""
                                  />
                                  <span>ETH7</span>
                                </li>}
                            </>
                          )}
                          {(this.state.deviceModel === "Duvel_Model" ||
                            this.state.deviceModel === "Moretti_Model" ||
                            this.state.deviceModel === "Stormbreaker" ||
                            this.state.deviceModel === "Bradshaw" ||
                            this.state.deviceModel === "Sander" ||
                            this.state.deviceModel === "Orval") && (
                              <li>
                                <img
                                  className="rounded"
                                  src={
                                    this.state.deviceData?.eth_port_status?.[
                                      "usb.status"
                                    ] === 1
                                      ? "/images/svg-icons/usb-green.svg"
                                      : "/images/svg-icons/usb-gray.svg"
                                  }
                                  alt=""
                                />
                                <span>USB</span>
                              </li>
                            )}
                          {/* // Radio icons added for Chimay device */}
                          {(this.state.deviceModel === "CHIMAY" ||
                            this.state.deviceModel === 'Moretti_Model' ||
                            this.state.deviceModel === 'Duvel_Model' ||
                            this.state.deviceModel === "Bradshaw" ||
                            this.state.deviceModel === "Orval") && (
                              <li>
                                <img
                                  className="rounded"
                                  src={
                                    this.state.deviceData.eth_port_status
                                      ?.radio_2g === 1
                                      ? "/images/svg-icons/2.4GHz-green.svg"
                                      : "/images/svg-icons/2.4GHz-grey.svg"
                                  }
                                  alt=""
                                />
                                <span>2.4GHz Radio</span>
                              </li>
                            )}
                          {(this.state.deviceModel === "CHIMAY" ||
                            this.state.deviceModel === 'Moretti_Model' ||
                            this.state.deviceModel === 'Duvel_Model' ||
                            this.state.deviceModel === "Bradshaw" ||
                            this.state.deviceModel === "Orval") && (
                              <li>
                                <img
                                  className="rounded"
                                  src={
                                    this.state.deviceData.eth_port_status
                                      ?.radio_5g === 1
                                      ? "/images/svg-icons/5GHz-green.svg"
                                      : "/images/svg-icons/5GHz-grey.svg"
                                  }
                                  alt=""
                                />
                                <span>5GHz Radio</span>
                              </li>
                            )}
                        </ul>
                        {(this.state.deviceData.eth_port_status?.wan_eth0 !== "" ||
                          this.state.deviceData.eth_port_status?.wan_eth1 !== "" ||
                          this.state.deviceData.eth_port_status?.wan_eth2 !== "" ||
                          this.state.deviceData.eth_port_status?.wan_eth3 !== "" ||
                          this.state.deviceData.eth_port_status?.wan_eth4 !== "" ||
                          this.state.deviceData.eth_port_status?.wan_eth5 !== "" ||
                          this.state.deviceData.eth_port_status?.wan_eth6 !== "" ||
                          this.state.deviceData.eth_port_status?.wan_eth7 !== "" ||
                          this.state.deviceData.eth_port_status?.wan_rmnet_data0 !== "") &&
                          <>
                            <div className="ds-txt">WAN</div>
                            <ul className="port-wifi border rounded p-2 lan-wan-port">
                              {(this.state.deviceModel === "Duvel_Model" ||
                                this.state.deviceModel === "Stormbreaker" ||
                                this.state.deviceModel === "Packetfence" ||
                                this.state.deviceModel === "Firestorm" ||
                                this.state.deviceModel === "vCPE") &&
                                this.state.deviceData.eth_port_status?.wan_eth0 !== "" &&
                                (
                                  <li>
                                    <span>{
                                      (parseInt(this.state.deviceData.eth_port_status?.wan_eth0?.prio) !== 0 &&
                                        this.state.deviceData.eth_port_status?.wan_eth0?.prio !== undefined)
                                        ? this.state.deviceData.eth_port_status?.wan_eth0?.prio :
                                        '\u00A0'
                                    }</span>
                                    <img
                                      className={"rounded"}
                                      src={
                                        this.state.deviceData.eth_port_status
                                          ?.eth0 === 1
                                          ? "/images/svg-icons/port-green.svg"
                                          : "/images/svg-icons/port-gray.svg"
                                      }
                                      alt=""
                                    />
                                    <span>ETH0</span>
                                    <div className="ds-txt">{
                                      (this.state?.deviceData?.eth_port_status?.eth0 === 1 &&
                                        String(this.state?.deviceData?.eth_port_status?.wan_eth0?.status) === "Active") &&
                                      (this.state.deviceData?.eth_port_status?.wan_eth0?.status || '')
                                    }</div>
                                  </li>
                                )}
                              {(this.state.deviceModel === "vCPE" ||
                                this.state.deviceModel === "Bradshaw" ||
                                this.state.deviceModel === "Orval" ||
                                this.state.deviceModel === "Sander") &&
                                this.state.deviceData.eth_port_status?.wan_eth1 !== "" &&
                                <li>
                                  <span>{
                                    (parseInt(this.state.deviceData.eth_port_status?.wan_eth1?.prio) !== 0 &&
                                      this.state.deviceData.eth_port_status?.wan_eth1?.prio !== undefined)
                                      ? this.state.deviceData.eth_port_status?.wan_eth1?.prio :
                                      '\u00A0'
                                  }</span>
                                  <img
                                    className={"rounded"}
                                    src={
                                      this.state.deviceData.eth_port_status
                                        ?.eth1 === 1
                                        ? "/images/svg-icons/port-green.svg"
                                        : "/images/svg-icons/port-gray.svg"
                                    }
                                    alt=""
                                  />
                                  <span>ETH1</span>
                                  <div className="ds-txt">{
                                    (this.state?.deviceData?.eth_port_status?.eth1 === 1 &&
                                      String(this.state?.deviceData?.eth_port_status?.wan_eth1?.status) === "Active") &&
                                    (this.state.deviceData?.eth_port_status?.wan_eth1?.status || '')
                                  }</div>
                                </li>}
                              {this.state.deviceModel !== "Duvel_Model" &&
                                this.state.deviceModel !== "Packetfence" &&
                                this.state.deviceModel !== "Moretti_Model" &&
                                this.state.deviceModel !== "Stormbreaker" &&
                                this.state.deviceModel !== "Firestorm" &&
                                this.state.deviceModel !== "Bradshaw" &&
                                this.state.deviceModel !== "Sander" &&
                                this.state.deviceData.eth_port_status?.wan_eth2 !== "" &&
                                (
                                  <li>
                                    <span>
                                      {
                                        (parseInt(this.state.deviceData.eth_port_status?.wan_eth2?.prio) !== 0 &&
                                          this.state.deviceData.eth_port_status?.wan_eth2?.prio !== undefined)
                                          ? this.state.deviceData.eth_port_status?.wan_eth2?.prio
                                          : '\u00A0'
                                      }
                                    </span>
                                    <img
                                      className="rounded"
                                      src={
                                        this.state.deviceData.eth_port_status
                                          ?.eth2 === 1
                                          ? "/images/svg-icons/port-green.svg"
                                          : "/images/svg-icons/port-gray.svg"
                                      }
                                      alt=""
                                    />
                                    <span>ETH2</span>
                                    {this.state.deviceModel === "CHIMAY" && <span><small>(WAN)</small></span>}
                                    <div className="ds-txt">
                                      {(this.state?.deviceData?.eth_port_status?.eth2 === 1 &&
                                        String(this.state?.deviceData?.eth_port_status?.wan_eth2?.status) === "Active") &&
                                        (this.state.deviceData?.eth_port_status?.wan_eth2?.status || '')
                                      }
                                    </div>
                                  </li>
                                )}
                              {this.state.deviceModel !== "Packetfence" &&
                                this.state.deviceModel !== "Duvel_Model" &&
                                this.state.deviceModel !== "Moretti_Model" &&
                                this.state.deviceModel !== "Stormbreaker" &&
                                this.state.deviceModel !== "Bradshaw" &&
                                this.state.deviceModel !== "Orval" &&
                                this.state.deviceModel !== "Sander" &&
                                this.state.deviceModel !== "Firestorm" && (
                                  <>
                                    {this.state.deviceData.eth_port_status?.wan_eth3 !== "" &&
                                      <li>
                                        <span>{
                                          (parseInt(this.state.deviceData.eth_port_status?.wan_eth3?.prio) !== 0 &&
                                            this.state.deviceData.eth_port_status?.wan_eth3?.prio !== undefined)
                                            ? this.state.deviceData.eth_port_status?.wan_eth3?.prio :
                                            '\u00A0'
                                        }</span>
                                        <img
                                          className="rounded"
                                          src={
                                            this.state.deviceData.eth_port_status
                                              ?.eth3 === 1
                                              ? "/images/svg-icons/port-green.svg"
                                              : "/images/svg-icons/port-gray.svg"
                                          }
                                          alt=""
                                        />
                                        <span>ETH3</span>
                                        <div className="ds-txt">{
                                          (this.state?.deviceData?.eth_port_status?.eth3 === 1 &&
                                            String(this.state?.deviceData?.eth_port_status?.wan_eth3?.status) === "Active") &&
                                          (this.state.deviceData?.eth_port_status?.wan_eth3?.status || '')
                                        }</div>
                                      </li>}
                                    {this.state.deviceData.eth_port_status?.wan_eth4 !== "" &&
                                      <li>
                                        <span>{
                                          (parseInt(this.state.deviceData.eth_port_status?.wan_eth4?.prio) !== 0 &&
                                            this.state.deviceData.eth_port_status?.wan_eth4?.prio !== undefined)
                                            ? this.state.deviceData.eth_port_status?.wan_eth4?.prio :
                                            '\u00A0'
                                        }</span>
                                        <img
                                          className="rounded"
                                          src={
                                            this.state.deviceData.eth_port_status
                                              ?.eth4 === 1
                                              ? "/images/svg-icons/port-green.svg"
                                              : "/images/svg-icons/port-gray.svg"
                                          }
                                          alt=""
                                        />
                                        <span>ETH4</span>
                                        <div className="ds-txt">{
                                          (this.state?.deviceData?.eth_port_status?.eth4 === 1 &&
                                            String(this.state?.deviceData?.eth_port_status?.wan_eth4?.status) === "Active") &&
                                          (this.state.deviceData?.eth_port_status?.wan_eth4?.status || '')
                                        }</div>
                                      </li>
                                    }
                                    {this.state.deviceData.eth_port_status?.wan_eth5 !== "" &&
                                      <li>
                                        <span>{
                                          (parseInt(this.state.deviceData.eth_port_status?.wan_eth5?.prio) !== 0 &&
                                            this.state.deviceData.eth_port_status?.wan_eth5?.prio !== undefined)
                                            ? this.state.deviceData.eth_port_status?.wan_eth5?.prio :
                                            '\u00A0'
                                        }</span>
                                        <img
                                          className="rounded"
                                          src={
                                            this.state.deviceData.eth_port_status
                                              ?.eth5 === 1
                                              ? "/images/svg-icons/port-green.svg"
                                              : "/images/svg-icons/port-gray.svg"
                                          }
                                          alt=""
                                        />
                                        <span>ETH5</span>
                                        <div className="ds-txt">{
                                          (this.state?.deviceData?.eth_port_status?.eth5 === 1 &&
                                            String(this.state?.deviceData?.eth_port_status?.wan_eth5?.status) === "Active") &&
                                          (this.state.deviceData?.eth_port_status?.wan_eth5?.status || '')
                                        }</div>
                                      </li>
                                    }
                                  </>
                                )}
                              {this.state.deviceModel === "vCPE" &&
                                <>
                                  {this.state.deviceData.eth_port_status?.wan_eth6 !== "" &&
                                    <li>
                                      <span>{
                                        (parseInt(this.state.deviceData.eth_port_status?.wan_eth6?.prio) !== 0 &&
                                          this.state.deviceData.eth_port_status?.wan_eth6?.prio !== undefined)
                                          ? this.state.deviceData.eth_port_status?.wan_eth6?.prio :
                                          '\u00A0'
                                      }</span>
                                      <img
                                        className={"rounded"}
                                        src={
                                          this.state.deviceData.eth_port_status
                                            ?.eth6 === 1
                                            ? "/images/svg-icons/port-green.svg"
                                            : "/images/svg-icons/port-gray.svg"
                                        }
                                        alt=""
                                      />
                                      <span>ETH6</span>
                                      <div className="ds-txt">{
                                        (this.state?.deviceData?.eth_port_status?.eth6 === 1 &&
                                          String(this.state?.deviceData?.eth_port_status?.wan_eth6?.status) === "Active") &&
                                        (this.state.deviceData?.eth_port_status?.wan_eth6?.status || '')
                                      }</div>
                                    </li>}
                                  {this.state.deviceData.eth_port_status?.wan_eth7 !== "" &&
                                    <li>
                                      <span>{
                                        (parseInt(this.state.deviceData.eth_port_status?.wan_eth7?.prio) !== 0 &&
                                          this.state.deviceData.eth_port_status?.wan_eth7?.prio !== undefined)
                                          ? this.state.deviceData.eth_port_status?.wan_eth7?.prio :
                                          '\u00A0'
                                      }</span>
                                      <img
                                        className={"rounded"}
                                        src={
                                          this.state.deviceData.eth_port_status
                                            ?.eth7 === 1
                                            ? "/images/svg-icons/port-green.svg"
                                            : "/images/svg-icons/port-gray.svg"
                                        }
                                        alt=""
                                      />
                                      <span>ETH7</span>
                                      <div className="ds-txt">{
                                        (this.state?.deviceData?.eth_port_status?.eth7 === 1 &&
                                          String(this.state?.deviceData?.eth_port_status?.wan_eth7?.status) === "Active") &&
                                        (this.state.deviceData?.eth_port_status?.wan_eth7?.status || '')
                                      }</div>
                                    </li>}
                                </>
                              }
                              {(this.state.deviceModel === "Duvel_Model" ||
                                this.state.deviceModel === "Moretti_Model" ||
                                this.state.deviceModel === "CHIMAY" ||
                                this.state.deviceModel === "Bradshaw" ||
                                this.state.deviceModel === "Orval" ||
                                this.state.deviceModel === "Stormbreaker" ||
                                this.state.deviceModel === "Firestorm" ||
                                this.state.deviceModel === "Sander") && (
                                  <li>
                                    {
                                      (
                                        (
                                          parseInt(this.state.deviceData.eth_port_status?.wan_rmnet_data0?.prio) !== 0 &&
                                          this.state.deviceData.eth_port_status?.wan_rmnet_data0?.prio !== undefined
                                        ) ||
                                        parseInt(this.state.deviceData.eth_port_status?.wan_rmnet_data1?.prio) !== 0 &&
                                        this.state.deviceData.eth_port_status?.wan_rmnet_data1?.prio !== undefined
                                      ) ? (
                                        <span>
                                          {
                                            (
                                              this.state.deviceData.eth_port_status?.wan_rmnet_data0?.prio ||
                                              this.state.deviceData.eth_port_status?.wan_rmnet_data1?.prio ||
                                              '\u00A0'
                                            )
                                          }
                                        </span>
                                      ) : (
                                        <span>&nbsp;</span>
                                      )
                                    }
                                    <img
                                      className="rounded"
                                      src={
                                        (
                                          parseInt(this.state.deviceData.eth_port_status?.rmnet_data0) === 1 ||
                                          parseInt(this.state.deviceData.eth_port_status?.rmnet_data1) === 1
                                        )
                                          ? "/images/svg-icons/5g.svg"
                                          : "/images/svg-icons/5g-gray.svg"
                                      }
                                      alt=""
                                    />
                                    <span>Cellular</span>
                                    <div className="ds-txt">{
                                      (
                                        (
                                          (
                                            parseInt(this.state.deviceData.eth_port_status?.rmnet_data0) === 1 ||
                                            parseInt(this.state.deviceData.eth_port_status?.rmnet_data1) === 1
                                          ) &&
                                          (String(this.state?.deviceData?.eth_port_status?.wan_rmnet_data0?.status) === "Active") ||
                                          (String(this.state?.deviceData?.eth_port_status?.wan_rmnet_data1?.status) === "Active")
                                        ) &&
                                        (
                                          this.state.deviceData?.eth_port_status?.wan_rmnet_data0?.status ||
                                          this.state.deviceData?.eth_port_status?.wan_rmnet_data1?.status ||
                                          ""
                                        )
                                      )
                                    }</div>
                                  </li>
                                )}
                            </ul>
                          </>}
                      </div>
                      <div className="memory-cpu">
                        <div className="gray-bg rounded p-3">
                          <ul className="d-flex align-items-center justify-content-between">
                            <li>
                              <img src="/images/svg-icons/memory.svg" alt="" />
                              <strong>Memory</strong>
                            </li>
                            {this.state.responseData !== 1 ? (
                              <>
                                {this.state.deviceStat.tot_memory_data_info &&
                                  this.state.deviceStat.av_memory_data_info !== "" ? (
                                  <>
                                    <li>
                                      <strong>
                                        {this.state.deviceStat
                                          .tot_memory_data_info === ""
                                          ? 0
                                          : (
                                            this.state.deviceStat
                                              .tot_memory_data_info /
                                            (1000 * 1000)
                                          ).toFixed(2)}
                                        MB
                                      </strong>
                                      Total
                                      <br />
                                      Memory
                                    </li>
                                    <li>
                                      <strong>
                                        {isNaN(this.state.deviceStat
                                          .tot_memory_data_info)
                                          ? '-'
                                          : (
                                            (this.state.deviceStat
                                              .tot_memory_data_info -
                                              this.state.deviceStat
                                                .av_memory_data_info) /
                                            (1000 * 1000)
                                          ).toFixed(2)}
                                        MB
                                      </strong>
                                      Utilised
                                      <br />
                                      Memory
                                    </li>
                                    <li>
                                      <strong>
                                        {isNaN(this.state.deviceStat
                                          .av_memory_data_info)
                                          ? '-'
                                          : (
                                            this.state.deviceStat
                                              .av_memory_data_info /
                                            (1000 * 1000)
                                          ).toFixed(2)}
                                        MB
                                      </strong>
                                      Available
                                      <br />
                                      Memory
                                    </li>
                                  </>
                                ) : (
                                  <li className="text-center flex-grow-1">
                                    No Data
                                  </li>
                                )}
                              </>
                            ) : (<div className="text-center flex-grow-1">Configurations are disabled</div>)}
                          </ul>
                        </div>
                        <div className="gray-bg rounded p-3 mt-2">
                          <ul className="d-flex align-items-center justify-content-between">
                            <li>
                              <img src="/images/svg-icons/cpu.svg" alt="" />
                              <strong>CPU</strong>
                            </li>
                            {this.state.cpuResponseData !== 1 ? (
                              <>
                                {this.state.deviceStat.cpu_usg_data_info !== "" ? (
                                  <>
                                    <li>
                                      <strong>
                                        {isNaN(this.state.deviceStat.cpu_usg_data_info)
                                          ? '-'
                                          : (
                                            this.state.deviceStat
                                              .cpu_usg_data_info / 1
                                          ).toFixed(2) + '%'}

                                      </strong>
                                      CPU
                                      <br />
                                      Utilised
                                    </li>
                                    <li>
                                      <strong>
                                        {isNaN(this.state.deviceStat.cpu_usg_data_info)
                                          ? '-'
                                          : (
                                            100 -
                                            this.state.deviceStat
                                              .cpu_usg_data_info /
                                            1
                                          ).toFixed(2) + '%'}

                                      </strong>
                                      CPU
                                      <br />
                                      Available
                                    </li>
                                    {/* EMGT-319-EM - Zabbix configuration field validation */}
                                    {this.state.deviceModel === "vCPE" ||
                                      this.state.deviceModel === "CHIMAY" || this.state.deviceModel === "Packetfence"
                                      ? (
                                        <li>
                                          <strong>
                                            {isNaN(this.state.deviceStat.cpus_count)
                                              ? '-'
                                              : this.state.deviceStat.cpus_count}
                                          </strong>
                                          CPU
                                          <br />
                                          Cores
                                        </li>
                                      ) : ""}
                                  </>
                                ) : (
                                  <li className="text-center flex-grow-1">
                                    No Data
                                  </li>
                                )}
                              </>
                            ) : (<div className="text-center flex-grow-1">Configurations are disabled</div>)}
                          </ul>
                        </div>
                      </div>
                      <div
                        className={this.state.deviceModel === 'Packetfence' ? "disabled-section-wa warning-alerts ml-auto" : "warning-alerts  ml-auto"}>
                        <h1 className="page-title txt-danger mb-2">
                          Alerts{" "}
                          <span>{this.state.totalCountWarningAndAlert}</span>
                        </h1>
                        <div className="row no-gutters">
                          <div className="col-md-12">
                            <a
                              id="pills-Troubleshoot-tab"
                              data-toggle="pill"
                              href="#pills-Troubleshoot"
                              role="tab"
                              aria-controls="pills-Troubleshoot"
                              aria-selected="false"
                              onClick={() => this.navigate("Disaster")
                              }
                            >
                              <div className="emergency">

                                <span>Critical</span>
                                <span className="no">
                                  {this.state.alertWarningCount.Disaster}
                                </span>
                              </div>
                            </a>
                          </div>
                          <div className="col-md-12">
                            <a
                              id="pills-Troubleshoot-tab"
                              data-toggle="pill"
                              href="#pills-Troubleshoot"
                              role="tab"
                              aria-controls="pills-Troubleshoot"
                              aria-selected="false"
                              onClick={() => this.navigate("High")
                              }
                            >
                              <div className="critical">
                                <span>High</span>{" "}
                                <span className="no">
                                  {this.state.alertWarningCount.High}
                                </span>
                              </div>
                            </a>
                          </div>
                          <div className="col-md-12">
                            <a
                              id="pills-Troubleshoot-tab"
                              data-toggle="pill"
                              href="#pills-Troubleshoot"
                              role="tab"
                              aria-controls="pills-Troubleshoot"
                              aria-selected="false"
                              onClick={() => this.navigate("Average")
                              }
                            >
                              <div className="average">
                                <span>Medium</span>{" "}
                                <span className="no">
                                  {this.state.alertWarningCount.Average}
                                </span>
                              </div>
                            </a>
                          </div>
                          <div className="col-md-12">
                            <a
                              id="pills-Troubleshoot-tab"
                              data-toggle="pill"
                              href="#pills-Troubleshoot"
                              role="tab"
                              aria-controls="pills-Troubleshoot"
                              aria-selected="false"
                              onClick={() => this.navigate("Warning")
                              }
                            >
                              <div className="warning">
                                <span>Low</span>{" "}
                                <span className="no">
                                  {this.state.alertWarningCount.Warning}
                                </span>
                              </div>
                            </a>
                          </div>
                          <div className="col-md-12">
                            <a
                              id="pills-Troubleshoot-tab"
                              data-toggle="pill"
                              href="#pills-Troubleshoot"
                              role="tab"
                              aria-controls="pills-Troubleshoot"
                              aria-selected="false"
                              onClick={() => this.navigate("Information")
                              }
                            >
                              <div className="information">
                                <span>Information</span>{" "}
                                <span className="no">
                                  {this.state.alertWarningCount.Information}
                                </span>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters">
                    <div className="col-md-5 col-sm-12">
                      <div
                        key={this.state.keyUpdate}
                        className="gps-box bg-white h-100 d-flex flex-column"
                      >
                        {this.state.deviceData.is_headendcpe !== "1" && (
                          <div
                            className={
                              this.state.mapFullView
                                ? "bg-white radius-spacing map"
                                : "bg-white radius-spacing map"
                            }
                          >
                            {/* {this.state.deviceModel !== 'Packetfence' && */}
                            <div className={this.state.deviceModel === 'Packetfence' ? "disabled-section-wa" : "gps-location"}>
                              {this.state.long_lat === 0 &&
                                <>
                                  <li
                                    className="nav-item fwidth float-right ml-2"
                                    key="fullscreen"
                                    onClick={() =>
                                      this.setState({ mapFullView: true })
                                    }
                                  >
                                    <img
                                      src="./images/svg-icons/fullscreen.svg"
                                      className="view-btn"
                                      alt=""
                                    />
                                  </li>
                                  <li
                                    key="fullscreen"
                                    title="Refresh Telemetry data"
                                    className="nav-item fwidth float-right"
                                    onClick={this.state.deviceData.device_status === 1 ?
                                      () => this.refreshTelemetry() : undefined}
                                  >
                                    <img
                                      className={this.state.deviceData.device_status === 1 ? "" : "disabled"}
                                      style={{ width: "23px" }}
                                      src="/images/refreshT.png"
                                      alt=""
                                    />
                                  </li>
                                </>}
                              <h1 className="page-title txt-blue mb-2">
                                GPS Location
                              </h1>
                              {this.state.long_lat === 0 ? (
                                <div className="row no-gutters">
                                  {/* EMGT-110 : GPS Map not reporting accurate location of device */}
                                  {this.state.mapAddress && (this.state.center[0] !== "0.000000" || this.state.center[1] !== "0.000000") ? (
                                    <>
                                      <div className="col-md-6 col-sm-12 ">
                                        <div className="gps-address pr-md-4 pr-sm-0 mt-md-0 mt-sm-3">
                                          <div className="di-list p-0">
                                            <small>Address</small>
                                            <span>
                                              {this.state.mapAddress
                                                .display_name !== "Soul Buoy"
                                                ? this.state.mapAddress
                                                  .display_name
                                                : ""}
                                            </span>
                                          </div>
                                          <div className="d-flex">
                                            <div className="di-list w-50">
                                              <small>Latitude</small>
                                              <span>{this.state.center[0]}</span>
                                            </div>
                                            <div className="di-list  w-50">
                                              <small>Longitude</small>
                                              <span>{this.state.center[1]}</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-6 col-sm-12 " key={this.state.mapUpdate}>
                                        {this.state.center.length > 0 && (
                                          <Mapnew
                                            selectedLayer={this.state.selectedLayer}
                                            setSelectedLayer={this.setSelectedLayer}
                                            center={this.state.center}
                                            zoom={10}
                                            maxZoom={20}
                                            scrollWheelZoom={true}
                                          />
                                        )}
                                      </div>
                                    </>
                                  ) : (
                                    <div>Location not available</div>
                                  )}
                                </div>
                              )
                                : (
                                  <div>Configurations are disabled</div>
                                )}
                            </div>
                            {/* } */}
                          </div>
                        )}

                        <div className="device-info mt-2">
                          <h1 className="page-title txt-blue mb-2">
                            Device Info
                          </h1>
                          <div className="row no-gutters">
                            <div className="col-md-4 col-sm-12">
                              <div className="di-list">
                                <small>Serial Number</small>
                                <span className="text-truncate">
                                  {this.state.deviceData.device_serial}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-4 col-sm-12">
                              <div className="di-list">
                                <small>MAC Address</small>
                                <span className="text-truncate">
                                  {this.state.deviceData.device_mac
                                    ? this.state.deviceData.device_mac.toUpperCase()
                                    : ""}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-4 col-sm-12">
                              <div className="di-list">
                                <small>Model Number</small>
                                <span className="text-truncate">
                                  {this.state.deviceData.device_model}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="row no-gutters">
                            {this.state.deviceModel !== "vCPE" && this.state.deviceModel !== "Packetfence" && (
                              <div className="col-md-4 col-sm-12">
                                <div className="di-list">
                                  <small>IMEI</small>
                                  <span className="text-truncate">
                                    {this.state.deviceData.device_imei}
                                  </span>
                                </div>
                              </div>
                            )}
                            <div className="col-md-4 col-sm-12">
                              <div className="di-list">
                                <small>Mgmt. Plane IP</small>
                                <span className="text-truncate">
                                  {
                                    this.state.deviceData
                                      .device_control_tunnel_ip
                                  }
                                </span>
                              </div>
                            </div>
                            {this.state.deviceModel !== "vCPE" && <div className="col-md-4 col-sm-12">
                              <div className="di-list">
                                <small>Web UI Password</small>
                                <span className="text-truncate">
                                  {
                                    this.state.deviceStat.webui_pwd !== ""
                                      ? <>
                                        <input
                                          className="form-control"
                                          type="password"
                                          id="webui-password"
                                          value={this.state.deviceStat.webui_pwd}
                                          readOnly={true}
                                          name="password"
                                        />
                                        <div className="sh-icon" style={{ top: "24px", right: "20px" }}>
                                          <img
                                            src={
                                              this.state.showWebUiPassword
                                                ? "/images/show.png"
                                                : "/images/hide.png"
                                            }
                                            alt=""
                                            onClick={() => this.showWebUiPass()}
                                          />
                                          &nbsp;
                                          {this.state.webUiCopied === false
                                            ? (
                                              <div
                                                className="copy-icon">
                                                <CopyToClipboard text={this.state.deviceStat.webui_pwd}
                                                  onCopy={() => this.copyWebUiPassword()}>
                                                  <img
                                                    className="copy"
                                                    src="/images/svg-icons/copy.svg"
                                                    alt=""
                                                  />
                                                </CopyToClipboard>
                                                <div className="tooltip">
                                                  Copy Password
                                                </div>
                                              </div>
                                            ) : (
                                              <div className="copy-icon">
                                                <img
                                                  className="copy"
                                                  src="/images/svg-icons/tick.svg"
                                                  alt=""
                                                />
                                                <div className="tooltip hideme">
                                                  Copied
                                                </div>
                                              </div>
                                            )}
                                        </div>
                                      </>
                                      : "-"
                                  }
                                </span>
                              </div>
                            </div>}
                            {this.state.deviceModel === "CHIMAY" && <div className="col-md-4 col-sm-12">
                              <div className="di-list">
                                <small>Modem Version</small>
                                <span className="text-truncate">
                                  {
                                    this.state.deviceData
                                      .modem_version
                                  }
                                </span>
                              </div>
                            </div>}
                            {(this.state.deviceModel === "vCPE" || this.state.deviceModel === "Packetfence") && (
                              <div className="col-md-4 col-sm-12">
                                <div className="di-list">
                                  <small>{`Internet WAN IP${this.state.deviceModel === "Packetfence" ? '/Domain' : ''}`}</small>
                                  <span className="text-truncate">
                                    {this.state.deviceModel === "Packetfence" ? this.state.deviceData.device5g_interface_ip :
                                      this.state.deviceData.device_eth_wan_ip}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="row no-gutters">
                            <div className="col-md-4 col-sm-12">
                              <div className="di-list mb-0">
                                <small>Registered On</small>
                                <span className="text-truncate"
                                  title={this.getTimeExact(this.state.deviceData.created_date)}
                                >
                                  {this.getTimeExact(
                                    this.state.deviceData.created_date
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-4 col-sm-12">
                              <div className="di-list">
                                <small>Firmware Version</small>
                                <span className="text-truncate" title={this.state.deviceData
                                  .device_firmware_version}>
                                  {this.state.deviceData
                                    .device_firmware_version === ""
                                    ? "-"
                                    : this.state.deviceData
                                      .device_firmware_version}
                                </span>
                              </div>
                            </div>
                          </div>
                          {/* LONG-2708-EM - MiFi || Device Dashboard || Password Changes || Frontend */}
                          {this.state.deviceModel === "CHIMAY" ||
                            this.state.deviceModel === "vCPE" || this.state.deviceModel === "Packetfence" ? (
                            <div className="row no-gutters">
                              {/* Increased device password box size */}
                              <div className="col-md-12 col-sm-12">
                                <div className="di-list mb-0">
                                  <div className="form-group">
                                    <label>Device Password</label>
                                    <input
                                      className="form-control"
                                      type="password"
                                      id="device-password"
                                      placeholder=""
                                      defaultValue={
                                        this.state.deviceData.device_password
                                      }
                                      readOnly={true}
                                      name="password"
                                    />
                                    <div className="sh-icon">
                                      <img
                                        src={
                                          this.state.showPassword
                                            ? "/images/show.png"
                                            : "/images/hide.png"
                                        }
                                        alt=""
                                        onClick={() => this.showPass()}
                                      />
                                      &nbsp;
                                      {this.state.copied === false ? (
                                        <div className="copy-icon">
                                          {/* EMGT-435-EM - Not Able to copy the Encrypted password in HTTP on KVM bringup EM */}
                                          <CopyToClipboard text={this.state.deviceData.device_password}
                                            onCopy={() => this.copyPassword()}>
                                            <img
                                              className="copy"
                                              src="/images/svg-icons/copy.svg"
                                              alt=""
                                            />
                                          </CopyToClipboard>
                                          <div className="tooltip">
                                            Copy Password
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="copy-icon">
                                          <img
                                            className="copy"
                                            src="/images/svg-icons/tick.svg"
                                            alt=""
                                          />
                                          <div className="tooltip hideme">
                                            Copied
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-7 col-sm-12">
                      <div className="d-flex flex-column h-100">
                        <div className={this.state.deviceModel === 'Packetfence' ? "disabled-section" : "du-box bg-white h-fill"}>
                          <div className="d-flex justify-content-between align-items-center">
                            <h1 className="page-title txt-blue flex-grow-1">
                              Total Bandwidth Usages
                            </h1>
                            {!this.state.dataUsage_Conf &&
                              <>
                                <div className="form-pos">
                                  <select
                                    className="form-control"
                                    onChange={this.setUsageType}
                                    value={this.state.dataUsageType}
                                    disabled={this.state.selectedDuration === "custom" && this.state.endDate === ''}
                                    style={{ cursor: this.state.selectedDuration === "custom" && this.state.endDate === '' ? "not-allowed" : "" }}
                                  >
                                    <option value='daily'>Daily</option>
                                    <option value='cumulative'>Cumulative</option>
                                  </select>
                                  <select
                                    className="form-control"
                                    onChange={(e) => this.getDeviceUsageData(e)}
                                    value={this.state.selectedDuration.toString()}
                                  >
                                    <option value='1'>Last 24 hours</option>
                                    <option value='7'>Week</option>
                                    <option value='30'>Month</option>
                                    <option value='custom'>Custom</option>
                                  </select>
                                  {this.state.custom && <div className="tbu-date">
                                    <div className="form-group" id="schedulerDiv">
                                      <label>Start Date</label>
                                      <Calendar
                                        id="navigatorstemplate"
                                        value={this.state.startDate}
                                        onChange={(e) => this.setStartTime(e)}
                                        readonlyInput="true"
                                        placeholder="MM/DD/YYYY"
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        hideOnDateTimeSelect={true}
                                        yearRange={`${new Date().getFullYear() - 10}: ${new Date().getFullYear() + 10}`}
                                        maxDate={new Date()}
                                        minDate={new Date(moment().subtract(90, 'days'))}
                                      />
                                    </div>

                                    <div className="form-group" id="schedulerDiv">
                                      <div className={this.state.startDate ? "" : "disabled"} key={this.state.keyUpdate}>
                                        <label>End Date</label>
                                        <Calendar
                                          id="navigatorstemplate"
                                          value={this.state.endDate}
                                          onChange={this.state.startDate ? (e) => this.setEndTime(e) : "disabled"}
                                          disabled={this.state.startDate === '' ? true : false}
                                          readonlyInput="true"
                                          placeholder="MM/DD/YYYY"
                                          monthNavigator={true}
                                          yearNavigator={true}
                                          showOtherMonths={false}
                                          hideOnDateTimeSelect={true}
                                          yearRange={`${new Date().getFullYear() - 10}: ${new Date().getFullYear() + 10}`}
                                          minDate={new Date(moment(this.state.startDate).add(1, 'days'))}
                                          maxDate={new Date(moment(this.state.startDate).add(90, 'days')) > new Date() ? new Date() :
                                            new Date(moment(this.state.startDate).add(90, 'days'))}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  }
                                  <select
                                    className="form-control"
                                    onChange={this.setDataUsageType}
                                    value={this.state.selectedUsageOption}
                                    disabled={this.state.selectedDuration === "custom" && this.state.endDate === ''}
                                    style={{ cursor: this.state.selectedDuration === "custom" && this.state.endDate === '' ? "not-allowed" : "" }}
                                  >
                                    {!this.state.wan_data_conf &&
                                      (this.state.deviceModel !== 'Moretti_Model' && this.state.deviceModel !== 'Duvel_Model' && this.state.deviceModel !== 'Stormbreaker' && this.state.deviceModel !== 'Firestorm' &&
                                        this.state.deviceModel !== "Bradshaw" && this.state.deviceModel !== "Orval" &&
                                        this.state.deviceModel !== "Sander")
                                      &&
                                      <option value='wan_data_usage'>ETH-WAN</option>}
                                    {!this.state.cellular_data_conf && this.state.deviceModel !== 'vCPE' &&
                                      <option value='cellular_data_usage'>Cellular-WAN</option>}
                                    {!this.state.control_tunnel_conf && <option value='control_tunnel_usage'>Mgmt. Plane</option>}
                                    {/* {!this.state.data_tunnel_conf &&
                                      (this.state.deviceModel !== 'Moretti_Model' && this.state.deviceModel !== 'Duvel_Model' && this.state.deviceModel !== 'Stormbreaker' && this.state.deviceModel !== 'Firestorm')
                                      &&
                                      <option value='Data-Tunnel'>Data Tunnel</option>}
                                    {!this.state.wifi_data_conf &&
                                      (this.state.deviceModel !== 'Moretti_Model' && this.state.deviceModel !== 'Duvel_Model' && this.state.deviceModel !== 'Stormbreaker' && this.state.deviceModel !== 'Firestorm' && this.state.deviceModel !== 'vCPE')
                                      &&
                                      <option value='Wifi-Usage'>Wifi Usage</option>} */}
                                  </select>
                                  <button
                                    className="form-control border-0"
                                    style={{ width: "100px" }}
                                    title={"Download Graph Data"}
                                    onClick={() => { this.downloadGraph(this.state.graphData, "Bandwidth") }}
                                  >
                                    <img src="/images/svg-icons/download-arrow.svg" />
                                  </button>
                                  <li
                                    className="nav-item fwidth"
                                    key="fullscreen"
                                    onClick={() => this.setState({ bandwidthFullView: true })}>

                                    <img
                                      src="./images/svg-icons/fullscreen.svg"
                                      className="view-btn"
                                      alt=""
                                    />
                                  </li>
                                </div>
                              </>
                            }
                          </div>
                          {!this.state.dataUsage_Conf ?
                            <>
                              {this.state?.graphData?.length !== 0 ?
                                <div
                                  className={this.state.custom ? "graph-box mt-8" : "graph-box mt-2"}
                                  key={this.state.keyUpdate}
                                >
                                  <HighchartsReact
                                    highcharts={Highcharts}
                                    options={this.state.option}
                                  />
                                </div> : <div className="no-data-key">There are no records to display</div>}
                            </>
                            : (
                              <div className="text-center"
                                style={{
                                  "display": "flex",
                                  "align-items": "center",
                                  "justify-content": "center",
                                  "height": "80%"
                                }}>
                                Configurations are disabled</div>
                            )}
                        </div>
                        <div className="du-box bg-white mt-2">
                          <div className="d-flex justify-content-between align-items-center ">
                            <h1 className="page-title text-blue">
                              Device Connectivity
                              <div
                                className="tooltip-c"
                                data-toggle="tooltip"
                                data-placement="right"
                                title="To check connectivity time span mouse hover on the bar"
                              >
                                <img src="/images/svg-icons/info.svg" alt="" />
                              </div>
                              <div className="sesion-time">
                                {`Downtime in Last ${this.state.selected_con_time}`}{" "}
                                <strong>{`${this.state.totalDownTime} (HH:mm:ss)`}</strong>
                              </div>
                            </h1>
                            <ul className="nav nav-pills">
                              <li className="nav-item">
                                <button
                                  className={
                                    this.state.selectedDurationForCon === 1
                                      ? "nav-link active"
                                      : "nav-link"
                                  }
                                  onClick={() => this.getDeviceUpTime(1)}
                                >
                                  24 hrs
                                </button>
                              </li>
                              <li className="nav-item">
                                <button
                                  className={
                                    this.state.selectedDurationForCon === 7
                                      ? "nav-link active"
                                      : "nav-link"
                                  }
                                  onClick={() => this.getDeviceUpTime(7)}
                                >
                                  7 days
                                </button>
                              </li>
                              <li className="nav-item">
                                <button
                                  className={
                                    this.state.selectedDurationForCon === 30
                                      ? "nav-link active"
                                      : "nav-link"
                                  }
                                  onClick={() => this.getDeviceUpTime(30)}
                                >
                                  30 Days
                                </button>
                              </li>
                              <li
                                className="nav-item fwidth pt-1 pl-2"
                                key="fullscreen"
                                onClick={() =>
                                  this.setState({ connectivityFullView: true })
                                }
                              >
                                <img
                                  src="./images/svg-icons/fullscreen.svg"
                                  className="view-btn"
                                  alt=""
                                />
                              </li>
                            </ul>

                          </div>
                          <div className="graph-box mt-4 dc-graph">
                            {this.state.conData.length > 0 ? (
                              <ConGraph
                                device_status={this.state.deviceData.device_status}
                                redirect={(time, status) => this.reportpage(time, status)}
                                data={this.state.conData}
                                selected_con_time={this.state.selected_con_time}
                              />
                            ) : (
                              <div className="text-center p-5">
                                No Data Available
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* // end dashboard tab // */}
                <div
                  className="tab-pane fade"
                  id="pills-cd"
                  role="tabpanel"
                  aria-labelledby="pills-cd-tab"
                >
                  {this.state.selectedTab === "ConnectedClient" && (
                    <ConnectedClientList
                      data={this.state.deviceData}
                      deviceData={this.state.deviceData}
                    />
                  )}
                </div>
                {/* // end Connected Clients tab // */}

                {/* {this.state.selectedTab === "Warning&Alerts" && (
                  <div
                    className="tab-pane fade"
                    id="pills-wa"
                    role="tabpanel"
                    aria-labelledby="pills-wa-tab"
                  >
                    <Warnings data={this.state.device_id} />
                  </div>
                )} */}

                {/* // end Warning alerts tab // */}
                <div
                  className="tab-pane fade"
                  id="pills-reports"
                  role="tabpanel"
                  aria-labelledby="pills-reports-tab"
                >
                  reports
                </div>
                {/* // end reports tab // */}

                <div
                  className="tab-pane fade"
                  id="pills-templates"
                  role="tabpanel"
                  aria-labelledby="pills-templates-tab"
                >
                  {this.state.selectedTab === "AppliedTemplate" && (
                    <AppliedTemplateList
                      id={this.state.device_id}
                      deviceData={this.state.deviceData}
                      selectedTab={(tab) => this.changeSelectedTab(tab)}
                      refreshDropDown={() => this.getDeviceDetails()}
                      supported_templates={this.state.DeviceLookUpDeviceList.supported_templates}
                    />
                  )}
                </div>

                {/* // end templates tab // */}
                {this.state.selectedTab === "ModemInfo" && (
                  <div
                    className="tab-pane fade"
                    id="pills-modeminfo"
                    role="tabpanel"
                    aria-labelledby="pills-modeminfo-tab"
                  >
                    <ModemInfo data={this.state.deviceData}
                      model={this.state.deviceModel}
                      supported_templates={this.state.deviceData.supported_templates} />
                  </div>
                )}
                {this.state.selectedTab === "Networks" && (
                  <div
                    className="tab-pane fade"
                    id="pills-Networks"
                    role="tabpanel"
                    aria-labelledby="pills-Networks-tab"
                  >
                    <NetworkView
                      id={this.state.device_id}
                      data={this.state.deviceData}
                    />
                  </div>
                )}
                {this.state.selectedTab === "Tunnels" && (
                  <div
                    className="tab-pane fade"
                    id="pills-Tunnels"
                    role="tabpanel"
                    aria-labelledby="pills-Tunnelss-tab"
                  >
                    <TunnelsView
                      mac={this.state.deviceData.device_mac}
                      data={this.state.deviceData}
                    />
                  </div>
                )}
                {this.state.selectedTab === "Troubleshoot" && (
                  <div
                    className="tab-pane fade"
                    id="pills-Troubleshoot"
                    role="tabpanel"
                    aria-labelledby="pills-Troubleshoot-tab"
                  >
                    <Troubleshoot
                      id={this.state.device_id}
                      data={this.state.deviceData}
                      deviceSeverity={this.state.deviceSeverity}
                      selected_Tab={this.state.selected_Tab}
                      tabValue={this.state.tabValue}
                      refreshDeviceDetails={() => this.getDeviceDetails()}
                      refreshDropDown={(val) => this.setState({ deviceOptions: val })}
                    />
                  </div>
                )}
                {this.state.selectedTab === "Device-Checkpoint" && (
                  <div
                    className="tab-pane fade"
                    id="pills-Device-Checkpoint"
                    role="tabpanel"
                    aria-labelledby="pills-Device-Checkpoint-tab"
                  >
                    <DeviceCheckpoint
                      id={this.state.device_id}
                      deviceData={this.state.deviceData}
                    />
                  </div>
                )}
                {this.state.selectedTab === "Report" && (
                  <div
                    className="tab-pane fade"
                    id="pills-Report"
                    role="tabpanel"
                    aria-labelledby="pills-Report-tab"
                  >
                    <Report
                      deviceModel={this.state.deviceModel}
                      id={this.state.device_id}
                      startDate={this.state.status === 'green' ? this.state.endTime : moment(this.state.startTime).subtract(5, 'minutes')._d}
                      endDate={this.state.status === 'green' ? moment(this.state.endTime).add(5, 'minutes')._d : this.state.startTime}
                    />
                  </div>
                )}
                {this.state.selectedTab === "Chronology" &&
                  <div
                    className="tab-pane fade"
                    id="pills-Chronology"
                    role="tabpanel"
                    aria-labelledby="pills-Chronology-tab"
                  >
                    <Chronology
                      id={this.state.device_id}
                      device_mac={this.state.deviceData.device_mac}
                    />
                  </div>
                }
                {this.state.selectedTab === "Polling" && (
                  <div
                    className="tab-pane fade"
                    id="pills-Polling"
                    role="tabpanel"
                    aria-labelledby="pills-Polling-tab"
                  >
                    <DataPolling
                      deviceModel={this.state.deviceModel}
                      id={this.state.device_id}
                    />
                  </div>
                )}
              </div>
              {this.state.showList && (
                <LinkPopup
                  title={this.state.title}
                  message={this.state.message}
                  open={() => this.openOperationList(this.state.id)}
                  close={() => this.closeLinkpopup()}
                />
              )}

              {this.state.showCheck && (
                <ErrorPopup
                  title={this.state.title}
                  message={this.state.message}
                  error={this.state.error}
                  delete={this.state.delete}
                  reset={() => this.resetPopupFixedIp()}
                  onSubmit={() => this.changeIsFixedIp()}
                />
              )}
              {/* LONG-3010 : Page freezed when the user enabled/disable the Fixed IP  */}
              {this.state.showFixed && (
                <ErrorPopup
                  title={this.state.title}
                  message={this.state.message}
                  error={this.state.error}
                  delete={this.state.delete}
                  reset={() => this.resetPopupFixedIp()}
                  onSubmit={() => this.changeIsFixedIp()}
                />
              )}
            </div>
          )
          }
        </section>
        {this.state.showRefreshModal && <ErrorHandlePopup />}
        {
          this.state.showModel && (
            <ErrorPopup
              title={this.state.title}
              message={this.state.message}
              error={this.state.error}
              delete={this.state.delete}
              dataReset={this.state.dataReset}
              factoryReset={this.state.factoryReset}
              reset={() => this.resetPopup()}
              redirect={this.state.redirect}
              onCancel={() => this.factoryResetDevices(this.state.device_id)}
              onSubmit={() => {
                this.setState({ is_bolt_reset: true }, () =>
                  this.factoryResetDevices(this.state.device_id)
                );
              }}
            />
          )
        }
        {
          this.state.show && (
            <ErrorPopup
              title={this.state.title}
              message={this.state.message}
              error={this.state.error}
              delete={this.state.delete}
              dataReset={this.state.dataReset}
              offlineDevice={this.state.offlineDevice}
              onCancel={() => this.onCancel()}
              reset={() => this.resetPopup()}
              onSubmit={() =>
                this.chooseAction(
                  this.state.delete,
                  this.state.factoryReset,
                  this.state.reboot,
                  this.state.device_id,
                  this.state.dataReset,
                  this.state.redirect
                )
              }
            />
          )
        }
        {
          (this.state.isAxiosError) &&
          <ErrorPopup
            title={this.state.title}
            message={this.state.message}
            error={this.state.error}
            delete={this.state.delete}
            reset={() => this.setState({ isAxiosError: false })}
          />
        }
      </div >
    );
    // else return <div>{this.props.history.push("/")}</div>;
  }
}
const mapStateToProps = (state) => {
  return {
    authenticated: state.auth.authenticated,
    open: state.displaySidebarMenu.openSide,
    userRole: String(state.auth.role)
  };
};
export default withRouter(connect(mapStateToProps)(DeviceDetails));