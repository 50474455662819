import React, { useMemo } from 'react';
import Select from 'react-select-virtualized';

const DropDown = ({ DeviceLookUpDeviceList = [], deviceOptions = [], deviceData, onDropChange }) => {
    // Memoized and sorted list of device options by `device_name`
    const sortedDeviceOptions = useMemo(() => {
        if (!Array.isArray(DeviceLookUpDeviceList)) return [];
        return DeviceLookUpDeviceList.slice().sort((a, b) =>
            (a.device_name || '').localeCompare(b.device_name || '')
        );
    }, [DeviceLookUpDeviceList]);

    // Memoized selected value based on the `device_id`
    const selectedValue = useMemo(() => {
        if (!Array.isArray(deviceOptions)) return null;
        return deviceOptions.find(option => option.value === deviceData?.device_id) || null;
    }, [deviceOptions, deviceData?.device_id]);

    // Function to customize the label rendering for dropdown options
    const getOptionLabel = (item) => (
        <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden', width: '100%' }}>
            {/* Status indicator as a colored dot */}
            <div
                style={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    backgroundColor: item.device_status === 1 ? 'green' : 'red',
                    marginRight: '8px',
                }}
            ></div>
            {/* Display device name with ellipsis for overflow */}
            <span
                style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: 'calc(100% - 16px)',
                }}
                title={item.label} // Tooltip for full label
            >
                {item.label}
            </span>
        </div>
    );

    return (
        <div style={{ width: '100%', maxWidth: '400px' }}>
            <Select
                options={sortedDeviceOptions.map(item => ({
                    value: item.device_id,
                    label: item.device_name,
                    device_status: item.device_status,
                }))}
                placeholder="Select a device"
                className='select-device'
                value={selectedValue}
                onChange={onDropChange}
                formatOptionLabel={getOptionLabel} // Custom option rendering
                filterOption={(option, inputValue) =>
                    option.label.toLowerCase().includes(inputValue.toLowerCase())
                } // Case-insensitive filtering
                styles={{
                    control: (provided) => ({
                        ...provided,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }),
                    menu: (provided) => ({
                        ...provided,
                        width: '100%',
                    }),
                }}
            />
        </div>
    );
};

export default React.memo(DropDown);