import React, { Component } from 'react'
import * as Action from '../../redux/actionTypes';
import { connect } from "react-redux";
import ApiCall from "../../redux/API/apiCall";
import urlMapping from "../../redux/API/api";
import { Loader } from '../../layOuts/Loader';
import { ErrorPopup } from '../../layOuts/errorPopup';
import ErrorHandlePopup from '../ErrorHandlePopup';
import * as Common from './../../utils/common';
import $ from 'jquery';
class AddNewDevice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRefreshModal: false,
            deviceId: "",
            deviceModel: this?.props?.supported_models?.replaceAll(',', '/') || "Select",
            deviceModelName: this?.props?.supported_models ? Common.getModelNameFromModel(this?.props?.supported_models?.replaceAll(',', '/')) : "",
            wanIP: "",
            imei: "",
            macAddress: "",
            validWanIP: true,
            validIMEI: true,
            validMacAddress: true,
            link: "",
            activationMessage: '',
            showLoader: false,
            title: '',
            message: '',
            show: false,
            error: false,
            activatedLicense: false,
            isAxiosError: false,
            selectedFileName: undefined,
            downlaodEnable: false,
            modelData: Common.modelData() || []
        };
    };

    componentDidMount() {
        this.checkLicenseValidity();
    };

    checkLicenseValidity = () => {
        ApiCall(urlMapping.checkLicenseValidity(), (response) => {
            if (response?.data?.is_activated) {
                this.setState({
                    activatedLicense: true
                })
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({ showRefreshModal: true })
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        })
    };

    closeAddDevice = () => {
        this.props?.group_id ? this.props?.getDeviceGroupDetails() : this.props?.getDeviceList();
        this.props.dispatch({
            type: Action.CLOSE_ADD_DEVICE,
        });
    };

    setDeviceModel = (value) => {
        const elements = document.getElementsByClassName("error-txt");
        for (let i = 0; i < elements.length; i++) {
            elements[i].style.cssText = "display: none;"
        }
        let {
            deviceModel,
            deviceModelName,
            wanIP,
            imei,
            macAddress,
            validWanIP,
            validIMEI,
            validMacAddress,
            link,
            activationMessage,
            downlaodEnable
        } = this.state;
        wanIP = "";
        imei = "";
        macAddress = "";
        link = "";
        activationMessage = "";
        downlaodEnable = false;
        if (value) {
            deviceModel = value;
            deviceModelName = Common.getModelNameFromModel(value);
            switch (deviceModelName) {
                case "Cloud Router":
                    validWanIP = false;
                    break;
                case "Chimay":
                case "Stormbreaker":
                case "Firestorm":
                case "Bradshaw":
                case "Orval":
                case "Kona":
                case "Sanders":
                case "Duvel":
                    validIMEI = false;
                    break;
                case "Packet Fence":
                    validMacAddress = false;
                    break;
                default:
                    deviceModelName = "";
                    validWanIP = true;
                    validIMEI = true;
                    validMacAddress = true;
            }
        }
        this.setState({
            deviceModel,
            deviceModelName,
            wanIP,
            imei,
            macAddress,
            validWanIP,
            validIMEI,
            validMacAddress,
            link,
            activationMessage,
            downlaodEnable
        });
    };

    setWanIP = (e) => {
        this.removeError(e);
        this.setState({
            wanIP: e.target.value,
            validWanIP: true
        })
    };

    setIMEI = (e) => {
        if (Common.validateNumeric(e)) {
            this.removeError(e);
            this.setState({
                imei: e.target.value,
                validIMEI: true
            })
        }
    };

    setMacAddress = (e) => {
        this.removeError(e);
        this.setState({
            macAddress: e.target.value,
            validMacAddress: true
        })
    };

    removeError = (e) => {
        const { name } = e.target;
        const element = document.getElementById(name);
        if (element) {
            element.style.display = 'none';
        }
        this.setState({
            link: "",
            activationMessage: "",
            selectedFileName: undefined,
            downlaodEnable: false,
        });
        const fileInput = document.getElementById("csvFile");
        if (fileInput) {
            fileInput.value = "";
        }
    };

    validateWanIP = (e) => {
        const element = document.getElementById("wanIP");
        if (element) {
            if (!Common.validateIPaddress(e)) {
                element.style.display = "block";
                element.innerHTML = 'The WAN IP is not valid';
                this.setState({
                    validWanIP: false
                })
            }
            else {
                element.style.display = 'none';
                this.setState({
                    validWanIP: true
                })
            }
        }
    };

    validateIMEI = (e) => {
        const imei = e.target.value;
        const element = document.getElementById('imei');
        if (element) {
            if (imei.length < 15 || !this.isValidIMEI(imei)) {
                element.style.display = 'block';
                element.innerHTML = "The IMEI number is not valid";
                this.setState({
                    validIMEI: false
                });
            } else {
                element.style.display = 'none';
                this.setState({
                    validIMEI: true
                });
            }
        }
    };

    isValidIMEI = (imei) => {
        if (!/^\d{15}$/.test(imei)) {
            return false;
        }
        // Calculate the Luhn checksum (IMEI check digit)
        let checksum = 0;
        for (let i = 0; i < 14; i++) {
            let digit = parseInt(imei[i], 10);
            if (i % 2 === 0) {
                checksum += digit;
            } else {
                let doubled = digit * 2;
                checksum += Math.floor(doubled / 10) + (doubled % 10);
            }
        }
        // Check if the total checksum plus the last digit is divisible by 10
        return (checksum + parseInt(imei[14], 10)) % 10 === 0;
    };

    validateMacAddress = (e) => {
        const element = document.getElementById('macAddress');
        if (element) {
            if (!Common.validateMac(e)) {
                element.style.display = "block";
                element.innerHTML = "The MAC Address is not valid";
                this.setState({
                    validMacAddress: false
                })
            }
            else {
                element.style.display = 'none';
                this.setState({
                    validMacAddress: true
                })
            }
        }
    };

    resetPopup = () => {
        this.setState({
            show: false,
            error: false,
        }, () => {
            $("#errorMessage").modal("hide");
            !this.props.group_id && this.closeAddDevice();
        })
    };

    checkError = () => {
        const { deviceModelName, wanIP, validWanIP, imei, validIMEI } = this.state;
        let result = false;
        if (deviceModelName === "") {
            result = true;
        } else if (deviceModelName === "Cloud Router") {
            if (wanIP.trim() === "" || !validWanIP) {
                result = true;
            }
        } else if (
            deviceModelName === "Chimay" ||
            deviceModelName === "Stormbreaker" ||
            deviceModelName === "Firestorm" ||
            deviceModelName === "Bradshaw" ||
            deviceModelName === "Orval" ||
            deviceModelName === "Kona" ||
            deviceModelName === "Sanders" ||
            deviceModelName === "Duvel"
        ) {
            if (imei.trim() === "" || !validIMEI) {
                result = true;
            }
        }
        return result;
    };

    checkPfError = () => {
        const { deviceModelName, macAddress, validMacAddress } = this.state;
        let result = false;
        if (deviceModelName === "") {
            result = true;
        } else if (deviceModelName === "Packet Fence") {
            if (macAddress.trim() === "" || !validMacAddress) {
                result = true;
            }
        }
        return result;
    };

    checkDeviceGroupError = () => {
        const { selectedFileName } = this.state;
        const hasFileError = !selectedFileName || !/\.csv$/i.test(selectedFileName.name.trim());
        const hasOtherErrors = this.checkError() || this.checkPfError();
        return hasFileError && hasOtherErrors;
    };


    addPfDevice = () => {
        this.setState({ showLoader: true });
        const { deviceModelName, macAddress } = this.state;
        const data = {
            device_model: deviceModelName,
            device_id: macAddress
        };
        ApiCall(urlMapping.addDevicePTK(data), (response) => {
            if (response?.success) {
                this.setState({
                    title: 'Add New Device',
                    message: response?.message,
                    showLoader: false,
                    delete: false,
                    error: false,
                    show: true
                }, () => $("#errorMessage").modal("show"))
            }
            else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showRefreshModal: true,
                        showLoader: false
                    });
                }
                else if (
                    response?.isAxiosError &&
                    response?.success === undefined &&
                    response?.response?.data?.success === undefined
                ) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (!response?.success && response?.message !== "") {
                    this.setState({
                        title: 'Add New Device',
                        message: response?.message,
                        showLoader: false,
                        delete: false,
                        error: true,
                        show: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({
                        showLoader: false
                    })
                }
            }
        })
    };

    generateActivation = () => {
        this.setState({ showLoader: true });
        const { deviceModelName, wanIP, imei } = this.state;
        const data = {
            device_model: deviceModelName,
            device_id:
                (deviceModelName === "Cloud Router")
                    ? wanIP
                    : imei
        };
        ApiCall(urlMapping.addDevicePTK(data), (response) => {
            if (response?.success) {
                this.setState({
                    link: response?.data,
                    showLoader: false,
                    deviceId: response?.device_id,
                    activationMessage: ""
                })
            }
            else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showRefreshModal: true,
                        showLoader: false,
                        link: "",
                        deviceId: "",
                        activationMessage: ""
                    });
                }
                else if (
                    response?.isAxiosError &&
                    response?.success === undefined &&
                    response?.response?.data?.success === undefined
                ) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true,
                        link: "",
                        deviceId: ""
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (!response?.success && response?.message !== "") {
                    this.setState({
                        activationMessage: response?.message,
                        showLoader: false,
                        link: response?.data,
                        deviceId: response?.device_id
                    })
                }
                else {
                    this.setState({
                        showLoader: false,
                        link: "",
                        deviceId: "",
                        activationMessage: ""
                    });
                }
            }
        })
    };

    addInDeviceGroup = () => {
        this.setState({ showLoader: true });
        const { deviceModelName, macAddress, wanIP, imei } = this.state;
        const data = {
            device_model: deviceModelName,
            device_id:
                (
                    (deviceModelName === "Packet Fence")
                        ? macAddress
                        : (deviceModelName === "Cloud Router")
                            ? wanIP
                            : imei
                ),
            ...(this.props?.group_id && { group_id: this.props.group_id })
        };
        ApiCall(urlMapping.addDevicePTK(data), (response) => {
            if (response?.success) {
                this.setState({
                    title: 'Add New Device',
                    message: response?.message,
                    showLoader: false,
                    delete: false,
                    error: false,
                    show: true,
                    downlaodEnable: true
                }, () => $("#errorMessage").modal("show"))
            }
            else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showRefreshModal: true,
                        showLoader: false,
                        downlaodEnable: false
                    });
                }
                else if (
                    response?.isAxiosError &&
                    response?.success === undefined &&
                    response?.response?.data?.success === undefined
                ) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true,
                        downlaodEnable: false
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (!response?.success && response?.message !== "") {
                    this.setState({
                        title: 'Add New Device',
                        message: response?.message,
                        showLoader: false,
                        delete: false,
                        error: true,
                        show: true,
                        downlaodEnable: false
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({
                        showLoader: false,
                        downlaodEnable: false
                    })
                }
            }
        })
    };

    downloadActivation = () => {
        this.setState({ showLoader: true });
        const data = {
            device_id: this.state.deviceId
        }
        ApiCall(urlMapping.activationKey(data), (response) => {
            if (response) {
                this.setState({
                    showLoader: false
                });
                const csvLines = Object.values(response).join('').split('\n');
                if (csvLines[csvLines.length - 1].includes('[object Object]')) {
                    csvLines.pop();
                }
                const csvData = csvLines.join('\n');
                const blobData = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
                const url = window.URL.createObjectURL(blobData);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Activation_Code.csv');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({ showRefreshModal: true, showLoader: false });
                } else if (
                    response?.isAxiosError &&
                    response?.success === undefined &&
                    response?.response?.data?.success === undefined
                ) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true,
                    }, () => {
                        $("#errorMessage").modal("show");
                    });
                } else {
                    this.setState({
                        showLoader: false
                    });
                }
            }
        });
    };

    downloadSampleCSV = () => {
        const csvContent = `NAME,MAC,IMEI,WAN_IP,IS_FIXED_IP`;
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'sample.csv';
        link.click();
    };

    downloadActivationForGroup = () => {
        this.setState({ showLoader: true });
        const data = {
            group_id: this.props?.group_id
        }
        ApiCall(urlMapping.activationKeyForGroup(data), (response) => {
            if (response) {
                this.setState({
                    showLoader: false
                });
                const csvLines = Object.values(response).join('').split('\n');
                if (csvLines[csvLines.length - 1].includes('[object Object]')) {
                    csvLines.pop();
                }
                const csvData = csvLines.join('\n');
                const blobData = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
                const url = window.URL.createObjectURL(blobData);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Activation_Code.csv');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({ showRefreshModal: true, showLoader: false });
                } else if (
                    response?.isAxiosError &&
                    response?.success === undefined &&
                    response?.response?.data?.success === undefined
                ) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true,
                    }, () => {
                        $("#errorMessage").modal("show");
                    });
                } else {
                    this.setState({
                        showLoader: false
                    });
                }
            }
        });
    };

    uploadCSV = () => {
        const formData = new FormData();
        formData.append("file", this.state.selectedFileName);
        formData.append("group_model", "Cloud Router");
        formData.append("group_id", this.props?.group_id);
        formData.append("approve_status", "1");
        this.setState({
            showLoader: true
        });
        ApiCall(urlMapping.deviceListUpload(formData), (response) => {
            if (response?.success) {
                this.setState({
                    showLoader: false,
                    selectedFile: null,
                    downlaodEnable: true,
                    title: 'CSV Upload',
                    message: "CSV uploaded successfully!",
                    show: true,
                    error: false,
                }, () => {
                    $("#errorMessage").modal("show");
                });
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showLoader: false,
                        showRefreshModal: true,
                        downlaodEnable: false
                    })
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        downlaodEnable: false,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({
                        showLoader: false,
                        title: 'CSV Upload',
                        message: response?.message || "Failed",
                        showLoader: false,
                        downlaodEnable: false,
                        show: true,
                        error: true,
                    }, () => {
                        $("#errorMessage").modal("show");
                    });
                }
            }
        })
    };

    getModelOptions = () => {
        let modelOptions = [];
        Common.modelData().map(models => {
            modelOptions.push(<option value={models}>{models}</option>)
        })
        return modelOptions;
    }

    render() {
        return (
            <div>
                {this.state.showLoader && <Loader />}
                <div className={this.props.openAddDevice ? "slide-overlay open" : "slide-overlay"}></div>
                <div className={this.props.openAddDevice ? "sidebar-slide open" : "sidebar-slide"}>
                    <div className="s-header">
                        <h1 className="ss-title">Add New Device</h1>
                        <button onClick={() => this.closeAddDevice()}>
                            <img src="/images/svg-icons/s-cancel.svg" alt="" />
                        </button>
                    </div>
                    <div className="s-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Device Model</label>
                                    <span className="red-txt">*</span>
                                    <select
                                        className="form-control"
                                        value={this.state?.deviceModel}
                                        disabled={this.props?.group_id}
                                        onChange={(e) => this.setDeviceModel(e.target.value)}
                                    >
                                        <option value="Select">Select Model</option>
                                        {
                                            this.state.modelData.map((model) => {
                                                return (<option value={model}>{model}</option>)
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        {
                            (this.state.deviceModelName === "Cloud Router") &&
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>WAN IP</label>
                                        <span className="red-txt">*</span>
                                        <input
                                            className='form-control'
                                            type="text"
                                            name="wanIP"
                                            maxLength={15}
                                            value={this.state.wanIP}
                                            onChange={(e) => this.setWanIP(e)}
                                            onBlur={(e) => this.validateWanIP(e)}
                                        />
                                        <div className="error-txt" id="wanIP"></div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            (
                                this.state.deviceModelName === "Chimay" ||
                                this.state.deviceModelName === "Stormbreaker" ||
                                this.state.deviceModelName === "Firestorm" ||
                                this.state.deviceModelName === "Bradshaw" ||
                                this.state.deviceModelName === "Orval" ||
                                this.state.deviceModelName === "Kona" ||
                                this.state.deviceModelName === "Sanders" ||
                                this.state.deviceModelName === "Duvel"
                            ) &&
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>IMEI Number</label>
                                        <span className="red-txt">*</span>
                                        <input
                                            type="text"
                                            name="imei"
                                            className="form-control"
                                            maxLength={15}
                                            value={this.state.imei}
                                            onChange={(e) => this.setIMEI(e)}
                                            onBlur={(e) => this.validateIMEI(e)}
                                        />
                                        <div className="error-txt" id="imei"></div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            (this.state.deviceModelName === "Packet Fence") &&
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>MAC Address</label>
                                        <span className="red-txt">*</span>
                                        <input
                                            type="text"
                                            name="macAddress"
                                            className="form-control"
                                            maxLength={17}
                                            value={this.state.macAddress}
                                            onChange={(e) => this.setMacAddress(e)}
                                            onBlur={(e) => this.validateMacAddress(e)}
                                        />
                                        <div className="error-txt" id="macAddress">The MAC Address is not valid</div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            this.props?.group_id &&
                            <div className="form-group">
                                <div className="text-center mb-2">Or</div>
                                <label>Upload CSV</label>
                                <span className="red-txt">*</span>
                                <div className="custom-file d-flex align-items-center">
                                    <input
                                        type="file"
                                        className="custom-file-input"
                                        id="csvFile"
                                        accept=".csv"
                                        onChange={(e) => {
                                            const file = e.target?.files?.[0];
                                            if (file) {
                                                this.setState({
                                                    selectedFileName: file
                                                });
                                            }
                                            this.setDeviceModel();
                                        }}
                                    />
                                    <label className="custom-file-label" htmlFor="csvFile">
                                        {this.state.selectedFileName?.name || "Choose CSV file"}
                                    </label>
                                </div>
                                <small className="text-muted">
                                    <button
                                        type="button"
                                        className="btn btn-link"
                                        style={{
                                            backgroundColor: 'transparent',
                                            border: 'none',
                                            outline: 'none',
                                            padding: 0,
                                        }}
                                        onClick={() => this.downloadSampleCSV()}
                                        onFocus={(e) => e.target.style.color = '#007bff'}
                                    >
                                        Download Sample CSV
                                    </button>
                                </small>
                                <div>
                                    {this.state.selectedFileName && (
                                        !/\.csv$/i.test(this.state.selectedFileName.name.trim()) ? (
                                            <label className='license-btn'>Please upload a valid CSV file!</label>
                                        ) : null
                                    )}
                                </div>
                            </div>
                        }
                        {
                            (
                                (this.state.deviceModelName === "Packet Fence") &&
                                !this.props?.group_id
                            )
                                ? <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                    <button
                                        className='btn btn-primary'
                                        disabled={this.checkPfError()}
                                        onClick={() => this.addPfDevice()}
                                        style={{
                                            alignSelf: 'center',
                                            marginBottom: '2rem',
                                            marginTop: '2rem'
                                        }}>
                                        Add Device
                                    </button>
                                </div>
                                : <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                    <button
                                        className='btn btn-primary'
                                        disabled={this.props?.group_id ? this.checkDeviceGroupError() : this.checkError()}
                                        onClick={() => (
                                            this.props?.group_id
                                                ? (
                                                    this.state.selectedFileName
                                                        ? this.uploadCSV()
                                                        : this.addInDeviceGroup()
                                                )
                                                : this.generateActivation()
                                        )}
                                        style={{
                                            alignSelf: 'center',
                                            marginBottom: '2rem',
                                            marginTop: '2rem'
                                        }}>
                                        {this.props?.group_id ? "Add Device" : "Generate Link"}
                                    </button>
                                </div>
                        }
                        {
                            ((this.props?.group_id &&
                                this.state.downlaodEnable))
                            &&
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}>
                                <button
                                    className='btn btn-primary'
                                    disabled={!this.state.downlaodEnable}
                                    onClick={() => this.downloadActivationForGroup()}
                                    style={{
                                        alignSelf: 'center',
                                        marginBottom: '2rem',
                                        marginTop: '2rem'
                                    }}>
                                    Download CSV
                                </button>
                            </div>
                        }
                        {
                            (
                                (!this.props?.group_id) &&
                                (this.state.link !== "")
                            ) &&
                            <div
                                style={{
                                    border: '1px solid #ccc',
                                    borderRadius: '4px',
                                    padding: '1.5rem',
                                    position: 'relative',
                                    textAlign: 'center',
                                    backgroundColor: 'beige'
                                }}>
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '0.5rem',
                                        color: '#f00',
                                        fontSize: 'larger'
                                    }}>{this.state.activationMessage}</div>
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '0.5rem',
                                        right: '0.5rem'
                                    }}>
                                    <img
                                        title="Download the activation key"
                                        style={{
                                            width: '25px',
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => this.downloadActivation()}
                                        src="/images/svg-icons/download-arrow.svg" />
                                </div>
                                <div className='mt-4'>
                                    <p style={{ marginBottom: '0.5rem' }}>Click the link to onboard a device using the activation code</p>
                                    <a
                                        href={this.state.link}
                                        target="_blank"
                                        style={{
                                            color: 'blue',
                                            textDecoration: 'underline',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            display: 'block'
                                        }} >
                                        {this.state.link}
                                    </a>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {
                    this.state.show &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        reset={() => this.resetPopup()}
                    />
                }
                {
                    this.state.isAxiosError &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.setState({ isAxiosError: false })}
                    />
                }
                {this.state.showRefreshModal && <ErrorHandlePopup />}
            </div>
        )
    }
};

const mapStateToProps = (state) => {
    return ({
        openAddDevice: state.displayAddDevice.openAddDevice,
    });
};

export default connect(mapStateToProps)(AddNewDevice);