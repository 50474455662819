import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as Action from "../../../redux/actionTypes"
import ApiCall from "../../../redux/API/apiCall";
import urlMapping from "../../../redux/API/api";
import { Loader } from '../../../layOuts/Loader';
import $ from 'jquery';
import DataTable from "react-data-table-component";
import { ErrorPopup } from "../../../layOuts/errorPopup";
import ErrorHandlePopup from "../../ErrorHandlePopup";
import AddNewDevice from "../../deviceList/AddNewDevice";

class DeviceList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRefreshModal: false,
            showLoader: false,
            show: false,
            pageNo: 1,
            pageSize: 10,
            group_id: props.group_id,
            supported_models: props?.supported_models,
            columns: [
                {
                    name: 'Name',
                    cell: (row) =>
                    (
                        <span
                            title={row.device_name || "-"}
                            style={{ width: 'auto' }}
                            className="cursor-pointer"
                        >{row.device_name !== ""
                            ? <Link
                                to={{
                                    pathname: '/devicedetails',
                                    state: {
                                        data: row,
                                        backTo: true
                                    }
                                }}
                                className="txt-blue"
                            >{row.device_name || "-"}</Link>
                            : row.device_name || "-"
                            }
                            {
                                row.templates_need_to_apply !== ""
                                    ? (
                                        <img src="/images/svg-icons/failed-temp-alert.svg" alt=""
                                            style={{ width: "13px" }}
                                            title={"Some of the templates are not in sync with the group templates.To sync it click on Sync button(device must be onine)."} />
                                    )
                                    : ("")
                            }
                        </span>
                    )
                },
                {
                    name: 'Status',
                    selector: row => row.device_status === 1 ? 'Online' : 'Offline',
                    cell: (row) => (
                        <div id={`col${row.device_id}`}>
                            <span id={`icon${row.device_id}`}>
                                <i className={row.device_status === 1 ? "status online" : "status offline"}></i>
                                {row.device_status === 1 ? 'Online' : 'Offline'}
                            </span>
                        </div>
                    )
                },
                {
                    name: 'Model',
                    selector: row => row.device_model || "-",
                },
                {
                    name: 'WAN IP',
                    selector: row => row.device_eth_wan_ip || "-",
                }, {
                    name: 'IMEI',
                    selector: row => row.device_imei || "-",
                },
                {
                    name: 'MAC Address',
                    selector: row => row.device_mac || "-",
                },
                {
                    name: 'Failed Operations',
                    cell: (row) =>
                    (
                        <span
                            title={row.Failed_operations}
                            style={{ width: 'auto' }}
                            className={row.Failed_operations !== 0 ? "cursor-pointer" : ""}
                        >
                            {
                                row.Failed_operations !== 0
                                    ? <Link
                                        to={{
                                            pathname: '/operations',
                                            state: {
                                                failedOperation: row,
                                                group_uuid: this.state.group_uuid
                                            }
                                        }}
                                        className="txt-blue"
                                    >{row.Failed_operations}</Link>
                                    : row.Failed_operations
                            }
                        </span>
                    )
                },
                {
                    name: "Sync",
                    value: "Sync",
                    center: true,
                    cell: (row) => (
                        <label className="switch">
                            <input
                                type="checkbox"
                                name='sync'
                                disabled={!(row.templates_need_to_apply !== "" && row.device_status === 1)}
                                // checked={this.state.networkData.bridgeStatus === '1'} 
                                onClick={
                                    (row.templates_need_to_apply !== "" && row.device_status === 1)
                                        ? (e) => this.getDeviceSync(e, row)
                                        : undefined
                                }
                            />
                            <span className="slider round"></span>
                        </label>
                    )
                }
            ]
        }
    };

    componentDidMount() {
        this.getDeviceGroupDetails();
        this.closeAddDevice();
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps?.group_id !== this.state?.group_id) {
            this.setState(
                {
                    group_id: nextProps.group_id,
                    pageNo: 1
                },
                () => this.getDeviceGroupDetails()
            );
        }
        if (nextProps?.supported_models !== this.state?.supported_models) {
            this.setState({
                supported_models: nextProps?.supported_models,

            })
        }
    };

    openAddDeviceModel = () => {
        this.props.dispatch({
            type: Action.OPEN_ADD_DEVICE,
        });
    };

    closeAddDevice = () => {
        this.props.dispatch({
            type: Action.CLOSE_ADD_DEVICE,
        });
    };

    getDeviceGroupDetails = async () => {
        this.setState({
            showLoader: true
        });
        const data = {
            group_id: this.state.group_id,
            pageNo: this.state.pageNo,
            pageSize: this.state.pageSize
        }
        await ApiCall(urlMapping.deviceGroupDeviceList(data), (response) => {
            this.setState({
                showLoader: false,
            });
            if (response?.results) {
                this.setState({
                    deviceData: response?.results
                });
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({ showRefreshModal: true })
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        });
    };

    getDeviceSync = async (e, row) => {
        if (e.target.checked) {
            this.setState({
                showLoader: true
            });
            const data = {
                device_id: row.device_id,
                group_id: this.state.group_id,
                template_ids: row.templates_need_to_apply
            }
            await ApiCall(urlMapping.syncWithGroup(data), (response) => {
                this.setState({
                    showLoader: false,
                });
                if (response.success) {
                    this.setState({
                        title: 'Device Sync',
                        message: response?.message,
                        show: true,
                        delete: false,
                        error: false
                    }, () => $("#errorMessage").modal("show"))
                } else {
                    if (parseInt(response?.response?.status) === 401) {
                        this.setState({ showRefreshModal: true })
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            show: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                }
            });
        }
    };

    resetPopup = () => {
        this.setState({
            show: false,
            error: false,
            delete: false,
        }, () => {
            $("#errorMessage").modal("hide");
            this.getDeviceGroupDetails();
        })
    };

    downloadActivationForGroup = () => {
        this.setState({ showLoader: true });
        const data = {
            group_id: this.state?.group_id
        }
        ApiCall(urlMapping.activationKeyForGroup(data), (response) => {
            if (response) {
                this.setState({
                    showLoader: false
                });
                const csvLines = Object.values(response).join('').split('\n');
                if (csvLines[csvLines.length - 1].includes('[object Object]')) {
                    csvLines.pop();
                }
                const csvData = csvLines.join('\n');
                const blobData = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
                const url = window.URL.createObjectURL(blobData);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Activation_Code.csv');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({ showRefreshModal: true, showLoader: false });
                } else if (
                    response?.isAxiosError &&
                    response?.success === undefined &&
                    response?.response?.data?.success === undefined
                ) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true,
                    }, () => {
                        $("#errorMessage").modal("show");
                    });
                } else {
                    this.setState({
                        showLoader: false
                    });
                }
            }
        });
    };

    render() {
        return (
            <>
                {this.state.showLoader && <Loader />}
                <div className="white-bg  page-height">
                    <div className="ta-box">
                        <h1 className="page-title">
                            Add Devices{" "}
                            <img
                                src="/images/svg-icons/add.svg"
                                className={
                                    (this.props?.userRole === "Viewer_Group" ||
                                        this.props?.userRole === "Operator_Group")
                                        ? "add-btn disabled"
                                        : "add-btn"
                                }
                                alt=""
                                onClick={() =>
                                    (this.props?.userRole === "Viewer_Group" ||
                                        this.props?.userRole === "Operator_Group")
                                        ? undefined
                                        : this.openAddDeviceModel()
                                }
                            />
                        </h1>
                        <div className="action-btn">
                            <button
                                className="button-lst"
                                onClick={() => this.downloadActivationForGroup()}
                            >
                                Download Activation CSV
                            </button>
                        </div>
                    </div>
                    <div className="card">
                        <DataTable
                            columns={this.state.columns}
                            data={this.state.deviceData}
                            pagination
                            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                        />
                    </div>
                </div>
                {
                    this.props?.openAddDevice &&
                    <AddNewDevice
                        group_id={this.state.group_id}
                        getDeviceGroupDetails={() => this.getDeviceGroupDetails()}
                        supported_models={this.state.supported_models}
                    />
                }
                {this.state.showRefreshModal && <ErrorHandlePopup />}
                {
                    this.state.show &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={this.resetPopup}
                    />
                }
            </>
        )
    }
};

const mapStateToProps = (state) => {
    return {
        authenticated: state.auth.authenticated,
        open: state.displaySidebarMenu.openSide,
        openAddDevice: state.displayAddDevice.openAddDevice,
        SsoUser: state.auth.SsoUser,
        userRole: String(state.auth.role)
    };
};

export default withRouter(connect(mapStateToProps)(DeviceList));