import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import ApiCall from "../../../redux/API/apiCall";
import urlMapping from "../../../redux/API/api";
import { Loader } from '../../../layOuts/Loader';
import moment from 'moment';
import $ from 'jquery';
import DataTable from "react-data-table-component";
import * as Action from '../../../redux/actionTypes';
import ApplyTemplate from "../../templates/AddTemplates/ApplyTemplate";
import { ErrorPopup } from '../../../layOuts/errorPopup';
import * as Common from '../../../utils/common';
import ErrorHandlePopup from "../../ErrorHandlePopup";
import AddEditTemplates from "../../templates/AddTemplates/AddEditTemplates";

class AppliedTemplateList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRefreshModal: false,
            showLoader: false,
            title: '',
            message: '',
            edit: false,
            show: false,
            error: false,
            delete: false,
            id: this.props?.id || "",
            appliedTemplateList: [],
            applyTemplateData: {},
            openApplyTemplate: true,
            uploadTemp: false,
            jsonFile: undefined,
            deviceData: this.props?.deviceData || "",
            view: false,
            interfaces: [],
            showPopup: false,
            isAxiosError: false,
            templateDetails: "",
            configDeviceId: "",
            configModels: "",
            disableCancel: false,
            configApply: false,
            reorderEnable: false,
            isReorder: false,
            nonSyncTemplates: [],
            nonSyncCount: 0,
            nonSync: false,
            wireguardSupportedModules: "",
            configTunnel: false,
            checkpointName: '',
            isLastReorder: false,
            configEdit: true,
            reapply: false,
            deviceGroupApplyOnly: false,
            columns: [
                {
                    name: "Template Name",
                    selector: "template_name",
                    value: "Template Name",
                    cell: (row) => (
                        <span
                            title={row?.template_name}
                            style={{ width: 'auto' }}
                        >
                            {row?.template_name}
                        </span>
                    ),
                },
                {
                    name: "Template Type",
                    selector: "template_type",
                    value: "Template Type",
                    center: true,
                    cell: (row) => (
                        <span
                            title={Common.getTemplateType(row?.template_type)}
                            style={{ width: 'auto' }}
                        >
                            {Common.getTemplateType(row?.template_type)}
                        </span>
                    ),
                }, {
                    name: "Apply State",
                    selector: "applied",
                    value: "Apply State",
                    cell: (row) => (<span title={row?.applied ? "Applied" : "Not Applied"} style={{ width: 'auto' }}>
                        {row.applied ? "Applied" : "Not Applied"}</span>
                    ),
                },
                {
                    name: "Device Models",
                    selector: "device_model",
                    value: "Device Model",
                    cell: (row) => (<span title={row?.device_model} style={{ width: 'auto' }}>
                        {row?.device_model}</span>
                    ),
                },
                {
                    name: "Last Updated On",
                    selector: "created_date",
                    value: "Last Updated On",
                    center: true,
                    cell: (row) => (
                        <span
                            title={this.getTimeExact(row?.created_date)}
                            style={{ width: 'auto' }}
                        >
                            {this.getTimeExact(row?.created_date)}
                        </span>
                    ),
                },
                {
                    value: "Action",
                    center: true,
                    cell: (row) => (
                        <div className="da-box">
                            <img
                                className="a-dots"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                src="/images/svg-icons/ellipsis.svg"
                                alt=""
                            />
                            <div className="drop-box dropdown-menu">
                                <ul>
                                    {/* Disable view for Device Provision templates */}
                                    <li
                                        className={row.template_type === 'DeviceProvision' ? "disabled" : ""}
                                        onClick={this.disableClick(row, 'View')}>
                                        <img src="/images/svg-icons/view.svg" alt="" />View</li>
                                    {
                                        row.template_type !== "IPSecConfiguration" &&
                                        row.template_operation_status !== "Pending" &&
                                        <li
                                            className={
                                                (
                                                    (
                                                        this.props?.userRole === "Viewer_Group" ||
                                                        this.props?.userRole === "Operator_Group"
                                                    )
                                                )
                                                    ? "disabled"
                                                    : this.disableOption(row)
                                            }
                                            onClick={
                                                (
                                                    (this.props?.userRole === "Viewer_Group" ||
                                                        this.props?.userRole === "Operator_Group")
                                                )
                                                    ? undefined
                                                    : this.disableClick(row, 'Edit')
                                            }>
                                            <img
                                                className="edit"
                                                src="/images/svg-icons/reply.svg"
                                                alt=""
                                            />Edit Template</li>
                                    }
                                    {
                                        row.template_operation_status !== "Pending" &&
                                        <li
                                            className={
                                                (
                                                    (
                                                        this.props?.userRole === "Viewer_Group" ||
                                                        this.props?.userRole === "Operator_Group"
                                                    )
                                                )
                                                    ? "disabled"
                                                    : this.disableOption(row, "Apply")
                                            }
                                            onClick={
                                                (
                                                    (this.props?.userRole === "Viewer_Group" ||
                                                        this.props?.userRole === "Operator_Group")
                                                )
                                                    ? undefined
                                                    : this.disableClick(row, 'Apply')
                                            }>
                                            <img
                                                className="edit"
                                                src="/images/svg-icons/reply.svg"
                                                alt=""
                                            />Apply Template</li>
                                    }
                                </ul>
                            </div>
                        </div>
                    ),
                    allowOverflow: true,
                    button: true,
                    width: "56px",
                },
            ],
        }
    };

    async componentDidMount() {
        this.closeApplyTemplate();
        await this.getAppliedTemplateList();
        if (this.props?.isEditTunnel && !this.props?.customGroupTemplate) {
            await this.handleEditTemplate(this.props?.editTunnelData, false, true);
        }
        else if (this.props?.customGroupTemplate) {
            await this.setState(
                {
                    configTunnel: this.props?.editTunnelData?.template_type === "TunnelConfiguration",
                    configEdit: false,
                    deviceGroupApplyOnly: false,
                },
                () => {
                    this.handleEditTemplate(this.props?.editTunnelData, false, true);
                }
            );
        }
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.id || nextProps.deviceData) {
            this.setState({
                id: nextProps.id || "",
                deviceData: nextProps.deviceData || ""
            }, () => this.getAppliedTemplateList())
        }
    };

    getAppliedTemplateList = () => {
        this.setState({ showLoader: true }, () => {
            const data = { group_id: this.state.id }
            ApiCall(urlMapping.getGroupConfigDetails(data), (response) => {
                if (response?.success) {
                    this.setState({
                        appliedTemplateList: response?.data,
                        showLoader: false,
                        configModels: response?.data?.[0]?.device_model,
                        configTunnel: false,
                        configEdit: this.props?.customGroupTemplate ? false : true,
                        reapply: false
                    }, () => {
                        if (this.state.nonSync) {
                            this.setState({
                                nonSyncCount: this.state.nonSyncCount + 1,
                            }, () => {
                                this.nonSync();
                            })
                        }
                        else {
                            this.getModulesData();
                        }
                    })
                } else {
                    if (parseInt(response?.response?.status) === 401) {
                        this.setState({ showLoader: false, showRefreshModal: true })
                    } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    } else {
                        this.setState({
                            appliedTemplateList: [],
                            showLoader: false
                        })
                    }
                }
            });
        });
    };

    getModulesData = async () => {
        this.setState({
            showLoader: true
        });
        await ApiCall(urlMapping.getModuleList(), (response) => {
            if (response?.success) {
                this.setState({ showLoader: false });
                const { models_interface, supported_modules } = response?.data || {};
                const modelKey = this.state.configModels?.split(",")?.[0];
                if (modelKey && models_interface?.[modelKey]) {
                    this.setState({ interfaces: models_interface[modelKey] });
                }
                const wireguardModules = supported_modules?.TunnelConfiguration;
                if (wireguardModules) {
                    this.setState({ wireguardSupportedModules: wireguardModules.join(", ") }
                    );
                }
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showRefreshModal: true,
                        showLoader: false
                    })
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        });
    };

    getTimeExact = (time) => {
        let date = moment.utc(time);
        date = date.local();
        return date.format('DD MMMM YYYY hh:mm:ss A');
    };

    // Disable view for Device Provision templates
    disableOption = (row, value) => {
        if (row.template_type === 'DeviceProvision')
            return "disabled"
        else if (
            this.state.deviceData.is_ippt &&
            row.template_type !== "NetworkConfiguration" &&
            row.template_type !== "TriggerConfiguration" &&
            row.template_type !== "ModemConfiguration" &&
            row.template_type !== "SystemConfiguration"
        ) {
            return "disabled"
        }
        else if (value === "Apply" && !(this.state?.deviceData?.attached_devices?.length > 0)) {
            return "disabled"
        }        
        else if (value === "Apply" && row?.applied) {
            return "disabled";
        }
        else
            return undefined
    };

    disableClick = (row, value) => {
        // Disable conditions
        if (
            row.template_type === 'DeviceProvision' || // Condition 1: Disable for 'DeviceProvision'
            (
                this.state.deviceData.is_ippt && // Condition 3: Disable for specific cases when is_ippt is true
                (value === 'Edit' || value === 'Apply') &&
                !["NetworkConfiguration", "TriggerConfiguration", "ModemConfiguration", "SystemConfiguration"].includes(row.template_type)
            )
        ) {
            return undefined; // Disable click
        }
        if (value === "Apply" && !(this.state?.deviceData?.attached_devices?.length > 0)) {
            return undefined; // Disable click
        }        
        else if (value === "Apply" && row?.applied) {
            return "disabled";
        }
        // Handle actions
        if (value === 'View') {
            return () => this.setState({
                configTunnel: row?.template_type === "TunnelConfiguration" ? true : false,
                configEdit: true,
                reapply: false,
                deviceGroupApplyOnly: false
            }, () => this.handleEditTemplate(row, true, false));
        }
        else if (value === 'Apply') {
            return () => this.setState({
                configTunnel: row?.template_type === "TunnelConfiguration" ? true : false,
                configEdit: false,
                reapply: true,
                deviceGroupApplyOnly: false
            }, () => { this.handleEditTemplate(row, false, true) });
        }
        return () => this.setState({
            configTunnel: row?.template_type === "TunnelConfiguration" ? true : false,
            configEdit: true,
            reapply: false,
            deviceGroupApplyOnly: false
        }, () => { this.handleEditTemplate(row, false, true) });
    };

    handleEditTemplate = (data, view, edit) => {
        if (edit === true || view === true) {
            let model = Common.getModels(this.state.deviceData.supported_models);
            const idData = {
                group_id: this.state.id
            }
            let templateType = data?.template_type;
            let createdTemplateVersionCBR = data.template_version?.cbr;
            let createdTemplateVersionChimay = data.template_version?.chimay;
            let createdTemplateVersionMifi = data.template_version?.mifi;
            ApiCall(urlMapping.getModuleList(), (response1) => {
                if (response1?.success) {
                    if (templateType === 'TunnelConfiguration') {
                        templateType = 'DataTunnel'
                    } else if (templateType === 'DNSFiltering') {
                        templateType = 'DNSFilteringConfiguration'
                    }
                    let currentTemplateVersionCBR = response1?.data?.cbr_templates_version?.[templateType];
                    let currentTemplateVersionChimay = response1?.data?.chimay_templates_version?.[templateType];
                    let currentTemplateVersionMifi = response1?.data?.mifi_templates_version?.[templateType];
                    const versionCheck = model === 'vCPE' ?
                        createdTemplateVersionCBR === currentTemplateVersionCBR : (model === 'CHIMAY' ?
                            createdTemplateVersionChimay === currentTemplateVersionChimay :
                            createdTemplateVersionMifi === currentTemplateVersionMifi
                        )
                    if (data.template_version === null || templateType === 'TriggerConfiguration' || versionCheck) {
                        if (edit === true && data.template_type === 'NetworkConfiguration') {
                            this.setState({
                                networkViewData: [],
                                applyTemplateData: data,
                                openApplyTemplate: true,
                                view,
                                edit,
                                selectedModel: model,
                            }, () => {
                                this.props.dispatch({
                                    type: Action.OPEN_APPlY_TEMPLATE,
                                });
                            });
                        }
                        else {
                            if (String(idData.group_id) !== "")
                                ApiCall(urlMapping.groupNetworks(idData), (response) => {
                                    if (response) {
                                        this.setState({
                                            showLoader: false,
                                            networkViewData: response?.data,
                                            applyTemplateData: data,
                                            openApplyTemplate: true,
                                            selectedModel: model,
                                            view,
                                            edit
                                        }, () => {
                                            this.props.dispatch({
                                                type: Action.OPEN_APPlY_TEMPLATE,
                                            });
                                        });
                                    } else {
                                        if (parseInt(response?.response?.status) === 401) {
                                            this.setState({ showRefreshModal: true })
                                        }
                                        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                                            this.setState({
                                                title: 'Network Error',
                                                message: 'Something went wrong. Please try again later.',
                                                showLoader: false,
                                                isAxiosError: true,
                                                delete: false,
                                                error: true
                                            }, () => $("#errorMessage").modal("show"))
                                        }
                                    }
                                });
                        }
                    } else {
                        this.setState(
                            {
                                title: "Version Mismatch",
                                message: 'Template version is outdated, as EDGE Manager is upgraded, Please create new template to proceed further',
                                showPopup: true,
                                error: true,
                                delete: true,
                            },
                            () => {
                                $("#versionErrorMessage").modal("show");
                            }
                        );
                    }
                }
                else if (response1?.isAxiosError) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            })
        }
    };

    resetPopup = () => {
        this.setState({
            show: false,
            error: false,
            delete: false,
        }, () => {
            $("#errorMessage").modal("hide");
            if (this.state.isReorder) {
                let templates = [];
                this.state.appliedTemplateList.map((item => {
                    templates?.push(item?.template_type);
                }))
                let arr = ['StaticRoute', 'OSPFConfiguration', 'BGPConfiguration', 'WANConfiguration', 'WiFiConfiguration', 'QoSConfiguration', 'CaptivePortal', 'FirewallConfiguration'];
                let newTemplate = [];
                arr.map((item) => {
                    let list = templates?.filter((val) => val === item);
                    if (list?.length !== 0)
                        newTemplate.push(list[0]);
                })
                this.setState({
                    nonSyncTemplates: newTemplate,
                    nonSyncCount: 0,
                    nonSync: true
                }, () => this.nonSync())
            }
        })
    };

    nonSync = () => {
        if (this.state.nonSyncTemplates?.length > this.state.nonSyncCount) {
            setTimeout(() => {
                const data = this.state.appliedTemplateList?.filter((item) => item.template_type === this.state.nonSyncTemplates?.[parseInt(this.state.nonSyncCount)])?.[0];
                this.handleEditTemplate(data, false, true);
                this.setState({
                    disableCancel: true,
                    reorderEnable: true,
                    isLastReorder: this.state.nonSyncTemplates?.length - 1 === this.state.nonSyncCount ? true : false
                })
            }, 100)
        }
        else {
            this.setState({
                showLoader: false,
                nonSync: false,
                nonSyncTemplates: [],
                reorderEnable: false,
                isReorder: false,
                disableCancel: false,
                isLastReorder: false
            })
        }
    };

    openAddTemplate = () => {
        this.setState({
            configApply: true,
            nonSync: false,
            nonSyncTemplates: [],
            reorderEnable: false,
            isReorder: false,
            disableCancel: false,
            openApplyTemplate: false,
            isLastReorder: false,
        }, () => {
            this.props.dispatch({
                type: Action.CLOSE_APPLY_TEMPLATE,
            });
            this.props.dispatch({
                type: Action.OPEN_ADD_TEMPLATE,
            });
        })
    };

    closeApplyTemplate = () => {
        this.props.dispatch({
            type: Action.CLOSE_APPLY_TEMPLATE,
        });
        this.closeAddTemplate();
    };

    closeAddTemplate = () => {
        this.setState({
            templateDetails: undefined,
            configApply: false,
            openApplyTemplate: false,
            disableCancel: false,
            configTunnel: false
        })
        this.props.dispatch({
            type: Action.CLOSE_ADD_TEMPLATE,
        });
    };

    reorderTemplates = (value) => {
        const templateOrder = [
            'StaticRoute',
            'OSPFConfiguration',
            'BGPConfiguration',
            'WANConfiguration',
            'WiFiConfiguration',
            'QoSConfiguration',
            'CaptivePortal',
            'FirewallConfiguration'
        ];
        const dependentTemplate = this.state?.appliedTemplateList?.some(item =>
            templateOrder.includes(item.template_type)
        );
        if (dependentTemplate) {
            this.setState({
                title: 'Reorder Templates',
                message: "A few templates depend on the network template. This will reorder and sync them with the network template.",
                show: true,
                error: false,
                delete: false,
                configApply: false,
                isReorder: true,
            }, () => {
                $("#errorMessage").modal("show");
            });
        }
        else if (value === "reorder") {
            this.setState(
                {
                    title: 'Reorder Templates',
                    message: "All the templates are in Sync.",
                    show: true,
                    error: false,
                    delete: false,
                    showLoader: false,
                    configApply: false,
                    isReorder: false,
                },
                () => {
                    $("#errorMessage").modal("show");
                }
            );
        }
        else {
            this.setState(
                {
                    showLoader: false,
                    configApply: false,
                    isReorder: false,
                },
                () => {
                    $("#errorMessage").modal("hide");
                }
            );
        }
    };

    configRedirect = (configId) => {
        setTimeout(() => {
            const data = this.state?.appliedTemplateList?.filter((item) => item.id === configId)?.[0];
            this.setState({
                disableCancel: true,
                configApply: false
            }, () => {
                this.handleEditTemplate(data, false, true);
            })
        }, 1000)
    };
    createCheckpoint = () => {
        let data = {};
        if (this.state.createError) {
            data = {
                group_id: this.state.id,
                checkpoint_name: this.state.checkpointName,
                is_delete: true,
            };
        } else {
            data = {
                group_id: this.state.id,
                checkpoint_name: this.state.checkpointName,
                is_delete: false,
            };
        }
        this.setState({
            showLoader: true,
        });
        ApiCall(urlMapping.createCheckpoint(data), (response) => {
            this.setState({
                showLoader: false,
                show: false,
            });
            if (response?.success) {
                $("#exampleModalCheckpoint").modal("hide");
                this.setState(
                    {
                        title: "Create Checkpoint",
                        message: response?.message,
                        show: true,
                        error: false,
                        delete: false,
                        create: false,
                        createError: false,
                        checkpoint_name: ""
                    },
                    () => {
                        $("#errorMessage").modal("show");
                    }
                );
            } else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({
                        showRefreshModal: true,
                    });
                } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    $("#exampleModalCheckpoint").modal("hide")
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else {
                    $("#exampleModalCheckpoint").modal("hide")
                    this.setState(
                        {
                            title: "Create Checkpoint",
                            message: response?.message,
                            show: true,
                            error: true,
                            delete: false,
                            create: false,
                            createError: true,
                        },
                        () => $("#errorMessage").modal("show")
                    );
                }
            }
        });
    };
    confirmCreate = () => {
        let result = true;
        let createdTime = '';
        let diff = 0;
        let templates = [];
        let model = Common.getModels(this.state.configModels);
        switch (model) {
            //EMGT-383 EM - Able to create the checkpoint even though the template is getting failed	
            case 'CHIMAY':
                templates = this.state.appliedTemplateList.filter(item => item.template_type === 'NetworkConfiguration' ||
                    item.template_type === 'WiFiConfiguration' || item.template_type === 'WANConfiguration' ||
                    item.template_type === 'FirewallConfiguration' || item.template_type === 'TriggerConfiguration' ||
                    item.template_type === 'QoSConfiguration' || item.template_type === 'BGPConfiguration');
                if (templates.length < 7)
                    result = false;
                break;
            //EMGT-383 EM - Able to create the checkpoint even though the template is getting failed
            case 'vCPE':
                templates = this.state.appliedTemplateList.filter(item => item.template_type === 'NetworkConfiguration' ||
                    item.template_type === 'BGPConfiguration' || item.template_type === 'WANConfiguration' ||
                    item.template_type === 'FirewallConfiguration' || item.template_type === 'TriggerConfiguration' ||
                    item.template_type === 'QoSConfiguration');
                if (templates.length < 6)
                    result = false;
                break;
            case 'Stormbreaker':
                templates = this.state.appliedTemplateList.filter(item =>
                    item.template_type === 'NetworkConfiguration' ||
                    item.template_type === 'TriggerConfiguration' ||
                    item.template_type === "FirewallConfiguration");
                if (templates.length < 3)
                    result = false;
                break;
            case 'Firestorm':
                templates = this.state.appliedTemplateList.filter(item =>
                    item.template_type === 'NetworkConfiguration' ||
                    item.template_type === 'TriggerConfiguration');
                if (templates.length < 2)
                    result = false;
                break;
            case 'Moretti_Model':
            case 'Duvel_Model':
                templates = this.state.appliedTemplateList.filter(item =>
                    item.template_type === 'NetworkConfiguration' ||
                    item.template_type === 'TriggerConfiguration' ||
                    item.template_type === 'WiFiConfiguration' ||
                    item.template_type === "FirewallConfiguration");
                if (templates.length < 4)
                    result = false;
                break;
            case "Orval":
                templates = this.state.appliedTemplateList.filter(
                    (item) =>
                        item.template_type === "NetworkConfiguration" ||
                        item.template_type === "WANConfiguration" ||
                        item.template_type === "TriggerConfiguration"
                );
                if (templates.length < 3) result = false;
                break;
            case "Bradshaw":
                templates = this.state.appliedTemplateList.filter(
                    (item) =>
                        item.template_type === 'NetworkConfiguration' ||
                        item.template_type === 'TriggerConfiguration'
                );
                if (templates.length < 2) result = false;
                break;
            case "Sander":
                templates = this.state.appliedTemplateList.filter(
                    (item) =>
                        item.template_type === 'NetworkConfiguration' ||
                        item.template_type === 'TriggerConfiguration'
                );
                if (templates.length < 2) result = false;
                break;
            default:
                result = false;
        }
        if (!result) {
            this.setState({
                title: 'Create Checkpoint',
                message: 'Group doesn\'t have all the required templates',
                show: true,
                error: true,
                delete: false,
                create: false,
                restore: false
            }, () => {
                $("#errorMessage").modal("show")
            });
        } else if (templates.filter(item => item.applied !== true).length > 0) {
            this.setState({
                title: 'Create Checkpoint',
                message: 'All templates must be in applied state',
                show: true,
                error: true,
                delete: false,
                create: false,
                restore: false
            }, () => {
                $("#errorMessage").modal("show")
            });
        } else {
            createdTime = moment(templates.filter(item => item.template_type === 'NetworkConfiguration')[0]?.updated_date);
            // moment.utc(templates.filter(item => item.template_type === 'NetworkConfiguration')[0]?.updated_date).local();

            templates.map((item) => {
                if (createdTime !== '' && item.template_type !== "TriggerConfiguration") {
                    let templateTime = moment(item.updated_date);
                    diff = createdTime.diff(templateTime) > 0 ? createdTime.diff(templateTime) : 0
                }
            })
            if (diff === 0) {
                this.setState({
                    title: 'Create Checkpoint',
                    message: 'Group checkpoint will create a checkpoint on each device using the current configuration. If a device already has 5 checkpoints, the oldest one will be deleted to create this one. Click OK to proceed or click Cancel to cancel the operation.',
                    show: true,
                    error: true,
                    delete: true,
                    create: true,
                    restore: false
                }, () => {
                    $("#errorMessage").modal("show")
                });
            } else {
                this.setState({
                    title: 'Create Checkpoint',
                    message: 'The Network template and other templates are not in sync with each other. Kindly, check the dependent templates and update them to be in sync with the Network template.',
                    show: true,
                    error: true,
                    delete: false,
                    create: false,
                    restore: false
                }, () => {
                    $("#errorMessage").modal("show")
                });
            }
        }
    };
    openCheckpointPopup = () => {
        this.setState({
            createCheck: true,
            show: false
        }, () => {
            $("#errorMessage").modal("hide");
            $("#exampleModalCheckpoint").modal("show");
        })
    }

    openUpload = () => {
        this.setState({
            uploadTemp: true
        }, () => {
            $("#uploadTempModal").modal("show");
        })
    };

    closeUpload = () => {
        this.setState({
            uploadTemp: false,
            jsonFile: undefined
        }, () => {
            $("#uploadTempModal").modal("hide");
        })
    };

    jsonFileHandleSelect = (e) => {
        this.setState({ jsonFile: e.target.files[0] })
    };

    deleteJson = () => {
        this.setState({ jsonFile: undefined })
    };

    uploadTemplates = () => {
        const formData = new FormData();
        formData.append("device_template_file", this.state.jsonFile);
        formData.append("group_id", this.state.id);
        formData.append("upload_from", "config");
        this.setState({
            showLoader: true,
        });
        ApiCall(urlMapping.uploadDeviceTemplate(formData), (response) => {
            if (response?.success) {
                $("#uploadTempModal").modal("hide");
                this.setState({
                    jsonFile: undefined,
                    showLoader: false,
                    title: 'Upload Configuration',
                    message: response?.message,
                    show: true,
                    delete: false,
                    error: false
                }, () => {
                    this.getAppliedTemplateList();
                    $("#errorMessage").modal("show");
                })
            }
            else {
                $("#uploadTempModal").modal("hide");
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({ showLoader: false, showRefreshModal: true })
                } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else {
                    this.setState({
                        jsonFile: undefined,
                        showLoader: false,
                        title: 'Upload Configuration',
                        message: response?.response?.data ? response?.response?.data?.message : response?.message,
                        show: true,
                        error: true,
                        delete: false
                    }, () => {
                        $("#errorMessage").modal("show");
                    });
                }
            }
        })
    };

    downloadTemplates = () => {
        let tempId = [];
        this.state.appliedTemplateList?.map((item) => {
            tempId.push(item?.id);
        })
        let data = { template_ids: tempId, from: "config" }
        this.setState({
            showLoader: true,
        });
        ApiCall(urlMapping.downloadTemplate(data), (response) => {
            if (parseInt(response?.response?.status) === 200) {
                const url = window.URL.createObjectURL(new Blob([response?.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "templates.zip");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                this.setState({
                    showLoader: false,
                })
            }
            else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({ showLoader: false, showRefreshModal: true })
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({
                        showLoader: false,
                        title: 'Download Templates',
                        message: response?.message,
                        show: true,
                        error: true,
                    }, () => {
                        $("#errorMessage").modal("show");
                    });
                }
            }
        })
    };

    render() {
        return (
            <>
                {this.state.showLoader && <Loader />}
                <div className="white-bg">
                    <div className="ta-box set-pos-grp">
                        <div className="action-btn">
                            <button
                                onClick={() => this.getAppliedTemplateList()}>
                                <img src="/images/svg-icons/refreshing.svg" alt="" />&nbsp;Refresh</button>
                        </div>
                    </div>
                    <div className="ta-box">
                        <h1 className="page-title">Templates {" "}
                            <img
                                src="/images/svg-icons/add.svg"
                                className={
                                    (this.props?.userRole === "Viewer_Group" ||
                                        this.props?.userRole === "Operator_Group")
                                        ? "add-btn disabled"
                                        : "add-btn"
                                }
                                alt=""
                                onClick={() =>
                                    (this.props?.userRole === "Viewer_Group" ||
                                        this.props?.userRole === "Operator_Group")
                                        ? undefined
                                        : this.openAddTemplate()
                                }
                            />
                        </h1>
                        <div>
                            <div className="da-box mr-2">
                                <img
                                    className="a-dots"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    src="/images/svg-icons/ellipsis.svg"
                                    alt=""
                                />
                                <div className="drop-box dropdown-menu dropdown-menu-right">
                                    <ul>
                                        <li
                                            onClick={this.confirmCreate}
                                        >
                                            <img src="/images/svg-icons/settings-lock.svg" alt="" />{" "}
                                            Create Checkpoint
                                        </li>
                                        <li
                                            className={(process.env.REACT_APP_AD_USER === 'TRUE' &&
                                                (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
                                                    localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true')) ||
                                                (this.props?.userRole === "Viewer_Group" ||
                                                    this.props?.userRole === "Operator_Group") ? "disabled" : ""}
                                            onClick={() => this.reorderTemplates("reorder")}
                                        >
                                            <img
                                                src="/images/reorder.png"
                                                title="Rearrange templates to be in sync with Network template"
                                                alt=""
                                            />{" "}
                                            Reorder Template
                                        </li>
                                        <li
                                            className={(process.env.REACT_APP_AD_USER === 'TRUE'
                                                && (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
                                                    localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true')) ||
                                                (this.props?.userRole === "Viewer_Group" ||
                                                    this.props?.userRole === "Operator_Group")
                                                ?
                                                "disabled"
                                                : undefined
                                            }
                                            onClick={() => this.openUpload()}
                                        >
                                            <img src="/images/svg-icons/upload-icon.svg" alt=""
                                            /> Upload Templates</li>
                                        <li
                                            className={this.state.appliedTemplateList.filter(
                                                (item) => item.template_operation_status === "Pending"
                                            ).length > 0
                                                ?
                                                "disabled"
                                                : undefined
                                            }
                                            onClick={() => this.downloadTemplates()}
                                        >
                                            <img src="/images/svg-icons/download-arrow.svg" alt="" /> Download Templates
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DataTable
                        columns={this.state.columns}
                        data={this.state.appliedTemplateList}
                        highlightOnHover
                        fixedHeader
                    />
                    {
                        this.props.openAddTemplate &&
                        <AddEditTemplates
                            configAdd={true}
                            configEdit={false}
                            configGroup={true}
                            configApply={this.state.configApply}
                            group_name={this.props.group_name || undefined}
                            wireguardSupportedModules={this.state.wireguardSupportedModules}
                            configDeviceId={this.state.id}
                            configModels={this.state.configModels}
                            templateData={this.state.templateDetails}
                            interfaces={this.state.interfaces}
                            template_type={this.state.templateDetails?.template_type}
                            refreshList={() => this.getAppliedTemplateList()}
                            closeAddTemplate={() => this.closeAddTemplate()}
                            configRedirect={(configId) => this.configRedirect(configId)}
                        />
                    }
                    {
                        this.state.openApplyTemplate &&
                        Object.keys(this.state.applyTemplateData).length > 0 &&
                        <ApplyTemplate
                            data={this.state.applyTemplateData}
                            selectedModel={this.state.selectedModel}
                            deviceGroup={true}
                            configGroup={true}
                            reapply={this.state.reapply}
                            configTunnel={this.state.configTunnel}
                            isLastReorder={this.state.isLastReorder}
                            networkViewData={this.state.networkViewData}
                            group_id={this.state.id}
                            configEdit={this.state.configEdit}
                            configDeviceId={this.state.id}
                            configAdd={false}
                            disableCancel={this.state.disableCancel}
                            group_name={this.props.group_name ? this.props.group_name : ""}
                            device_group_uuid={this.state.deviceData.group_uuid}
                            view={this.state.view}
                            edit={(this.state.templateDetails?.template_type === "TunnelConfiguration" || this.state.reapply) ? false : this.state.edit}
                            link={!this.state.edit}
                            interfaces={this.state.interfaces}
                            info={false}
                            reorderEnable={this.state.reorderEnable}
                            props={this.props}
                            configNetworkReorder={() => this.state.deviceGroupApplyOnly ? undefined : this.reorderTemplates()}
                            refreshList={() => this.getAppliedTemplateList()}
                            configRefresh={() => this.closeApplyTemplate()}
                        />
                    }
                    {
                        (this.state.show) &&
                        <ErrorPopup
                            title={this.state.title}
                            message={this.state.message}
                            error={this.state.error}
                            delete={this.state.delete}
                            reset={() => this.resetPopup()}
                            createError={this.state.createError}
                            onSubmit={() =>
                                this.openCheckpointPopup()
                            }
                        />
                    }
                    {/* error popup for version mismatch */}
                    {
                        this.state.showPopup && (
                            <div
                                className="modal fade errorMessage"
                                id="versionErrorMessage"
                                tabIndex="-1"
                                data-backdrop="static"
                                role="dialog"
                                aria-labelledby="errorMessageTitle"
                                aria-hidden="true"
                            >
                                <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header bg-white border-0">
                                        </div>
                                        <div className="modal-body pt-5">
                                            <div className="error-msg">
                                                <span className={this.state.error ? "error-icon" : "success-icon"}>
                                                    {this.state.error ? (this.state.info ? 'i' : '!') : ''}
                                                </span>
                                                <div className="txt1">{this.state.title}</div>
                                                <div className="txt2">{this.state.message}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer border-top-0 justify-content-center pb-4">
                                            {(this.state.delete || this.state.error) &&
                                                <button
                                                    type="button"
                                                    className={this.state.error ? "btn btn-danger" : "btn btn-primary"}
                                                    data-dismiss="modal"
                                                    onClick={() => this.resetPopup()}>
                                                    {'OK'}
                                                </button>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {/* Upload */}
                    {this.state?.uploadTemp &&
                        <div className="modal fade licence" id="uploadTempModal" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" data-backdrop="static">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Upload Configuration</h5>
                                        <button
                                            type="button"
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                            onClick={() => this.closeUpload()}>
                                            <span aria-hidden="true"><img src="/images/svg-icons/cancel.svg" alt="" /></span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className='form-group mb-0'>
                                            <label>Add ZIP File</label>
                                            <div className="drag-box">
                                                <input
                                                    className="f1"
                                                    type="file"
                                                    accept={".zip"}
                                                    onClick={(e) => e.target.files[0] && this.jsonFileHandleSelect(e)}
                                                    onChange={(e) => this.jsonFileHandleSelect(e)} />
                                                <div className="csv-file">
                                                    <img src="../images/svg-icons/outbox.svg" alt="" />
                                                    <span>Click here to upload templates</span>
                                                </div>
                                            </div>
                                        </div>

                                        {(this.state.jsonFile !== undefined) && <div className="droped-files">
                                            <span>{this.state.jsonFile.name}</span>
                                            <img className="delete-icon" src="/images/svg-icons/delete-red.svg" alt="" onClick={() => this.deleteJson()} />
                                        </div>}
                                        <div>
                                            {(this.state.jsonFile !== undefined &&
                                                !this.state.jsonFile.name.includes('.zip')) &&
                                                <label className='license-btn'>Please upload a ZIP file!!</label>
                                            }
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="reset"
                                            onClick={() => this.closeUpload()}
                                            className="btn btn-light"
                                            data-dismiss="modal"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => this.uploadTemplates()}
                                            disabled={(this.state.jsonFile === undefined || !this.state.jsonFile.name.includes('.zip'))}
                                            className="btn btn-primary"
                                            data-dismiss="modal"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    {/* Upload Templates Ends */}
                    {this.state.showRefreshModal && <ErrorHandlePopup />}
                    {
                        (this.state.isAxiosError) &&
                        <ErrorPopup
                            title={this.state.title}
                            message={this.state.message}
                            error={this.state.error}
                            delete={this.state.delete}
                            reset={() => this.setState({ isAxiosError: false })}
                        />
                    }
                    {this.state.createCheck && <div className="modal fade exampleModal" id="exampleModalCheckpoint"
                        tabIndex="-1" aria-labelledby="exampleModalLabel" data-backdrop="static" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Create Checkpoint</h5>
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close">
                                        <span aria-hidden="true"><img src="/images/svg-icons/cancel.svg" alt="" /></span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label>Checkpoint Name</label>
                                        <input
                                            type="text"
                                            maxLength={50}
                                            className="form-control"
                                            name="template_name"
                                            value={this.state.checkpoint_name}
                                            onChange={(e) => this.setState({ checkpointName: e.target.value })} />

                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-light"
                                        data-dismiss="modal"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        disabled={(this.state.checkpointName)?.trim() === ''}
                                        onClick={() => this.createCheckpoint()}
                                    >
                                        Create
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </>
        )
    }
};

const mapStateToProps = (state) => {
    return ({
        openAddTemplate: state.displayAddTemplate.openAddTemplate,
        authenticated: state.auth.authenticated,
        open: state.displaySidebarMenu.openSide,
        userRole: String(state.auth.role)
    });
};

export default withRouter(connect(mapStateToProps)(AppliedTemplateList));