/* eslint-disable no-eval */
/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import SideMenu from "../../layOuts/SideMenu";
import Header from "../../layOuts/Header";
import AddTunnel from "./AddTunnel";
import * as Action from "../../redux/actionTypes";
import ApiCall from "../../redux/API/apiCall";
import urlMapping from "../../redux/API/api";
import { Loader } from "../../layOuts/Loader";
import EditTunnel from "./EditTunnel";
import moment from 'moment';
import * as Constant from "../../utils/constants";
import { ErrorPopup } from "../../layOuts/errorPopup";
import $, { error } from "jquery";
import { Link } from "react-router-dom";
import { LinkPopup } from '../../layOuts/linkPopup';
import ErrorHandlePopup from "../ErrorHandlePopup";
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import Select from 'react-select';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import '../deviceList/DataTableDemo.css';
import { Dropdown } from 'primereact/dropdown';

import { Calendar } from 'primereact/calendar';
import "react-datepicker/dist/react-datepicker.css";
import ApplyTemplate from "../templates/AddTemplates/ApplyTemplate";
class TunnelsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRefreshModal: false,
      multipleSelect: "",
      toggledClearRows: false,
      showLoader: false,
      title: "",
      message: "",
      selectedOption: [],
      show: false,
      showLink: false,
      error: false,
      pageNo: 1,
      pageSize: 10,
      first: 1,
      last: 10,
      next: true,
      prev: true,
      firstDefault: 0,
      delete: false,
      keyRotation: false,
      showPopup: false,
      configured: false,
      selectedTunnel: undefined,
      tunnelDevicesData: undefined,
      showFilter: false,
      selectedDevice: "",
      status: this.props.location?.state?.tunnelStatus || "",
      result: false,
      disableUpdate: false,
      TunnelsData: [],
      deviceList: [],
      tunnelListWithId: [],
      newDeviceList: [],
      searchValue: "",
      order: '',
      sortOrder: 1,
      flag: 0,
      sortField: '',
      searchClicked: false,
      sortOn: false,
      selectedHub: this.props.location?.state?.selectedHub || "",
      groupId: this.props.location?.state?.group_id || "",
      filterData: {},
      filterKey: this.props.location?.state?.filterKey || "",
      searchKey: this.props.location?.state?.searchKey || "",
      scheduleLater: false,
      startDate: null,
      allTunnels: false,
      isAxiosError: false,
      tunnelsColumns: [
        {
          field: 'tunnel_name',
          header: 'Tunnel Name',
          value: 'Tunnel Name',
          body: this.tunnelNameTemplate,
          frozen: false,
          alignFrozen: 'left',
          sortable: true,
          style: { width: '210px', flexGrow: 1, minWidth: '130px' }
        },
        {
          field: 'tunnel_status',
          header: 'Status',
          value: 'Status',
          body: this.statusTemplate,
          frozen: false,
          sortable: true,
          style: { width: '100px', flexGrow: 1, minWidth: '110px' }
        },
        {
          field: 'tunnel_type',
          header: 'Tunnel Type',
          frozen: false,
          value: 'Tunnel Type',
          sortable: true,
          body: this.tunnelType,
          style: { width: '130px', flexGrow: 1, minWidth: '130px' }
        },
        {
          field: 'device_a_name',
          header: 'Device A Name',
          value: 'Device A Name',
          frozen: false,
          sortable: false,
          body: this.deviceAName,
          style: { width: '130px', flexGrow: 1, minWidth: '130px' }
        },
        {
          field: 'device_b_name',
          header: 'Device B Name',
          value: 'Device B Name',
          frozen: false,
          sortable: false,
          body: this.deviceBName,
          style: { width: '130px', flexGrow: 1, minWidth: '130px' }
        },
        {
          field: 'group_name',
          header: 'Group Name',
          value: 'Group Name',
          frozen: false,
          sortable: false,
          body: this.groupName,
          style: { width: '130px', flexGrow: 1, minWidth: '130px' }
        },
        {
          field: 'created_date',
          header: 'Created Date',
          frozen: false,
          value: 'Created Date',
          sortable: true,
          body: this.tunnelCreated_date,
          style: { width: '220px', flexGrow: 1, minWidth: '200px' }
        },
        {
          field: 'updated_date',
          header: 'Last Updated On',
          frozen: false,
          value: 'Last Updated On',
          sortable: true,
          body: this.tunnelUpdated_date,
          style: { width: '220px', flexGrow: 1, minWidth: '200px' }
        },
        {
          field: 'tunnel_latency',
          header: 'Avg. Latency',
          frozen: false,
          value: 'Avg. Latency',
          sortable: false,
          body: this.tunnelLatencyTemplate,
          style: { width: '100px', flexGrow: 1, minWidth: '30px' }
        },
        {
          field: 'Action',
          header: '',
          body: this.actionTemplate,
          value: 'Action',
          style: { flexGrow: 1, flexBasis: '120px' },
          sortable: false,
          alignFrozen: 'right',
          frozen: true
        }
      ],
      addTunnel: false,
      deviceA_networks: [],
      deviceB_networks: [],
      ipsecData: {},
      ipsecEdit: false,
      tunnelType: ""
    };
  }

  componentDidMount() {
    if (this.props.location?.state?.data !== undefined && this.props.location?.state?.data !== "") {
      this.openEdit(this.props.location?.state?.data)
    }
    if (this.state.filterKey === "FilterClicked") {
      this.setState({
        selectedDevice: this.props.history.location.state.filterData.selectedDevice,
        status: this.props.history.location.state.filterData.status
      }, () => {
        this.getTunnelFilterList();
        this.getModelList();
      })
    }
    else if (this.state.searchKey === "SearchClicked") {
      this.setState({
        searchValue: this.props.history.location.state.searchValue
      }, () => {
        this.handleSearch()
      })
    }
    else {
      this.getTunnelList();
    }
    let newArray = this.state.tunnelsColumns;
    const localValue = JSON.parse(localStorage.getItem(Constant.TUNNEL_LIST));
    if (!!localValue) {
      newArray = [];
      localValue.map((item) => {
        const column = this.valueReturn(item);
        if (!!column) newArray.push(column);
      });
    }
    this.setState({ selectedOption: newArray });
  }
  sortAlphaNum = (a, b) => {
    var reA = /[^a-zA-Z]/g;
    var reN = /[^0-9]/g;
    var aA = a.replace(reA, "");
    var bA = b.replace(reA, "");
    if (aA === bA) {
      var aN = parseInt(a.replace(reN, ""), 10);
      var bN = parseInt(b.replace(reN, ""), 10);
      return aN === bN ? 0 : aN > bN ? 1 : -1;
    } else {
      return aA > bA ? 1 : -1;
    }
  };
  getConvertedData = (data) => {
    if (data / 1024 > 1024) {
      let value = data / (1024 * 1024);
      return value.toFixed(2).toString().concat(" MB");
    } else if (data / 1024 > 1) {
      let value = data / 1024;
      return value.toFixed(2).toString().concat(" KB");
    } else {
      return data.toString().concat(" Bytes");
    }
  };
  getTunnelList = (value) => {
    let sorting_parameter = value === 'sort' && this.state.sortField !== null ? this.state.sortField : 'created_date'
    let sorting_type = value === 'sort' ? this.state.order : 'descending'
    // Tunnel list page refresh issue after clicking on filter
    if (this.state.deviceList.length > 0) {
      this.getTunnelFilterList('sort')
    }
    else if ((this.state.status && value !== "Refresh") ||
      (this.state.selectedHub && this.state.groupId && value !== "Refresh")) {
      this.getTunnelFilterOnStatus('sort')
    }
    else if (this.state.status) {
      this.getTunnelFilterOnStatus();
    }
    else {
      this.setState({
        showLoader: true,
        searchValue: "",
      });
      ApiCall(
        urlMapping.getTunnelList(sorting_parameter, sorting_type, this.state.pageNo, this.state.pageSize),
        (response) => {
          this.setState({
            showLoader: false,
          });
          if (response?.results) {
            const data = response?.results;
            const totalCount = response?.count;
            const first = (this.state.pageSize * (this.state.pageNo - 1)) + 1;
            const last = response?.next ? this.state.pageSize * this.state.pageNo : response?.count;
            this.setState({
              next: response?.next ? false : true,
              prev: response?.previous ? false : true,
              first: first,
              last: last,
              TunnelsData: data,
              totalCount,
              showIcon: false,
            }, () => this.getTunnelListDrop());
          } else {
            if (parseInt(response?.response?.status) === 401) {
              this.setState({ showRefreshModal: true })
            }
            else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
              this.setState({
                title: 'Network Error',
                message: 'Something went wrong. Please try again later.',
                showLoader: false,
                isAxiosError: true,
                delete: false,
                error: true
              }, () => $("#errorMessage").modal("show"))
            }
          }
        }
      );
    }
  };
  getTunnelListDrop = () => {
    let data = {}
    this.state.TunnelsData.map((item) => {
      data = {
        id: item.tunnel_id, name: item.tunnel_name,
        mac: [item.device_A_mac, item.device_B_mac],
        // For updating tunnel info and status properly
        status: item.tunnel_status,
        tunnel_latency: item.tunnel_latency,
        drop_rate_in: item.drop_rate_in,
        drop_rate_out: item.drop_rate_out,
        tunnel_data_usage: item.tunnel_data_usage,
        created_date: item.created_date,
        tunnel_type: item.tunnel_type,
        tunnel_id: item?.tunnel_id
      }
      this.state.tunnelListWithId.push(data)
    })
  }

  onlineEdit = (row) => {
    const deviceAStatus = parseInt(row?.device_a_status);
    const deviceBStatus = parseInt(row?.device_b_status);
    let result = false;
    if (deviceAStatus === 1) {
      result = true;
    }
    if (row?.device_b_status !== undefined && row?.device_b_status !== "") {
      if (deviceBStatus === 1) {
        result = true;
      } else {
        result = false;
      }
    }
    if (result) {
      this.openEdit(row);
    }
    else {
      this.setState(
        {
          tunnelDevicesData: undefined,
          title: "Edit Tunnel",
          message: 'You can not edit this tunnel as some device is in offline. Please make sure all devices in the tunnel to be online to edit',
          show: true,
          error: true,
          pass: false,
        },
        () => {
          $("#errorMessage").modal("show");
        }
      );
    }
  };

  onlineDelete = (row) => {
    let result = false
    if (parseInt(row?.device_a_status) === 1) {
      result = true
    }
    if (row?.device_b_status !== undefined &&
      row?.device_b_status !== "" &&
      parseInt(row?.device_b_status) === 1) {
      result = true
    }
    if (result) {
      this.confirmDelete(row);
    }
    else {
      this.setState(
        {
          tunnelDevicesData: undefined,
          title: "Delete Tunnel",
          message: 'You can not delete this tunnel as some device is in offline. Please make sure all devices in the tunnel to be online to delete',
          show: true,
          error: true,
          pass: false,
        },
        () => {
          $("#errorMessage").modal("show");
        }
      );
    }
  };

  openEdit = (row) => {
    this.setState({
      ipsecData: {},
      ipsecEdit: false
    }, () => {
      if (
        (
          // row?.group_name !== undefined &&
          // row?.group_name !== "" &&
          row?.group_id !== null
        ) ||
        row?.tunnel_type === "IPSec Tunnel"
      ) {
        this.getTunnelDetails(row)
      }
      else {
        let tunnel_id = row.tunnel_id;
        const device_mac = [];
        device_mac.push(row.device_A_mac);
        device_mac.push(row.device_B_mac);
        const data = { device_mac, tunnel_id };
        this.setState({
          showLoader: true,
        });
        ApiCall(urlMapping.tunnelInfo(data), (response) => {
          this.setState({
            showLoader: false,
          });
          if (response?.success) {
            if (response?.data?.[0]?.device_A?.is_ippt || response?.data?.[0]?.device_B?.is_ippt) {
              this.setState(
                {
                  tunnelDevicesData: undefined,
                  title: "Edit Tunnel",
                  message: 'You can not edit this tunnel as one of the device has network with IPPT protocol',
                  show: true,
                  error: true,
                  pass: false,
                },
                () => {
                  $("#errorMessage").modal("show");
                }
              );
            } else {
              this.getNetworksDeviceA(response?.data?.[0]?.device_A?.device_id);
              this.getNetworksDeviceB(response?.data?.[0]?.device_B?.device_id);
              this.setState(
                {
                  tunnelDevicesData: response?.data?.[0],
                  pass: true,
                },
                () => {
                  this.props.dispatch({
                    type: Action.OPEN_EDIT_TUNNEL,
                    payload: tunnel_id
                  })
                }

              );
            }
          }
          else if (response?.data) {
            if (response?.data?.length < 1) {
              this.setState(
                {
                  tunnelDevicesData: undefined,
                  title: "Edit Tunnel",
                  message: response?.message,
                  show: true,
                  error: true,
                  pass: false,
                },
                () => {
                  $("#errorMessage").modal("show");
                }
              );
            } else {
              this.setState(
                {
                  tunnelDevicesData: undefined,
                  title: "Edit Tunnel",
                  message: response?.data?.message,
                  show: true,
                  error: true,
                  pass: false,
                },
                () => {
                  $("#errorMessage").modal("show");
                }
              );
            }
          } else {
            if (parseInt(response?.response?.status) === 401) {
              this.setState({ showRefreshModal: true })
            } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
              this.setState({
                title: 'Network Error',
                message: 'Something went wrong. Please try again later.',
                showLoader: false,
                isAxiosError: true,
                delete: false,
                error: true
              }, () => $("#errorMessage").modal("show"))
            } else {
              this.setState(
                {
                  tunnelDevicesData: undefined,
                  title: "Edit Tunnel",
                  message: response?.message,
                  show: true,
                  error: true,
                  pass: false,
                },
                () => {
                  $("#errorMessage").modal("show");
                }
              );
            }
          }
        });
      }
    })
  };

  getTunnelDetails = (value) => {
    let data = {};
    if (String(value?.tunnel_type) !== "IPSec Tunnel") {
      data.tunnel_id = value?.tunnel_id
    }
    if (String(value?.tunnel_type) === "IPSec Tunnel") {
      data.ipsec_tunnel_id = value?.tunnel_id
    }
    this.setState({
      showLoader: true,
    });
    ApiCall(urlMapping.tunnelInfo(data), (response) => {
      this.setState({
        showLoader: false,
      });
      if (
        response?.success &&
        response?.data
      ) {
        if (
          value?.group_id !== null &&
          value?.group_id !== undefined &&
          value?.group_id !== "" &&
          value?.group_id !== 0
        ) {

          if (value?.tunnel_type === "IPSec Tunnel") {
            let { template_details } = response?.data;
            template_details.ipsec_json = response?.data?.raw_json;
            this.props.history.push({
              pathname: '/devicegroupdetails',
              state: {
                editTunnelSelectedTab: "AppliedTemplate",
                editTunnel_group_id: value?.group_id,
                data: value?.tunnel_type === "IPSec Tunnel"
                  ? template_details
                  : response?.data?.[0]?.template_details,
              }
            })
          } else {
            this.props.history.push({
              pathname: '/devicegroupdetails',
              state: {
                editTunnelSelectedTab: "AppliedTemplate",
                editTunnel_group_id: value?.group_id,
                data: response?.data?.[0]?.template_details,
              }
            })
          }
        }
        else if (value?.tunnel_type === "IPSec Tunnel") {
          let { template_details } = response?.data;
          template_details.ipsec_json = response?.data?.raw_json;
          this.setState({
            ipsecData: template_details,
            ipsecEdit: true
          }, () => {
            this.props.dispatch({
              type: Action.OPEN_APPlY_TEMPLATE,
            });
          })
        }
      } else {
        if (
          parseInt(response?.response?.status) === 401
        ) {
          this.setState({ showRefreshModal: true })
        }
        else if (
          response?.isAxiosError &&
          response?.success === undefined &&
          response?.response?.data?.success === undefined
        ) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
      }
    })
  };

  multipleSelect(rows) {
    if (!rows.selectedRows.length) this.setState({ multipleSelect: "" });
    else {
      this.setState({ multipleSelect: rows.selectedRows });
    }
  }
  resetPopup = () => {
    this.setState(
      {
        show: false,
        showPopup: false,
        error: false,
        delete: false,
        keyRotation: false,
        multipleSelect: "",
        scheduleLater: false,
        startDate: null,
        allTunnels: false
      },
      () => {
        $("#errorMessage").modal("hide");
        this.getTunnelList();
        if (this.state.closeTunnel) this.closeEditTunnel();
      }
    );
  };
  checkDeviceTask = async () => {
    let deviceMac = [];

    this.state.multipleSelect.map((element) => (
      deviceMac.push(element.device_A_mac, element.device_B_mac)
    ));

    let data = { device_mac: deviceMac, operation_type: ['Firmware Upgrade'] }
    this.setState({
      showLoader: true
    });

    const trueArr = [];
    await ApiCall(urlMapping.checkDeviceTask(data), response => {
      this.setState({
        showLoader: false,
        show: false
      });
      $("#errorMessage").modal("hide");
      if (response?.success === true) {
        const data = response?.data;
        Object?.keys(data)?.forEach(item => {
          if (data?.[item]?.status === true) {
            trueArr?.push(data?.[item]);
          }
        });
        if (trueArr?.length > 0) {
          this.setState({
            title: 'Device Task',
            message: trueArr?.[0]?.message,
            show: true,
            error: true,
            delete: false,
            toggledClearRows: !this.state.toggledClearRows,
          }, () => $("#errorMessage").modal("show"));
        }
        this.setState({
          show: false,
          error: false,
          delete: false
        })
      } else {
        $("#errorMessage").modal("hide");
        if (parseInt(response?.response?.status) === 401) {
          this.setState({
            showRefreshModal: true
          })
        } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        } else {
          this.setState({
            title: 'Device Task',
            message: response?.message,
            show: true,
            error: true,
            delete: false
          }, () => $("#errorMessage").modal("show"));
        }
      }
    })
    return trueArr;
  };

  deleteTunnel = async () => {
    let tunnel_id = this.state.multipleSelect[0].tunnel_id;
    const haveTask = await this.checkDeviceTask();
    this.setState({
      showLoader: true,
    });
    if (haveTask.length === 0) {
      ApiCall(urlMapping.deleteTunnel(tunnel_id), (response) => {
        this.setState({
          showLoader: false,
        });
        if (response?.success === true) {
          let newPage = this.state.pageNo;
          if (this.state.pageNo > 1) {
            newPage = this.state.totalCount - this.state.pageSize * (this.state.pageNo - 1) -
              this.state.multipleSelect?.length === 0 && this.state.pageNo > 1 ? this.state.pageNo - 1
              : this.state.pageNo;
          }
          $("#errorMessage").modal("hide");
          this.setState(
            {
              title: "Delete Tunnels",
              message: 'Delete Tunnel Operation Initiated Successfully.To see the progress ',
              showLink: true,
              id: response?.data,
              toggledClearRows: !this.state.toggledClearRows,
              pageNo: newPage,
            },
            () => $("#exampleModal").modal("show")
          );
          this.getTunnelList();
        } else {
          if (parseInt(response?.response?.status) === 401) {
            this.setState({ showRefreshModal: true })
          }
          else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
            this.setState({
              title: 'Network Error',
              message: 'Something went wrong. Please try again later.',
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          }
          else {
            this.setState(
              {
                title: "Delete Tunnels",
                message: response?.message,
                show: true,
                error: true,
                delete: false,
              },
              () => $("#errorMessage").modal("show")
            );
          }
        }
      });
    } else {
      this.setState({
        showLoader: false,
      });
    }
  };

  ipsecDeleteTunnel = async () => {
    let tunnel_id = this.state.multipleSelect[0].tunnel_id;
    const haveTask = await this.checkDeviceTask();
    this.setState({
      showLoader: true,
    });
    const data = {
      ipsec_tunnel_id: tunnel_id
    }
    if (haveTask.length === 0) {
      ApiCall(urlMapping.deleteIPSecTunnel(data), (response) => {
        this.setState({
          showLoader: false,
        });
        if (response?.success === true) {
          let newPage = this.state.pageNo;
          if (this.state.pageNo > 1) {
            newPage = this.state.totalCount - this.state.pageSize * (this.state.pageNo - 1) -
              this.state.multipleSelect?.length === 0 && this.state.pageNo > 1 ? this.state.pageNo - 1
              : this.state.pageNo;
          }
          $("#errorMessage").modal("hide");
          this.setState(
            {
              title: "Delete Tunnels",
              message: 'Delete Tunnel Operation Initiated Successfully.To see the progress ',
              showLink: true,
              show: true,
              error: false,
              delete: false,
              toggledClearRows: !this.state.toggledClearRows,
              pageNo: newPage,
            },
            () => $("#exampleModal").modal("show")
          );
          this.getTunnelList();
        } else {
          if (parseInt(response?.response?.status) === 401) {
            this.setState({ showRefreshModal: true })
          }
          else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
            this.setState({
              title: 'Network Error',
              message: 'Something went wrong. Please try again later.',
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          }
          else {
            this.setState(
              {
                title: "Delete Tunnels",
                message: response?.message,
                show: true,
                error: true,
                delete: false,
              },
              () => $("#errorMessage").modal("show")
            );
          }
        }
      });
    }
  };

  confirmDelete = (row) => {
    this.setState(
      {
        title: "Delete Tunnel",
        // LONG-3646 : Tunnel Delete for Device Group
        message: (row.group_id === null || row.group_id === '') ? "Delete Tunnel Operation will delete the tunnel established between the devices. Click OK to proceed or Click Cancel to cancel the operation."
          : "Delete Tunnel Operation will delete all the associated tunnels with the Hub & Spokes under the group. Click OK to proceed or Click Cancel to cancel the operation.",
        show: true,
        error: true,
        delete: true,
        tunnelType: row?.tunnel_type
      },
      () => {
        $("#errorMessage").modal("show");
      }
    );
  };
  setOnAllTunnels = () => {
    this.setState({
      allTunnels: !this.state.allTunnels
    })
  }
  scheduleLater = () => {
    this.setState({
      scheduleLater: !this.state.scheduleLater
    });
  }
  setStartDate = (e) => {
    this.setState({
      startDate: e.value
    })
  }
  monthNavigatorTemplate(e) {
    return <Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)} style={{ lineHeight: 1 }} />;
  }

  yearNavigatorTemplate(e) {
    return <Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)} className="ml-2" style={{ lineHeight: 1 }} />;
  }
  checkOnlineDevices = () => {
    let result = false;
    let deviceA_status = this.state.multipleSelect.filter((item) => item.device_a_status === 0).length;
    let deviceB_status = this.state.multipleSelect.filter((item) => item.device_b_status === 0).length;
    if (deviceA_status > 0 || deviceB_status > 0)
      result = true;
    return result;
  };
  confirmRotateKey = (row) => {
    if (row) {
      if (row[0].device_a_status === 1 && row[0].device_b_status === 1) {
        this.setState({
          title: "Key Rotation",
          message: `Do you want to trigger Key rotation for: ${row[0].tunnel_name}${"\n"}Note: There will be loss in data tunnel traffic during key rotation.        `,
          showPopup: true,
          error: true,
          delete: true,
          keyRotation: true
        },
          () => {
            $("#keyRotationErrorMessage").modal("show");
          }
        );
      }
      else {
        let popupMessage;
        if (row[0].device_a_status === 0 && row[0].device_b_status === 0) {
          popupMessage = `WARNING: Due to the offline status of ${row[0].device_A_mac} & ${row[0].device_B_mac}, the key rotation will not be triggered for: ${row[0].tunnel_name}`
        }
        else if (row[0].device_a_status === 0) {
          popupMessage = `WARNING: Due to the offline status of ${row[0].device_A_mac}, the key rotation will not be triggered for: ${row[0].tunnel_name}.`
        }
        else {
          popupMessage = `WARNING: Due to the offline status of ${row[0].device_B_mac}, the key rotation will not be triggered for: ${row[0].tunnel_name}.`
        }
        this.setState({
          title: "Key Rotation",
          message: popupMessage,
          showPopup: true,
          error: true,
          keyRotation: false
        },
          () => {
            $("#keyRotationErrorMessage").modal("show");
          }
        );
      }
    }
    else {
      if (this.state.multipleSelect.length > 0) {
        this.setState(
          {
            title: "Key Rotation",
            message: `Do you want to trigger Key rotation? ${"\n"}Note: There will be loss in Mgmt. Plane traffic during key rotation.`,
            showPopup: true,
            error: true,
            delete: true,
            keyRotation: true
          },
          () => {
            $("#keyRotationErrorMessage").modal("show");
          }
        );
      }
    }
  }
  rotateKeyOperation = async () => {
    let data = {};
    if (this.state.multipleSelect.length > 0) {
      let tunnelIds = this.state.multipleSelect.map(item => item.tunnel_id)
      if (this.state.scheduleLater === true && this.state.startDate !== null) {
        data = {
          tunnel_ids: tunnelIds,
          scheduled_time: parseInt(new Date(this.state.startDate).getTime().toString().slice(0, 10)),
          on_all_tunnel: this.state.allTunnels
        }
      }
      else {
        data = {
          tunnel_ids: [this.state.multipleSelect?.[0]?.tunnel_id]
        }
      }
    }
    this.setState({
      showLoader: true,
    });
    let msg = `Key Rotation has been scheduled for ${this.state.startDate}`
    ApiCall(urlMapping.rotateTunnelKey(data), (response) => {
      this.setState({
        showLoader: false,
      });
      if (response?.success === true) {
        $("#errorMessage").modal("hide");
        this.setState(
          {
            title: "Key Rotation",
            message: this.state.scheduleLater ? msg + " To view the Operation logs" : response?.message + " To view the Operation logs",
            showPopup: true,
            error: false,
            id: response?.data,
            showLink: true,
            toggledClearRows: !this.state.toggledClearRows,
            multipleSelect: "",
            delete: false,
          },
          () => $("#exampleModal").modal("show")
        );
        this.getTunnelList();
      } else {
        $("#errorMessage").modal("hide");
        if (parseInt(response?.response?.status) === 401) {
          this.setState({ showRefreshModal: true });
        } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        } else {
          this.setState(
            {
              title: "Key Rotation",
              message: response?.message,
              showPopup: true,
              error: true,
              delete: false,
            },
            () => $("#errorMessage").modal("show")
          );
        }
      }
    });
  };
  // Disable Edit if group id exists
  disableEdit = (row) => {
    if (
      (row.group_id === 'null' ||
        row.group_id === null ||
        row.group_id === '') &&
      !(this.props?.userRole === "Viewer_Group" ||
        this.props?.userRole === "Operator_Group")
    )
      return undefined
    if (process.env.REACT_APP_AD_USER === 'TRUE' &&
      (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
        localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true'))
      return "disabled"
    else
      return "disabled"
  }

  handleChange = (e) => {
    let array = [];
    this.state.selectedOption.map((option) => (
      array.push(option.value)
    ));
    const result = e.target.value;
    if (result === "selectAll") {
      array = [];
      if (e.target.checked) {
        this.state.tunnelsColumns.map((column) => (
          array.push(column.value)
        ));
      } else {
        array = ["Tunnel Name", "Action"];
      }
    } else {
      if (e.target.checked) array.push(result);
      else array.splice(array.indexOf(result), 1);
    }
    let availableColumns = [
      "Tunnel Name",
      "Status",
      "Tunnel Type",
      "Device A Name",
      "Device B Name",
      "Group Name",
      "Created Date",
      "Last Updated On",
      "Avg. Latency",
      "Action"
    ];
    array = availableColumns?.filter(field => array?.includes(field));
    if (array.includes("Action")) {
      array.splice(array.indexOf("Action"), 1);
      array.push("Action");
    }
    const selectedVlaues = [];
    array.map((item) => {
      const column = this.valueReturn(item);
      selectedVlaues.push(column);
    });
    localStorage.setItem(Constant.TUNNEL_LIST, JSON.stringify(array));
    this.setState({ selectedOption: selectedVlaues });
  };
  valueReturn = (item) => {
    return this.state.tunnelsColumns.filter(
      (column) => column.value === item
    )[0];
  };
  getModelList = () => {
    this.setState((prevState) => ({ showFilter: !prevState.showFilter }));
    ApiCall(urlMapping.getDeviceTunnelListFilter(), (response) => {
      if (response?.success) {
        this.setState({ deviceList: response?.devices });
      } else {
        if (parseInt(response?.response?.status) === 401) {
          this.setState({ showRefreshModal: true })
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
      }
    });
  };
  getselectedDevice = (e) => {
    if (e && e.label) {
      this.setState({
        selectedDevice: e.value
      })
    }
    else {
      this.setState({
        selectedDevice: '',
      })
    }
  };
  getOptions = () => {
    let options = [];
    options = this.state.deviceList.map((item) => {
      if (item !== "")
        return { label: item.device_name, value: item.device_mac }
    })
    return options;
  }
  filterOptions = (option, inputValue) => {
    const { label } = option;
    const otherKey = this.getOptions().filter(
      (opt) => opt.label.toLowerCase() === label.toLowerCase() && opt.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    return label.toLowerCase().includes(inputValue.toLowerCase()) || otherKey.length > 0;
  }
  g
  tunnelSort = (columnSort) => {
    if (this.state.flag < 2) {
      this.setState({
        sortField: columnSort.sortField,
        sortOrder: columnSort.sortOrder,
        order: columnSort.sortOrder === 1 ? 'ascending' : 'descending',
        sortOn: true,
        flag: this.state.flag + 1,
      }, this.state.searchClicked ? () => this.handleSearch('sort') : () => this.getTunnelList("sort")
      )
    } else {
      this.setState({
        sortField: null,
        order: 'descending',
        sortOn: true,
        flag: 0,
      }, this.state.searchClicked ? () => this.handleSearch('sort') : () => this.getTunnelList("sort")
      )
    }
  }
  getTunnelFilterList = (sort) => {
    let data = {}
    if (this.state.selectedDevice) {
      data = {
        ...data, device_mac: this.state.selectedDevice,
        sorting_parameter: sort === 'sort' && this.state.sortField !== null ? this.state.sortField : 'created_date',
        sorting_type: sort === 'sort' ? this.state.order : 'descending'
      };
    }
    if (this.state.status) {
      data = {
        ...data, tunnel_status: this.state.status,
        sorting_parameter: sort === 'sort' && this.state.sortField !== null ? this.state.sortField : 'created_date',
        sorting_type: sort === 'sort' ? this.state.order : 'descending'
      };
    }
    //EMGT-151-EM- Unable to refresh the page, after filter is applied
    this.setState({ showLoader: true });
    ApiCall(urlMapping.tunnelFilterStatus(data, this.state.pageNo, this.state.pageSize), (response) => {
      this.setState({
        showLoader: false,
        filterData: {
          selectedDevice: this.state.selectedDevice,
          status: this.state.status
        }
      })
      if (response?.results) {
        const first = response?.previous ? (this.state.pageSize * (this.state.pageNo - 1)) + 1 : 1;
        const last = response?.next ? this.state.pageSize * this.state.pageNo : response?.count;
        this.setState({
          next: response?.next ? false : true,
          prev: response?.previous ? false : true,
          first: first,
          last: last,
          totalCount: response?.count,
          showFilter: false,
          showIcon: this.state.status || this.state.selectedDevice ? true : false,
          TunnelsData: response?.results
        })
        if (this.state.status === "") {
          this.setState({
            TunnelsData: response?.results
          })
        }
        else {
          this.setState({
            TunnelsData: response?.results,
          })
        }
      } else {
        if (parseInt(response?.response?.status) === 401) {
          this.setState({ showRefreshModal: true })
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
      }
    });
  };

  getTunnelFilterOnStatus = (sort) => {
    let data = {}
    if (this.state.status) {
      data = { tunnel_status: this.state.status };
    }
    if (this.state.selectedHub && this.state.groupId) {
      data = {
        group_id: this.state.groupId, device_mac: this.state.selectedHub,
        sorting_parameter: sort === 'sort' && this.state.sortField !== null ? this.state.sortField : 'created_date',
        sorting_type: sort === 'sort' ? this.state.order : 'descending'
      }
    }
    this.setState({ showLoader: true })
    ApiCall(urlMapping.tunnelFilterStatus(data, this.state.pageNo, this.state.pageSize), (response) => {
      this.setState({ showLoader: false })
      if (response?.results) {
        const first = response?.previous ? (this.state.pageSize * (this.state.pageNo - 1)) + 1 : 1;
        const last = response?.next ? this.state.pageSize * this.state.pageNo : response?.count;
        this.setState({
          next: response?.next ? false : true,
          prev: response?.previous ? false : true,
          first: first,
          last: last,
          totalCount: response?.count,
          showFilter: false,
          showIcon: this.state.status || this.state.selectedDevice ? true : false,
          TunnelsData: response?.results,
        }, () => this.getTunnelListDrop())
        if (this.state.status === "") {
          this.setState({
            newDeviceList: response?.results
          })
        }
        else {
          this.setState({
            TunnelsData: response?.results,
          })
        }
      } else {
        if (parseInt(response?.response?.status) === 401) {
          this.setState({ showRefreshModal: true })
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
      }
    });
  };

  setStatus = (e) => {
    if (e.target.value === "select") {
      this.setState({ status: "" });
    } else {
      this.setState({ status: e.target.value })
    }
  };
  resetValue = () => {
    // Tunnel list page refresh issue after clicking on filter
    this.setState({
      selectedDevice: "",
      status: "",
      deviceList: [],
      showIcon: false,
      newDeviceList: [],
      selectedHub: "",
      showFilter: false,
      groupId: "",
      pageNo: 1,
      filterData: {}
    }, () =>
      this.getTunnelList(),
      //LONG-3160-EM -In tunnel page, Device list is not showing in drop down for filter icon-Issue fixed code pushed
      // this.getModelList()
    );
  };
  hideDropdown = (e) => {
    var popup = $("#parentDiv");
    if (
      !$("#open").is(e.target) &&
      !popup.is(e.target) &&
      popup.has(e.target).length === 0
    ) {
      this.setState({
        showFilter: false, selectedDevice: this.state.showIcon ?
          this.state.selectedDevice : '', status: this.state.showIcon ? this.state.status : ""
      })
    }
  };
  clearDropdown = (type) => {
    if (type === "device") {
      this.setState({ selectedDevice: "", status: "" });
    } else {
      this.setState({ status: "" });
    }
  };
  getFinalFilterList = () => {
    this.setState({ showLoader: true })
    if (this.state.selectedDevice !== "" && this.state.status !== "") {
      const value = this.state.newDeviceList.filter((item) =>
        this.state.status === "Online"
          ? item.tunnel_status === this.state.status
          : item.tunnel_status !== "Online"
      )
      setTimeout(() => {
        this.setState({ TunnelsData: value, showFilter: false, showLoader: false, showIcon: true });
      }, 500);

    } else {
      setTimeout(() => {
        this.setState({ TunnelsData: this.state.newDeviceList, showFilter: false, showLoader: false });
      }, 500);

    }

  };

  openOperationList = (value) => {
    $("#exampleModal").modal("hide");
    this.props.history.push({
      pathname: '/operations',
      state: { data: value }
    })
  }
  closeLinkpopup = () => {
    $("#exampleModal").modal("hide");
    this.setState({ showLink: false, id: '', scheduleLater: false, startDate: null, allTunnels: false });
  }

  onSelectRow = (e) => {
    if (e.value.length === 0) {
      this.setState({
        multipleSelect: ''
      })
    } else {
      this.setState({
        multipleSelect: e.value
      })
    }
  }

  tunnelNameTemplate = (row) => {
    return (
      <span title={row.tunnel_name || "-"}>
        <Link
          to={{
            pathname: "/tunnelsView",
            state: {
              data: row,
              tunnelListWithId: this.state.tunnelListWithId,
              filterData: this.state.filterData,
              searchValue: this.state.searchValue
            }
          }}
          className="txt-blue"
        >
          {" "}
          {row.tunnel_name || "-"}
        </Link>
      </span>
    )
  }

  statusTemplate = (row) => {
    const getStatus = () => {
      if (row?.tunnel_type === "IPSec Tunnel") {
        const parsedStatus = parseTunnelStatus(row?.tunnel_status);
        if (parsedStatus?.status === "Online") {
          return "status online";
        } else if (parsedStatus?.status === "Partial") {
          return "status pending";
        }
        else {
          return "status offline";
        }
      } else if (row?.tunnel_status === "Online") {
        return "status online";
      }
      else return "status offline";
    };
    const parseTunnelStatus = (status) => {
      try {
        return JSON.parse(status.replace(/'/g, '"'));
      } catch (error) {
        return {};
      }
    };
    const getTitle = (parsedStatus) => {
      if (row?.tunnel_type === "IPSec Tunnel") {
        if (parsedStatus?.IPlist !== "" && parsedStatus?.IPlist !== undefined) {
          const plural1 = (parsedStatus?.IPlist)?.split(",")?.length > 1 ? "s" : "";
          const plural2 = (parsedStatus?.IPlist)?.split(",")?.length > 1 ? "are" : "is";
          return `The IPSec tunnel with remote subnet${plural1} ${parsedStatus?.IPlist} ${plural2} not configured correctly`;
        }
      }
      return "";
    };
    const status = getStatus();
    const parsedStatus = parseTunnelStatus(row?.tunnel_status);
    const title = getTitle(parsedStatus);
    return (
      <div
        title={title}
        id={`col${row?.tunnel_id}`}
      >
        <span id={`span${row?.tunnel_id}`}>
          <i className={status}></i>
          {row?.tunnel_type === "IPSec Tunnel" ? parsedStatus?.status : row?.tunnel_status}
        </span>
      </div>
    );
  };

  deviceATemplate = (row) => {
    return (
      <span title={row.device_A_mac.toUpperCase() || "-"}>{row.device_A_mac.toUpperCase() || "-"}</span>
    )
  }

  deviceAName = (row) => {
    return (
      <span title={row.device_a_name || "-"}>
        {row.device_a_name || "-"}
      </span>
    )
  }
  deviceBName = (row) => {
    return (
      <span title={row.device_b_name || "-"}>
        {row.device_b_name || "-"}
      </span>
    )
  }
  groupName = (row) => {
    return (
      <span title={row.group_name || "-"}>
        {row.group_name || "-"}
      </span>
    )
  };
  devicePrivateIpTemplate = (row) => {
    return (
      <span title={row.device_A_private_ip}>
        {row.device_A_private_ip}
      </span>
    )
  }

  deviceAEndTemplate = (row) => {
    return (
      <span title={row.device_A_endpoint}>{row.device_A_endpoint}</span>
    )
  }


  deviceBTemplate = (row) => {
    return (
      <span title={row.device_B_mac.toUpperCase()}>{row.device_B_mac.toUpperCase()}</span>
    )
  }
  tunnelType = (row) => {
    return (
      <span title={row.tunnel_type || "-"}>{row.tunnel_type || "-"}</span>
    )
  }

  deviceBPrivateIpTemplate = (row) => {
    return (
      <span title={row.device_B_private_ip}>
        {row.device_B_private_ip}
      </span>
    )
  }

  deviceBEndTemplate = (row) => {
    return (
      <span title={row.device_B_endpoint}>
        {row.device_B_endpoint ? row.device_B_endpoint : "-"}
      </span>
    )
  }


  tunnelLatencyTemplate = (row) => {
    return (
      <span>{row.tunnel_latency ? row.tunnel_latency : "-"}</span>
    )
  }
  dropRateOutTemplate = (row) => {
    return (
      <span title={row.drop_rate_out}>
        {row.drop_rate_out ? row.drop_rate_out : "-"}
      </span>
    )
  }
  dropRateInTemplate = (row) => {
    return (
      <span title={row.drop_rate_in}>
        {row.drop_rate_in ? row.drop_rate_in : "-"}
      </span>
    )
  }
  tunnelDataTemplate = (row) => {
    return (
      <div>
        <span title={row.tunnel_data_usage}>
          {row.tunnel_data_usage
            ? this.getConvertedData(parseInt(row.tunnel_data_usage))
            : "-"}
        </span>
      </div>
    )
  }
  tunnelCreated_date = (row) => {
    return (
      <div>
        <span className="date-time" title={this.getTime(row?.created_date)}>
          {this.getTime(row?.created_date)}</span>
      </div>
    )
  };

  tunnelUpdated_date = (row) => {
    return (
      <div>
        <span className="date-time" title={this.getTime(row.updated_date)}>
          {this.getTime(row.updated_date)}</span>
      </div>
    )
  };

  getTime = (time) => {
    let date = moment.utc(time);
    date = date.local();
    return date.format('DD MMMM YYYY hh:mm:ss A');
  };

  actionTemplate = (row) => {
    return (
      <div className="da-box">
        <img
          className="a-dots"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          src="/images/svg-icons/ellipsis.svg"
          alt=""
        />
        <div className="drop-box dropdown-menu">
          <ul>
            {
              (
                row?.tunnel_type !== "Wireguard Tunnel" ||
                (
                  !row?.device_a_stale_info &&
                  !row?.device_b_stale_info
                )
              )
              &&
              < li
                onClick={() =>
                  this.props.history.push({
                    pathname: "/tunnelsView",
                    state: { data: row, tunnelListWithId: this.state.tunnelListWithId }
                  })
                }

              >
                <img src="/images/svg-icons/view.svg" alt="" /> View
              </li>
            }
            {
              (
                row?.tunnel_type !== "Wireguard Tunnel" ||
                (
                  !row?.device_a_stale_info &&
                  !row?.device_b_stale_info
                )
              )
              &&
              (
                row?.tunnel_type !== "Wireguard Tunnel" ||
                row?.group_id === null ||
                row?.group_id === ""
              )
              &&
              <li
                onClick={
                  (
                    process.env.REACT_APP_AD_USER === 'TRUE' &&
                    (this.props.userRole !== 'Admin' &&
                      this.props.userRole !== 'Manager' &&
                      localStorage.getItem('ssoUser') !== 'true' &&
                      sessionStorage.getItem('ssoUser') !== 'true')
                  ) ||
                    (
                      this.props?.userRole === "Viewer_Group" ||
                      this.props?.userRole === "Operator_Group"
                    )
                    ? undefined
                    : () => this.onlineEdit(row)
                }
                className={
                  (
                    process.env.REACT_APP_AD_USER === 'TRUE' &&
                    (
                      this.props.userRole !== 'Admin' &&
                      this.props.userRole !== 'Manager' &&
                      localStorage.getItem('ssoUser') !== 'true' &&
                      sessionStorage.getItem('ssoUser') !== 'true'
                    )
                  ) ||
                    (
                      this.props?.userRole === "Viewer_Group" ||
                      this.props?.userRole === "Operator_Group"
                    )
                    ? 'disabled'
                    : ''
                }
              >
                <img
                  className="edit"
                  src="/images/svg-icons/edit.svg"
                  alt=""
                />{" "}
                Edit
              </li>
            }
            <li
              className={
                (process.env.REACT_APP_AD_USER === 'TRUE' &&
                  (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
                    localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true')) ||
                  (this.props?.userRole === "Viewer_Group" ||
                    this.props?.userRole === "Operator_Group")
                  ? "disabled"
                  : ""
              }
              onClick={() =>
                (process.env.REACT_APP_AD_USER === 'TRUE' &&
                  (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
                    localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true')) ||
                  (this.props?.userRole === "Viewer_Group" ||
                    this.props?.userRole === "Operator_Group")
                  ? undefined
                  : this.setState({ multipleSelect: [row] },
                    () => this.onlineDelete(row))
              }
            >
              <img
                className="delete"
                src="/images/svg-icons/delete.svg"
                alt=""
              />{" "}
              Delete
            </li>
            {
              (
                (row.tunnel_type !== "IPSec Tunnel") &&
                (
                  !row?.device_a_stale_info &&
                  !row?.device_b_stale_info
                )
              )
              &&
              <li
                className={
                  (process.env.REACT_APP_AD_USER === 'TRUE' &&
                    (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
                      localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true')) ||
                    (this.props?.userRole === "Viewer_Group" ||
                      this.props?.userRole === "Operator_Group")
                    ? "disabled"
                    : ""
                }
                onClick={() =>
                  (process.env.REACT_APP_AD_USER === 'TRUE' &&
                    (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
                      localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true')) ||
                    (this.props?.userRole === "Viewer_Group" ||
                      this.props?.userRole === "Operator_Group")
                    ? undefined
                    : this.setState({
                      multipleSelect: [row]
                    }, () =>
                      this.confirmRotateKey([row]))
                }
              >
                <img
                  src="/images/svg-icons/rotate_key.svg"
                  alt=""
                />{" "}
                Rotate Key
              </li>
            }
            {
              row.tunnel_type === "IPSec Tunnel" &&
              <li
                className={
                  (process.env.REACT_APP_AD_USER === 'TRUE' &&
                    (this.props.userRole !== 'Admin' &&
                      this.props.userRole !== 'Manager' &&
                      localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true')) ||
                    (this.props?.userRole === "Viewer_Group" ||
                      this.props?.userRole === "Operator_Group")
                    ? "disabled"
                    : ""
                }
                onClick={() =>
                  (process.env.REACT_APP_AD_USER === 'TRUE' &&
                    (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
                      localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true')) ||
                    (this.props?.userRole === "Viewer_Group" ||
                      this.props?.userRole === "Operator_Group")
                    ? undefined
                    : this.setState({
                      multipleSelect: [row]
                    }, () =>
                      this.changeStatus(row))
                }
              >
                <img
                  src={
                    (
                      (JSON.parse(row?.tunnel_status?.replace(/'/g, '"'))?.status === 'Online') ||
                      (JSON.parse(row?.tunnel_status?.replace(/'/g, '"'))?.status === 'Partial')
                    )
                      ? "/images/svg-icons/disconnect.svg"
                      : "/images/svg-icons/connect.svg"
                  }
                  alt=""
                />{" "}
                {
                  (
                    (JSON.parse(row?.tunnel_status?.replace(/'/g, '"'))?.status === 'Online') ||
                    (JSON.parse(row?.tunnel_status?.replace(/'/g, '"'))?.status === 'Partial')
                  )
                    ? "Disconnect"
                    : 'Connect'
                }
              </li>}
          </ul>
        </div>
      </div >
    )
  }

  setPerPage = (e) => {

    this.setState({
      pageNo: 1,
      pageSize: e.value
    }, () => {
      (this.state.searchValue !== "" && this.state.sortOn === true) ? this.handleSearch('sort') :
        (this.state.searchValue === "" && this.state.sortOn === true) ? this.getTunnelList('sort') :
          (this.state.searchValue !== "" && this.state.sortOn !== true) ? this.handleSearch() :
            this.getTunnelList()
    });
  };

  nextPageChangeHandle = () => {
    this.setState({
      pageNo: this.state.pageNo + 1
    }, () => {
      (this.state.searchValue !== "" && this.state.sortOn === true) ? this.handleSearch('sort') :
        (this.state.searchValue === "" && this.state.sortOn === true) ? this.getTunnelList('sort') :
          (this.state.searchValue !== "" && this.state.sortOn !== true) ? this.handleSearch() :
            this.getTunnelList()
    });
  };
  firstPageChangeHandle = () => {
    this.setState({
      pageNo: 1
    }, () => {
      (this.state.searchValue !== "" && this.state.sortOn === true) ? this.handleSearch('sort') :
        (this.state.searchValue === "" && this.state.sortOn === true) ? this.getTunnelList('sort') :
          (this.state.searchValue !== "" && this.state.sortOn !== true) ? this.handleSearch() :
            this.getTunnelList()
    });
  }
  lastPageChangeHandle = () => {
    this.setState({
      pageNo: Math.ceil(this.state.totalCount / this.state.pageSize)
    }, () => {
      (this.state.searchValue !== "" && this.state.sortOn === true) ? this.handleSearch('sort') :
        (this.state.searchValue === "" && this.state.sortOn === true) ? this.getTunnelList('sort') :
          (this.state.searchValue !== "" && this.state.sortOn !== true) ? this.handleSearch() :
            this.getTunnelList()
    });
  }

  prevPageChangeHandle = () => {
    this.setState({
      pageNo: this.state.pageNo - 1
    }, () => {
      (this.state.searchValue !== "" && this.state.sortOn === true) ? this.handleSearch('sort') :
        (this.state.searchValue === "" && this.state.sortOn === true) ? this.getTunnelList('sort') :
          (this.state.searchValue !== "" && this.state.sortOn !== true) ? this.handleSearch() :
            this.getTunnelList()
    });
  }
  getNetworksDeviceA = (id) => {
    let data = {
      device_id: id
    }
    if (String(data.device_id) !== "") {
      this.setState({ showLoader: true })
      ApiCall(urlMapping.getNetworkView(data), (response) => {
        if (response?.Success) {
          this.setState({
            showLoader: false,
            deviceA_networks: response?.data,
          });
        } else {
          this.setState({
            showLoader: false
          });
          if (parseInt(response?.response?.status) === 401) {
            this.setState({ showRefreshModal: true })
          }
          else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
            this.setState({
              title: 'Network Error',
              message: 'Something went wrong. Please try again later.',
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          }
          else {
            this.setState({
              title: 'Update Tunnel',
              message: 'No Networks found on device. Network template must be applied to update tunnel',
              show: true,
              error: true,
              showLoader: false,
              disableUpdate: true
            }, () => {
              $("#errorMessage").modal("show")
            });
          }
        }
      });
    }
  }
  getNetworksDeviceB = (id) => {
    let data = {
      device_id: id
    }
    if (String(data.device_id) !== "") {
      this.setState({ showLoader: true })
      ApiCall(urlMapping.getNetworkView(data), (response) => {
        if (response?.Success) {
          this.setState({
            showLoader: false,
            deviceB_networks: response?.data,
          });
        } else {
          if (parseInt(response?.response?.status) === 401) {
            this.setState({ showRefreshModal: true })
          }
          else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
            this.setState({
              title: 'Network Error',
              message: 'Something went wrong. Please try again later.',
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          }
          else {
            this.setState({
              title: 'Update Tunnel',
              message: 'No Networks found on device. Network template must be applied to update tunnel',
              show: true,
              error: true,
              showLoader: false,
              disableUpdate: true
            }, () => {
              $("#errorMessage").modal("show")
            });
          }
        }
      });
    }
  }
  handleOnChange = (e) => {
    this.setState({
      searchValue: e.target.value,
    });
    if (e.target.value === "") {
      this.getTunnelList();
    }
  };
  handleKeypress = (e) => {
    if (e.key === 'Enter') {
      this.setState(
        {
          pageNo: 1,
          pageSize: 10,
          searchClicked: true,
          selectedDevice: "",
          status: "",
          deviceList: [],
          showIcon: false,
          newDeviceList: [],
          selectedHub: "",
          showFilter: false,
          groupId: "",
          filterData: {}
        },
        () => { this.handleSearch() });
    }
  };
  clearSearch = () => {
    this.setState(
      {
        searchValue: "",
        pageNo: 1,
        searchClicked: false,
      },
      () => this.getTunnelList()
    );
  };
  handleSearch = (value) => {
    this.setState({ showLoader: true });
    let data = {
      "tunnel_data": this.state.searchValue, sorting_parameter: value === 'sort' && this.state.sortField !== null ? this.state.sortField : 'created_date',
      sorting_type: value === 'sort' ? this.state.order : 'descending'
    };
    ApiCall(
      urlMapping.searchTunnel(
        data,
        this.state.pageNo,
        this.state.pageSize
      ),
      (response) => {
        if (response) {
          if (response?.results) {
            const first =
              this.state.pageSize * (this.state.pageNo - 1) + 1;
            const last = response?.next
              ? this.state.pageSize * this.state.pageNo
              : response?.count;
            this.setState(
              {
                showLoader: false,
                next: response?.next ? false : true,
                prev: response?.previous ? false : true,
                first: first,
                last: last,
                TunnelsData: response?.results,
                totalCount: response?.count,
              }
            );
          } else {
            if (parseInt(response?.response?.status) === 401) {
              this.setState({ showRefreshModal: true });
            }
            else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
              this.setState({
                title: 'Network Error',
                message: 'Something went wrong. Please try again later.',
                showLoader: false,
                isAxiosError: true,
                delete: false,
                error: true
              }, () => $("#errorMessage").modal("show"))
            }
          }
        }
      }
    )
  };
  changeStatus = (row) => {
    this.setState({ showLoader: true });
    let data = {
      ipsec_tunnel_id: row.tunnel_id,
      ipsec_tag: JSON.parse(row?.tunnel_status?.replace(/'/g, '"'))?.status === 'Offline' ? "connect" : 'disconnect'
    };
    ApiCall(urlMapping.ipsecStatus(
      data
    ), (response) => {
      if (response?.success) {
        this.setState(
          {
            showLoader: false,
            title: "Tunnel Status",
            message: response?.message,
            error: false,
            show: true,
          },
          () => {
            $("#errorMessage").modal("show");
            this.getTunnelList();
          }
        );

      } else {
        if (parseInt(response?.response?.status) === 401) {
          this.setState({ showRefreshModal: true });
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        } else {
          this.setState({
            title: 'Tunnel Status',
            message: response?.message,
            show: true,
            error: true,
            showLoader: false,
          }, () => {
            $("#errorMessage").modal("show")
          });
        }
      }
    })
  }

  getModulesData = async () => {
    this.setState({
      showLoader: true,
    });
    await ApiCall(urlMapping.getModuleList(), (response) => {
      this.setState({
        showLoader: false,
      });
      if (response?.success) {
        Object.keys(response?.data?.models_interface)?.map(item => {
          if (item === this.state.deviceData.device_model) {
            this.setState({
              interfaces: response?.data?.models_interface?.[item],
            });
          }
        })
      } else {
        if (
          parseInt(response?.response?.status) === 401
        ) {
          this.setState({ showRefreshModal: true });
        }
        else if (
          response?.isAxiosError &&
          response?.success === undefined &&
          response?.response?.data?.success === undefined
        ) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
      }
    });
  };

  render() {
    let colArray = this.state.selectedOption.length === 0 ? this.state.tunnelsColumns : this.state.selectedOption;
    const dynamicColumns = colArray.map((col, i) => {
      return <Column key={col.field} field={col.field} resizable={col.resizable}
        sortable={col.sortable}
        header={col.header} body={col.body} style={col.style} alignFrozen={col.alignFrozen} frozen={col.frozen} />;

    });
    const template2 = {
      layout: 'RowsPerPageDropdown CurrentPageReport FirstPageLink PrevPageLink NextPageLink LastPageLink',
      'RowsPerPageDropdown': (options) => {
        const dropdownOptions = [
          { label: "10", value: 10 },
          { label: "20", value: 20 },
          { label: "30", value: 30 },
          { label: "40", value: 40 },
          { label: "50", value: 50 }
        ];

        return (
          <React.Fragment>
            <span className="p-mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>Rows per page: </span>
            <Dropdown value={this.state.pageSize} options={dropdownOptions} onChange={(e) => this.setPerPage(e)} />
          </React.Fragment>
        );
      },
      'CurrentPageReport': (options) => {
        return (
          <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
            {this.state.first} - {this.state.last} of {this.state.totalCount}
          </span>
        )
      },
      'FirstPageLink': () => {
        return (
          <button type="button"
            className=" p-link1"
            onClick={() => this.firstPageChangeHandle()}
            disabled={this.state.prev}
          >
            <img
              className={this.state.prev ? "p-link3" : "p-link2"}
              src={this.state.prev ? "/images/svg-icons/arrow-pre-disabled.svg" : "/images/svg-icons/arrow-pre.svg"}
              alt=""
            />
          </button>
        )
      },
      'PrevPageLink': () => {
        return (
          <button type="button"
            className=" p-link1"
            onClick={() => this.prevPageChangeHandle()}
            disabled={this.state.prev}
          >
            <i className="pi pi-chevron-left"></i>
          </button>
        )
      },
      'NextPageLink': () => {
        return (
          <button
            type="button"
            className="p-link1"
            onClick={() => this.nextPageChangeHandle()}
            disabled={this.state.next}
          >
            <i className="pi pi-chevron-right"></i>
          </button>
        )
      },
      'LastPageLink': () => {
        return (
          <button type="button"
            onClick={() => this.lastPageChangeHandle()}
            className=" p-link1"
            disabled={this.state.next}
          >
            <img
              className={this.state.next ? "p-link3" : "p-link2"}
              src={this.state.next ? "/images/svg-icons/arrow-next-disabled.svg" : "/images/svg-icons/arrow-next.svg"}
              alt=""
            />
          </button>
        )
      },
    };
    return (
      <div onMouseDown={(e) => this.hideDropdown(e)}>
        <SideMenu />
        {this.state.showLoader && <Loader />}
        <section
          id="rhsMain"
          className={this.props.open ? "rhs-main" : "rhs-main expand-w"}
        >
          <Header />
          <div className="white-bg  page-height">
            <div className="ta-box">
              <h1 className="page-title">
                Tunnels{" "}
              </h1>
              <div className="action-btn d-flex align-items-cenetr">
                <div className="search-box">
                  <input
                    type="text"
                    className="show"
                    placeholder="Enter tunnel name or MAC address"
                    onChange={(e) => this.handleOnChange(e)}
                    value={this.state.searchValue}
                    onKeyPress={(e) => this.handleKeypress(e)}
                  />
                  {
                    this.state.searchValue && (
                      <>
                        <img
                          className="clear-icon show"
                          src="/images/svg-icons/cancel.svg"
                          onClick={() => this.clearSearch()}
                          alt=""
                        />
                      </>
                    )
                  }
                  <button
                    className="search-btn"
                    disabled={this.state.searchValue === ""}
                    onClick={() => {
                      this.setState(
                        {
                          pageNo: 1,
                          pageSize: 10,
                          searchClicked: true,
                          selectedDevice: "",
                          status: "",
                          deviceList: [],
                          showIcon: false,
                          newDeviceList: [],
                          selectedHub: "",
                          showFilter: false,
                          groupId: "",
                          filterData: {}
                        },
                        () => { this.handleSearch() });
                    }
                    }
                  >
                    <img src="/images/svg-icons/search.svg" alt="" />
                  </button>
                </div>
                <div className="action-btn">
                  <button
                    onClick={() => this.getTunnelList("Refresh")}>
                    <img src="/images/svg-icons/refreshing.svg" alt="" /> Refresh
                  </button>
                  <button
                    className="button-lst"
                    disabled={
                      this.state.multipleSelect === "" ||
                      this.checkOnlineDevices() ||
                      (process.env.REACT_APP_AD_USER === 'TRUE' &&
                        (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
                          localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true')) ||
                      (this.props?.userRole === "Viewer_Group" ||
                        this.props?.userRole === "Operator_Group") || (this.state.multipleSelect !== "" &&
                          this.state.multipleSelect.filter(tunnel =>
                            tunnel.tunnel_type === "IPSec Tunnel").length > 0)
                    }
                    onClick={() => { this.confirmRotateKey() }}
                  >
                    <img src="/images/svg-icons/rotate_key.svg" alt="" /> Rotate Key
                  </button>
                  <button
                    className="dropdown-toggle filter-btn"
                    data-toggle="dropdown"
                  >
                    <img src="/images/svg-icons/columns.svg" alt="" /> Columns
                  </button>
                  <div
                    className="filter-section dropdown-menu dropdown-menu-right"
                    onChange={(e) => this.handleChange(e)}
                  >
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="selectAll"
                        checked={
                          this.state.selectedOption.length ===
                          this.state.tunnelsColumns.length
                        }
                        style={{ cursor: "pointer" }}
                      />
                      <label className="form-check-label">Select All</label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="Tunnel Name"
                        checked={true}
                        disabled
                        style={{ cursor: "not-allowed" }}
                      />
                      <label className="form-check-label">Tunnel Name</label>
                    </div>
                    {
                      this.state.tunnelsColumns.map((item, index) => {
                        if (item.value !== "Tunnel Name" && item.value !== "Action")
                          return (
                            <div className="form-check" key={index}>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={item.value}
                                checked={JSON.stringify(
                                  this.state.selectedOption
                                ).includes(item.value)}
                                style={{ cursor: "pointer" }}
                              />
                              <label className="form-check-label">
                                {item.value}
                              </label>
                            </div>
                          );
                      })
                    }
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="Action"
                        checked={true}
                        disabled
                        style={{ cursor: "not-allowed" }}
                      />
                      <label className="form-check-label">Action</label>
                    </div>
                  </div>
                  <div className="btn-group">
                    <button
                      type="button"
                      className="dropdown-toggle  filter-btn"
                      disabled={this.state.TunnelsData.length === 0 && !this.state.showIcon}
                      onClick={
                        (e) => this.getModelList()
                      }
                    >
                      {
                        (this.state.showIcon) ?
                          <img src="/images/svg-icons/info-icon.svg" alt="" />
                          :
                          <img src="/images/svg-icons/filter.svg" alt="" />
                      }
                      Filter
                    </button>
                    {
                      this.state.showFilter && <div
                        className={
                          this.state.showFilter
                            ? "dropdown-menu show  dropdown-menu-right filter-section width360 overflow-visible"
                            : "dropdown-menu dropdown-menu-right filter-section width360 overflow-visible"
                        }
                        id="parentDiv"
                      >
                        <div className="form-group">
                          <div className="d-flex align-items-center justify-content-between">
                            <label>Device</label>
                            <span onClick={() => this.clearDropdown("device")}>
                              Clear
                            </span>
                          </div>
                          <Select
                            options={this.getOptions()}
                            placeholder={"Select Device"}
                            filterOption={(o, i) => this.filterOptions(o, i)}
                            isClearable
                            isSearchable
                            className="filter-dropdown"
                            classNamePrefix="react-select-container"
                            value={
                              this.getOptions().filter(option =>
                                option.value === this.state.selectedDevice)
                            }
                            onChange={(e) => this.getselectedDevice(e)}
                          />
                        </div>
                        <div className="form-group">
                          <div className="d-flex align-items-center justify-content-between">
                            <label>Status</label>
                            <span onClick={() => this.clearDropdown()}>Clear</span>
                          </div>
                          <select
                            className="form-control"
                            value={this.state.status ? this.state.status : "select"}
                            onChange={(e) => this.setStatus(e)}
                          >
                            <option value="select">Select Status</option>
                            <option value="Online">Online</option>
                            <option value="Offline">Offline</option>
                          </select>
                        </div>
                        <div className="action-box">
                          <button
                            className="btn btn-light"
                            onClick={() => this.resetValue()}
                          >
                            Reset
                          </button>
                          <button
                            className="btn btn-primary"
                            onClick={() => this.setState({
                              searchValue: "",
                              searchClicked: false,
                            }, () => this.getTunnelFilterList())}
                            disabled={this.state.selectedDevice === '' && this.state.status === ''}
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <DataTable
                value={this.state.TunnelsData}
                onSort={(columnSort) => {
                  this.tunnelSort(columnSort);
                }}
                sortField={this.state.sortField}
                sortOrder={this.state.sortOrder}
                scrollable scrollHeight="400px"
                loading={this.state.loading}
                removableSort
                resizableColumns
                columnResizeMode="fit"
                showGridlines
                paginator
                paginatorTemplate={template2}
                first={this.state.firstDefault}
                rows={this.state.pageSize}
                paginatorClassName="p-jc-end"
                selectionMode="checkbox"
                selection={this.state.multipleSelect}
                onSelectionChange={e => this.onSelectRow(e)}
                dataKey="tunnel_id" scrollDirection="both" className="p-mt-3">
                <Column selectionMode="multiple" style={{ flexGrow: 1, flexBasis: '80px' }} ></Column>
                {dynamicColumns}
              </DataTable>
            </div>
          </div>
          {
            this.props.openEditTunnel && (
              <EditTunnel
                data={this.state.tunnelDevicesData}
                deviceA_networks={this.state.deviceA_networks}
                deviceB_networks={this.state.deviceB_networks}
                getTunnelList={() => this.getTunnelList()}
                disableUpdate={this.state.disableUpdate}
              />
            )
          }
          {
            this.state.ipsecEdit &&
            <ApplyTemplate
              data={this.state.ipsecData}
              tunnelEdit={true}
              reapply={false}
              view={false}
              edit={true}
              info={false}
              nonSync={false}
              props={this.props}
              refreshList={() => this.getTunnelList("Refresh")}
            />
          }
        </section>
        {
          this.state.show && (
            <ErrorPopup
              title={this.state.title}
              message={this.state.message}
              error={this.state.error}
              delete={this.state.delete}
              approve={this.state.approve}
              reset={() => this.resetPopup()}
              onSubmit={
                this.state.delete
                  ? (
                    (this.state.tunnelType === "IPSec Tunnel")
                      ? () => this.ipsecDeleteTunnel()
                      : () => this.deleteTunnel()
                  )
                  : undefined
              }
            />
          )
        }
        {
          this.state.showPopup &&
          (
            <div
              className="modal fade errorMessage"
              id="keyRotationErrorMessage"
              tabIndex="-1"
              data-backdrop="static"
              role="dialog"
              aria-labelledby="errorMessageTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-sm modal-dialog-centered"
                role="document">
                <div className="modal-content">
                  <div className="modal-header bg-white border-0">
                    {
                      this.state.keyRotation &&
                      <div className="pt-3">
                        <div className="col-md-8 col-sm-12">
                          <label >
                            <input
                              type="checkbox"
                              checked={this.state.scheduleLater}
                              onChange={this.scheduleLater}
                            />
                            <span className="ml-2">Schedule Later</span>
                          </label>
                          <label>
                            <input type="checkbox"
                              checked={this.state.scheduleLater ? this.state.allTunnels : false}
                              disabled={this.state.scheduleLater === false || this.state.startDate === null}
                              onChange={() => this.setOnAllTunnels()}
                            />
                            <span className="ml-2">On All Tunnels</span>
                          </label>
                        </div>
                      </div>
                    }
                    {
                      this.state.scheduleLater &&
                      <div className="pt-2">
                        <Calendar
                          id="navigatorstemplate"
                          value={this.state.startDate}
                          onChange={(e) => this.setStartDate(e)}
                          showTime
                          hourFormat="12"
                          monthNavigator={true}
                          yearNavigator={true}
                          hideOnDateTimeSelect={true}
                          yearRange={`${new Date().getFullYear() - 10}:${new Date().getFullYear() + 10}`}
                          minDate={new Date()}
                        // monthNavigatorTemplate={this.monthNavigatorTemplate}
                        // yearNavigatorTemplate={this.yearNavigatorTemplate}
                        />
                      </div>
                    }
                  </div>
                  <div className="modal-body pt-5">
                    <div className="error-msg">
                      <span className={this.state.error ? "error-icon" : "success-icon"}>{this.state.error ? (this.state.info ? 'i' : '!') : ''}</span>
                      <div className="txt1">{this.state.title}</div>
                      <div className="txt2">{this.state.message}
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer border-top-0 justify-content-center pb-4">
                    {
                      (this.state.delete || this.state.approve) &&
                      <button
                        type="button"
                        className="btn btn-light"
                        data-dismiss="modal"
                        onClick={() => this.resetPopup()}>
                        {'Cancel'}</button>
                    }
                    {
                      (this.state.delete || this.state.error) &&
                      <button
                        type="button"
                        className={this.state.error ? "btn btn-danger" : "btn btn-primary"}
                        data-dismiss="modal"
                        disabled={this.state.scheduleLater === true && this.state.startDate === null}
                        onClick={this.state.keyRotation ? () => this.rotateKeyOperation() : undefined}>
                        {'OK'}</button>
                    }
                  </div>
                </div>
              </div>
            </div>
          )
        }
        {
          (this.state.showLink) &&
          <LinkPopup
            title={this.state.title}
            message={this.state.message}
            open={() => this.openOperationList(this.state.id)}
            close={() => this.closeLinkpopup()}
          />
        }
        {this.state.showRefreshModal && <ErrorHandlePopup />}
        {
          (this.state.isAxiosError) &&
          <ErrorPopup
            title={this.state.title}
            message={this.state.message}
            error={this.state.error}
            delete={this.state.delete}
            reset={() => this.setState({ isAxiosError: false })}
          />
        }
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    authenticated: state.auth.authenticated,
    open: state.displaySidebarMenu.openSide,
    openEditTunnel: state.displayAddTunnel.openEditTunnel,
    SsoUser: state.auth.SsoUser,
    userRole: JSON.parse(localStorage.getItem('user'))?.userRole
  };
};
export default withRouter(connect(mapStateToProps)(TunnelsList));