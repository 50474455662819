/* eslint-disable array-callback-return */

import React, { Component } from "react";
import urlMapping from "../../redux/API/api";
import apiCall from "../../redux/API/apiCall";
import { Loader } from "../../layOuts/Loader";
import ErrorHandlePopup from "../ErrorHandlePopup";
import { ErrorPopup } from "../../layOuts/errorPopup";
import $ from 'jquery';
export default class ModemInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRefreshModal: false,
      id: this.props?.data?.device_id || "",
      Status5g: [],
      Service_info: [],
      Signal_status: [],
      Slot_info_1: [],
      Slot_info_2: [],
      Gen_Details: [],
      IP_Details: [],
      modemData: {},
      modemDataMessage: "",
      responseData: 0,
      showLoader: false,
      keyService_info: 0,
      keyStatus5g: 0,
      keySlot_info: 0,
      keySignal_status: 0,
      keyModem_info: 0,
      keyIp_info: 0,
      isAxiosError: false,
      deviceData: this.props?.data
    };
  }
  componentDidMount() {
    this.updatePolling();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps?.data?.device_id !== "" && nextProps?.data?.device_id !== this.props?.data?.device_id) {
      this.setState(
        {
          id: nextProps?.data?.device_id || "",
          Status5g: [],
          Service_info: [],
          Signal_status: [],
          Slot_info_1: [],
          Slot_info_2: [],
          Gen_Details: [],
          IP_Details: [],
          modemData: {},
          modemDataMessage: "",
          responseData: 0,
          keyService_info: 0,
          keyStatus5g: 0,
          keySlot_info: 0,
          keySignal_status: 0,
          keyModem_info: 0,
          keyIp_info: 0,
        },
        () => this.updatePolling()
      );
    }
  }
  updatePolling() {
    this.setState({
      showLoader: true
    });
    let data = {
      device_id: this.state.id,
    }
    apiCall(urlMapping.getPollingData(data), async (response) => {
      this.setState({
        showLoader: false,
      });
      if (response?.success) {
        let data = response?.data?.modem_info?.keys
        if (data?.["5g_status"]?.status === 0 && data?.["service_info"]?.status === 0 &&
          data?.["signal_stats"]?.status === 0 && data?.["slots_info"]?.status === 0 &&
          data?.["gen_details"]?.status === 0 && data?.["ip_details"]?.status === 0) {
          this.setState({
            responseData: 1
          }, () => this.getModemInfo()
          );
        }
        else {
          if (data?.["5g_status"]?.status === 1)
            this.setState({ keyStatus5g: 1 });
          if (data?.["service_info"]?.status === 1)
            this.setState({ keyService_info: 1 });
          if (data?.["signal_stats"]?.status === 1)
            this.setState({ keySignal_status: 1 });
          if (data?.["slots_info"]?.status === 1)
            this.setState({ keySlot_info: 1 });
          if (data?.["gen_details"]?.status === 1)
            this.setState({ keyModem_info: 1 });
          if (data?.["ip_details"]?.status === 1)
            this.setState({ keyIp_info: 1 });
        }
        await this.getModemInfo();
      } else {
        if (parseInt(response?.response?.status) === 401) {
          this.setState({
            showRefreshModal: true,
          });
        } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        } else {
          this.setState({
            showLoader: false
          });
        }
      }
    });
  }
  getModemInfo = () => {
    let data = {
      device_id: this.state.id,
    };
    this.setState({ showLoader: true });
    apiCall(urlMapping.modemInfo(data), (response) => {
      if (response?.Success) {
        this.setState({
          modemData: response?.data,
          showLoader: false
        }, async () => {
          await this.getDataFromModem();
          await this.savedData();
        }
        );
        if (response?.message) {
          this.setState({
            modemDataMessage: response?.message
          })
        }
      }
      else {
        if (parseInt(response?.response?.status) === 401) {
          this.setState({ showRefreshModal: true });
        } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        } else {
          this.setState({
            showLoader: false,
            title: "Modem Info",
            message: "Unable to get data!",
            show: true,
            error: true,
          }, () => $("#errorMessage").modal("show"));
        }
      }
    });
  };
  savedData = () => {
    let data = {
      device_id: this.state.id,
      data_key: "modem"
    };
    this.setState({ showLoader: true });
    apiCall(urlMapping.savedData(data), (response) => {
      if (response?.success) {
        this.setState({
          modemData: response?.data,
          showLoader: false
        }, () => {
          this.getDataFromModem();
        }
        );
      }
      else {
        if (parseInt(response?.response?.status) === 401) {
          this.setState({
            showRefreshModal: true
          });
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else {
          this.setState({
            showLoader: false
          });
        }
      }
    })
  }
  getDataFromModem = () => {
    Object.keys(this.state.modemData).map((item) => {
      if (item === "Status_5G")
        this.setState({ Status5g: this.state?.modemData?.[item]?.data?.[0] });
      else if (item === "Signal_Stats")
        this.setState({ Signal_status: this.state?.modemData?.[item]?.data?.[0] });
      else if (item === "Service_Info")
        this.setState({ Service_info: this.state?.modemData?.[item]?.data?.[0] });
      else if (item === "Slots_Info") {
        this.setState({ Slot_info_1: this.state?.modemData?.[item]?.data?.[0] });
        this.setState({ Slot_info_2: this.state?.modemData?.[item]?.data?.[1] });
      }
      else if (item === "Gen_Details")
        this.setState({ Gen_Details: this.state?.modemData?.[item]?.data?.[0] });
      else if (item === "IP_Details")
        this.setState({ IP_Details: this.state?.modemData?.[item]?.data?.[0] });
    });
  };
  refreshIntervals = (value) => {
    this.setState({ showLoader: true });
    let data = {
      device_ip: this.props.data.device_control_tunnel_ip,
      key: value,
      device_id: this.state.id,
      device_model: ""
    }
    apiCall(urlMapping.getTelemetry(data), (response) => {
      this.setState({ showLoader: false });
      if (response?.success) {
        switch (value) {
          case "global_modem":
            return this.setState({ modemData: response?.data, showLoader: false }, () => this.getDataFromModem());
          case "5g_status":
            return this.setState({ Status5g: response?.data?.data?.[0] })
          case "signal_status":
            return this.setState({ Signal_status: response?.data?.data?.[0] })
          case "slots_info":
            return this.setState({ Slot_info_1: response?.data?.data?.[0], Slot_info_2: response?.data?.data?.[1] });
          case "service_info":
            return this.setState({ Service_info: response?.data?.data?.[0] })
          case "modem_info":
            return this.setState({ Gen_Details: response?.data?.data?.[0] })
          case "ip_details":
            return this.setState({ IP_Details: response?.data?.data?.[0] })
          default:
            return "";
        }
      }
      else {
        if (parseInt(response?.response?.status) === 401) {
          this.setState({
            showRefreshModal: true
          });
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else {
          this.setState({
            showLoader: false
          });
        }
      }
    })
  }
  resetPopup = () => {
    this.setState({
      show: false,
      error: false,
    }, () => {
      $("#errorMessage").modal("hide");
    });
  }
  render() {
    return (
      <>
        {this.state.showLoader && <Loader />}
        <div className="modem-info">
          <div className="ta-box set-pos mb-0">
            <div className="action-btn">
              <img
               className={this.state.deviceData?.device_status === 1 ? "mr-2" : "mr-2 disabled"}
                style={{ width: "25px", cursor: "pointer" }}
                src="/images/refreshT.png"
                title="Refresh Telemetry data"
                onClick={this.state.deviceData?.device_status === 1 ? 
                  () => this.refreshIntervals("global_modem") : undefined}
              />
            </div>
          </div>
          <div className="bg-white rounded">
            {this.state?.responseData === 0 ? (
              <div>
                <div className="row">
                  <div className="col-6">
                    <div className="d-flex align-items-center justify-content-between lightBg">
                      <h1 class="page-title text-black">5G Status</h1>
                      <img 
                       className={this.state.deviceData?.device_status === 1 ? "" : "disabled"}
                        style={{ width: "25px", cursor: "pointer" }}
                        src="/images/refreshT.png"
                        title="Refresh Telemetry data"
                        onClick={this.state.deviceData?.device_status === 1 ? 
                          () => this.refreshIntervals("5g_status") : undefined}
                      />
                    </div>
                    <div className="row no-gutters">
                      {this.state.keyStatus5g === 1 ?
                        <>
                          <div className="col-6">
                            <div className="columnDataWrapper">
                              <div className="columnData">
                                <label>Service Available</label>
                                <span>:</span>
                                <span>{this.state?.Status5g?.["Service Available"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>Data Connected</label>
                                <span>:</span>
                                <span>{this.state?.Status5g?.["Data Connected"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>Bars</label>
                                <span>:</span>
                                <span>{this.state?.Status5g?.["Bars"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>SNR</label>
                                <span>:</span>
                                <span>{this.state?.Status5g?.["SNR"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>RSRP</label>
                                <span>:</span>
                                <span>{this.state?.Status5g?.["RSRP"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>RSRQ</label>
                                <span>:</span>
                                <span>{this.state?.Status5g?.["RSRQ"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>ENDC</label>
                                <span>:</span>
                                <span>{this.state?.Status5g?.["ENDC"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>DCNR</label>
                                <span>:</span>
                                <span>{this.state?.Status5g?.["DCNR"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>PCI</label>
                                <span>:</span>
                                <span>{this.state?.Status5g?.["PCI"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>Band</label>
                                <span>:</span>
                                <span>{this.state?.Status5g?.["Band"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>Bandwidth</label>
                                <span>:</span>
                                <span>{this.state?.Status5g?.["Bandwidth"] || "-"}</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="columnDataWrapper">
                              <div className="columnData">
                                <label>Channel</label>
                                <span>:</span>
                                <span>{this.state?.Status5g?.["Channel"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>Bearer</label>
                                <span>:</span>
                                <span>{this.state?.Status5g?.["Bearer"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>Mode</label>
                                <span>:</span>
                                <span>{this.state?.Status5g?.["Mode"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>cdrx_cfg_present</label>
                                <span>:</span>
                                <span>{this.state?.Status5g?.["cdrx_cfg_present"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>ssb_index</label>
                                <span>:</span>
                                <span>{this.state?.Status5g?.["ssb_index"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>scs</label>
                                <span>:</span>
                                <span>{this.state?.Status5g?.["scs"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>rlf_count</label>
                                <span>:</span>
                                <span>{this.state?.Status5g?.["rlf_count"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>num_cc</label>
                                <span>:</span>
                                <span>{this.state?.Status5g?.["num_cc"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>channel_ul</label>
                                <span>:</span>
                                <span>{this.state?.Status5g?.["channel_ul"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>sb_status</label>
                                <span>:</span>
                                <span>{this.state?.Status5g?.["sb_status"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>icon</label>
                                <span>:</span>
                                <span>{this.state?.Status5g?.["icon"] || "-"}</span>
                              </div>
                            </div>
                          </div>
                        </>
                        :
                        <div className="col-12">
                          <div className="no-data-key">Configurations are disabled</div>
                        </div>
                      }
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-flex align-items-center justify-content-between lightBg">
                      <h1 class="page-title text-black">Signal Status</h1>
                      <img 
                       className={this.state.deviceData?.device_status === 1 ? "" : "disabled"}
                        style={{ width: "25px", cursor: "pointer" }}
                        src="/images/refreshT.png"
                        title="Refresh Telemetry data"
                        onClick={this.state.deviceData?.device_status === 1 ?
                          () => this.refreshIntervals("signal_status") : undefined}
                      />
                    </div>
                    <div className="row no-gutters">
                      {this.state.keySignal_status === 1 ?
                        <>
                          <div className="col-6">
                            <div className="columnDataWrapper">
                              <div className="columnData">
                                <label>Tech</label>
                                <span>:</span>
                                <span>{this.state?.Signal_status?.["Tech"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>Bars</label>
                                <span>:</span>
                                <span>{this.state?.Signal_status?.["Bars"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>SNR</label>
                                <span>:</span>
                                <span>{this.state?.Signal_status?.["SNR"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>Oper Name</label>
                                <span>:</span>
                                <span>{this.state?.Signal_status?.["Oper Name"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>Femto</label>
                                <span>:</span>
                                <span>{this.state?.Signal_status?.["Femto"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>PCI</label>
                                <span>:</span>
                                <span>{this.state?.Signal_status?.["PCI"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>RSRP</label>
                                <span>:</span>
                                <span>{this.state?.Signal_status?.["RSRP"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>Oper ID</label>
                                <span>:</span>
                                <span>{this.state?.Signal_status?.["Oper Id"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>Band</label>
                                <span>:</span>
                                <span>{this.state?.Signal_status?.["Band"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>RSSI</label>
                                <span>:</span>
                                <span>{this.state?.Signal_status?.["RSSI"] || "-"}</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="columnDataWrapper">
                              <div className="columnData">
                                <label>Cell ID</label>
                                <span>:</span>
                                <span>{this.state?.Signal_status?.["Cell Id"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>Bandwidth</label>
                                <span>:</span>
                                <span>{this.state?.Signal_status?.["Bandwidth"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>RSRQ</label>
                                <span>:</span>
                                <span>{this.state?.Signal_status?.["RSRQ"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>Lac</label>
                                <span>:</span>
                                <span>{this.state?.Signal_status?.["Lac"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>Channel</label>
                                <span>:</span>
                                <span>{this.state?.Signal_status?.["Channel"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>Aggregated bandwidth</label>
                                <span>:</span>
                                <span>{this.state?.Signal_status?.["Aggregated Bandwidth"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>CA Band List - PCI</label>
                                <span>:</span>
                                <span>{this.state?.Signal_status?.["CA Band list - PCI"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>CA Band List - Bands</label>
                                <span>:</span>
                                <span>{this.state?.Signal_status?.["CA Band list - bands"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>CA Bandwidth</label>
                                <span>:</span>
                                <span>{this.state?.Signal_status?.["CA Bandwidth"] || "-"}</span>
                              </div>
                            </div>
                          </div>
                        </>
                        :
                        <div className="col-12">
                          <div className="no-data-key">Configurations are disabled</div>
                        </div>
                      }
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="d-flex align-items-center justify-content-between lightBg">
                          <h1 class="page-title text-black">Service Info</h1>
                          <img 
                            className={this.state.deviceData?.device_status === 1 ? "" : "disabled"}
                            style={{ width: "25px", cursor: "pointer" }}
                            src="/images/refreshT.png"
                            title="Refresh Telemetry data"
                            onClick={this.state.deviceData?.device_status === 1 ? 
                              () => this.refreshIntervals("service_info") : undefined}
                          />
                        </div>
                        <div className="row no-gutters">
                          {this.state.keyService_info === 1 ?
                            <>
                              <div className="col-6">
                                <div className="columnDataWrapper">
                                  <div className="columnData">
                                    <label>Tech</label>
                                    <span>:</span>
                                    <span>{this.state?.Service_info?.["Service"] || "-"}</span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="columnDataWrapper">
                                  <div className="columnData">
                                    <label>Roam</label>
                                    <span>:</span>
                                    <span>{this.state?.Service_info?.["Roam"] || "-"}</span>
                                  </div>
                                </div>
                              </div>
                            </>
                            :
                            <div className="col-12">
                              <div className="no-data-key">Configurations are disabled</div>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-6">
                    <div className="d-flex align-items-center justify-content-between lightBg">
                      <h1 class="page-title text-black">SIM Info</h1>
                      <img 
                      className={this.state.deviceData?.device_status === 1 ? "" : "disabled"}
                        style={{ width: "25px", cursor: "pointer" }}
                        src="/images/refreshT.png"
                        title="Refresh Telemetry data"
                        onClick={this.state.deviceData?.device_status === 1 ? 
                          () => this.refreshIntervals("slots_info") : undefined}
                      />
                    </div>
                    <div className="row no-gutters">
                      {this.state.keySlot_info === 1 ?
                        <>
                          <div className="col-6">
                            <div className="columnDataWrapper">
                              <div className="columnData">
                                <label>Slot</label>
                                <span>:</span>
                                <span className="text-left">{this.state?.Slot_info_1?.["slot"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>SIM Status</label>
                                <span>:</span>
                                <span className={this.state?.Slot_info_1?.["sim_status"] === "INACTIVE" ? "text-red text-left" : "text-green text-left"}>{this.state?.Slot_info_1?.["sim_status"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>SIM Carrier</label>
                                <span>:</span>
                                <span className="text-left">{this.state?.Slot_info_1?.["sim_carrier"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>IMSI</label>
                                <span>:</span>
                                <span className="text-left">{this.state?.Slot_info_1?.["imsi"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>Operator Name</label>
                                <span>:</span>
                                <span className="text-left">{this.state?.Slot_info_1?.["oper_name"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>Card State</label>
                                <span>:</span>
                                <span className="text-left">{this.state?.Slot_info_1?.["card_state"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>SIM State</label>
                                <span>:</span>
                                <span className="text-left">{this.state?.Slot_info_1?.["sim_state"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>ICCID</label>
                                <span>:</span>
                                <span className="text-left">{this.state?.Slot_info_1?.["iccid"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>MDN</label>
                                <span>:</span>
                                <span className="text-left">{this.state?.Slot_info_1?.["mdn"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>WWAN State</label>
                                <span>:</span>
                                <span className="text-left">{this.state?.Slot_info_1?.["wwan_state"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>PDP Type</label>
                                <span>:</span>
                                <span className="text-left">{this.state?.Slot_info_1?.["PDP Type"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>APN</label>
                                <span>:</span>
                                <span className="text-left">{this.state?.Slot_info_1?.["APN"] || "-"}</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="columnDataWrapper">
                              <div className="columnData">
                                <label>Slot</label>
                                <span>:</span>
                                <span className="text-left">{this.state?.Slot_info_2?.["slot"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>SIM Status</label>
                                <span>:</span>
                                <span className={this.state?.Slot_info_2?.["sim_status"] === "INACTIVE" ? "text-red text-left" : "text-green text-left"}>
                                  {this.state?.Slot_info_2?.["sim_status"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>SIM Carrier</label>
                                <span>:</span>
                                <span className="text-left">{this.state?.Slot_info_2?.["sim_carrier"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>IMSI</label>
                                <span>:</span>
                                <span className="text-left">{this.state?.Slot_info_2?.["imsi"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>Operator Name</label>
                                <span>:</span>
                                <span className="text-left">{this.state?.Slot_info_2?.["oper_name"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>Card State</label>
                                <span>:</span>
                                <span className="text-left">{this.state?.Slot_info_2?.["card_state"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>SIM State</label>
                                <span>:</span>
                                <span className="text-left">{this.state?.Slot_info_2?.["sim_state"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>ICCID</label>
                                <span>:</span>
                                <span className="text-left">{this.state?.Slot_info_2?.["iccid"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>MDN</label>
                                <span>:</span>
                                <span className="text-left">{this.state?.Slot_info_2?.["mdn"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>WWAN State</label>
                                <span>:</span>
                                <span className="text-left">{this.state?.Slot_info_2?.["wwan_state"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>PDP Type</label>
                                <span>:</span>
                                <span className="text-left">{this.state?.Slot_info_2?.["PDP Type"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>APN</label>
                                <span>:</span>
                                <span className="text-left">{this.state?.Slot_info_2?.["APN"] || "-"}</span>
                              </div>
                            </div>
                          </div>
                        </>
                        :
                        <div className="col-12">
                          <div className="no-data-key">Configurations are disabled</div>
                        </div>
                      }
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="row">
                      <div className="col-6">
                        <div className="d-flex align-items-center justify-content-between lightBg">
                          <h1 class="page-title text-black">Modem Info</h1>
                          <img 
                          className={this.state.deviceData?.device_status === 1 ? "" : "disabled"}
                            style={{ width: "25px", cursor: "pointer" }}
                            src="/images/refreshT.png"
                            title="Refresh Telemetry data"
                            onClick={this.state.deviceData?.device_status === 1 ? 
                              () => this.refreshIntervals("modem_info") : undefined}
                          />
                        </div>
                        {this.state.keyModem_info === 1 ?
                          <>
                            <div className="columnDataWrapper mh-305">
                              <div className="columnData">
                                <label>LTE Bands</label>
                                <span>:</span>
                                <span className="text-left">{this.state?.Gen_Details?.["LTE bands"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>5G NR NSA</label>
                                <span>:</span>
                                <span className="text-left">{this.state?.Gen_Details?.["5G NR NSA"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>5G NR SA</label>
                                <span>:</span>
                                <span className="text-left">{this.state?.Gen_Details?.["5G NR SA"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>PRI Version</label>
                                <span>:</span>
                                <span className="text-left">{this.state?.Gen_Details?.["PRI Version"] || "-"}</span>
                              </div>
                            </div>
                          </>
                          :
                          <div className="no-data-key">Configurations are disabled</div>
                        }
                      </div>
                      <div className="col-6">
                        <div className="d-flex align-items-center justify-content-between lightBg">
                          <h1 class="page-title text-black">IP Details</h1>
                          <img 
                          className={this.state.deviceData?.device_status === 1 ? "" : "disabled"}
                            style={{ width: "25px", cursor: "pointer" }}
                            src="/images/refreshT.png"
                            title="Refresh Telemetry data"
                            onClick={this.state.deviceData?.device_status === 1 ? 
                              () => this.refreshIntervals("ip_details") : undefined}
                          />
                        </div>
                        {this.state.keyIp_info === 1 ?
                          <>
                            <div className="columnDataWrapper mh-305">
                              <div className="columnData">
                                <label>IPv4 Address</label>
                                <span>:</span>
                                <span className="text-left">{this.state?.IP_Details?.["IPv4 Address"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>Gateway</label>
                                <span>:</span>
                                <span className="text-left">{this.state?.IP_Details?.["Gateway"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>DNS Server</label>
                                <span>:</span>
                                <span className="text-left">{this.state?.IP_Details?.["DNS Servers"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>IPv6 Address</label>
                                <span>:</span>
                                <span className="text-left">{this.state?.IP_Details?.["IPv6 Address"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>Prefix6</label>
                                <span>:</span>
                                <span className="text-left">{this.state?.IP_Details?.["Prefix6"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>Gateway6</label>
                                <span>:</span>
                                <span className="text-left">{this.state?.IP_Details?.["Gateway6"] || "-"}</span>
                              </div>
                              <div className="columnData">
                                <label>DNS Server6</label>
                                <span>:</span>
                                <span className="text-left">{this.state?.IP_Details?.["DNS Servers6"] || "-"}</span>
                              </div>
                            </div>
                          </>
                          :
                          <div className="no-data-key">Configurations are disabled</div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
              :
              (<div className="no-data">{this.state.modemDataMessage || "Configurations are disabled"}</div>)}
          </div>
        </div>
        {this.state.show && (
          <ErrorPopup
            title={this.state.title}
            message={this.state.message}
            error={this.state.error}
            reset={() => this.resetPopup()}
          />
        )}
        {(this.state.isAxiosError) &&
          <ErrorPopup
            title={this.state.title}
            message={this.state.message}
            error={this.state.error}
            delete={this.state.delete}
            reset={() => this.setState({ isAxiosError: false })}
          />
        }
        {this.state.showRefreshModal && <ErrorHandlePopup />}
      </>
    );
  }
}