/* eslint-disable no-eval */
/* eslint-disable array-callback-return */
import React, { Component } from 'react'
import { withRouter } from "react-router";
import { connect } from "react-redux";
import SideMenu from '../../layOuts/SideMenu';
import Header from '../../layOuts/Header';
import ApiCall from "../../redux/API/apiCall";
import urlMapping from "../../redux/API/api";
import { Loader } from '../../layOuts/Loader';
import DataTable from "react-data-table-component";
import * as Action from '../../redux/actionTypes';
import * as Common from "../../utils/common";
import $ from 'jquery';
import moment from 'moment';
import { Link } from "react-router-dom";
import ErrorHandlePopup from '../ErrorHandlePopup';
import { ErrorPopup } from '../../layOuts/errorPopup'
import AddEditTemplates from '../templates/AddTemplates/AddEditTemplates';
import ApplyTemplate from '../templates/AddTemplates/ApplyTemplate';

class OnboardingConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRefreshModal: false,
            columnsData: [],
            isAxiosError: false,
            show: false,
            templateDetails: "",
            configDeviceId: "",
            configModels: "",
            configApply: false,
            uploadTemp: false,
            jsonFile: undefined,
            configName: "",
            openApplyTemplate: false,
            interfaces: "",
            disableCancel: false,
            reorderEnable: false,
            isReorder: false,
            nonSyncTemplates: [],
            nonSyncCount: 0,
            nonSync: false,
            isLastReorder: false,
            wireguardSupportedModules: "",
            configTunnel: false,
            columns: [
                {
                    name: "Template Name",
                    selector: "template_name",
                    value: "Template Name",
                    cell: (row) => (<span title={row?.template_name} style={{ width: 'auto' }} className="fav-icon">
                        {
                            row.template_type === "DeviceProvision"
                                ? row.template_name
                                : <Link to={{
                                    pathname: '/templateInfo',
                                    state: {
                                        data: row,
                                        default: false,
                                        searchValue: undefined,
                                        filterValue: undefined,
                                        configDeviceId: this.state.configDeviceId
                                    }
                                }}
                                    className="txt-blue">
                                    {row.locked === true && <img
                                        src="/images/svg-icons/lock.svg"
                                        alt="" />} {row?.template_name}</Link>
                        }</span>
                    ),
                },
                {
                    name: "Template Type",
                    selector: "template_type",
                    value: "Template Type",
                    center: true,
                    cell: (row) => (
                        <span
                            title={Common.getTemplateType(row?.template_type)}
                            style={{ width: "auto" }}
                        >
                            {Common.getTemplateType(row?.template_type)}
                        </span>
                    ),
                },
                {
                    name: "Apply State",
                    selector: "applied",
                    value: "Apply State",
                    cell: (row) => (<span title={row?.applied ? "Applied" : "Not Applied"} style={{ width: 'auto' }}>
                        {row.applied ? "Applied" : "Not Applied"}</span>
                    ),
                }, {
                    name: "Device Models",
                    selector: "device_model",
                    value: "Device Model",
                    cell: (row) => (<span title={row?.device_model} style={{ width: 'auto' }}>
                        {row?.device_model}</span>
                    ),
                },
                {
                    name: "Last Updated On",
                    selector: "updated_date",
                    value: "Last Updated On",
                    center: true,
                    cell: (row) => (
                        <span title={this.getTimeExact(row?.updated_date)} style={{ width: "auto" }}>
                            {this.getTimeExact(row?.updated_date)}
                        </span>
                    ),
                },
                {
                    value: "Action",
                    center: true,
                    cell: (row) => (
                        <div className="da-box">
                            <img
                                className="a-dots"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                src="/images/svg-icons/ellipsis.svg"
                                alt=""
                            />
                            <div className="drop-box dropdown-menu">
                                <ul>
                                    <li
                                        className={
                                            (this.props?.userRole === "Viewer_Group" ||
                                                this.props?.userRole === "Operator_Group" ||
                                                row?.template_type === "DeviceProvision")
                                                ? "disabled"
                                                : ""
                                        }
                                        onClick={() =>
                                            (this.props?.userRole === "Viewer_Group" ||
                                                this.props?.userRole === "Operator_Group" ||
                                                row?.template_type === "DeviceProvision")
                                                ? undefined
                                                : this.setState({
                                                    configTunnel: row?.template_type === "TunnelConfiguration" ? true : false
                                                }, () => this.openAddTemplate(row))
                                        }>
                                        <img className="edit" src="/images/svg-icons/edit.svg" alt="" />Edit</li>
                                    <li
                                        className={
                                            (this.props?.userRole === "Viewer_Group" ||
                                                this.props?.userRole === "Operator_Group" ||
                                                row?.template_type === "DeviceProvision") ||
                                                !this.checkLock(row.template_type)
                                                ? "disabled"
                                                : ""
                                        }
                                        onClick={() =>
                                            (this.props?.userRole === "Viewer_Group" ||
                                                this.props?.userRole === "Operator_Group" ||
                                                row?.template_type === "DeviceProvision") ||
                                                !this.checkLock(row.template_type)
                                                ? undefined
                                                : this.lockTemplate(row.locked === false ? 1 : 0, [row.id])
                                        }>
                                        <img className="edit"
                                            src={row.locked === false ? "/images/svg-icons/lock.svg" : "/images/svg-icons/unlock.svg"}
                                            alt="" />
                                        {row.locked === false ? "Lock" : "Unlock"}</li>
                                </ul>
                            </div>
                        </div>
                    ),
                    allowOverflow: true,
                    button: true,
                    width: "56px",
                },
            ]
        }
    };

    checkLock = (template_type) => {
        let allowLock = true;
        let arr = ['StaticRoute', 'OSPFConfiguration', 'BGPConfiguration', 'FirewallConfiguration', 'WANConfiguration', 'WiFiConfiguration', 'QoSConfiguration', 'CaptivePortal'];
        if (arr.indexOf(template_type) !== -1 && this.state?.columnsData?.filter((item) =>
            item.template_type === "NetworkConfiguration" && item.locked === true).length > 0) {
            allowLock = false;
        }
        return allowLock;
    }

    componentDidMount() {
        this.closeAddTemplate();
        this.closeApplyTemplate();
        const configName = this.props?.location?.state?.configName;
        const deviceId = this.props?.location?.state?.configDeviceId;
        if (deviceId) {
            this.setState({ configDeviceId: deviceId }, this.getConfigDetails);
        }
        if (configName) {
            this.setState({ configName });
        }
    };

    getConfigDetails = () => {
        this.setState({
            showLoader: true
        });
        ApiCall(urlMapping.getConfigDetails(this.state.configDeviceId), (response) => {
            if (response?.data) {
                this.setState({
                    columnsData: response?.data,
                    configModels: response?.data?.[0]?.device_model,
                    showLoader: false,
                    disableCancel: false,
                    configTunnel: false,
                    reorderEnable: false
                }, () => this.getModulesData())
                if (this.state.nonSync) {
                    this.setState({
                        nonSyncCount: this.state.nonSyncCount + 1,
                    }, () => {
                        this.nonSync();
                    })
                }
                else {
                    this.closeAddTemplate();
                    this.closeApplyTemplate();
                }
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showLoader: false,
                        showRefreshModal: true
                    });
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState(
                        {
                            title: "Configuration",
                            message: response?.message,
                            show: true,
                            error: true,
                            showLoader: false
                        },
                        () => {
                            $("#errorMessage").modal("show");
                        }
                    );
                }
            }
        })
    };

    getModulesData = async () => {
        this.setState({
            showLoader: true,
        });
        await ApiCall(urlMapping.getModuleList(), (response) => {
            this.setState({
                showLoader: false,
            });
            if (response?.success) {
                const { models_interface, supported_modules } = response?.data || {};
                const modelKey = this.state.configModels?.split(",")?.[0];
                if (modelKey && models_interface?.[modelKey]) {
                    this.setState({ interfaces: models_interface[modelKey] });
                }
                const wireguardModules = supported_modules?.TunnelConfiguration;
                if (wireguardModules) {
                    this.setState({ wireguardSupportedModules: wireguardModules.join(", ") }
                    );
                }
            }
            else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({ showRefreshModal: true })
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        });
    };

    openUpload = () => {
        this.setState({
            uploadTemp: true
        }, () => {
            $("#uploadTempModal").modal("show");
        })
    };

    closeUpload = () => {
        this.setState({
            uploadTemp: false,
            jsonFile: undefined
        }, () => {
            $("#uploadTempModal").modal("hide");
        })
    };

    jsonFileHandleSelect = (e) => {
        this.setState({ jsonFile: e.target.files[0] })
    };

    deleteJson = () => {
        this.setState({ jsonFile: undefined })
    };

    uploadTemplates = () => {
        const formData = new FormData();
        formData.append("device_template_file", this.state.jsonFile);
        formData.append("device_id", this.state.configDeviceId);
        formData.append("upload_from", "config");
        this.setState({
            showLoader: true,
        });
        ApiCall(urlMapping.uploadDeviceTemplate(formData), (response) => {
            if (response?.success) {
                $("#uploadTempModal").modal("hide");
                this.setState({
                    jsonFile: undefined,
                    showLoader: false,
                    title: 'Upload Configuration',
                    message: response?.message,
                    show: true,
                    delete: false,
                    error: false
                }, () => {
                    this.getConfigDetails();
                    $("#errorMessage").modal("show");
                })
            }
            else {
                $("#uploadTempModal").modal("hide");
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({ showLoader: false, showRefreshModal: true })
                } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else {
                    this.setState({
                        jsonFile: undefined,
                        showLoader: false,
                        title: 'Upload Configuration',
                        message: response?.response?.data ? response?.response?.data?.message : response?.message,
                        show: true,
                        error: true,
                        delete: false
                    }, () => {
                        $("#errorMessage").modal("show");
                    });
                }
            }
        })
    };

    downloadTemplates = () => {
        let tempId = [];
        this.state.columnsData?.map((item) => {
            tempId.push(item?.id);
        })
        let data = { template_ids: tempId, from: "config" }
        this.setState({
            showLoader: true,
        });
        ApiCall(urlMapping.downloadTemplate(data), (response) => {
            if (parseInt(response?.response?.status) === 200) {
                const url = window.URL.createObjectURL(new Blob([response?.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "templates.zip");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                this.setState({
                    showLoader: false,
                })
            }
            else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({ showLoader: false, showRefreshModal: true })
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({
                        showLoader: false,
                        title: 'Download Templates',
                        message: response?.message,
                        show: true,
                        error: true,
                    }, () => {
                        $("#errorMessage").modal("show");
                    });
                }
            }
        })
    };

    getTimeExact = (time) => {
        let date = moment.utc(time);
        date = date.local();
        return date.format("DD MMMM YYYY hh:mm:ss A");
    };

    openAddTemplate = (templateData) => {
        let arr = ['StaticRoute', 'OSPFConfiguration', 'BGPConfiguration', 'WANConfiguration', 'WiFiConfiguration', 'QoSConfiguration', 'CaptivePortal', 'FirewallConfiguration'];
        if (templateData) {
            this.setState({
                templateDetails: templateData,
                openApplyTemplate: true,
                configApply: arr.indexOf(templateData.template_type) !== -1 ? true : false
            }, () => {
                this.props.dispatch({
                    type: Action.OPEN_APPlY_TEMPLATE
                });
            })
        }
        else {
            this.setState({
                configApply: true
            }, () => {
                this.props.dispatch({
                    type: Action.OPEN_ADD_TEMPLATE,
                });
            })
        }
    };

    closeAddTemplate = () => {
        this.setState({
            templateDetails: undefined,
            disableCancel: false,
            reorderEnable: false,
            configTunnel: false
        })
        this.props.dispatch({
            type: Action.CLOSE_ADD_TEMPLATE,
        });
    };

    configRedirect = (configId) => {
        setTimeout(() => {
            const data = this.state.columnsData.filter((item) => item.id === configId)?.[0];
            this.openAddTemplate(data);
            this.setState({
                disableCancel: true,
                reorderEnable: false
            })
        }, 1000)
    };

    closeApplyTemplate = () => {
        this.setState({
            templateDetails: undefined,
            openApplyTemplate: false,
            disableCancel: false,
            reorderEnable: false
        }, () => {
            this.props.dispatch({
                type: Action.CLOSE_APPLY_TEMPLATE,
            });
            this.closeAddTemplate();
        })
    }

    resetPopup = () => {
        this.setState(
            {
                show: false,
                error: false
            },
            () => {
                $("#errorMessage").modal("hide")
                if (this.state.isReorder) {
                    let templates = [];
                    this.state.columnsData.map((item => {
                        templates?.push(item?.template_type);
                    }))
                    let arr = ['StaticRoute', 'OSPFConfiguration', 'BGPConfiguration', 'WANConfiguration', 'WiFiConfiguration', 'QoSConfiguration', 'CaptivePortal', 'FirewallConfiguration'];
                    let newTemplate = [];
                    arr.map((item) => {
                        let list = templates?.filter((val) => val === item);
                        if (list?.length !== 0)
                            newTemplate.push(list[0]);
                    })
                    this.setState({
                        nonSyncTemplates: newTemplate,
                        nonSyncCount: 0,
                        nonSync: true
                    }, () => this.nonSync())
                }
            }
        );
    };
    lockTemplate = (lock, id) => {
        this.setState({
            isReorder: false
        })
        let data = {
            template_id: id,
            locking: lock
        }
        ApiCall(urlMapping.TemplateLock(data), (response) => {
            if (response?.success) {
                this.setState({
                    title: 'Template Lock',
                    message: response.message,
                    showLoader: false,
                    delete: false,
                    error: false,
                    show: true,
                }, () => {
                    this.getConfigDetails();
                    $("#errorMessage").modal("show");
                })
            }
            else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({ showLoader: false, showRefreshModal: true })
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({
                        showLoader: false,
                        title: 'Template Lock',
                        message: response?.message,
                        show: true,
                        error: true,
                    }, () => {
                        $("#errorMessage").modal("show");
                    });
                }
            }
        })
    }


    nonSync = () => {
        if (this.state.nonSyncTemplates?.length > this.state.nonSyncCount) {
            setTimeout(() => {
                const data = this.state.columnsData.filter((item) => item.template_type === this.state.nonSyncTemplates?.[parseInt(this.state.nonSyncCount)])?.[0];
                this.openAddTemplate(data);
                this.setState({
                    disableCancel: true,
                    reorderEnable: true,
                    isLastReorder: this.state.nonSyncTemplates?.length - 1 === this.state.nonSyncCount ? true : false
                })
            }, 100)
        }
        else {
            this.setState({
                showLoader: false,
                nonSync: false,
                nonSyncTemplates: [],
                reorderEnable: false,
                isLastReorder: false,
            })
        }
    };

    reorderTemplates = (value) => {
        const templateOrder = [
            'StaticRoute',
            'OSPFConfiguration',
            'BGPConfiguration',
            'WANConfiguration',
            'WiFiConfiguration',
            'QoSConfiguration',
            'CaptivePortal',
            'FirewallConfiguration'
        ];
        const dependentTemplate = this.state?.columnsData?.some(item =>
            templateOrder.includes(item.template_type)
        );
        if (dependentTemplate) {
            this.setState(
                {
                    title: 'Reorder Templates',
                    message: 'A few templates depend on the network template. This will reorder and sync them with the network template.',
                    show: true,
                    error: false,
                    delete: false,
                    isReorder: true,
                },
                () => {
                    $("#errorMessage").modal("show");
                }
            );
        }
        else if (value === "reorder") {
            this.setState(
                {
                    title: 'Reorder Templates',
                    message: "All the templates are in Sync.",
                    show: true,
                    error: false,
                    delete: false,
                    showLoader: false,
                    configApply: false,
                    isReorder: false,
                },
                () => {
                    $("#errorMessage").modal("show");
                }
            );
        }
        else {
            this.setState(
                { showLoader: false },
                () => {
                    $("#errorMessage").modal("hide");
                }
            );
        }
    };

    render() {
        return (
            <div>
                <SideMenu />
                {this.state.showLoader && <Loader />}
                <section id="rhsMain" className={this.props.open ? "rhs-main" : "rhs-main expand-w"}>
                    <Header />
                    <div className="page-header bg d-flex align-items-center">
                        <div className="back-page-icon"
                            onClick={() => this.props.history.push(
                                {
                                    pathname: "/devices"
                                }
                            )
                            }>
                            <img src="/images/svg-icons/back-page.svg" alt="" title="Back" />
                            <small>{" "}Back to the Device List</small>
                        </div>
                        <span className='ml-3'>
                            WAN IP/IMEI/MAC address: <strong>{this.state.configName}{" "}</strong>
                        </span>
                    </div>
                    <div className="white-bg">
                        <div className="ta-box">
                            <h1 className="page-title">Device Configuration {" "}
                                <img
                                    src="/images/svg-icons/add.svg"
                                    className={
                                        (this.props?.userRole === "Viewer_Group" ||
                                            this.props?.userRole === "Operator_Group")
                                            ? "add-btn disabled"
                                            : "add-btn"
                                    }
                                    alt=""
                                    onClick={() =>
                                        (this.props?.userRole === "Viewer_Group" ||
                                            this.props?.userRole === "Operator_Group")
                                            ? undefined
                                            : this.openAddTemplate()
                                    }
                                />
                            </h1>
                            <div className="action-btn">
                                <button
                                    onClick={() => this.downloadTemplates()}>
                                    Download
                                </button>
                                <button
                                    onClick={() => this.openUpload()}>
                                    Upload
                                </button>
                                <button
                                    onClick={() => this.reorderTemplates("reorder")}>
                                    Reorder
                                </button>
                                <button
                                    onClick={() => this.getConfigDetails()}>
                                    Refresh
                                </button>
                            </div>
                        </div>
                        <div>
                            <DataTable
                                columns={this.state.columns}
                                data={this.state.columnsData}
                                highlightOnHover
                                fixedHeader
                            />
                        </div>
                        {this.props.openAddTemplate &&
                            <AddEditTemplates
                                configAdd={true}
                                configEdit={false}
                                configApply={this.state.configApply}
                                configModels={this.state.configModels}
                                configDeviceId={this.state.configDeviceId}
                                wireguardSupportedModules={this.state.wireguardSupportedModules}
                                refreshList={() => this.getConfigDetails()}
                                templateData={this.state.templateDetails}
                                interfaces={this.state.interfaces}
                                closeAddTemplate={() => this.closeAddTemplate()}
                                configRedirect={(configId) => this.configRedirect(configId)}
                                template_type={this.state.templateDetails?.template_type}
                            />
                        }
                        {this.state.openApplyTemplate &&
                            <ApplyTemplate
                                configAdd={false}
                                configEdit={true}
                                configModels={this.state.configModels}
                                configDeviceId={this.state.configDeviceId}
                                configTunnel={this.state.configTunnel}
                                data={this.state.templateDetails}
                                interfaces={this.state.interfaces}
                                selectedModel={Common.getModels(this.state.configModels?.split(",")?.[0])}
                                reapply={false}
                                view={false}
                                edit={(this.state.templateDetails?.template_type === "TunnelConfiguration" && !this.state.configTunnel) ? false : true}
                                info={false}
                                nonSync={false}
                                props={this.props}
                                disableCancel={this.state.disableCancel}
                                reorderEnable={this.state.reorderEnable}
                                refreshList={() => this.getConfigDetails()}
                                configNetworkReorder={() => this.reorderTemplates()}
                                configRefresh={() => this.closeApplyTemplate()}
                                isLastReorder={this.state.isLastReorder}
                            />
                        }
                        {
                            this.state.show &&
                            <ErrorPopup
                                title={this.state.title}
                                message={this.state.message}
                                error={this.state.error}
                                reset={() => this.resetPopup()}
                            />
                        }
                        {
                            this.state.isAxiosError &&
                            <ErrorPopup
                                title={this.state.title}
                                message={this.state.message}
                                error={this.state.error}
                                delete={this.state.delete}
                                reset={() => this.setState({ isAxiosError: false })}
                            />
                        }
                    </div>
                    {/* Upload */}
                    {this.state.uploadTemp &&
                        <div className="modal fade licence" id="uploadTempModal" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" data-backdrop="static">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Upload Configuration</h5>
                                        <button
                                            type="button"
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                            onClick={() => this.closeUpload()}>
                                            <span aria-hidden="true"><img src="/images/svg-icons/cancel.svg" alt="" /></span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className='form-group mb-0'>
                                            <label>Add ZIP File</label>
                                            <div className="drag-box">
                                                <input
                                                    className="f1"
                                                    type="file"
                                                    accept={".zip"}
                                                    onClick={(e) => e.target.files[0] && this.jsonFileHandleSelect(e)}
                                                    onChange={(e) => this.jsonFileHandleSelect(e)} />
                                                <div className="csv-file">
                                                    <img src="../images/svg-icons/outbox.svg" alt="" />
                                                    <span>Click here to upload templates</span>
                                                </div>
                                            </div>
                                        </div>

                                        {(this.state.jsonFile !== undefined) && <div className="droped-files">
                                            <span>{this.state.jsonFile.name}</span>
                                            <img className="delete-icon" src="/images/svg-icons/delete-red.svg" alt="" onClick={() => this.deleteJson()} />
                                        </div>}
                                        <div>
                                            {(this.state.jsonFile !== undefined &&
                                                !this.state.jsonFile.name.includes('.zip')) &&
                                                <label className='license-btn'>Please upload a ZIP file!!</label>
                                            }
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="reset"
                                            onClick={() => this.closeUpload()}
                                            className="btn btn-light"
                                            data-dismiss="modal"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => this.uploadTemplates()}
                                            disabled={(this.state.jsonFile === undefined || !this.state.jsonFile.name.includes('.zip'))}
                                            className="btn btn-primary"
                                            data-dismiss="modal"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    {/* Upload Templates Ends */}
                </section>
                {this.state.showRefreshModal && <ErrorHandlePopup />}
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return ({
        authenticated: state.auth.authenticated,
        open: state.displaySidebarMenu.openSide,
        openAddTemplate: state.displayAddTemplate.openAddTemplate,
        userRole: String(state.auth.role)
    });
};

export default withRouter(connect(mapStateToProps)(OnboardingConfig));
