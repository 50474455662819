/* eslint-disable array-callback-return */
import React, { Component } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import ApiCall from "../../../../redux/API/apiCall";
import { ErrorPopup } from "../../../../layOuts/errorPopup";
import urlMapping from "../../../../redux/API/api";
import * as Common from "../../../../utils/common";
import { Link } from "react-router-dom";
import ErrorHandlePopup from "../../../ErrorHandlePopup";
import debounce from 'lodash/debounce';
import * as Action from "../../../../redux/actionTypes";
import isEqual from 'lodash/isEqual';
class BGPTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRefreshModal: false,
      showError: false,
      showPassword: false,
      multipleSelect: "",
      toggledClearRows: false,
      selectedProtocol: '',
      show: false,
      error: false,
      delete: false,
      showPopup: false,
      openAddTemplate: false,
      selectedTemplateId: this.props?.id ? this.props?.id : "",
      template_type: this.props?.templateData?.template_type || "",
      template_name: this.props?.templateData?.template_name || "",
      template_desc: this.props?.templateData?.template_desc || "",
      device_model: this.props?.templateData?.device_model || "",
      collection_version: this.props?.templateData?.collection_version || "",
      fw_version: this.props?.templateData?.fw_version || "",
      chimay: this.props.chimay,
      cbr: this.props.cbr,
      mifi: this.props.mifi,
      selectedBGP: [],
      isAxiosError: false,
      templateData: {
        network: '',
        interface: '',
        "local_ASN": '',
        neighbor_details: [],
        redistribution: {
          protocol: '',
        },
        distanceConfiguration: {
          ibgp_routes: "200",
          ebgp_routes: "20",
          local_routes: "20"
        },
      },
      networkViewData: [],
      selectedNetworks: ['all'],
      availableNetworks: [],
      configId: "",
      BGPData: {
        "id": Math.random().toString(36).substring(2, 11).toUpperCase(),
        "neighbor_ip": "",
        "remote_ASN": "",
        "Advanced_options": "0",
        "next_hop_self": "0",
        "EBGP_multihop": "0",
        "multihop_value": "1",
        "md5_authentication": "",
        "password": "",
        "loopback": "0",
        "keepalive_time": "60",
        "hold_time": "180",
        "isdeleted": "0",
        "rReflector": "0",
        "rReflectorClient": "0"
      },
      availableProtocol: [
        "connected",
        "static",
        "rip",
        "ospf",
        "kernel"
      ],
    };
  }
  componentDidMount() {
    if (this.props.template_schema_json !== undefined) {
      let { availableProtocol } = this.state;
      this.props.template_schema_json.redistribution.protocol.split(',').map((value) => {
        if (value)
          availableProtocol = availableProtocol.filter(item => item !== value);
      })
      this.setState({
        availableProtocol,
        templateData: JSON.parse(
          JSON.stringify(this.props.template_schema_json)
        ),
        old_ASN: JSON.parse(
          JSON.stringify(this.props.template_schema_json)).local_ASN
      });
      if (this.props.view && (this.props.networkViewData || this.props.edit)) {
        if (this.props?.networkViewData) {
          let { availableNetworks } = this.state;
          // LONG-1836 : Removed networks with blank ip address in case of device group only
          if (this.props?.group_id) {
            availableNetworks = this.props?.networkViewData?.filter(element => element.ip_address !== "");
            availableNetworks = availableNetworks?.filter(element => element.name !== "Control Tunnel");
            // Long-1884 show data tunnel in OSPF, BGP and Firewall templates in apply phase
          }
          else {
            availableNetworks = this.props?.networkViewData?.filter(element => element.name !== "Control Tunnel"
            )
          };
          let selectedNetworks = [];
          // LONG-2158 : Fix for incorrect network dropdown for edit and Re-apply page 
          this.props?.template_schema_json?.network?.split(',')?.map((item) => {
            if (item) {
              //LONG-1836 : Directly using the name in network field of json
              // let network = this.getNetwork(item);
              Array.isArray(availableNetworks) && availableNetworks.map((val) => {
                if (val.name === item) {
                  let network = item;
                  selectedNetworks.push(network);
                }
              })
            }
          })
          if (selectedNetworks.length === 0) {
            selectedNetworks = ['all']
          }
          this.setState({
            availableNetworks,
            selectedNetworks
          })
        }
      }
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (Object.keys(nextProps.templateData).length !== 0) {
      if (nextProps.templateData !== undefined) {
        this.setState({
          selectedTemplateId: nextProps.id ? nextProps.id : '',
          template_type: nextProps?.templateData?.template_type || "",
          template_name: nextProps?.templateData?.template_name || "",
          template_desc: nextProps?.templateData?.template_desc || "",
          device_model: nextProps?.templateData?.device_model || "",
          collection_version: nextProps?.templateData?.collection_version || "",
          fw_version: nextProps?.templateData?.fw_version || ""
        })
      }
    }
    if (nextProps.networkViewData) {
      let { availableProtocol } = this.state;
      let { availableNetworks } = this.state;
      let { networkViewData } = nextProps;
      let networkViewDataNames = networkViewData?.map(i => i?.name)
      let selectedNetworks = [];
      if (nextProps.template_schema_json) {
        // LONG-2158 : Fix for incorrect network dropdown for edit and Re-apply page
        nextProps.template_schema_json?.network?.split(',')?.map((item) => {
          if (item && networkViewDataNames?.includes(item)) {
            //LONG-1836 : Directly using the name in network field of json
            let network = item;
            selectedNetworks?.push(network);
          }
        })
        if (nextProps.networkViewData) {
          // LONG-1836 : Removed networks with blank ip address in case of device group only
          if (nextProps.group_id) {
            availableNetworks = nextProps.networkViewData?.filter(element => element.ip_address !== "");
            availableNetworks = availableNetworks?.filter(element => element.name !== "Control Tunnel");
            // Long-1884 show data tunnel in OSPF, BGP and Firewall templates in apply phase
          }
          else {
            availableNetworks = nextProps.networkViewData.filter(element => element.name !== "Control Tunnel"
            )
          };
          availableNetworks = availableNetworks.filter(item => !selectedNetworks.includes(item.name));
        }
        nextProps.template_schema_json?.redistribution?.protocol?.split(',')?.map((value) => {
          if (value)
            availableProtocol = availableProtocol.filter(item => item !== value);
        })
      }
      if (selectedNetworks.length === 0) {
        selectedNetworks = ['all'];
      } else {
        availableNetworks.push({ name: 'all', ip_address: '' });
      }
      this.setState({
        availableProtocol,
        selectedNetworks,
        availableNetworks,
      });
    }
    if (nextProps.networkViewData) {
      this.setState({
        networkViewData: JSON.parse(
          JSON.stringify(nextProps.networkViewData))
      })
    }
    if (nextProps.edit && nextProps.template_schema_json !== undefined) {
      this.setState({
        templateData: JSON.parse(
          JSON.stringify(nextProps.template_schema_json)),
        old_ASN: JSON.parse(
          JSON.stringify(nextProps.template_schema_json)).local_ASN
      });
    }
    if (!isEqual(this.props?.chimay, nextProps?.chimay) ||
      !isEqual(this.props?.cbr, nextProps?.cbr) ||
      !isEqual(this.props?.mifi, nextProps?.mifi)) {
      this.setState({
        chimay: nextProps.chimay,
        cbr: nextProps.cbr,
        mifi: nextProps.mifi
      })
    }
  }
  setDistanceValue = (e) => {
    if (Common.removeError(e)) {
      this.setState({
        showError: true
      })
    }
    const name = e.target.name;
    const { templateData } = this.state;
    templateData.distanceConfiguration[name] = e.target.value;
    this.setState({
      templateData
    })
  }
  setBGPValue = (e) => {
    if (Common.removeError(e)) {
      this.setState({
        showError: true,
      });
    }
    let data = this.state.BGPData;
    let name = e.target.name;
    let value = e.target.type === "checkbox" ? e.target.checked === true ? "1" : "0" : e.target.value;
    if (name === 'remote_ASN') {
      if (this.state.templateData.local_ASN !== value && this.state.BGPData.loopback === '1') {
        data.EBGP_multihop = '1';
        data.multihop_value = '2';
      } else if (this.state.templateData.local_ASN === value) {
        data.EBGP_multihop = '0';
        data.multihop_value = '1';
      } else if (this.state.templateData.local_ASN !== value) {
        data.rReflector = '0';
        data.rReflectorClient = '0';
      }
    }
    if (name === 'local_ASN') {
      this.setState({
        old_ASN: this.state.templateData.local_ASN
      })
    }
    if (name === 'loopback' && this.state.templateData.local_ASN !== this.state.BGPData.remote_ASN) {
      if (value === '1') {
        data.EBGP_multihop = '1';
        data.multihop_value = '2';
      } else {
        data.EBGP_multihop = '0';
        data.multihop_value = '1';
      }
    }
    value = name === 'password' ? value.replace(/\s/g, '') : value;
    data[name] = value;
    this.setState({ BGPData: data })
    if (this.state.BGPData.md5_authentication === "0") {
      data.password = "";
      this.setState({ BGPData: data })
    }
  };
  addBGP = () => {
    if (this.state.BGPData.md5_authentication === "1" && this.state.BGPData.password === "") {
      const element = document.getElementById("password");
      if (element) {
        element.style.display = "block";
        element.innerHTML = "Value is Required"
        this.setState({ showError: true })
      }
    }
    let result = false;
    const elements = document.getElementsByClassName("error-txt");
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].style.cssText === "display: block;")
        result = true;
    }
    if (result === false) {
      const data = this.state.templateData;
      let found = false;
      data.neighbor_details.map((item, index) => {
        if (item.id === this.state.BGPData.id) {
          found = true;
          data.neighbor_details[index] = this.state.BGPData;
        }
      });
      if (!found)
        data.neighbor_details.push(this.state.BGPData);
      this.setState(
        {
          templateData: data,
          BGPData: {
            "id": Math.random().toString(36).substring(2, 11).toUpperCase(),
            "neighbor_ip": "",
            "remote_ASN": "",
            "Advanced_options": "0",
            "next_hop_self": "0",
            "EBGP_multihop": "0",
            "multihop_value": "1",
            "md5_authentication": "0",
            "password": "",
            "loopback": "0",
            "keepalive_time": "60",
            "hold_time": "180",
            "isdeleted": "0",
            "rReflector": "0",
            "rReflectorClient": "0"
          }
        },
        () => this.closeBGP()
      );
    }
  }
  openBGP = (data) => {
    if (data) {
      //LONG-2843-EM - While editing the AS number, clicking cancel also updating the AS number in BGP template for Neighbors.
      let rowData = JSON.parse(JSON.stringify(data));;
      this.setState({
        BGPData: rowData,
        openAddTemplate: true,
        update: true,
      })
    }
    else {
      const elements = document.getElementsByClassName("error-txt");
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.cssText = "display: none;"
      }
      this.setState({
        BGPData: {
          "id": Math.random().toString(36).substring(2, 11).toUpperCase(),
          "neighbor_ip": "",
          "remote_ASN": "",
          "Advanced_options": "0",
          "next_hop_self": "0",
          "EBGP_multihop": "0",
          "multihop_value": "1",
          "md5_authentication": "0",
          "password": "",
          "loopback": "0",
          "keepalive_time": "60",
          "hold_time": "180",
          "isdeleted": "0",
          "rReflector": "0",
          "rReflectorClient": "0"
        },
        openAddTemplate: true,
      })
    }
  }
  closeBGP = () => {
    const elements = document.getElementsByClassName("error-txt");
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.cssText = "display: none;"
    }
    this.setState({
      openAddTemplate: false,
      update: false,
      selectedBGP: [],
      showError: false,
      BGPData: {
        "id": Math.random().toString(36).substring(2, 11).toUpperCase(),
        "neighbor_ip": "",
        "remote_ASN": "",
        "Advanced_options": "0",
        "next_hop_self": "0",
        "EBGP_multihop": "0",
        "multihop_value": "1",
        "md5_authentication": "0",
        "password": "",
        "loopback": "0",
        "keepalive_time": "",
        "hold_time": "",
        "isdeleted": "0",
        "rReflector": "0",
        "rReflectorClient": "0"
      },
    })
  }
  checkRange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (!Common.removeError(e)) {
      this.setState({
        showError: false
      })
    }
    if (name === "local_ASN" || name === "remote_ASN") {
      this.isRequired(e);
      if ((parseInt(value) < 64512 || parseInt(value) > 65534) || (!Common.validateNumeric(e))) {
        const element = document.getElementById(name);
        if (element) {
          element.style.display = "block";
          this.setState({ showError: true })
        }
      }
    }
    else if (name === "neighbor_ip") {
      this.isRequired(e);
      if (!Common.validateIPaddress(e))
        this.setState({ showError: true });
    }
    else if (name === 'password') {
      value = value.replace(/\s/g, '');
      if (value.length > 16) {
        const element = document.getElementById(name);
        if (element) {
          element.style.display = "block";
          element.innerHTML = "Password length can not be greater than 16."
          this.setState({ showError: true })
        }
      }
    }
    else if (name === 'keepalive_time') {
      if ((parseInt(value) < 0 || parseInt(value) > 65535) || (!Common.validateNumeric(e))) {
        const element = document.getElementById(name);
        if (element) {
          element.style.display = "block";
          this.setState({ showError: true })
        }
      }
    }
    else if (name === "hold_time") {
      if (
        !Common.validateNumeric(e) ||
        parseInt(value) < 3 * parseInt(this.state.BGPData.keepalive_time)
      ) {
        const element = document.getElementById(name);
        if (element) {
          element.style.display = "block";
          element.innerHTML =
            "The value of hold time should be 3 times or greater than that of the keep alive time";
          this.setState({ showError: true });
        }
      }
    } else if (
      name === "ibgp_routes" ||
      name === "ebgp_routes" ||
      name === "local_routes" ||
      (this.state.BGPData.EBGP_multihop === "1" && name === "multihop_value")
    ) {
      if (this.state.BGPData.EBGP_multihop === "1" && name === "multihop_value") {
        this.isRequired(e);
      }
      if (this.state.BGPData.loopback === "1" &&
        (parseInt(value) < 2 ||
          parseInt(value) > 255 ||
          !Common.validateNumeric(e))
      ) {
        const element = document.getElementById(name);
        if (element) {
          element.style.display = "block";
          this.setState({ showError: true });
        }
      } else if ((parseInt(value) < 1 ||
        parseInt(value) > 255 ||
        !Common.validateNumeric(e))) {
        const element = document.getElementById(name);
        if (element) {
          element.style.display = "block";
          this.setState({ showError: true });
        }
      }
    }
  }
  isRequired = (e) => {
    const errorMap = {
      local_ASN: "Local ASN value must be between 64512 and 65534",
      remote_ASN: "Remote ASN value must be between 64512 and 65534",
      neighbor_ip: "Invalid IP Address value",
      multihop_value: this.state.BGPData.loopback === '1' ? "Multihop value must be between 2 and 255" :
        "Multihop value must be between 1 and 255"
    };
    let val = e.target.value;
    let name = e.target.name;
    const element = document.getElementById(name);
    if (element) {
      if (!val || val.length === 0) {
        element.style.display = "block";
        element.innerHTML = "Value is Required";
        this.setState({ showError: true });
      } else if (element.innerHTML === "Value is Required") {
        element.innerHTML = errorMap[name];
      }
      if (name === 'multihop_value' && this.state.loopback === '1' && this.state.local_ASN === this.state.BGPData.remote_ASN) {
        element.innerHTML = errorMap[name];
      }
    }
  }
  checkSpecificRange1 = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    if ((parseInt(value) < 1 || parseInt(value) > 65535) || (!Common.validateNumeric(e))) {
      const element = document.getElementById(name);
      if (element) {
        element.style.display = "block";
        this.setState({ showError: true })
      }
    }
  }
  checkSpecificRange2 = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    if ((parseInt(value) < 1 || parseInt(value) > 255) || (!Common.validateNumeric(e))) {
      const element = document.getElementById(name);
      if (element) {
        element.style.display = "block";
        this.setState({ showError: true })
      }
    }
  }
  deleteBGP = () => {
    let data = this.state.templateData;
    this.state.selectedBGP.map((item) => (
      data.neighbor_details = this.state.templateData.neighbor_details.filter(finalValue => finalValue.id !== item.id)
    ))
    this.setState({
      templateData: data,
      toggledClearRows: !this.state.toggledClearRows
    })
  }
  multipleSelect(rows) {
    if (!rows.selectedRows.length)
      this.setState({ multipleSelect: "" });
    else {
      this.setState({ multipleSelect: rows.selectedRows }
      )
    }
  }
  showPass = () => {
    this.setState({
      showPassword: !this.state.showPassword
    })
  }
  saveData = () => {
    let data = {};
    data.template_name = this.state.template_name;
    data.template_desc = this.state.template_desc;
    data.device_model = this.props?.selectedModelList ? this.props?.selectedModelList?.toString() : this.state.device_model.toString();
    data.template_type = this.state.template_type;
    data.collection_version = this.state.collection_version;
    data.fw_version = this.state.fw_version;
    data.template_order = 6;
    data.template_schema_json = this.state.templateData;
    if (this.state.selectedTemplateId === "") {
      data.template_version = {
        chimay: this.state.chimay,
        cbr: this.state.cbr,
        mifi: this.state.mifi
      }
    }
    this.setState({
      showLoader: true,
    });
    if (this?.props?.configDeviceId && !this?.props?.configAdd) {
      const { templateData } = this.state;
      let interfaces = '';
      templateData.network = "";
      this.state.selectedNetworks.map((value) => {
        if (value === 'all') {
          templateData.network = '';
        } else {
          const network = this.props.networkViewData.filter(item => item.name === value)[0];
          if (network) {
            templateData.network = templateData.network === '' ?
              network.name : templateData.network + ',' + network.name;
            if (network.bridgeStatus === "Disabled" || network.network_zone === "datatunnel") {
              if (interfaces === '') {
                interfaces = network.interfaceName;
              } else {
                interfaces = interfaces + " " + network.interfaceName;
              }
            }
            else if (network.bridgeStatus === "Enabled") {
              if (interfaces === '') {
                interfaces = "br-" + network.networkName;
              } else {
                interfaces = interfaces + " br-" + network.networkName;
              }
            }
          }
          templateData.interface = interfaces;
        }
      });
      let config_data = {
        ...(!this.props?.configGroup && this.props.configDeviceId && { device_id: this.props.configDeviceId }),
        ...(this.props?.configGroup && { group_id: this.props.configDeviceId }),
        template_name: this.state.template_name,
        template_desc: this.state.template_desc,
        template_type: this.state.template_type,
        template_schema_json: templateData,
      }
      ApiCall(
        urlMapping.setConfigDetails(config_data),
        (response) => {
          this.setState({
            showLoader: false,
          });
          if (response?.success) {
            if (this.props?.reorderEnable) {
              if (this.props?.isLastReorder) {
                this.setState(
                  {
                    title: "Reorder Configuration",
                    message: "The configurations have been successfully reordered",
                    show: true,
                    error: false,
                  }, () => {
                    $("#errorMessage").modal("show");
                  }
                )
              }
              else {
                this.resetPopup();
              }
            }
            else {
              this.setState(
                {
                  title: "Update Configuration",
                  message: response?.message,
                  show: true,
                  error: false,
                }, () => {
                  $("#errorMessage").modal("show");
                }
              )
            }
          } else {
            if (parseInt(response?.response?.status) === 401) {
              this.setState({ showRefreshModal: true });
            }
            else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
              this.setState({
                title: 'Network Error',
                message: 'Something went wrong. Please try again later.',
                showLoader: false,
                isAxiosError: true,
                delete: false,
                error: true
              }, () => $("#errorMessage").modal("show"));
            }
            else {
              this.setState(
                {
                  title: "Update Configuration",
                  message: response?.message,
                  show: true,
                  error: true,
                }, () => $("#errorMessage").modal("show"));
            }
          }
        }
      );
    }
    else if (this?.props?.configDeviceId && this?.props?.configAdd) {
      let config_data = {
        ...(!this.props?.configGroup && this.props.configDeviceId && { device_id: this.props.configDeviceId }),
        ...(this.props?.configGroup && { group_id: this.props.configDeviceId }),
        template_name: this.state.template_name,
        template_desc: this.state.template_desc,
        device_model: this?.props?.configModels,
        template_type: this.state.template_type,
        collection_version: this.state.collection_version,
        fw_version: this.state.fw_version,
        template_order: 6,
        template_version: {
          chimay: this.state.chimay,
          cbr: this.state.cbr,
          mifi: this.state.mifi
        },
        template_schema_json: this.state.templateData,
      }
      ApiCall(
        urlMapping.setConfigDetails(config_data),
        (response) => {
          this.setState({
            showLoader: false,
          });
          if (response?.success) {
            this.setState(
              {
                title: "New Configuration",
                configId: response?.data,
                message: response?.message,
                show: true,
                error: false,
              }, () => {
                $("#errorMessage").modal("show");
              }
            )
          } else {
            if (parseInt(response?.response?.status) === 401) {
              this.setState({ showRefreshModal: true });
            }
            else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
              this.setState({
                title: 'Network Error',
                message: 'Something went wrong. Please try again later.',
                showLoader: false,
                isAxiosError: true,
                delete: false,
                error: true
              }, () => $("#errorMessage").modal("show"));
            }
            else {
              this.setState(
                {
                  title: "New Configuration",
                  message: response?.message,
                  show: true,
                  error: true,
                }, () => $("#errorMessage").modal("show"));
            }
          }
        }
      );
    }
    else if (this.state.selectedTemplateId !== "") {
      ApiCall(
        urlMapping.updateTemplate(this.state.selectedTemplateId, data),
        (response) => {
          if (response?.Success) {
            this.setState({
              showLoader: false,
            });
            this.props.refreshList();
            this.props.openApplyTemplate(this.state.selectedTemplateId);
            this.props.closeAddTemplate();
          } else {
            if (parseInt(response?.response?.status) === 401) {
              this.setState({
                showLoader: false,
                showRefreshModal: true
              });
            }
            else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
              this.setState({
                title: 'Network Error',
                message: 'Something went wrong. Please try again later.',
                showLoader: false,
                isAxiosError: true,
                delete: false,
                error: true
              }, () => $("#errorMessage").modal("show"))
            }
            else {
              this.setState(
                {
                  title: "Update Template",
                  message: response?.message,
                  showLoader: false,
                  show: true,
                  error: true,
                },
                () => $("#errorMessage").modal("show")
              );
            }
          }
        }
      );
    } else {
      ApiCall(urlMapping.createTemplate(data), (response) => {
        if (response?.success) {
          this.setState({
            showLoader: false,
          });
          this.props.refreshList();
          this.props.openApplyTemplate(response?.data?.id);
          this.props.closeAddTemplate();
        } else {
          if (parseInt(response?.response?.status) === 401) {
            this.setState({
              showLoader: false,
              showRefreshModal: true
            });
          }
          else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
            this.setState({
              title: 'Network Error',
              message: 'Something went wrong. Please try again later.',
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          }
          else {
            this.setState(
              {
                title: "Create Template",
                message: response?.message,
                showLoader: false,
                show: true,
                error: true,
              },
              () => $("#errorMessage").modal("show")
            );
          }
        }
      });
    }
  };
  resetPopup = () => {
    this.setState(
      {
        show: false,
        error: false,
      },
      () => {
        $("#errorMessage").modal("hide");
        this.props.refreshList();
        this.props.closeAddTemplate();
        if (this.props?.configApply) {
          this.props.configRedirect(this.state.configId);
        }
      }
    );
  };
  checkError = () => {
    let result = false;
    const elements = document.getElementsByClassName("error-txt");
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].style.cssText === "display: block;")
        result = true;
    }
    if (
      this.state.template_name === "" ||
      this.state.template_type === "" ||
      this.state.templateData.local_ASN === "" ||
      this.state.templateData.neighbor_details.length === 0
    )
      result = true;
    return result;
  };
  checkAddError = () => {
    let result = false;
    if (this.props.applying) {
      result = true;
    }
    const elements = document.getElementsByClassName("error-txt");
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].style.cssText === "display: block;")
        result = true;
    }
    if (this.state.BGPData.neighbor_ip === "" || this.state.BGPData.remote_ASN === '')
      result = true;
    return result;
  };
  setProtocol = (e) => {
    let { templateData } = this.state;
    let { availableProtocol } = this.state;
    if (e.target.checked) {
      templateData.redistribution.protocol = templateData.redistribution.protocol === '' ?
        e.target.id : templateData.redistribution.protocol + ',' + e.target.id;
      availableProtocol = availableProtocol.filter(item => item !== e.target.id);
    }
    else {
      if (!availableProtocol.includes(e.target.id))
        availableProtocol.push(e.target.id);
      const protocols = templateData.redistribution.protocol?.split(',');
      templateData.redistribution.protocol = protocols.filter(item => item !== e.target.id)?.join(',');
    }
    this.setState({
      templateData,
      availableProtocol,
      selectedProtocol: availableProtocol
    })
  }
  selectedBGP = (e, bgp) => {
    let { selectedBGP } = this.state;
    if (e.target.checked) {
      selectedBGP.push(bgp);
    }
    else {
      selectedBGP = selectedBGP.filter(item => item.id !== bgp.id);
    }
    this.setState({
      selectedBGP
    })
  }
  setASNValue = (e) => {
    if (!Common.removeError(e)) {
      this.setState({
        showError: false
      })
    }
    let { templateData } = this.state;
    templateData.local_ASN = e.target.value;
    templateData.neighbor_details.map((item) => {
      if (item.remote_ASN !== e.target.value) {
        item.rReflector = '0';
        item.rReflectorClient = '0';
      }
    })
    this.setState({
      templateData,
    })
  }
  handleChange = (e) => {
    let { selectedNetworks } = this.state;
    let { availableNetworks } = this.state;
    if (e.target.checked) {
      if (e.target.id === 'all') {
        selectedNetworks.map((value) => {
          const network = this.props?.networkViewData.filter(item => item.name === value)?.[0];
          availableNetworks.push(network);
        })
        selectedNetworks = ['all'];
        availableNetworks = availableNetworks.filter(item => item.name !== e.target.id);
      } else {
        selectedNetworks = selectedNetworks.filter(item => item !== 'all');
        let found = false;
        availableNetworks.map((network) => {
          if (network.name === 'all')
            found = true;
        });
        if (!found)
          availableNetworks.push({ name: 'all', ip_address: '' })
        if (!selectedNetworks.includes(e.target.id)) {
          selectedNetworks.push(e.target.id);
          availableNetworks = availableNetworks.filter(item => item.name !== e.target.id);
        }
      }
    } else {
      selectedNetworks = selectedNetworks.filter(item => item !== e.target.id);
      const network = e.target.id === 'all' ?
        { name: e.target.id, ip_address: '' } :
        this.props.networkViewData.filter(item => item.name === e.target.id)[0];
      availableNetworks.push(network);
    }
    this.setState({
      selectedNetworks,
      availableNetworks
    })
  }
  //LONG-1836 : Directly using the name in network field of json
  // getNetwork = (value) => {
  //   let networkName = '';
  //   this.props.networkViewData.map((network) => {
  //     const ipValue = value.split('/')[0];
  //     const subnet = value.split('/')[1];
  //     if (ipValue === network.ip_address && subnet === network.subnet) {
  //       networkName = network.name;
  //     }
  //   })
  //   return networkName;
  // }
  checkASN = (event) => {
    if (this.props.edit && this.state.old_ASN !== this.state.templateData.local_ASN) {
      this.setState(
        {
          title: "Update Template",
          message: "Kindly ensure to modify the Remote ASN configuration in other Device(s) which established BGP connection with this device",
          showPopup: true,
          error: true,
          delete: true
        },
        () => $("#errorMessageNew").modal("show")
      );
    } else {
      $("#errorMessageNew").modal("hide")
      this.debounceApply(this.applyTemplate, event);
    }
  }
  applyTemplate = () => {
    const { templateData } = this.state;
    let interfaces = '';
    // LONG-1758 : Fix for duplicate network appended in JSON in edit & reapply
    templateData.network = "";
    this.state.selectedNetworks.map((value) => {
      if (value === 'all') {
        templateData.network = '';
      } else {
        const network = this.props.networkViewData.filter(item => item.name === value)[0];
        if (network) {
          templateData.network = templateData.network === '' ?
            network.name : templateData.network + ',' + network.name;
          //LONG-2160-Data tunnel network is not included in BGP configuration json file
          //Adding interface name for data tunnel network zones in operation logs
          if (network.bridgeStatus === "Disabled" || network.network_zone === "datatunnel") {
            if (interfaces === '') {
              interfaces = network.interfaceName;
            } else {
              interfaces = interfaces + " " + network.interfaceName;
            }
          }
          else if (network.bridgeStatus === "Enabled") {
            if (interfaces === '') {
              interfaces = "br-" + network.networkName;
            } else {
              interfaces = interfaces + " br-" + network.networkName;
            }
          }
        }
        templateData.interface = interfaces;
      }
    });
    this.props.dispatch({
      type: Action.SET_APPLY_TEMPLATE,
    });
    this.props.applyTemplate(templateData);
  }
  debounceApply = debounce((fn, event) => {
    fn(event)
  }, 500);
  //LONG-3014: EM - Issue in checkbox enable/disable case.
  selectedTabChange = (name) => {
    if (name === "neighbor") {
      this.setState({ selectedBGP: [] })
    }
  }
  //LONG-3015 : Cancel Operation is not working for BGP template in edit and reapply case
  closeAll = () => {
    //LONG-3014-EM - Issue in checkbox enable/disable case.
    this.setState({
      selectedBGP: []
    })
    this.props.dispatch({
      type: Action.CLOSE_APPLY_TEMPLATE,
    });
    this.props.closeAddTemplate()
  }
  render() {
    return (
      <div>
        <div className="BGP-template">
          <div className={this.state.openAddTemplate ? "disabled-slide" : ""}></div>
          {this.props.edit && <div className="form-group col-md-6">
            <label>Network</label>
            <div className="dropdown">
              {!this.props.view ? <ul className="chip-list z-index-9 bg-white radius">
                {
                  this.state.selectedNetworks?.map((network) => {
                    // Long-1778 Page break when deleting the non existing network in BGP template
                    return (network !== '' &&
                      <li>
                        {network === 'all' ? 'None' : network}
                        {!this.props.view && <img src="/images/svg-icons/cancel.svg" alt="" id={network}
                          onClick={(e) => this.handleChange(e)} />}
                      </li>)
                  })
                }
              </ul> :
                <ul className="dm-list d-block">
                  {
                    // LONG-2158 : Fix for incorrect network dropdown for edit and Re-apply page
                    this.state.templateData.network?.split(',')?.length > 0 &&
                    this.state.templateData.network?.split(',')?.map(item => {
                      //LONG-1836 : Directly using the name in network field of json
                      return (<li>{item === '' ? 'All' :
                        // this.getNetwork(item)}
                        item}
                      </li>)
                    })
                  }
                </ul>}
              {!this.props.view &&
                <>
                  <div className="chip-box add-arrow" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  </div>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <ul>
                      {this.state.availableNetworks.map((item) => {
                        return <li >
                          <input
                            type="checkbox"
                            id={item.name}
                            checked={this.state?.selectedNetworks?.includes(item?.name)}
                            onClick={(e) => this.handleChange(e)} />
                          {item.name === 'all' ? 'None' : item.name}</li>
                      })}
                    </ul>
                  </div>
                </>}
            </div>
          </div>}
          <div className="tab-box">
            <ul className="tab-nav nav nav-pills bg-gray" id="networkTab" role="tablist">
              <li className="nav-item" role="presentation">
                <a className="nav-link active" id="neighbor-tab" data-toggle="tab" href="#neighbor" role="tab" aria-controls="neighbor" aria-selected="true" onClick={() => this.selectedTabChange('neighbor')}>Neighbor</a>
              </li>
              <li className="nav-item" role="presentation">
                <a className="nav-link" id="redistribute-tab" data-toggle="tab" href="#redistribute" role="tab" aria-controls="redistribute" aria-selected="false">Redistribute</a>
              </li>
              <li className="nav-item" role="presentation">
                <a className="nav-link" id="dist-config-tab" data-toggle="tab" href="#dist-config" role="tab" aria-controls="dist-config" aria-selected="false">Distance Configuration</a>
              </li>
            </ul>
            <div className="tab-content pt-2">
              <div className="tab-pane active" id="neighbor" role="tabpanel" aria-labelledby="neighbor-tab">
                <div className="row">
                  <div className="form-group col-md-6 mt-2 mb-0">
                    <label>Local ASN<span className="red-txt">*</span>
                      <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                        title="Local autonomous system number of the router">
                        <img src="/images/svg-icons/info.svg" alt="" /></div>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="local_ASN"
                      defaultValue={this.state.templateData?.local_ASN}
                      onBlur={(e) => this.checkRange(e)}
                      onChange={(e) => this.setASNValue(e)} />
                    <div className="error-txt" id="local_ASN">
                      Local ASN value must be between 64512 and 65534
                    </div>
                  </div>
                </div>
                <div className="page-sub-title">
                  <div><strong>Neighbor</strong>
                    <img
                      src="/images/svg-icons/add.svg"
                      className={(this.props?.templateData?.default_type !== ''
                        && this.props.templateData?.default_type !== null) ? "add-btn ml-2 disabled" : "add-btn ml-2"} alt=""
                      onClick={(this.props?.templateData?.default_type !== ''
                        && this.props?.templateData?.default_type !== null) ? undefined : () => this.openBGP()} />
                  </div>
                  <div className="device-action">
                    <span className={this.state.selectedBGP?.length !== 1 ? "icon-box disabled" : "icon-box"}>
                      <img
                        src="/images/svg-icons/edit.svg"
                        onClick={this.state.selectedBGP.length === 1
                          ? () => this.openBGP(this.state.selectedBGP?.[0])
                          : undefined
                        }
                        alt="" />
                    </span>
                    <span className={this.state.selectedBGP.length === 0 ? "icon-box disabled" : "icon-box"}>
                      <img
                        src="/images/svg-icons/delete.svg"
                        alt=""
                        onClick={this.state.selectedBGP !== "" &&
                          (this.props?.templateData?.default_type === ''
                            || this.props?.templateData?.default_type === null)
                          ? () => this.deleteBGP()
                          : undefined} />
                    </span>
                  </div>
                </div>
                <div className="table-responsive">
                  {this.state.templateData?.neighbor_details?.length > 0 &&
                    <table className="table">
                      <thead>
                        <tr>
                          <th>
                          </th>
                          <th>Neighbor IP</th>
                          <th>Neighbor Type</th>
                          <th>Loopback</th>
                          <th>Remote ASN</th>
                          <th>Advance Options</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.state.templateData?.neighbor_details?.map(item => {
                            return (
                              <tr>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={this.state.selectedBGP?.filter(bgpid => (bgpid.id === item.id))?.length > 0}
                                    onClick={(e) => this.selectedBGP(e, item)} />
                                </td>
                                <td>
                                  {<Link
                                    to={{}}
                                    className="txt-blue"
                                    onClick={() => this.openBGP(item)} >
                                    {item?.neighbor_ip}
                                  </Link>}
                                </td>
                                <td>{item?.remote_ASN === this.state.templateData?.local_ASN ? 'IBGP' : 'EBGP'}</td>
                                <td>
                                  <label
                                    className="switch gray">
                                    <input
                                      type="checkbox"
                                      checked={item?.loopback === '1'} />
                                    <span className="slider round"></span>
                                  </label>
                                </td>
                                <td>{item.remote_ASN}</td>
                                <td>
                                  <div className="sa-box">
                                    <ul>
                                      <li>
                                        <span>Next-Hop-Self</span>
                                        <span>{item?.next_hop_self === '1' ? 'Enabled' : 'Disabled'}</span>
                                      </li>
                                      <li>
                                        <span>EBGP-Multihop</span>
                                        <span>{item?.EBGP_multihop === '1' ? 'Enabled' : 'Disabled'}</span>
                                      </li>
                                      <li>
                                        <span>MD5 Authentication</span>
                                        <span>{item?.md5_authentication === '1' ? 'Enabled' : 'Disabled'}</span>
                                      </li>
                                      <li>
                                        <span>Password</span>
                                        <span className="mask">{item?.password}</span>
                                      </li>
                                      <li>
                                        <span>Keep Alive Time</span>
                                        <span>{item?.keepalive_time} Seconds</span>
                                      </li>
                                      <li>
                                        <span>Hold Time</span>
                                        <span>{item?.hold_time} Seconds</span>
                                      </li>
                                      <li>
                                        <span>Loopback</span>
                                        <span>{item?.loopback === '1' ? 'Enabled' : 'Disabled'}</span>
                                      </li>
                                      <li>
                                        <span>Route Reflector</span>
                                        <span>{this.state.templateData?.local_ASN !== '' && item?.remote_ASN !== this.state.templateData?.local_ASN ? 'Disabled' :
                                          item?.rReflector === '1' ? 'Enabled' : 'Disabled'}</span>
                                      </li>
                                      <li>
                                        <span>Route Reflector Client</span>
                                        <span>{this.state.templateData?.local_ASN !== '' && item?.remote_ASN !== this.state.templateData?.local_ASN ? 'Disabled' :
                                          item?.rReflectorClient === '1' ? 'Enabled' : 'Disabled'}</span>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  }
                </div>
              </div>
              <div className="tab-pane" id="redistribute" role="tabpanel" aria-labelledby="redistribute-tab">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Protocol</label>
                      <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                        title="Redistribute routes of the specified protocol into BGP"><img src="/images/svg-icons/info.svg" alt="" /></div>
                      <div className="dropdown">
                        <ul className="chip-list z-index-9 bg-white radius">
                          {
                            this.state.templateData?.redistribution?.protocol?.length > 0 &&
                            this.state.templateData?.redistribution?.protocol?.split(',')?.map((protocol) => {
                              return (
                                <li>{Common.getProtocol(protocol)}
                                  <img src="/images/svg-icons/cancel.svg" alt="" id={protocol}
                                    onClick={(e) => this.setProtocol(e)} />
                                </li>
                              )
                            })
                          }
                        </ul>
                        <div className="chip-box add-arrow" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        </div>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          <ul>
                            {
                              this.state.availableProtocol?.map((protocol) => {
                                return (
                                  <li>
                                    <input
                                      type="checkbox"
                                      checked={this.state.selectedProtocol === protocol}
                                      name="protocol"
                                      id={protocol}
                                      onChange={(e) => this.setProtocol(e)} />
                                    {Common.getProtocol(protocol)}
                                  </li>)
                              })
                            }
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane" id="dist-config" role="tabpanel" aria-labelledby="dist-config-tab">
                <div className="d-flex align-items-center justify-content-between">
                  <p><strong>Distance Configuration</strong></p>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>IBGP Routes
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                          title="Distance for routes internal to the AS"><img src="/images/svg-icons/info.svg" alt="" /></div>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="ibgp_routes"
                        value={this.state.templateData?.distanceConfiguration?.ibgp_routes}
                        onBlur={(e) => this.checkRange(e)}
                        onChange={(e) => this.setDistanceValue(e)} />
                      <div className="error-txt" id="ibgp_routes">
                        IBGP Routes value must be between 1 and 255
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>EBGP Routes
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                          title="Distance for routes external to the AS"><img src="/images/svg-icons/info.svg" alt="" /></div>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="ebgp_routes"
                        value={this.state.templateData?.distanceConfiguration?.ebgp_routes}
                        onBlur={(e) => this.checkRange(e)}
                        onChange={(e) => this.setDistanceValue(e)} />
                      <div className="error-txt" id="ebgp_routes">
                        EBGP Routes value must be between 1 and 255
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Local Routes
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                          title="Distance for local routes"><img src="/images/svg-icons/info.svg" alt="" /></div>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="local_routes"
                        defaultValue={this.state.templateData?.distanceConfiguration?.local_routes}
                        onBlur={(e) => this.checkRange(e)}
                        onChange={(e) => this.setDistanceValue(e)} />
                      <div className="error-txt" id="local_routes">
                        Local Routes value must be between 1 and 255
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {<div className="s-footer">
            {/* 1665 reapply page issue */}
            <button
              disabled={this.props?.disableCancel || false}
              className="btn btn-light"
              onClick={(this.props.apply && this.props.edit && this.props.reapply) ? () => this.props.closeApplyTemplate() :
                () => this.closeAll()}>
              {/* 1665 reapply page issue */}
              {this.props.apply && !this.props.edit && !this.props.reapply ? 'Back' : 'Cancel'}</button>
            <button className="btn btn-primary"
              disabled={this.checkError() || this.props.applying}
              onClick={
                (this.props.apply && !this.props?.configEdit)
                  ? (event) => this.checkASN(event)
                  : () => this.saveData()}
            >
              {
                this.props?.isLastReorder
                  ? "Update"
                  : (this.props?.configApply || this.props?.reorderEnable)
                    ? "Next"
                    : (this.props.apply && !this.props?.configEdit)
                      ? "Apply"
                      : (this?.props?.configAdd || this.props?.configEdit)
                        ? "Save"
                        : (this.state.selectedTemplateId !== "" || this?.props?.configDeviceId)
                          ? "Update"
                          : "Save"
              }
            </button>
          </div>}
        </div>
        <div className={
          (this.props.edit ? this.state.openAddTemplate === true : this.state.openAddTemplate) ? "sidebar-slide  zindex10 w-350 second-open r-757" : "sidebar-slide  zindex10 w-350"}>
          <div className="s-header">
            <h1 className="ss-title">{this.state.update ? "Update Neighbor" : "Add New Neighbor"}</h1>
            <button>
              <img src="/images/svg-icons/s-cancel.svg" alt="" onClick={() => this.closeBGP()} />
            </button>
          </div>
          <div className="s-body">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <span className="mr-5">Loopback <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                title="Toggle to enable loopback for the neighbor"><img src="/images/svg-icons/info.svg" alt="" /></div></span>
              <label className="switch">
                <input
                  type="checkbox"
                  name="loopback"
                  checked={this.state.BGPData?.loopback === "1"}
                  onChange={(e) => this.setBGPValue(e)} />
                <span className="slider round"></span>
              </label>
            </div>
            <div className="form-group">
              <label>Neighbor IP<span className="red-txt">*</span>
                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                  title="Neighbor IP to whom the router want to share the routes">
                  <img src="/images/svg-icons/info.svg" alt="" /></div>
              </label>
              <input
                type="text"
                className="form-control"
                name="neighbor_ip"
                value={this.state.BGPData?.neighbor_ip}
                onBlur={(e) => this.checkRange(e)}
                onChange={(e) => this.setBGPValue(e)} />
              <div className="error-txt" id="neighbor_ip">
                Invalid IP Address value
              </div>
            </div>
            <div className="form-group">
              <label>Remote ASN<span className="red-txt">*</span>
                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                  title="Remote Autonomous system number of the neighbor IP">
                  <img src="/images/svg-icons/info.svg" alt="" /></div>
              </label>
              <input
                type="text"
                className="form-control"
                name="remote_ASN"
                value={this.state.BGPData?.remote_ASN}
                onBlur={(e) => this.checkRange(e)}
                onChange={(e) => this.setBGPValue(e)} />
              <div className="error-txt" id="remote_ASN">
                Remote ASN value must be between 64512 and 65534
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-3 pb-2 border-bottom">
              <span className="mr-5">Advance Option <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                title="Toggle to enable advanced configuration">
                <img src="/images/svg-icons/info.svg" alt="" /></div></span>
              <label className="switch">
                <input
                  type="checkbox"
                  name="Advanced_options"
                  checked={this.state.BGPData?.Advanced_options === "1"}
                  onChange={(e) => this.setBGPValue(e)} />
                <span className="slider round"></span>
              </label>
            </div>
            {this.state.BGPData?.Advanced_options === "1" &&
              <div className="gray-bg p-3 rounded">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <span className="mr-5">Next-Hop-Self <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                    title="Toggle to enable the next hop calculation for this neighbor">
                    <img src="/images/svg-icons/info.svg" alt="" /></div></span>
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="next_hop_self"
                      checked={this.state.BGPData?.next_hop_self === "1"}
                      onChange={(e) => this.setBGPValue(e)} />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <span className="mr-5">EBGP Multihop
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                      title="Allow EBGP neighbors not on directly connected networks">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div></span>
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="EBGP_multihop"
                      checked={this.state.BGPData?.EBGP_multihop === "1"}
                      onChange={(e) => this.setBGPValue(e)}
                      disabled={this.state.BGPData?.remote_ASN === this.state.templateData?.local_ASN ||
                        this.state.BGPData?.loopback === "1"} />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="form-group">
                  <label>Multihop Value
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enables a neighbor connection"><img src="/images/svg-icons/info.svg" alt="" /></div>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    name="multihop_value"
                    onChange={(e) => this.setBGPValue(e)}
                    min='1'
                    max='255'
                    onBlur={(e) => this.checkRange(e)}
                    disabled={(this.state.BGPData?.remote_ASN === this.state.templateData?.local_ASN) || this.state.BGPData?.EBGP_multihop === "0"}
                    value={parseInt(this.state.BGPData?.multihop_value)} />
                  <div className="error-txt" id="multihop_value">{
                    (this.state.BGPData.loopback === '1' && this.state.BGPData?.remote_ASN !== this.state.templateData?.local_ASN) ?
                      'Multihop value must be between 2 and 255' :
                      'Multihop value must be between 1 and 255'}</div>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <span className="mr-5">MD5 Authentication
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                      title="MD5 authentication for a neighbor">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </span>
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="md5_authentication"
                      checked={this.state.BGPData?.md5_authentication === "1"}
                      onChange={(e) => this.setBGPValue(e)} />
                    <span className="slider round"></span>
                  </label>
                </div>
                {this.state.BGPData?.md5_authentication === "1" &&
                  <div className="form-group">
                    <label>Password
                      <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                        title="Password packet to be shared for the particular neighbor"><img src="/images/svg-icons/info.svg" alt="" />
                      </div>
                    </label>
                    <input
                      type={this.state.showPassword ? "text" : "password"}
                      className="form-control"
                      name="password"
                      value={this.state?.BGPData?.password}
                      onBlur={(e) => this.checkRange(e)}
                      onChange={(e) => this.setBGPValue(e)}
                      disabled={this.state.BGPData?.md5_authentication === "0"}
                    />
                    <div className="error-txt" id="password">
                      Invalid value
                    </div>
                    <div className="sh-icon">
                      <img src={this.state.showPassword ? "/images/show.png" : "/images/hide.png"} alt="" onClick={() => this.showPass()} />
                    </div>
                  </div>
                }
                <div className="form-group">
                  <label>Keep Alive Time
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                      title="Time after which keep alive message will be send"><img src="/images/svg-icons/info.svg" alt="" /></div>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="keepalive_time"
                    defaultValue={this.state.update ? this.state.BGPData?.keepalive_time : "60"}
                    onBlur={(e) => this.checkRange(e)}
                    onChange={(e) => this.setBGPValue(e)} />
                  <span className="units r-15">Seconds</span>
                  <div className="error-txt" id="keepalive_time">
                    Please enter a value from 0 to 65535
                  </div>
                </div>
                <div className="form-group">
                  <label>Hold Time
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                      title="Time interval after which the router will decide that the session is ended when not recived the keepalive message"><img src="/images/svg-icons/info.svg" alt="" /></div>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="hold_time"
                    defaultValue={this.state.update ? this.state.BGPData?.hold_time : "180"}
                    onBlur={(e) => this.checkRange(e)}
                    onChange={(e) => this.setBGPValue(e)} />
                  <span className="units r-15">Seconds</span>
                  <div className="error-txt" id="hold_time">
                    Invalid value
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <span className="mr-5">Route Reflector <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                    title="Enable to make this neighbor as route reflector"><img src="/images/svg-icons/info.svg" alt="" /></div></span>
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="rReflector"
                      checked={this.state.BGPData?.rReflector === "1"}
                      onChange={(e) => this.setBGPValue(e)}
                      disabled={(this.state.templateData?.local_ASN && this.state.BGPData?.remote_ASN !== this.state.templateData?.local_ASN) ||
                        this.state.BGPData.rReflectorClient === "1"} />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <span className="mr-5">Route Reflector Client <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                    title="Enable to make this neighbor as route reflector client"><img src="/images/svg-icons/info.svg" alt="" /></div></span>
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="rReflectorClient"
                      checked={this.state.BGPData?.rReflectorClient === "1"}
                      onChange={(e) => this.setBGPValue(e)}
                      //EMGT-149-EM - Once enable that Route reflector Client ,Not able to disable that again	
                      disabled={(this.state.templateData.local_ASN && this.state.BGPData?.remote_ASN !== this.state.templateData?.local_ASN) ||
                        this.state.BGPData?.rReflector === "1"} />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>}
          </div>
          <div className="s-footer">
            <button className="btn btn-light" onClick={() => this.closeBGP()}>Cancel</button>
            <button className="btn btn-primary" disabled={this.checkAddError()}
              onClick={() => this.addBGP()}>Add</button>
            {/* LONG-1634_Issue Fixed for error popup */}
            {(this.state.show) ?
              <ErrorPopup
                title={this.state.title}
                message={this.state.message}
                error={this.state.error}
                reset={() => this.resetPopup()}
              /> : null
            }
            {this.state.showPopup && <div className="modal fade errorMessage" id="errorMessageNew" tabIndex="-1" data-backdrop="static"
              role="dialog" aria-labelledby="errorMessageTitle" aria-hidden="true"
            >
              <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                {/* LONG-2242-EM - Bootstrap page - name for Bolt and cancel button in bolt popup  */}
                <div className="modal-content">
                  <div className="modal-header bg-white border-0">
                  </div>
                  <div className="modal-body pt-5">
                    <div className="error-msg">
                      <span className={this.state.error ? "error-icon" : "success-icon"}>{this.state.error ? (this.state.info ? 'i' : '!') : ''}</span>
                      <div className="txt1">{this.state.title}</div>
                      <div className="txt2">{this.state.message}
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer border-top-0 justify-content-center pb-4">
                    {(this.state.delete) && <button type="button" className="btn btn-light"
                      data-dismiss="modal" onClick={() => this.resetPopup()}>
                      {'Cancel'}</button>}
                    {
                      <button
                        type="button"
                        className={this.state.error ? "btn btn-danger" : "btn btn-primary"}
                        data-dismiss="modal"
                        onClick={(this.state.delete) ? (event) => this.debounceApply(this.applyTemplate, event) : this.resetPopup()}>
                        {'OK'}
                      </button>
                    }
                  </div>
                </div>moduleList
              </div>
            </div>
            }
          </div>
        </div>
        {this.state.showRefreshModal && <ErrorHandlePopup />}
        {(this.state.isAxiosError) &&
          <ErrorPopup
            title={this.state.title}
            message={this.state.message}
            error={this.state.error}
            delete={this.state.delete}
            reset={() => this.setState({ isAxiosError: false })}
          />
        }
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    applying: state.displayAddTemplate.applying,
    openStaticTemplate: state.displayAddTemplate.openStaticTemplate,
  };
};
export default connect(mapStateToProps)(BGPTemplate);