import React, { Component } from 'react'
import { withRouter } from "react-router";
import { connect } from "react-redux";
import ApiCall from "../../../redux/API/apiCall";
import urlMapping from "../../../redux/API/api";
import { Loader } from '../../../layOuts/Loader';
import moment from 'moment';
import DataTable from "react-data-table-component";
import { ErrorPopup } from '../../../layOuts/errorPopup';
import $ from 'jquery';
import ErrorHandlePopup from '../../ErrorHandlePopup';

class DeviceGroupCheckpoint extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showRefreshModal: false,
            pageNo: 1,
            pageSize: 10,
            totalCount: 0,
            title: '',
            message: '',
            show: false,
            error: false,
            delete: false,
            deviceLogData: [],
            group_id: this.props?.group_id,
            showLoader: false,
            multipleSelect: [],
            create: false,
            createError: false,
            restore: false,
            checkpointName: '',
            updateCheck: '',
            selectedUUID: '',
            isAxiosError: false,
            columns: [
                {
                    name: "Name",
                    selector: "checkpoint_name",
                    sortable: true,
                    cell: (row) => (
                        <span title={row.checkpoint_name}>
                            {row.checkpoint_name !== "" && row.checkpoint_name !== "null" && row.checkpoint_name !== null
                                ? row.checkpoint_name
                                : "-"}
                        </span>
                    )
                },
                {
                    name: "Checkpoint UUID",
                    selector: "checkpoint_uuid",
                    sortable: true,
                    cell: (row) => (
                        <span title={row.checkpoint_uuid}>
                            {row.checkpoint_uuid !== "" && row.checkpoint_uuid !== "null" && row.checkpoint_uuid !== null
                                ? row.checkpoint_uuid
                                : "-"}
                        </span>
                    )
                },
                {
                    name: "Created By",
                    selector: "created_by",
                    sortable: true,
                    cell: (row) => (
                        <span title={row.created_by} >
                            {row.created_by !== "" && row.created_by !== "null" && row.created_by !== null
                                ? row.created_by
                                : "-"}
                        </span>
                    )
                },
                {
                    name: "Created Date",
                    selector: "created_date",
                    sortable: true,
                    cell: (row) => (
                        <span title={moment.utc(row.created_date).local().format('DD MMMM YYYY hh:mm:ss A')}>
                            {moment.utc(row.created_date).local().format('DD MMMM YYYY hh:mm:ss A')}
                        </span>
                    ),
                },
                {
                    name: "Updated Date",
                    selector: "updated_date",
                    sortable: true,
                    cell: (row) => (
                        <span title={moment.utc(row.updated_date).local().format('DD MMMM YYYY hh:mm:ss A')}>
                            {moment.utc(row.updated_date).local().format('DD MMMM YYYY hh:mm:ss A')}
                        </span>
                    ),
                },
                {
                    value: "Action",
                    center: true,
                    cell: (row) => (
                        <div className="da-box">
                            <img className="a-dots" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" src="/images/svg-icons/ellipsis.svg" alt="" />
                            <div className="drop-box dropdown-menu">
                                <ul>
                                    <li
                                        className={(
                                            (
                                                process.env.REACT_APP_AD_USER === 'TRUE' &&
                                                (
                                                    this.props.userRole !== 'Admin' &&
                                                    this.props.userRole !== 'Manager' &&
                                                    localStorage.getItem('ssoUser') !== 'true' &&
                                                    sessionStorage.getItem('ssoUser') !== 'true'
                                                )
                                            ) ||
                                            (
                                                this.props?.userRole === "Viewer_Group" ||
                                                this.props?.userRole === "Operator_Group"
                                            ) ||                                           
                                            row.auto_checkPoint === true
                                        )
                                            ? "disabled"
                                            : ""
                                        }
                                        onClick={() =>
                                            (
                                                (
                                                    process.env.REACT_APP_AD_USER === 'TRUE' &&
                                                    (
                                                        this.props.userRole !== 'Admin' &&
                                                        this.props.userRole !== 'Manager' &&
                                                        localStorage.getItem('ssoUser') !== 'true' &&
                                                        sessionStorage.getItem('ssoUser') !== 'true'
                                                    )
                                                ) ||
                                                (
                                                    this.props?.userRole === "Viewer_Group" ||
                                                    this.props?.userRole === "Operator_Group"
                                                ) ||
                                            row.auto_checkPoint === true
                                            )
                                                ? undefined
                                                : this.setState({
                                                    multipleSelect: [row]
                                                }, () => {
                                                    this.setState({
                                                        updateCheck: true,
                                                        checkpointName: row.checkpoint_name,
                                                        selectedUUID: row.checkpoint_uuid
                                                    }, () => $("#exampleModalCheckpoint").modal("show"))
                                                })
                                        }
                                    >
                                        <img
                                            className="delete"
                                            src="/images/svg-icons/edit.svg"
                                            alt=""
                                        /> Update</li>
                                    <li
                                        className={(
                                            (
                                                process.env.REACT_APP_AD_USER === 'TRUE' &&
                                                (
                                                    this.props.userRole !== 'Admin' &&
                                                    this.props.userRole !== 'Manager' &&
                                                    localStorage.getItem('ssoUser') !== 'true' &&
                                                    sessionStorage.getItem('ssoUser') !== 'true'
                                                )
                                            ) ||
                                            (
                                                this.props?.userRole === "Viewer_Group" ||
                                                this.props?.userRole === "Operator_Group"
                                            ) ||
                                            row.auto_checkPoint === true
                                        )
                                            ? "disabled"
                                            : ""
                                        }
                                        onClick={() =>
                                            (
                                                (
                                                    process.env.REACT_APP_AD_USER === 'TRUE' &&
                                                    (
                                                        this.props.userRole !== 'Admin' &&
                                                        this.props.userRole !== 'Manager' &&
                                                        localStorage.getItem('ssoUser') !== 'true' &&
                                                        sessionStorage.getItem('ssoUser') !== 'true'
                                                    )
                                                ) ||
                                                (
                                                    this.props?.userRole === "Viewer_Group" ||
                                                    this.props?.userRole === "Operator_Group"
                                                ) ||
                                                row.auto_checkPoint === true
                                            )
                                                ? undefined
                                                : this.setState({
                                                    multipleSelect: [row]
                                                }, () => {
                                                    this.confirmDelete()
                                                })
                                        }
                                    >
                                        <img
                                            className="delete"
                                            src="/images/svg-icons/delete.svg"
                                            alt=""
                                        /> Delete</li>
                                    <li
                                        className={(
                                            (
                                                process.env.REACT_APP_AD_USER === 'TRUE' &&
                                                (
                                                    this.props.userRole !== 'Admin' &&
                                                    this.props.userRole !== 'Manager' &&
                                                    localStorage.getItem('ssoUser') !== 'true' &&
                                                    sessionStorage.getItem('ssoUser') !== 'true'
                                                )
                                            ) ||
                                            (
                                                this.props?.userRole === "Viewer_Group" ||
                                                this.props?.userRole === "Operator_Group"
                                            ) 
                                        )
                                            ? "disabled"
                                            : ""
                                        }
                                        onClick={() =>
                                            (
                                                (
                                                    process.env.REACT_APP_AD_USER === 'TRUE' &&
                                                    (
                                                        this.props.userRole !== 'Admin' &&
                                                        this.props.userRole !== 'Manager' &&
                                                        localStorage.getItem('ssoUser') !== 'true' &&
                                                        sessionStorage.getItem('ssoUser') !== 'true'
                                                    )
                                                ) ||
                                                (
                                                    this.props?.userRole === "Viewer_Group" ||
                                                    this.props?.userRole === "Operator_Group"
                                                )
                                            )
                                                ? undefined
                                                : this.setState({
                                                    multipleSelect: [row]
                                                }, () => {
                                                    this.confirmRestore()
                                                })
                                        }
                                    >
                                        <img
                                            className="delete"
                                            src="/images/svg-icons/retry.svg"
                                            alt=""
                                        /> Restore</li>
                                </ul>
                            </div>
                        </div>
                    ),
                    allowOverflow: true,
                    button: true,
                    width: "56px",
                },
            ],
        }
    }


    componentDidMount() {
        this.getDeviceCheckpoints();
    }
    UNSAFE_componentWillReceiveProps(nextProps) {       
            if (Object.keys(nextProps && nextProps.history && nextProps.history.location && nextProps.history.location.state &&
                nextProps.history.location.state.data)) {
                this.setState({
                    group_id: nextProps.history.location.state.data.group_id
                }, () => this.getDeviceCheckpoints())
            }
    }
    getDeviceCheckpoints = async () => {
        this.setState({
            showLoader: true,
        });
        let group_id = this.state.group_id;

        await ApiCall(urlMapping.deviceCheckpoints(group_id, true), (response) => {
            if (response?.success) {
                this.setState({
                    showLoader: false,
                    deviceLogData: response?.data,
                })
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showLoader: false,
                        showRefreshModal: true
                    })
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({
                        showLoader: false
                    })
                }
            }
        })
    }

    updateCheckpoint = () => {
        let data = {};
        if (this.state.createError) {
            data = {
                checkpoint_uuid: this.state.selectedUUID,
                is_delete: true,
            };
        } else {
            data = {
                checkpoint_uuid: this.state.selectedUUID,
                checkpoint_name: this.state.checkpointName,
                is_delete: false,
            };
        }
        this.setState({
            showLoader: true,
        });
        ApiCall(urlMapping.createCheckpoint(data), (response) => {
            this.setState({
                showLoader: false,
                show: false,
            });
            if (response?.success) {
                $("#exampleModalCheckpoint").modal("hide");
                this.setState(
                    {
                        title: "Create Checkpoint",
                        message: response?.message,
                        show: true,
                        error: false,
                        delete: false,
                        create: false,
                        createError: false,
                        checkpoint: true,
                    },
                    () => {
                        $("#errorMessage").modal("show");
                        this.getDeviceCheckpoints();
                    }
                );
            } else {
                $("#exampleModalCheckpoint").modal("hide");
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({
                        showRefreshModal: true,
                    });
                } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else {
                    this.setState(
                        {
                            title: "Create Checkpoint",
                            message: response?.message,
                            show: true,
                            error: true,
                            delete: false,
                            create: false,
                            createError: true,
                        },
                        () => $("#errorMessage").modal("show")
                    );
                }
            }
        });
    }

    deleteCheckpoint = () => {
        let uuid = this.state.multipleSelect[0].checkpoint_uuid;

        this.setState({
            showLoader: true,
        });
        ApiCall(urlMapping.deleteCheckpoint(uuid), (response) => {
            $("#errorMessage").modal("hide");
            this.setState({
                showLoader: false,
                show: false
            })
            if (response?.success) {
                this.setState({
                    title: "Delete Checkpoint",
                    message: response?.message,
                    show: true,
                    error: false,
                    delete: false,
                    create: false,
                }, () => {
                    this.getDeviceCheckpoints();
                    $("#errorMessage").modal("show");
                }
                );
            } else {
                $("#errorMessage").modal("hide");
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showRefreshModal: true
                    })
                } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else {
                    this.setState(
                        {
                            title: "Delete Checkpoint",
                            message: response?.message,
                            show: true,
                            error: true,
                            delete: false,
                            create: false
                        },
                        () => $("#errorMessage").modal("show")
                    );
                }
            }
        })
    }
    restoreCheckpoint = () => {
        let data = {
            checkpoint_id: this.state.multipleSelect[0].checkpoint_uuid,
            device_id: this.state.device_id
        }
        this.setState({
            showLoader: true,
        });
        ApiCall(urlMapping.restoreCheckpoint(data), (response) => {
            $("#errorMessage").modal("hide");
            this.setState({
                showLoader: false,
                show: false
            })
            if (response?.success) {
                this.setState({
                    title: "Restore Checkpoint",
                    message: response?.message,
                    show: true,
                    error: false,
                    delete: false,
                    create: false,
                    restore: false
                }, () => {
                    this.getDeviceCheckpoints();
                    $("#errorMessage").modal("show");
                }
                );
            } else {
                $("#errorMessage").modal("hide");
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showRefreshModal: true
                    })
                } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else {
                    this.setState(
                        {
                            title: "Restore Checkpoint",
                            message: response?.message,
                            show: true,
                            error: true,
                            delete: false,
                            create: false,
                            restore: false
                        },
                        () => $("#errorMessage").modal("show")
                    );
                }
            }
        })
    }
    confirmDelete = () => {
        this.setState(
            {
                title: "Delete Checkpoint",
                message: "Are you sure you want to delete checkpoint?",
                show: true,
                error: true,
                delete: true,
                create: false,
                restore: false,
            },
            () => {
                $("#errorMessage").modal("show");
            }
        );
    };
    confirmRestore = () => {
        this.setState(
            {
                title: "Restore Checkpoint",
                message: "Are you sure you want to restore checkpoint?",
                show: true,
                error: false,
                delete: true,
                create: false,
                restore: true
            },
            () => {
                $("#errorMessage").modal("show");
            }
        );
    };

    chooseAction = (deleteAct, createAct, restoreAct) => {
        if (deleteAct && createAct) {
            this.updateCheckpoint();
        }
        else if (this.state.createError) {
            this.updateCheckpoint();
        }
        else if (deleteAct && restoreAct) {
            this.restoreCheckpoint();
        }
        else if (deleteAct) {
            this.deleteCheckpoint();
        }
        else {
            return "undefined";
        }
    }
    resetPopup = () => {
        this.setState({
            show: false,
            error: false,
            delete: false,
            createError: false
        }, () => {
            $("#errorMessage").modal("hide");
        })
    }

    render() {
        return (
            <div>
                {this.state.showLoader && <Loader />}
                <div className="white-bg">
                    <div className="ta-box set-pos mb-0">
                    </div>
                    <div className="no-bg page-height">
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="pills-ma-snapshot" role="tabpanel" aria-labelledby="pills-ma-snapshot-tab">
                                    <div className="white-bg">
                                        <div className="table-box">
                                            <div>

                                            </div>
                                            < DataTable
                                                className="new-device-list"
                                                columns={
                                                    this.state.columns
                                                }
                                                data={this.state.deviceLogData}
                                                highlightOnHover
                                                fixedHeader
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.show &&
                            <ErrorPopup
                                title={this.state.title}
                                message={this.state.message}
                                error={this.state.error}
                                delete={this.state.delete}
                                createError={this.state.createError}
                                reset={() => this.resetPopup()}
                                onSubmit={() => this.chooseAction(this.state.delete, this.state.create, this.state.restore)}
                            />
                        }
                        {(this.state.isAxiosError) &&
                            <ErrorPopup
                                title={this.state.title}
                                message={this.state.message}
                                error={this.state.error}
                                delete={this.state.delete}
                                reset={() => this.setState({ isAxiosError: false })}
                            />
                        }
                    </div>
                    {this.state.showRefreshModal && <ErrorHandlePopup />}
                    {this.state.updateCheck && <div className="modal fade exampleModal" id="exampleModalCheckpoint"
                        tabIndex="-1" aria-labelledby="exampleModalLabel" data-backdrop="static" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Update Checkpoint</h5>
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close">
                                        <span aria-hidden="true"><img src="/images/svg-icons/cancel.svg" alt="" /></span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label>Checkpoint Name</label>
                                        <input
                                            type="text"
                                            maxLength={50}
                                            className="form-control"
                                            name="template_name"
                                            value={this.state.checkpointName}
                                            onChange={(e) => this.setState({
                                                checkpointName: e.target.value,
                                            })} />

                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-light"
                                        data-dismiss="modal"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        disabled={(this.state.checkpointName).trim() === ''}
                                        onClick={() => this.updateCheckpoint()}
                                    >
                                        Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </div >
            </div>
        );

    }
}
const mapStateToProps = (state) => {
    return ({
        authenticated: state.auth.authenticated,
        open: state.displaySidebarMenu.openSide,
        SsoUser: state.auth.SsoUser,
        userRole: String(state.auth.role)
    });
}
export default withRouter(connect(mapStateToProps)(DeviceGroupCheckpoint));

