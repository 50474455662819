/* eslint-disable array-callback-return */
import React, { Component } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import ApiCall from "../../../../redux/API/apiCall";
import urlMapping from "../../../../redux/API/api";
import { ErrorPopup } from "../../../../layOuts/errorPopup";
import * as Common from "../../../../utils/common";
import { Link } from "react-router-dom";
import ErrorHandlePopup from "../../../ErrorHandlePopup";
import debounce from 'lodash/debounce';
import * as Action from "../../../../redux/actionTypes";
import isEqual from 'lodash/isEqual';
class OSPFTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRefreshModal: false,
      showError: false,
      showPassword: false,
      checkPass: false,
      multipleSelect: "",
      toggledClearRows: false,
      selectedProtocol: '',
      show: false,
      openAddTemplate: false,
      selectedTemplateId: this.props.id ? this.props.id : "",
      template_type: this.props?.templateData?.template_type || "",
      template_name: this.props?.templateData?.template_name || "",
      template_desc: this.props?.templateData?.template_desc || "",
      device_model: this.props?.templateData?.device_model || "",
      collection_version: this.props?.templateData?.collection_version || "",
      fw_version: this.props?.templateData?.fw_version || "",
      chimay: this.props.chimay,
      cbr: this.props.cbr,
      mifi: this.props.mifi,
      configId: "",
      selectedOSPF: [],
      applied: false,
      templateData: {
        areaDetails: [],
        redistribution:
          { protocol: "" },
        distanceConfiguration: {
          externalRouteDistance: "110",
          interAreaDistance: "110",
          intraAreaDistance: "110"
        }
      },
      networkViewData: [],
      availableNetworks: [],
      usedNetworks: [],
      selectedNetwork: '',
      isAxiosError: false,
      OSPFData: {
        "id": Math.random().toString(36).substring(2, 11).toUpperCase(),
        "area": "",
        "advancedOptions": "0",
        "helloTimer": "10",
        "deadTimer": "40",
        "priority": "1",
        "cost": "1",
        "retransmitInterval": "5",
        "transmitDelay": "1",
        "authType": "none",
        "keyID": "",
        "password": "",
        "isdeleted": "0",
        "network": "",
        "interface": "",
      },
      availableProtocol: [
        "connected",
        "static",
        "bgp",
        "kernel"
      ],
      columns: [
        {
          name: "Area ID",
          selector: "area",
          cell: (row) => (
            <span
              title={row.area}
              style={{ width: "auto" }}
              className="cursor-pointer"
            >
              <Link
                to={{}}
                className="txt-blue"
                onClick={() => this.openOSPF(row)}
              >
                {" "}
                {row.area}
              </Link>
            </span>
          ),
        },
        {
          name: "Hello Timer ",
          selector: "helloTimer",
          cell: (row) => <span title={row?.helloTimer}>{row?.helloTimer}</span>,
        },
        {
          name: "Dead Timer ",
          selector: "deadTimer",
          cell: (row) => <span title={row?.deadTimer}>{row?.deadTimer}</span>,
        },
        {
          name: "Priority ",
          selector: "priority",
          cell: (row) => <span title={row?.priority}>{row?.priority}</span>,
        },
        {
          name: "Cost ",
          selector: "cost",
          cell: (row) => <span title={row?.cost}>{row?.cost}</span>,
        },
        {
          name: "Retransmit Interval ",
          selector: "retransmitInterval",
          minWidth: '150px',
          cell: (row) => <span title={row?.retransmitInterval}>{row?.retransmitInterval}</span>,
        },
        {
          name: "Transmit Delay ",
          selector: "transmitDelay",
          minWidth: '120px',
          cell: (row) => <span title={row?.transmitDelay}>{row?.transmitDelay}</span>,
        },
        {
          name: "Authentication Type ",
          selector: "authType",
          minWidth: '150px',
          cell: (row) => <span title={row?.authType}>{row?.authType}</span>,
        },
        {
          name: "KeyID ",
          selector: "keyID",
          cell: (row) => <span title={row?.keyID}>{row?.keyID}</span>,
        },
        {
          name: "Password ",
          selector: "password",
          cell: (row) => <span title={row?.password}>{row?.password}</span>,
        },
      ],
    };
  }
  componentDidMount() {
    if (this.props.template_schema_json !== undefined) {
      let { availableProtocol } = this.state;
      this.props.template_schema_json?.redistribution?.protocol?.split(',')?.map((value) => {
        if (value)
          availableProtocol = availableProtocol?.filter(item => item !== value);
      })
      if (this.props.networkViewData) {
        let networks = this.props?.networkViewData?.filter(element => element?.network_zone !== "wan")
        this.props?.template_schema_json?.areaDetails?.map((item) => {
          let newArr = [];
          let newValue = "";
          item?.network !== "" && item?.network?.split(' ')?.map((val) => {
            newArr = networks?.filter(e => e?.name === val)
            if (newArr?.length !== 0)
              newValue = newValue?.length === 0 ? newValue + newArr?.[0]?.name : newValue + " " + newArr?.[0]?.name
            else
              newValue = newValue
          })
          item.network = newValue
        });
      }
      this.setState({
        availableProtocol,
        templateData: JSON.parse(
          JSON.stringify(this.props.template_schema_json)
        )
      });
    }
    if (this.props.view && (this.props.networkViewData || this.props.edit)) {
      if (this.props.networkViewData) {
        let networkViewData = this.props?.networkViewData?.filter(element => element?.network_zone !== "wan");
        networkViewData = networkViewData?.filter(element => !(element?.bridgeStatus === 'Disabled' && element?.interfaceName === ""));
        // LONG-1836 : Removing condition for not including networks with blank ip address in case of device group only
        if (!this.props.group_id) {
          networkViewData = networkViewData?.filter(element => element?.ip_address !== "" && element?.name !== "Control Tunnel"
            && element?.network_zone !== "datatunnel"
          )
        }
        else {
          networkViewData = networkViewData?.filter(element => element?.name !== "Control Tunnel"
            && element?.network_zone !== "datatunnel"
          )
        };
        this.setState({ networkViewData })
      }
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (Object.keys(nextProps.templateData).length !== 0) {
      if (nextProps.templateData !== undefined) {
        this.setState({
          selectedTemplateId: nextProps.id ? nextProps.id : '',
          template_type: nextProps?.templateData?.template_type || "",
          template_name: nextProps?.templateData?.template_name || "",
          template_desc: nextProps?.templateData?.template_desc || "",
          device_model: nextProps?.templateData?.device_model || "",
          collection_version: nextProps?.templateData?.collection_version || "",
          fw_version: nextProps?.templateData?.fw_version || ""
        });
      }
    }
    if (nextProps.networkViewData && nextProps.template_schema_json !== undefined) {
      let { availableProtocol } = this.state;
      nextProps?.template_schema_json?.redistribution?.protocol?.split(',')?.map((value) => {
        if (value)
          availableProtocol = availableProtocol?.filter(item => item !== value);
      })
      let networkViewData = nextProps?.networkViewData?.filter(element => element?.network_zone !== "wan");
      let { availableNetworks, usedNetworks } = this.state;
      networkViewData = networkViewData?.filter(element => !(element?.bridgeStatus === 'Disabled' && element?.interfaceName === ""));
      // LONG-1836 : Removing condition for not including networks with blank ip address
      // networkViewData = networkViewData.filter(element => element.ip_address !== "" && element.name !== "Control Tunnel"
      //   && element.name !== "Data Tunnel"
      if (!nextProps.group_id) {
        networkViewData = networkViewData?.filter(element => element?.ip_address !== "" && element?.name !== "Control Tunnel"
          && element?.network_zone !== "datatunnel"
        )
      }
      else {
        networkViewData = networkViewData?.filter(element => element?.name !== "Control Tunnel"
          && element?.network_zone !== "datatunnel"
        )
      };
      availableNetworks = networkViewData?.map((item) => item?.name);
      nextProps.template_schema_json?.areaDetails?.length > 0 && nextProps?.template_schema_json?.areaDetails?.map((item) => {
        if (item.network !== '' && item.isdeleted !== '1') {
          item?.network?.split(' ')?.map((test) => {
            // LONG-1836 : Removing condition for not including networks with blank ip address
            // if (!usedNetworks.includes(this.getNetwork(test))) {
            //   usedNetworks.push(this.getNetwork(test));
            if (!usedNetworks.includes(test)) {
              usedNetworks?.push(test);
            }
          })
        }
      })
      if (usedNetworks.length > 0) {
        usedNetworks = usedNetworks?.filter(value => value !== '');
      }
      usedNetworks?.length > 0 && usedNetworks?.map((item) => {
        availableNetworks = availableNetworks?.filter(value => value !== item);
      })
      this.setState({
        availableProtocol,
        networkViewData,
        availableNetworks,
        usedNetworks
      })
    }
    if (!isEqual(this.props?.chimay, nextProps?.chimay) ||
      !isEqual(this.props?.cbr, nextProps?.cbr) ||
      !isEqual(this.props?.mifi, nextProps?.mifi)) {
      this.setState({
        chimay: nextProps.chimay,
        cbr: nextProps.cbr,
        mifi: nextProps.mifi
      })
    }
  }
  setDistanceValue = (e) => {
    if (Common.removeError(e)) {
      this.setState({
        showError: true
      })
    }
    const name = e.target.name;
    const { templateData } = this.state;
    templateData.distanceConfiguration[name] = e.target.value;
    this.setState({
      templateData
    })
  }
  setOSPFValue = (e) => {
    if (Common.removeError(e)) {
      this.setState({
        showError: true,
      });
    }
    let data = this.state.OSPFData;
    let name = e.target.name;
    let value = e.target.type === "checkbox" ? (e.target.checked === true ? "1" : "0") : e.target.value;
    data[name] = value;
    if (name === "authType") {
      if (value !== "md5") {
        data["keyID"] = "";
      }
      if (value === "none") {
        data["password"] = "";
      }
    }
    if (value === "simple password" || value === "md5") {
      data.password = "";
      this.setState({ checkPass: true });
    }
    if ((name === "authType" && value === "none") || (name === "password" && value.length > 0)) {
      this.setState({ checkPass: false });
    }
    this.setState({ OSPFData: data });
  };
  addOSPF = () => {
    let result = false;
    const elements = document.getElementsByClassName("error-txt");
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].style.cssText === "display: block;")
        result = true;
    }
    if (result === false) {
      const data = this.state.templateData;
      let found = false;
      data.areaDetails?.map((item, index) => {
        if (item.id === this.state.OSPFData.id) {
          found = true;
          data.areaDetails[index] = this.state.OSPFData;
        }
      });
      if (!found)
        data.areaDetails.push(this.state.OSPFData);
      this.setState(
        {
          templateData: data,
          OSPFData: {
            "id": Math.random().toString(36).substring(2, 11).toUpperCase(),
            "area": "",
            "advancedOptions": "0",
            "helloTimer": "",
            "deadTimer": "",
            "priority": "",
            "cost": "",
            "retransmitInterval": "",
            "transmitDelay": "",
            "authType": "none",
            "keyID": "",
            "password": "",
            "isdeleted": "0",
            "network": "",
            "interface": "",
          }
        },
        () => this.closeOSPF('')
      );
    }
  }
  openOSPF = (data) => {
    if (data) {
      const OSPFData = JSON.parse(JSON.stringify(data));
      this.setState({
        OSPFData,
        openAddTemplate: true,
        update: true,
      })
    }
    else {
      const elements = document.getElementsByClassName("error-txt");
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.cssText = "display: none;"
      }
      this.setState({
        OSPFData: {
          "id": Math.random().toString(36).substring(2, 11).toUpperCase(),
          "area": "",
          "advancedOptions": "0",
          "helloTimer": "10",
          "deadTimer": "40",
          "priority": "1",
          "cost": "",
          "retransmitInterval": "5",
          "transmitDelay": "1",
          "authType": "none",
          "keyID": "",
          "password": "",
          "isdeleted": "0",
          "network": "",
          "interface": "",
        },
        openAddTemplate: true,
      })
    }
  }
  closeOSPF = (value) => {
    if (value !== '') {
      if (this.state.OSPFData.area === '' || this.state.OSPFData.area !== '') {
        const element = document.getElementById(value);
        if (element) {
          element.style.display = 'none';
          this.setState({
            showError: false,
          });
        }
      }
    }
    this.setState({
      openAddTemplate: false,
      update: false,
      OSPFData: {
        "id": Math.random().toString(36).substring(2, 11).toUpperCase(),
        "area": "",
        "advancedOptions": "0",
        "helloTimer": "",
        "deadTimer": "",
        "priority": "",
        "cost": "",
        "retransmitInterval": "",
        "transmitDelay": "",
        "authType": "none",
        "keyID": "",
        "password": "",
        "isdeleted": "0",
        "network": "",
        "interface": ""
      }
    })
  }
  checkRange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (Common.removeError(e)) {
      this.setState({
        showError: true
      })
    }
    if (name !== "cost") {
      this.isRequired(e);
    }
    if (name === "area") {
      if ((parseInt(value) < 0 || parseInt(value) > 4294967295) || (!Common.validateNumeric(e))) {
        const element = document.getElementById("area");
        if (element) {
          element.style.display = "block";
          this.setState({ showError: true })
        }
      }
    }
    else if (name === "helloTimer" || name === "deadTimer" || name === "cost" || name === "transmitDelay") {
      this.checkSpecificRange1(e)
    }
    else if (name === 'retransmitInterval') {
      if ((parseInt(value) < 3 || parseInt(value) > 65535) || (!Common.validateNumeric(e))) {
        const element = document.getElementById(name);
        if (element) {
          element.style.display = "block";
          this.setState({ showError: true })
        }
      }
    }
    else if (name === 'priority' || name === "keyID" || name === "intraAreaDistance" || name === "interAreaDistance"
      || name === "externalRouteDistance") {
      this.checkSpecificRange2(e)
    }
    else if (name === 'password') {
      if (value.length > 16) {
        const element = document.getElementById(name);
        if (element) {
          element.style.display = "block";
          element.innerHTML = "Password length can not be greater than 16."
          this.setState({ showError: true })
        }
      }
    }
    if (name === "deadTimer" && parseInt(value) < parseInt(this.state.OSPFData.helloTimer)) {
      const element = document.getElementById(name);
      if (element) {
        element.style.display = "block";
        element.innerHTML = "DeadTimer value  must be grater than HelloTimer value."
        this.setState({ showError: true })
      }
    }
  }
  checkSpecificRange1 = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    if ((parseInt(value) < 1 || parseInt(value) > 65535) || (!Common.validateNumeric(e))) {
      const element = document.getElementById(name);
      if (element) {
        element.style.display = "block";
        element.innerHTML = 'Value must be between 1 and 65535';
        this.setState({ showError: true })
      }
    }
  }
  checkSpecificRange2 = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    if ((parseInt(value) < 1 || parseInt(value) > 255) || (!Common.validateNumeric(e))) {
      const element = document.getElementById(name);
      if (element) {
        element.style.display = "block";
        this.setState({ showError: true })
      }
    }
  }
  isRequired = (e) => {
    const errorMap = {
      intraAreaDistance: "Intra-Area routes distance must be between 1 and 255",
      interAreaDistance: "Inter-Area routes distance must be between 1 and 255",
      externalRouteDistance:
        "External-Area routes distance must be between 1 and 255",
      area: "Area Id must be between 0 and 4294967295",
      helloTimer: "Hello Timer must be between 1 and 65535",
      deadTimer: "Dead Timer must be between 1 and 65535",
      priority: "Priority must be between 1 and 255",
      cost: "Cost must be between 1 and 65535",
      retransmitInterval: "Retransmit Interval must be between 3 and 65535",
      transmitDelay: "Transmit Delay must be between 1 and 65535",
      keyID: "Key ID must be between 1 and 255",
      password: "Invalid value"
    };
    let val = e.target.value;
    let name = e.target.name;
    const element = document.getElementById(name);
    if (element) {
      if (!val || val.length === 0) {
        element.style.display = "block";
        element.innerHTML = "Value is Required";
        this.setState({ showError: true });
      } else if (element.innerHTML === "Value is Required") {
        element.innerHTML = errorMap[name];
      }
    }
  }
  deleteOSPF = () => {
    let data = this.state.templateData;
    let { availableNetworks, usedNetworks } = this.state;
    if (this.props.edit) {
      this.state.selectedOSPF.map((item) => {
        let found = false;
        this.props?.template_schema_json?.areaDetails?.map((area) => {
          if (item.id === area.id) {
            this.state.templateData.areaDetails.filter(value => value.id === item.id)[0].isdeleted = "1";
            found = true;
          }
          return area;
        })
        if (found === false) {
          data.areaDetails = this.state.templateData?.areaDetails?.filter(finalValue => finalValue?.id !== item?.id);
        }
      })
    } else {
      this.state.selectedOSPF.map((item) => {
        data.areaDetails = this.state.templateData?.areaDetails?.filter(finalValue => finalValue?.id !== item?.id);
      })
    }
    this.state.selectedOSPF?.map((item) => {
      const networks = item?.network?.split(' ');
      networks?.map((value) => {
        // LONG-1836 : Removing condition for not including networks with blank ip address
        if (usedNetworks.includes(value)) {
          usedNetworks = usedNetworks?.filter(item2 => item2 !== value);
          availableNetworks?.push(value);
        } else {
        }
      })
    })
    this.setState({
      templateData: data,
      selectedOSPF: [],
      toggledClearRows: !this.state.toggledClearRows,
      availableNetworks,
      usedNetworks
    })
    this.setState({
      OSPFData: {
        "area": ""
      }
    })
  }
  multipleSelect(rows) {
    if (!rows.selectedRows.length)
      this.setState({ multipleSelect: "" });
    else {
      this.setState({ multipleSelect: rows.selectedRows }
      )
    }
  }
  showPass = () => {
    this.setState({
      showPassword: !this.state.showPassword
    })
  }
  saveData = () => {
    let data = {};
    data.template_name = this.state.template_name;
    data.template_desc = this.state.template_desc;
    data.device_model = this.props?.selectedModelList ? this.props?.selectedModelList?.toString() : this.state.device_model.toString();
    data.template_type = this.state.template_type;
    data.collection_version = this.state.collection_version;
    data.fw_version = this.state.fw_version;
    data.template_order = 7;
    data.template_schema_json = this.state.templateData;
    if (this.state.selectedTemplateId === "") {
      data.template_version = {
        chimay: this.state.chimay,
        cbr: this.state.cbr,
        mifi: this.state.mifi
      }
    }
    this.setState({
      showLoader: true,
    });
    if (this?.props?.configDeviceId && !this?.props?.configAdd) {
      let config_data = {
        ...(!this.props?.configGroup && this.props.configDeviceId && { device_id: this.props.configDeviceId }),
        ...(this.props?.configGroup && { group_id: this.props.configDeviceId }),
        template_name: this.state.template_name,
        template_desc: this.state.template_desc,
        template_type: this.state.template_type,
        template_schema_json: this.state.templateData,
      }
      ApiCall(
        urlMapping.setConfigDetails(config_data),
        (response) => {
          this.setState({
            showLoader: false,
          });
          if (response?.success) {
            if (this.props?.reorderEnable) {
              if (this.props?.isLastReorder) {
                this.setState(
                  {
                    title: "Reorder Configuration",
                    message: "The configurations have been successfully reordered",
                    show: true,
                    error: false,
                  }, () => {
                    $("#errorMessage").modal("show");
                  }
                )
              }
              else {
                this.resetPopup();
              }
            }
            else {
              this.setState(
                {
                  title: "Update Configuration",
                  message: response?.message,
                  show: true,
                  error: false,
                }, () => {
                  $("#errorMessage").modal("show");
                }
              )
            }
          } else {
            if (parseInt(response?.response?.status) === 401) {
              this.setState({ showRefreshModal: true });
            }
            else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
              this.setState({
                title: 'Network Error',
                message: 'Something went wrong. Please try again later.',
                showLoader: false,
                isAxiosError: true,
                delete: false,
                error: true
              }, () => $("#errorMessage").modal("show"));
            }
            else {
              this.setState(
                {
                  title: "Update Configuration",
                  message: response?.message,
                  show: true,
                  error: true,
                }, () => $("#errorMessage").modal("show"));
            }
          }
        }
      );
    }
    else if (this?.props?.configDeviceId && this?.props?.configAdd) {
      let config_data = {
        ...(!this.props?.configGroup && this.props.configDeviceId && { device_id: this.props.configDeviceId }),
        ...(this.props?.configGroup && { group_id: this.props.configDeviceId }),
        template_name: this.state.template_name,
        template_desc: this.state.template_desc,
        device_model: this?.props?.configModels,
        template_type: this.state.template_type,
        collection_version: this.state.collection_version,
        fw_version: this.state.fw_version,
        template_order: 7,
        template_version: {
          chimay: this.state.chimay,
          cbr: this.state.cbr,
          mifi: this.state.mifi
        },
        template_schema_json: this.state.templateData,
      }
      ApiCall(
        urlMapping.setConfigDetails(config_data),
        (response) => {
          this.setState({
            showLoader: false,
          });
          if (response?.success) {
            this.setState(
              {
                title: "New Configuration",
                configId: response?.data,
                message: response?.message,
                show: true,
                error: false,
              }, () => {
                $("#errorMessage").modal("show");
              }
            )
          } else {
            if (parseInt(response?.response?.status) === 401) {
              this.setState({ showRefreshModal: true });
            }
            else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
              this.setState({
                title: 'Network Error',
                message: 'Something went wrong. Please try again later.',
                showLoader: false,
                isAxiosError: true,
                delete: false,
                error: true
              }, () => $("#errorMessage").modal("show"));
            }
            else {
              this.setState(
                {
                  title: "New Configuration",
                  message: response?.message,
                  show: true,
                  error: true,
                }, () => $("#errorMessage").modal("show"));
            }
          }
        }
      );
    }
    else if (this.state.selectedTemplateId !== "") {
      ApiCall(
        urlMapping.updateTemplate(this.state.selectedTemplateId, data),
        (response) => {
          this.setState({
            showLoader: false,
          });
          if (response?.Success) {
            this.props.refreshList();
            this.props.openApplyTemplate(this.state.selectedTemplateId);
            this.props.closeAddTemplate();
          } else {
            if (parseInt(response?.response?.status) === 401) {
              this.setState({ showRefreshModal: true })
            }
            else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
              this.setState({
                title: 'Network Error',
                message: 'Something went wrong. Please try again later.',
                showLoader: false,
                isAxiosError: true,
                delete: false,
                error: true
              }, () => $("#errorMessage").modal("show"))
            }
            else {
              this.setState(
                {
                  title: "Update Template",
                  message: response?.message,
                  show: true,
                  error: true,
                },
                () => $("#errorMessage").modal("show")
              );
            }
          }
        }
      );
    } else {
      ApiCall(urlMapping.createTemplate(data), (response) => {
        this.setState({
          showLoader: false,
        });
        if (response?.success) {
          this.props.refreshList();
          this.props.openApplyTemplate(response?.data?.id);
          this.props.closeAddTemplate();
        } else {
          if (parseInt(response?.response?.status) === 401) {
            this.setState({ showRefreshModal: true })
          }
          else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
            this.setState({
              title: 'Network Error',
              message: 'Something went wrong. Please try again later.',
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          }
          else {
            this.setState(
              {
                title: "Create Template",
                message: response?.message,
                show: true,
                error: true,
              },
              () => $("#errorMessage").modal("show")
            );
          }
        }
      });
    }
  };
  resetPopup = () => {
    this.setState(
      {
        show: false,
        error: false,
      },
      () => {
        $("#errorMessage").modal("hide");
        this.props.refreshList();
        this.props.closeAddTemplate();
        if (this.props?.configApply) {
          this.props.configRedirect(this.state.configId);
        }
      }
    );
  };
  checkError = () => {
    let result = false;
    if (this.props.applying)
      result = true;
    const elements = document.getElementsByClassName("error-txt");
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].style.cssText === "display: block;") {
        result = true;
      }
    }
    if (this.state.applied) {
      result = true;
    }
    if (this.state.templateData.areaDetails.length === 0 ||
      this.state.templateData.areaDetails.filter(item => item.isdeleted === '0').length === 0) {
      result = true;
    }
    if (this.props.apply) {
      this.state.templateData.areaDetails.length > 0 && this.state.templateData.areaDetails.map((item) => {
        if (item.network === '' && item.isdeleted !== '1') {
          result = true;
        }
      }
      )
    }
    if (
      this.state.template_name === "" ||
      this.state.template_type === ""
    ) {
      result = true;
    }
    return result;
  }
  setProtocol = (e) => {
    let { templateData } = this.state;
    let { availableProtocol } = this.state;
    if (e.target.checked) {
      templateData.redistribution.protocol = templateData.redistribution.protocol === '' ?
        e.target.id : templateData.redistribution.protocol + ',' + e.target.id;
      availableProtocol = availableProtocol.filter(item => item !== e.target.id);
    }
    else {
      if (!availableProtocol.includes(e.target.id))
        availableProtocol.push(e.target.id);
      const protocols = templateData.redistribution.protocol?.split(',');
      templateData.redistribution.protocol = protocols.filter(item => item !== e.target.id)?.join(',');
    }
    this.setState({
      templateData,
      availableProtocol,
      selectedProtocol: availableProtocol
    })
  }
  selectedOSPF = (e, ospf) => {
    let { selectedOSPF } = this.state;
    if (e.target.checked) {
      selectedOSPF.push(ospf);
    }
    else {
      selectedOSPF = selectedOSPF.filter(item => item?.id !== ospf?.id);
    }
    this.setState({
      selectedOSPF
    })
  }
  handleChange = (e, givenID, name) => {
    let { templateData, availableNetworks, usedNetworks } = this.state;
    templateData.areaDetails.map((item) => {
      if (e.target.checked) {
        if (item.id === givenID) {
          item.network = item.network === '' ?
            e.target.id : item.network + ' ' + e.target.id;
          availableNetworks = availableNetworks.filter(item => item !== name);
          usedNetworks.push(name);
        }
      } else {
        if (item.id === givenID) {
          if (!availableNetworks.includes(name)) {
            availableNetworks.push(name);
          }
          usedNetworks = usedNetworks.filter(item => item !== name);
          const networks = item.network?.split(' ');
          item.network = networks.filter(item => item !== e.target.id)?.join(' ');
        }
      }
      return item;
    });
    this.setState({
      templateData,
      availableNetworks,
      usedNetworks,
      selectedNetwork: availableNetworks
    })
  }
  // LONG-1836 : Removing condition for not including networks with blank ip address. So these functions are not needed
  // getNetwork = (value) => {
  //   let networkName = '';
  //   if (value !== '') {
  //     this.state.networkViewData.map((network) => {
  //       const ipValue = value.split('/')[0];
  //       const subnet = value.split('/')[1];
  //       if (ipValue === network.ip_address && subnet === network.subnet) {
  //         networkName = network.name;
  //       }
  //     })
  //     return networkName;
  //   }
  //   // return networkName;
  // }
  // getIpAddress = (value) => {
  //   let ipAddress = '';
  //   this.state.networkViewData.map((item) => {
  //     if (item.name === value) {
  //       ipAddress = item.ip_address + "/" + item.subnet;
  //     }
  //   })
  //   return ipAddress;
  // }
  selectAll = (e) => {
    if (e.target.checked) {
      this.setState({
        selectedOSPF: this.state.templateData.areaDetails
      })
    } else {
      this.setState({
        selectedOSPF: []
      })
    }
  }
  applyTemplate = () => {
    const { templateData } = this.state;
    let networks = [];
    this.state.networkViewData?.map((item, index) => {
      networks.push(item);
    });
    templateData.areaDetails?.map((area) => {
      let network = area.network;
      let interfaces = '';
      network?.split(' ')?.map((value) => {
        let networkData;
        // LONG-1836 : Removing condition for not including networks with blank ip address
        // networkData = networks.filter(item => item.ip_address + "/" + item.subnet === value)[0];
        networkData = networks.filter(item => item.name === value)[0];
        if (networkData) {
          if (networkData.bridgeStatus === "Disabled") {
            if (interfaces === '') {
              interfaces = networkData.interfaceName;
            } else {
              interfaces = interfaces + " " + networkData.interfaceName;
            }
          }
          else if (networkData.bridgeStatus === "Enabled") {
            if (interfaces === '') {
              interfaces = "br-" + networkData.networkName;
            } else {
              interfaces = interfaces + " br-" + networkData.networkName;
            }
          }
        }
      })
      area.interface = interfaces;
      return area;
    })
    this.props.dispatch({
      type: Action.SET_APPLY_TEMPLATE,
    });
    this.props.applyTemplate(templateData);
  }
  debounceApply = debounce((fn, event) => {
    fn(event)
  }, 500);
  closeApplyTemplate = () => {
    this.setState(
      {
        templateData: JSON.parse(
          JSON.stringify(this.props.template_schema_json)
        ),
      },
      () => {
        this.closeOSPF();
        this.props.closeApplyTemplate();
      }
    );
  };
  closeOSPF = () => {
    this.setState({
      openAddTemplate: false,
      update: false
    });
  };
  render() {
    return (
      <div>
        <div className="ospf-box">
          <div className="tab-box">
            <div className={this.state.openAddTemplate ? "disabled-slide" : ""}></div>
            <ul className="tab-nav nav nav-pills bg-gray" role="tablist">
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link active"
                  id="area-configuration-tab"
                  data-toggle="tab"
                  href="#area-configuration"
                  role="tab"
                  aria-controls="area-configuration"
                  aria-selected="true"
                >Area Configuration</a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link"
                  id="redistribute-tab"
                  data-toggle="tab"
                  href="#redistribute"
                  role="tab"
                  aria-controls="redistribute"
                  aria-selected="false"
                >Redistribute</a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link"
                  id="dc-tab"
                  data-toggle="tab"
                  href="#dc"
                  role="tab"
                  aria-controls="dc"
                  aria-selected="false"
                >Distance Configuration</a>
              </li>
            </ul>
            <div className="tab-content mt-2">
              <div className="tab-pane active" id="area-configuration" role="tabpanel" aria-labelledby="area-configuration-tab">
                <div className="page-sub-title mt-4">
                  <div className="d-flex align-items-center">
                    <strong>Area</strong>
                    <img
                      src="/images/svg-icons/add.svg"
                      className={(this.props.templateData.default_type !== ''
                        && this.props.templateData.default_type !== null) ? "add-btn ml-2 disabled" : "add-btn ml-2"}
                      alt=""
                      onClick={(this.props.templateData.default_type !== ''
                        && this.props.templateData.default_type !== null) ? undefined : () => this.openOSPF()} />
                  </div>
                  <div className="device-action">
                    <span
                      className={this.state.selectedOSPF.length !== 1 ? "icon-box disabled" : "icon-box"}
                      onClick={this.state.selectedOSPF?.length === 1 ? () => this.openOSPF(this.state?.selectedOSPF?.[0]) : undefined}>
                      <img src="/images/svg-icons/edit.svg" alt="" disabled={this.state.selectedOSPF?.length !== 1} />
                    </span >
                    <span
                      className={(this.props.templateData?.default_type !== ''
                        && this.props.templateData?.default_type !== null) || (this.state.selectedOSPF?.length === 0) ? "icon-box disabled" : "icon-box"}
                      onClick={this.state.selectedOSPF !== "" && (this.props.templateData?.default_type === ''
                        || this.props.templateData?.default_type === null) ? () => this.deleteOSPF() : undefined}>
                      <img
                        src="/images/svg-icons/delete.svg"
                        alt=""
                        disabled={this.state.selectedOSPF?.length === 0} />
                    </span>
                  </div>
                </div>
                {
                  this.state.templateData.areaDetails?.length > 0 &&
                  <table className="table">
                    <thead>
                      <tr>
                        <th>
                          <input
                            type="checkbox"
                            onChange={(e) => { this.selectAll(e) }}
                            checked={this.state.selectedOSPF?.length === this.state.templateData?.areaDetails?.length}
                          />
                        </th>
                        <th>Area ID</th>
                        {this.props.edit && <th>Network <span className="red-txt">*</span></th>}
                        <th>Advance Options</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        // Long-1549 Configaration Delete operation not working in edit and re-apply template
                        this.state.templateData?.areaDetails?.filter(item => item?.isdeleted !== '1')?.map((item, i) => {
                          return (
                            <tr>
                              <td>
                                <input
                                  type="checkbox"
                                  checked={this.state.selectedOSPF?.filter(data => (data?.id === item?.id))?.length > 0}
                                  onClick={(e) => this.selectedOSPF(e, item)} />
                              </td>
                              <td>{<Link to={{}} className="txt-blue" onClick={() => this.openOSPF(item)} >
                                {item.area}</Link>}</td>
                              {this.props.edit &&
                                <td>
                                  <div className="dropdown">
                                    <ul className="chip-list z-index-9 bg-white radius">
                                      {
                                        item?.network === '' ? '' :
                                          item?.network?.split(' ')?.map((item2) => {
                                            // LONG-1836 : Removing condition for not including networks with blank ip address
                                            // return <li > {this.getNetwork(item2)}
                                            return (<li > {item2}
                                              <img
                                                src="/images/svg-icons/cancel.svg"
                                                alt=""
                                                id={item2}
                                                onClick={(e) => this.handleChange(e, item.id, item2)} /></li>)
                                          }
                                          )
                                      }
                                    </ul>
                                    <div className="chip-box add-arrow" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></div>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                      <ul>
                                        {
                                          this.state.availableNetworks?.map((val) => {
                                            return (<li>
                                              <input
                                                type="checkbox"
                                                checked={this.state.selectedNetwork === val}
                                                name="networkname"
                                                // LONG-1836 : Removing condition for not including networks with blank ip address
                                                // id={this.getIpAddress(val)} 
                                                id={val}
                                                onChange={(e) => this.handleChange(e, item.id, val)} />
                                              {val}</li>)
                                          })
                                        }
                                      </ul>
                                    </div>
                                  </div>
                                </td>
                              }
                              <td>
                                <ul className="h-list">
                                  <li>
                                    <span>Hello Timer</span>
                                    <span>{item?.helloTimer}</span>
                                  </li>
                                  <li>
                                    <span>Dead Timer</span>
                                    <span>{item?.deadTimer}</span>
                                  </li>
                                  <li>
                                    <span>Priority</span>
                                    <span>{item?.priority}</span>
                                  </li>
                                  <li>
                                    <span>Cost</span>
                                    <span>{item?.cost}</span>
                                  </li>
                                  <li>
                                    <span>Retransmit Interval</span>
                                    <span>{item?.retransmitInterval}</span>
                                  </li>
                                  <li>
                                    <span>Transmit Delay</span>
                                    <span>{item?.transmitDelay}</span>
                                  </li>
                                  <li>
                                    <span>Authentication Type</span>
                                    <span>{item?.authType}</span>
                                  </li>
                                  <li>
                                    <span>Key ID</span>
                                    <span>{item?.keyID}</span>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                }
              </div>
              <div className="tab-pane" id="redistribute" role="tabpanel" aria-labelledby="redistribute-tab">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Protocol
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Select the protocol from which to redistribute routes into OSPF.">
                          <img src="/images/svg-icons/info.svg" alt="" />
                        </div>
                      </label>
                      <div className="dropdown">
                        <ul className="chip-list z-index-9 bg-white radius">
                          {
                            this.state.templateData?.redistribution?.protocol?.length > 0 &&
                            this.state.templateData?.redistribution?.protocol?.split(',')?.map((protocol) => {
                              return (<li >
                                {Common.getProtocol(protocol)}
                                <img
                                  src="/images/svg-icons/cancel.svg"
                                  alt=""
                                  id={protocol}
                                  onClick={(e) => this.setProtocol(e)} /></li>)
                            })
                          }
                        </ul>
                        <div className="chip-box add-arrow" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></div>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          <ul>
                            {
                              this.state.availableProtocol?.map((protocol) => {
                                return (<li>
                                  <input
                                    type="checkbox"
                                    checked={this.state.selectedProtocol === protocol}
                                    name="protocol"
                                    id={protocol}
                                    onChange={(e) => this.setProtocol(e)}
                                  /> {Common.getProtocol(protocol)}</li>)
                              })
                            }
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane" id="dc" role="tabpanel" aria-labelledby="dc-tab">
                <p className="mt-3"><strong>Basic Configuration</strong></p>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Intra-Area Routes Distance
                        <span className="red-txt">*</span>
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Specify the OSPF route administration distance for routes within an area.">
                          <img src="/images/svg-icons/info.svg" alt="" />
                        </div>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="intraAreaDistance"
                        value={this.state.templateData?.distanceConfiguration?.intraAreaDistance}
                        onBlur={(e) => this.checkRange(e)}
                        onChange={(e) => this.setDistanceValue(e)} />
                      <div className="error-txt" id="intraAreaDistance">
                        Intra-Area routes distance must be between 1 and 255
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Inter-Area Routes Distance
                        <span className="red-txt">*</span>
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Specify the OSPF route administration distance for routes coming from one area into another.">
                          <img src="/images/svg-icons/info.svg" alt="" />
                        </div>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="interAreaDistance"
                        value={this.state.templateData?.distanceConfiguration?.interAreaDistance}
                        onBlur={(e) => this.checkRange(e)}
                        onChange={(e) => this.setDistanceValue(e)} />
                      <div className="error-txt" id="interAreaDistance">
                        Inter-Area routes distance must be between 1 and 255
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>External-Area Routes Distance
                        <span className="red-txt">*</span>
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Specify the OSPF route administration distance for routes learned from other domains.">
                          <img src="/images/svg-icons/info.svg" alt="" />
                        </div>
                      </label>
                      <input type="text" className="form-control"
                        name="externalRouteDistance"
                        value={this.state.templateData?.distanceConfiguration?.externalRouteDistance}
                        onBlur={(e) => this.checkRange(e)}
                        onChange={(e) => this.setDistanceValue(e)} />
                      <div className="error-txt" id="externalRouteDistance">
                        External-Area routes distance must be between 1 and 255
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {<div className="s-footer">
            <button
              disabled={this.props?.disableCancel || false}
              className="btn btn-light"
              onClick={(this.props.apply && this.props.edit) ? () => { this.closeApplyTemplate(); this.setState({ applied: false }) } :
                () => this.props.closeAddTemplate()}>{this.props.apply && !this.props.edit ? 'Back' : 'Cancel'}</button>
            <button className="btn btn-primary"
              disabled={this.checkError()}
              onClick={
                (this.props.apply && !this.props?.configEdit)
                  ? (event) => this.debounceApply(this.applyTemplate, event) :
                  () => this.saveData()
              }
            >
              {
                this.props?.isLastReorder
                  ? "Update"
                  : (this.props?.configApply || this.props?.reorderEnable)
                    ? "Next"
                    : (this.props.apply && !this.props?.configEdit)
                      ? "Apply"
                      : (this?.props?.configAdd || this.props?.configEdit)
                        ? "Save"
                        : (this.state.selectedTemplateId !== "" || this?.props?.configDeviceId)
                          ? "Update"
                          : "Save"
              }
            </button>
          </div>}
        </div>
        <div className={
          (this.props.edit ? this.state.openAddTemplate === true : this.state.openAddTemplate) ? "sidebar-slide  zindex10 w-350 second-open r-757" : "sidebar-slide  zindex10 w-350"}>
          <div className="s-header">
            <h1 className="ss-title">{this.state.update ? "Edit OSPF" : "Add New OSPF"}</h1>
            <button onClick={() => this.closeOSPF('area')} ><img src="/images/svg-icons/s-cancel.svg" alt="" /></button>
          </div>
          <div className="s-body">
            <div className="form-group">
              <label>Area ID
                <span className="red-txt">*</span>
                <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="OSPF Area ID">
                  <img src="/images/svg-icons/info.svg" alt="" />
                </div>
              </label>
              <input
                type="text"
                className="form-control"
                name="area"
                value={this.state.OSPFData?.area}
                onBlur={(e) => this.checkRange(e)}
                onChange={(e) => this.setOSPFValue(e)} />
              <div className="error-txt" id="area">
                Area Id must be between 0 and 4294967295
              </div>
            </div>
            <div className="form-group">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <span className="mr-5">Advance Options
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Advance Options">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </span>
                <label className="switch">
                  <input
                    type="checkbox"
                    name="advancedOptions"
                    onChange={this.setOSPFValue}
                    checked={this.state.OSPFData?.advancedOptions === '1'} />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            {this.state.OSPFData?.advancedOptions === '1' &&
              <div className="gray-bg rounded p-3">
                <div className="form-group">
                  <label>Hello Timer
                    <span className="red-txt">*</span>
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Hello packet interval. Should be same across all devices in the topology">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="helloTimer"
                    value={this.state.OSPFData?.helloTimer}
                    onBlur={(e) => this.checkRange(e)}
                    onChange={(e) => this.setOSPFValue(e)}
                  />
                  <span className="units r-15">Seconds</span>
                  <div className="error-txt" id="helloTimer">
                    Hello Timer must be between 1 and 65535
                  </div>
                </div>
                <div className="form-group">
                  <label>Dead Timer
                    <span className="red-txt">*</span>
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Dead interval. Should be same across all devices in the topology">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="deadTimer"
                    value={this.state.OSPFData?.deadTimer}
                    onBlur={(e) => this.checkRange(e)}
                    onChange={(e) => this.setOSPFValue(e)}
                  />
                  <span className="units r-15">Seconds</span>
                  <div className="error-txt" id="deadTimer">
                    Dead Timer must be between 1 and 65535
                  </div>
                </div>
                <div className="form-group">
                  <label>Priority
                    <span className="red-txt">*</span>
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Set Router priority integer values">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="priority"
                    value={this.state.OSPFData?.priority}
                    onBlur={(e) => this.checkRange(e)}
                    onChange={(e) => this.setOSPFValue(e)}
                  />
                  <div className="error-txt" id="priority">
                    Priority must be between 1 and 255
                  </div>
                </div>
                <div className="form-group">
                  <label>Cost
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Set link cost for the specified interface">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  <input type="text" className="form-control"
                    name="cost"
                    value={this.state.OSPFData?.cost}
                    onBlur={(e) => this.checkRange(e)}
                    onChange={(e) => this.setOSPFValue(e)}
                  />
                  <div className="error-txt" id="cost">
                    Cost must be between 1 and 65535
                  </div>
                </div>
                <div className="form-group">
                  <label>Retransmit Interval
                    <span className="red-txt">*</span>
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Set the number for RxmtInterval timer value">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  <input type="text" className="form-control"
                    name="retransmitInterval"
                    value={this.state.OSPFData?.retransmitInterval}
                    onBlur={(e) => this.checkRange(e)}
                    onChange={(e) => this.setOSPFValue(e)}
                  />
                  <span className="units r-15">Seconds</span>
                  <div className="error-txt" id="retransmitInterval">
                    Retransmit Interval must be between 3 and 65535
                  </div>
                </div>
                <div className="form-group">
                  <label>Transmit Delay
                    <span className="red-txt">*</span>
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Time required to transmit a link state update packet">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="transmitDelay"
                    value={this.state.OSPFData?.transmitDelay}
                    onBlur={(e) => this.checkRange(e)}
                    onChange={(e) => this.setOSPFValue(e)}
                  />
                  <span className="units r-15">Seconds</span>
                  <div className="error-txt" id="transmitDelay">
                    Transmit Delay must be between 1 and 65535
                  </div>
                </div>
                <div className="form-group">
                  <label>Authentication Type
                    <span className="red-txt">*</span>
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Authentication Type">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  <select
                    className="form-control"
                    name="authType"
                    value={this.state.OSPFData?.authType}
                    onChange={(e) => this.setOSPFValue(e)}
                  >
                    <option value={"none"}>None</option>
                    <option value={"simple password"}>Simple Password</option>
                    <option value={"md5"}>MD5</option>
                  </select>
                </div>
                {this.state.OSPFData.authType === "md5" && <div className="form-group">
                  <label>Key ID
                    <span className="red-txt">*</span>
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Secret key used to create message digest">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="keyID"
                    value={this.state.OSPFData?.keyID}
                    onBlur={(e) => this.checkRange(e)}
                    onChange={(e) => this.setOSPFValue(e)}
                    disabled={this.state.OSPFData?.authType === "none" || this.state.OSPFData?.authType === "simple password"}
                  />
                  <div className="error-txt" id="keyID">
                    Key ID must be between 1 and 255
                  </div>
                </div>}
                {this.state.OSPFData.authType !== "none" && <div className="form-group">
                  <label>Password
                    <span className="red-txt">*</span>
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Actual message digest key">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  <input
                    type={this.state.showPassword ? "text" : "password"}
                    className="form-control"
                    name="password"
                    value={this.state.OSPFData?.password}
                    onBlur={(e) => this.checkRange(e)}
                    onChange={(e) => this.setOSPFValue(e)}
                    disabled={this.state.OSPFData?.authType === "none"}
                  />
                  <div className="error-txt" id="password">
                    Invalid value
                  </div>
                  <div className="sh-icon">
                    <img src={this.state.showPassword ? "/images/show.png" : "/images/hide.png"} alt="" onClick={() => this.showPass()} />
                  </div>
                </div>}
              </div>
            }
          </div>
          <div className="s-footer">
            <button className="btn btn-light" onClick={() => this.closeOSPF('area')}>Cancel</button>
            <button className="btn btn-primary" disabled={this.state.OSPFData?.area === "" || this.state?.checkPass || this.props.applying}
              onClick={() => this.addOSPF()}>{this.state.update ? 'Update' : 'Add'}</button>
          </div>
        </div>
        {/* LONG-1634_Issue Fixed for error popup */}
        {(this.state.show) &&
          <ErrorPopup
            title={this.state.title}
            message={this.state.message}
            error={this.state.error}
            reset={() => this.resetPopup()}
          />
        }
        {(this.state.isAxiosError) &&
          <ErrorPopup
            title={this.state.title}
            message={this.state.message}
            error={this.state.error}
            delete={this.state.delete}
            reset={() => this.setState({ isAxiosError: false })}
          />
        }
        {this.state.showRefreshModal && <ErrorHandlePopup />}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    applying: state.displayAddTemplate.applying,
    openStaticTemplate: state.displayAddTemplate.openStaticTemplate,
  };
};
export default connect(mapStateToProps)(OSPFTemplate);
